/* THIS FILE WAS GENERATED, DO NOT MODIFY */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  Email: { input: any; output: any };
  ISODate: { input: any; output: any };
  JSON: { input: any; output: any };
  Phone: { input: any; output: any };
  PowerUnit: { input: any; output: any };
  RentalTimezone: { input: any; output: any };
  SanitizeString: { input: any; output: any };
  TariffPrice: { input: any; output: any };
  Time: { input: any; output: any };
  Timestamp: { input: any; output: any };
  UUID: { input: any; output: any };
  Upload: { input: any; output: any };
  VIN: { input: any; output: any };
  Void: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  accountStatus: AccountStatus;
  accountType?: Maybe<Scalars['String']['output']>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId: Scalars['ID']['output'];
  balance?: Maybe<Scalars['Float']['output']>;
  balanceType?: Maybe<FalconConstraint>;
  billingAddress?: Maybe<Address>;
  cardOrders?: Maybe<CardOrdersWithMeta>;
  cards?: Maybe<CardsWithMeta>;
  contracts?: Maybe<ContractsWithMeta>;
  driivzSyncedAt?: Maybe<Scalars['Date']['output']>;
  drivers?: Maybe<DriversWithMeta>;
  enrolledOn?: Maybe<Scalars['Date']['output']>;
  evcId?: Maybe<Scalars['String']['output']>;
  evcSyncedAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isFcms?: Maybe<Scalars['Boolean']['output']>;
  mailingAddress?: Maybe<Address>;
  optIn?: Maybe<Scalars['Boolean']['output']>;
  optInPromotions?: Maybe<Scalars['Boolean']['output']>;
  partnerSource?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorAccountType?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['Int']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Date']['output']>;
};

export type AccountAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type AccountCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type AccountContractsArgs = {
  input?: InputMaybe<ListContractsInput>;
};

export type AccountDriversArgs = {
  input?: InputMaybe<ListDriversInput>;
};

export type AccountOwner = {
  __typename?: 'AccountOwner';
  account?: Maybe<Account>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId?: Maybe<Scalars['ID']['output']>;
  cards?: Maybe<CardsWithMeta>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  driverStatus?: Maybe<FalconConstraint>;
  driverType?: Maybe<DriverType>;
  email?: Maybe<Scalars['String']['output']>;
  enrolledOn?: Maybe<Scalars['Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notificationPreferences?: Maybe<NotificationPreferencesWithMeta>;
  optInPromotions?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  subscriptionCopy?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorAccountType?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type AccountOwnerAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type AccountOwnerCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type AccountOwnerNotificationPreferencesArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type AccountSearchResult = {
  __typename?: 'AccountSearchResult';
  driivz?: Maybe<Array<Maybe<DriivzAccount>>>;
  falcon?: Maybe<Array<Maybe<Driver>>>;
  firebase?: Maybe<Array<Maybe<FirebaseAccount>>>;
};

export enum AccountStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Closed */
  Closed = 'CLOSED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Registered */
  Registered = 'REGISTERED',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Unknown */
  Unknown = 'UNKNOWN',
  /** UnSuspended */
  Unsuspended = 'UNSUSPENDED',
}

export type AccountVehicle = {
  __typename?: 'AccountVehicle';
  accountId?: Maybe<Scalars['ID']['output']>;
  altId?: Maybe<Scalars['UUID']['output']>;
  autochargeFlag?: Maybe<Scalars['Boolean']['output']>;
  autochargeStatus?: Maybe<FalconConstraint>;
  card?: Maybe<Card>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  driverFirstName?: Maybe<Scalars['String']['output']>;
  driverId: Scalars['ID']['output'];
  driverLastName?: Maybe<Scalars['String']['output']>;
  isRental?: Maybe<Scalars['Boolean']['output']>;
  macAddress?: Maybe<Scalars['SanitizeString']['output']>;
  media?: Maybe<Media>;
  nickName?: Maybe<Scalars['SanitizeString']['output']>;
  rentalReturnTime?: Maybe<Scalars['ISODate']['output']>;
  rentalReturnTimezone?: Maybe<Scalars['RentalTimezone']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['SanitizeString']['output']>;
};

export type AccountVehicleInput = {
  accountVendorId?: InputMaybe<Scalars['ID']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  vehicleYear?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountVehicleVinStatus = {
  __typename?: 'AccountVehicleVinStatus';
  vinInUse: Scalars['Boolean']['output'];
};

export type AccountVehiclesWithMeta = {
  __typename?: 'AccountVehiclesWithMeta';
  edges?: Maybe<Array<Maybe<AccountVehicle>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AccountsWithMeta = {
  __typename?: 'AccountsWithMeta';
  edges?: Maybe<Array<Maybe<Account>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<ListAccountsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ActivateCardForMobileInput = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  visibleCardNumber: Scalars['String']['input'];
};

export type ActivateCardInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['ID']['input'];
  visibleCardNumber: Scalars['String']['input'];
};

export type ActivateCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']['input']>;
  cardIds: Array<Scalars['UUID']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ActiveSessionsTotals = {
  __typename?: 'ActiveSessionsTotals';
  /** Total number of active sessions for the given sites */
  sessionCount: Scalars['Int']['output'];
  /** Total power dispensed for the active sessions for the given sites */
  totalKwh: Scalars['Float']['output'];
};

export type ActiveSessionsTotalsInput = {
  siteIds: Array<Scalars['ID']['input']>;
};

export enum ActivityStatus {
  AvailableToCharge = 'AVAILABLE_TO_CHARGE',
  Charging = 'CHARGING',
  Enroute = 'ENROUTE',
  UnavailableToCharge = 'UNAVAILABLE_TO_CHARGE',
  Unknown = 'UNKNOWN',
}

export enum ActivityStatusLabel {
  Charging = 'CHARGING',
  Done = 'DONE',
  Enroute = 'ENROUTE',
  Idle = 'IDLE',
  InMaintenance = 'IN_MAINTENANCE',
}

export type AdHocContract = {
  __typename?: 'AdHocContract';
  amountUsed?: Maybe<Scalars['Float']['output']>;
  contractId?: Maybe<Scalars['String']['output']>;
  creditBalance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Input for adding chargers to a charger group */
export type AddChargersToChargerGroupInput = {
  /** Charger Group altId */
  altId: Scalars['ID']['input'];
  /** List of charger altIds to add to the charger group */
  chargerIds: Array<Scalars['ID']['input']>;
  /** Option to not wait for downstream tariff integration to vendor/charger. You can check on the status async via the tariff status property. This can be helpful in avoiding timeouts */
  integrateTariffAsync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddFavoriteSiteInput = {
  siteVendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea: Scalars['String']['output'];
  altId: Scalars['ID']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  locality: Scalars['String']['output'];
  longitude?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AggregatedMeterValues = {
  __typename?: 'AggregatedMeterValues';
  powerMeterValuesInWatts?: Maybe<Array<Maybe<PowerMeterValue>>>;
};

export enum AlertType {
  ChargerOffline = 'CHARGER_OFFLINE',
  ConnectorDisconnected = 'CONNECTOR_DISCONNECTED',
  ConnectorFailed = 'CONNECTOR_FAILED',
  ConnectorUnavailable = 'CONNECTOR_UNAVAILABLE',
  MeterTimeout = 'METER_TIMEOUT',
  NetworkUnavailable = 'NETWORK_UNAVAILABLE',
  PeakHoursCharging = 'PEAK_HOURS_CHARGING',
  SessionComplete = 'SESSION_COMPLETE',
  SessionEndedBeforeTargetMet = 'SESSION_ENDED_BEFORE_TARGET_MET',
  SessionFailed = 'SESSION_FAILED',
  SessionStarted = 'SESSION_STARTED',
  SessionStopped = 'SESSION_STOPPED',
  SessionTerminated = 'SESSION_TERMINATED',
  SiteOffline = 'SITE_OFFLINE',
  ThrottleAttemptFailed = 'THROTTLE_ATTEMPT_FAILED',
  ThrottleFinished = 'THROTTLE_FINISHED',
  ThrottleStarted = 'THROTTLE_STARTED',
  ThrottleValidationFailed = 'THROTTLE_VALIDATION_FAILED',
}

/** Mobile Input for reassigning a card to a driver */
export type AssignCardForMobileInput = {
  /** alt-id in falcon for card that is to be reassigned */
  cardId: Scalars['ID']['input'];
  /** alt-id in falcon for driver card is to be assigned to */
  driverId: Scalars['ID']['input'];
};

export type AssignCardInput = {
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  nickName?: InputMaybe<Scalars['String']['input']>;
};

export type AssignCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']['input']>;
  cardIds: Array<Scalars['UUID']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum AuthServiceType {
  ShpHsmWelcome = 'SHP_HSM_WELCOME',
  ShpPasswordReset = 'SHP_PASSWORD_RESET',
  ShpShWelcome = 'SHP_SH_WELCOME',
}

export type AuthenticateUserInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum BalanceType {
  /** non paying */
  NonPaying = 'NON_PAYING',
  /** payment immediately */
  PaymentImmediately = 'PAYMENT_IMMEDIATELY',
  /** post paid */
  PostPaid = 'POST_PAID',
  /** post paid immediately */
  PostPaidImmediately = 'POST_PAID_IMMEDIATELY',
  /** prepaid */
  PrePaid = 'PRE_PAID',
}

export type BillingStatementNotificationInput = {
  /** An Id parameter required to find statements in Driivz */
  invoiceId: Scalars['ID']['input'];
  vendorId: Scalars['ID']['input'];
};

export enum CableCooling {
  /** Air Cooled */
  AirCooled = 'AIR_COOLED',
  /** Liquid Cooled */
  LiquidCooled = 'LIQUID_COOLED',
}

export enum CableLength {
  /** 18 ft */
  EighteenFeet = 'EIGHTEEN_FEET',
  /** 15 ft */
  FifteenFeet = 'FIFTEEN_FEET',
  /** 10 ft */
  TenFeet = 'TEN_FEET',
  /** 12 ft */
  TwelveFeet = 'TWELVE_FEET',
  /** 20 ft */
  TwentyFeet = 'TWENTY_FEET',
  /** 25 ft */
  TwentyFiveFeet = 'TWENTY_FIVE_FEET',
}

export enum CableManufacturer {
  /** Delphi */
  Delphi = 'DELPHI',
  /** Huber + Suhner */
  HuberSuhner = 'HUBER_SUHNER',
  /** ITT */
  Itt = 'ITT',
  /** JAE */
  Jae = 'JAE',
  /** KST */
  Kst = 'KST',
  /** Phoenix Contact */
  PhoenixContact = 'PHOENIX_CONTACT',
  /** REMA */
  Rema = 'REMA',
  /** Sumitomo (Metal Body) */
  SumitomoMetalBody = 'SUMITOMO_METAL_BODY',
  /** Sumitomo (Plastic Body) */
  SumitomoPlasticBody = 'SUMITOMO_PLASTIC_BODY',
  /** Yazaki */
  Yazaki = 'YAZAKI',
  /** Yazaki (Gen 2) */
  YazakiGen_2 = 'YAZAKI_GEN_2',
}

export type CancelAutochargeEnrollmentInput = {
  altId: Scalars['UUID']['input'];
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CancelInviteDriverInput = {
  altId: Scalars['ID']['input'];
};

export type CancelReservationForDriverInput = {
  accountId: Scalars['ID']['input'];
  reservationId: Scalars['ID']['input'];
};

export type CancelReservationInput = {
  reservationId: Scalars['ID']['input'];
};

export type Card = {
  __typename?: 'Card';
  account?: Maybe<Account>;
  activatedAt?: Maybe<Scalars['Date']['output']>;
  altId: Scalars['ID']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  cardStatus?: Maybe<CardStatus>;
  cardType?: Maybe<Scalars['String']['output']>;
  cardTypeId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  driver?: Maybe<Driver>;
  evcId?: Maybe<Scalars['ID']['output']>;
  externalNumber?: Maybe<Scalars['String']['output']>;
  internalNumber?: Maybe<Scalars['String']['output']>;
  nickName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Date']['output']>;
};

export enum CardBrand {
  /** EVgo */
  Evgo = 'EVGO',
  /** Lyft */
  Lyft = 'LYFT',
  /** Maven */
  Maven = 'MAVEN',
}

export type CardOrder = {
  __typename?: 'CardOrder';
  account?: Maybe<CardOrderAccount>;
  altId?: Maybe<Scalars['ID']['output']>;
  driver?: Maybe<Driver>;
  fulfilledAt?: Maybe<Scalars['Date']['output']>;
  orderStatus?: Maybe<CardOrdersStatus>;
  orderedAt?: Maybe<Scalars['Date']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type CardOrderAccount = {
  __typename?: 'CardOrderAccount';
  accountStatus?: Maybe<AccountStatus>;
  altId?: Maybe<Scalars['ID']['output']>;
  balance?: Maybe<Scalars['Int']['output']>;
  billingAddress?: Maybe<Address>;
  enrolledOn?: Maybe<Scalars['Date']['output']>;
  evcId?: Maybe<Scalars['String']['output']>;
  evcSyncedAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mailingAddress?: Maybe<Address>;
  status?: Maybe<AccountStatus>;
};

export type CardOrderNotificationInput = {
  startDate: Scalars['ISODate']['input'];
};

export enum CardOrdersStatus {
  /** Completed */
  Completed = 'COMPLETED',
  /** Ordered */
  Ordered = 'ORDERED',
  /** Sent */
  Sent = 'SENT',
}

export type CardOrdersWithMeta = {
  __typename?: 'CardOrdersWithMeta';
  edges?: Maybe<Array<Maybe<CardOrder>>>;
  filter?: Maybe<ListCardOrdersFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListCardOrdersSearchType>;
  sort?: Maybe<ListCardOrdersSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum CardStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Deactivated */
  Deactivated = 'DEACTIVATED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Inactive */
  Inactive = 'INACTIVE',
  /** Lost */
  Lost = 'LOST',
  /** Reserved */
  Reserved = 'RESERVED',
  /** Stolen */
  Stolen = 'STOLEN',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Unassigned */
  Unassigned = 'UNASSIGNED',
}

export type CardsWithMeta = {
  __typename?: 'CardsWithMeta';
  edges?: Maybe<Array<Maybe<Card>>>;
  filter?: Maybe<ListCardsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListCardsSearchType>;
  sort?: Maybe<ListCardsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Charger = {
  __typename?: 'Charger';
  additionalEquipment?: Maybe<Scalars['String']['output']>;
  additionalSerial?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  authenticationMethodsConstraint?: Maybe<FalconConstraint>;
  authenticationMode?: Maybe<Scalars['String']['output']>;
  authenticationModeId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  brand?: Maybe<Scalars['String']['output']>;
  ccReaderType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  certified?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  chargePointIdentity?: Maybe<Scalars['String']['output']>;
  chargerId?: Maybe<Scalars['ID']['output']>;
  chargerIsManaged?: Maybe<Scalars['Boolean']['output']>;
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']['output']>;
  chargerStatus?: Maybe<Scalars['String']['output']>;
  chargerStatusId?: Maybe<Scalars['Int']['output']>;
  cid?: Maybe<Scalars['String']['output']>;
  coBranding?: Maybe<Scalars['String']['output']>;
  communicationMethod?: Maybe<Scalars['String']['output']>;
  connectionDate?: Maybe<Scalars['String']['output']>;
  connectionUri?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  connectors?: Maybe<Array<Maybe<Connector>>>;
  description?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  displayTariffSynced?: Maybe<Scalars['Boolean']['output']>;
  energyUtilization?: Maybe<Scalars['Float']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  evcSyncedAt?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Cleanup */
  evseId?: Maybe<Scalars['String']['output']>;
  evses?: Maybe<EvsesWithMeta>;
  excludeFromOcpi?: Maybe<Scalars['Boolean']['output']>;
  excluded?: Maybe<Scalars['Boolean']['output']>;
  fieldStationStatus?: Maybe<FieldStationStatus>;
  firmwareVersion?: Maybe<Scalars['SanitizeString']['output']>;
  fundingSource?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  identityKey?: Maybe<Scalars['String']['output']>;
  ignoreStatusNotifications?: Maybe<Scalars['Boolean']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Cleanup */
  infrastructureProviderCompanyId?: Maybe<Scalars['Int']['output']>;
  installDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastReceivedCommSignal?: Maybe<Scalars['Timestamp']['output']>;
  lastReportedFirmwareVersion?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']['output']>;
  localLoadBalancingEnabled?: Maybe<Scalars['Boolean']['output']>;
  localNetworkMaster?: Maybe<Scalars['Boolean']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  maintenanceComments?: Maybe<Scalars['String']['output']>;
  maintenanceVendor?: Maybe<Scalars['String']['output']>;
  maintenanceVendorId?: Maybe<Scalars['Int']['output']>;
  managed?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  maxPower?: Maybe<Scalars['Int']['output']>;
  meterSigningSupported?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  modelNotes?: Maybe<Scalars['String']['output']>;
  modemSerialNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  mountType?: Maybe<Scalars['String']['output']>;
  networkConnectionType?: Maybe<Scalars['String']['output']>;
  networkConnectionTypeId?: Maybe<Scalars['Int']['output']>;
  nextMaintenanceDate?: Maybe<Scalars['Date']['output']>;
  nonNetworked: Scalars['Boolean']['output'];
  physicalLocation?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  ports?: Maybe<PortsWithMeta>;
  /** @deprecated Cleanup */
  ports2?: Maybe<PortsWithMeta>;
  productIdentityKeyId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  publish?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serverSideLocalLoadBalancingEnabled?: Maybe<Scalars['Boolean']['output']>;
  setTariffSynced?: Maybe<Scalars['Boolean']['output']>;
  showInThirdPartyFilters: Scalars['Boolean']['output'];
  simCardImsi?: Maybe<Scalars['String']['output']>;
  simultaneousChargingEnabled: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  smartChargingEnabled: Scalars['Boolean']['output'];
  smiCardIccid?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<TagsWithMeta>;
  tariffMessageCapable?: Maybe<Scalars['Boolean']['output']>;
  tariffSyncStatus?: Maybe<TariffSyncStatus>;
  teslaAdapter: Scalars['Boolean']['output'];
  /** @deprecated Cleanup */
  underEaProgram?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  usageStartDate?: Maybe<Scalars['String']['output']>;
  validateMeterSignature: Scalars['Boolean']['output'];
  vendorId?: Maybe<Scalars['Int']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Timestamp']['output']>;
  warehouse?: Maybe<Warehouse>;
  warrantyExpirationDate?: Maybe<Scalars['Date']['output']>;
  warrantyLength?: Maybe<Scalars['String']['output']>;
  warrantyLengthId?: Maybe<Scalars['Int']['output']>;
  warrantyStartDate?: Maybe<Scalars['Date']['output']>;
  warrantyStatus?: Maybe<Scalars['String']['output']>;
  warrantyStatusId?: Maybe<Scalars['Int']['output']>;
  wirelessConnectionCarrier?: Maybe<Scalars['String']['output']>;
  wirelessConnectionCarrierId?: Maybe<Scalars['Int']['output']>;
};

export type ChargerPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerPorts2Args = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerAggregateMeterValues = {
  __typename?: 'ChargerAggregateMeterValues';
  chargerCapacity?: Maybe<Scalars['Int']['output']>;
  powerMeterValuesInWatts?: Maybe<Array<Maybe<ChargerPowerMeterValue>>>;
};

export type ChargerFilterInput = {
  chargerName?: InputMaybe<TableStringFilterInput>;
  cid?: InputMaybe<TableStringFilterInput>;
};

export type ChargerFilterType = {
  __typename?: 'ChargerFilterType';
  chargerName?: Maybe<TableStringFilterType>;
  cid?: Maybe<TableStringFilterType>;
};

export type ChargerForFleet = {
  __typename?: 'ChargerForFleet';
  chargerAltId: Scalars['ID']['output'];
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']['output']>;
  chargerSerialNumber?: Maybe<Scalars['SanitizeString']['output']>;
  chargingVehicleSoC?: Maybe<Scalars['String']['output']>;
  connector?: Maybe<Connector>;
  connectorIndex?: Maybe<Scalars['Int']['output']>;
  session?: Maybe<Session>;
};

export type ChargerForMobile = {
  __typename?: 'ChargerForMobile';
  altId: Scalars['ID']['output'];
  chargerName?: Maybe<Scalars['String']['output']>;
  chargerStatus?: Maybe<Scalars['String']['output']>;
  chargerType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use simultaneousChargingEnabled instead */
  concurrentCharging?: Maybe<Scalars['Boolean']['output']>;
  evses?: Maybe<Array<Maybe<EvseForMobile>>>;
  lastUsed?: Maybe<Scalars['String']['output']>;
  maxConnectorPower?: Maybe<Scalars['Float']['output']>;
  openFrom?: Maybe<Scalars['String']['output']>;
  openTo?: Maybe<Scalars['String']['output']>;
  plugAndCharge: Scalars['Boolean']['output'];
  reservedByDriver: Scalars['Boolean']['output'];
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean']['output'];
  site?: Maybe<ChargerForMobileSite>;
};

export type ChargerForMobileSite = {
  __typename?: 'ChargerForMobileSite';
  access?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type ChargerGroup = {
  __typename?: 'ChargerGroup';
  altId: Scalars['ID']['output'];
  chargerGroupName: Scalars['String']['output'];
  /** CHARGER_PRICE or CUSTOM_ACCESS_PLAN */
  chargerGroupType: Scalars['String']['output'];
  /** 401 - CHARGER_PRICE, 402 - CUSTOM_ACCESS_PLAN */
  chargerGroupTypeId: Scalars['Int']['output'];
  /** @deprecated use chargersLoaded for perf */
  chargers: ChargersWithMeta;
  chargersLoaded: ChargersV2WithMeta;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Host>;
  hostId?: Maybe<Scalars['ID']['output']>;
  planDuration?: Maybe<Scalars['Int']['output']>;
  planId?: Maybe<Scalars['ID']['output']>;
  planTariffTags?: Maybe<Array<Maybe<PlanTariffTag>>>;
  /** tariffs for plan pricing applied to this charger group */
  planTariffs: Array<Maybe<PlanTariff>>;
  pricingRegionName?: Maybe<Scalars['String']['output']>;
  status: ChargerGroupSyncStatus;
  /** this is the base pricing applied directly to the chargers in the group */
  tariff?: Maybe<ChargerPricing>;
  updatedAt: Scalars['Date']['output'];
};

/** Charger Group sync status with driivz */
export enum ChargerGroupSyncStatus {
  Draft = 'DRAFT',
  NotSynced = 'NOT_SYNCED',
  Synced = 'SYNCED',
}

export enum ChargerGroupsV2Sort {
  ChargerCountAsc = 'CHARGER_COUNT_ASC',
  ChargerCountDesc = 'CHARGER_COUNT_DESC',
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type ChargerGroupsWithMeta = {
  __typename?: 'ChargerGroupsWithMeta';
  edges: Array<ChargerGroup>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ChargerImage = {
  __typename?: 'ChargerImage';
  url?: Maybe<Scalars['SanitizeString']['output']>;
};

export enum ChargerLevel {
  Dcfc = 'DCFC',
  L2 = 'L2',
}

export type ChargerModel = {
  __typename?: 'ChargerModel';
  additionalDetails?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  autoChargeSupported?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  breakerRating?: Maybe<Scalars['Float']['output']>;
  /**
   * chargerLevel is used to group chargers by how fast they can charge
   * @deprecated Changed to connector type based level of charging
   */
  chargerLevel?: Maybe<ChargerLevel>;
  chargerModelEvses?: Maybe<ChargerModelEvsesWithMeta>;
  communicationProtocol?: Maybe<Scalars['String']['output']>;
  currentThd?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  efficiency?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use chargerModelEvses */
  evses?: Maybe<ChargerModelEvsesWithMeta>;
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  ingressProtection?: Maybe<Scalars['String']['output']>;
  inputAcVoltage?: Maybe<InputAcVoltage>;
  interfaceVersion?: Maybe<InterfaceVersion>;
  isoIec?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use manufacturerFalconConstraint { columnValue } */
  manufacturer?: Maybe<Manufacturer>;
  manufacturerFalconConstraint?: Maybe<FalconConstraint>;
  modelName?: Maybe<Scalars['String']['output']>;
  modelNotes?: Maybe<Scalars['String']['output']>;
  modelSku?: Maybe<Scalars['String']['output']>;
  mountType?: Maybe<Scalars['String']['output']>;
  networkCommunication?: Maybe<NetworkCommunication>;
  nfcReader?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  ports?: Maybe<ChargerModelPortsWithMeta>;
  powerFactor?: Maybe<Scalars['Float']['output']>;
  powerOutput?: Maybe<Scalars['PowerUnit']['output']>;
  simultaneousChargeSupported?: Maybe<Scalars['Boolean']['output']>;
  smartEnergyProfileSupport?: Maybe<Scalars['Boolean']['output']>;
  unitType?: Maybe<UnitType>;
  weight?: Maybe<Scalars['String']['output']>;
};

export type ChargerModelPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerModelConnector = {
  __typename?: 'ChargerModelConnector';
  altId?: Maybe<Scalars['ID']['output']>;
  cableCooling?: Maybe<CableCooling>;
  cableLength?: Maybe<CableLength>;
  cableManufacturer?: Maybe<CableManufacturer>;
  chargerConnectorId?: Maybe<Scalars['String']['output']>;
  connectorMaxCurrent?: Maybe<Scalars['Float']['output']>;
  connectorPlacement?: Maybe<ConnectorPlacement>;
  connectorType?: Maybe<FalconConstraint>;
  connectorTypeId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']['output']>;
  evseConnectorId?: Maybe<Scalars['String']['output']>;
  evseConnectorNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  format?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  inMaintenance?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  lastMeterUpdateTime?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  lastMeterValue?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  legacyConnectorId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']['output']>;
  maxConnectorPower?: Maybe<Scalars['Float']['output']>;
  outputAmperes?: Maybe<Scalars['Int']['output']>;
  outputVoltageRange?: Maybe<Scalars['String']['output']>;
  outputVolts?: Maybe<Scalars['Float']['output']>;
  outputWatts?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  port?: Maybe<ChargerModelPort>;
  powerType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  status?: Maybe<ConnectorStatus>;
  /** @deprecated Cleanup */
  teslaConnectorType?: Maybe<TeslaConnectorType>;
  /** @deprecated Cleanup */
  teslaInstallationDate?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Cleanup */
  teslaSerialNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use chargerConnectorId */
  uniqueConnectorId?: Maybe<Scalars['String']['output']>;
};

export type ChargerModelConnectorInput = {
  altId?: InputMaybe<Scalars['SanitizeString']['input']>;
  cableCooling?: InputMaybe<CableCooling>;
  cableLength?: InputMaybe<CableLength>;
  cableManufacturer?: InputMaybe<CableManufacturer>;
  chargerConnectorId?: InputMaybe<Scalars['String']['input']>;
  chargerModelEvseId?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectorMaxCurrent: Scalars['Float']['input'];
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  connectorType?: InputMaybe<VehicleConnectorInput>;
  connectorTypeId?: InputMaybe<Scalars['ID']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evseConnectorId?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['SanitizeString']['input']>;
  identityKey?: InputMaybe<Scalars['Int']['input']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  lastMeterUpdateTime?: InputMaybe<Scalars['SanitizeString']['input']>;
  lastMeterValue?: InputMaybe<Scalars['Int']['input']>;
  legacyConnectorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  maxConnectorPower?: InputMaybe<Scalars['Float']['input']>;
  outputAmperes?: InputMaybe<Scalars['Int']['input']>;
  outputVoltageRange?: InputMaybe<Scalars['SanitizeString']['input']>;
  outputVolts?: InputMaybe<Scalars['Int']['input']>;
  outputWatts?: InputMaybe<Scalars['Int']['input']>;
  portId?: InputMaybe<Scalars['ID']['input']>;
  powerType?: InputMaybe<Scalars['SanitizeString']['input']>;
  status?: InputMaybe<ConnectorStatus>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  teslaInstallationDate?: InputMaybe<Scalars['Date']['input']>;
  teslaSerialNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  uniqueConnectorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  wiredPhase?: InputMaybe<Scalars['Int']['input']>;
};

export type ChargerModelConnectorsWithMeta = {
  __typename?: 'ChargerModelConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelConnector>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargerModelEvse = {
  __typename?: 'ChargerModelEvse';
  altId?: Maybe<Scalars['ID']['output']>;
  chargerModelConnectors?: Maybe<ChargerModelConnectorsWithMeta>;
  /** @deprecated Cleanup */
  connectors?: Maybe<ConnectorsWithMeta>;
  /** @deprecated Cleanup */
  evseId?: Maybe<Scalars['String']['output']>;
  identityKey?: Maybe<Scalars['String']['output']>;
};

export type ChargerModelEvseInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  chargerModelConnectors?: InputMaybe<Array<InputMaybe<ChargerModelConnectorInput>>>;
  connectors?: InputMaybe<Array<InputMaybe<ChargerModelConnectorInput>>>;
  evseId?: InputMaybe<Scalars['String']['input']>;
  identityKey?: InputMaybe<Scalars['String']['input']>;
};

export type ChargerModelEvsesWithMeta = {
  __typename?: 'ChargerModelEvsesWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelEvse>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargerModelPort = {
  __typename?: 'ChargerModelPort';
  accessType?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  chargeBoxIdentity?: Maybe<Scalars['String']['output']>;
  concurrentChargingSupported?: Maybe<Scalars['Boolean']['output']>;
  connectors?: Maybe<ChargerModelConnectorsWithMeta>;
  evcId?: Maybe<Scalars['String']['output']>;
  handicapAccessible?: Maybe<Scalars['Boolean']['output']>;
  lastHeartbeat?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  powerLevel?: Maybe<Scalars['String']['output']>;
  qrCode?: Maybe<Scalars['String']['output']>;
};

export type ChargerModelPortConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type ChargerModelPortsWithMeta = {
  __typename?: 'ChargerModelPortsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelPort>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargerModelsWithMeta = {
  __typename?: 'ChargerModelsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModel>>>;
  filter?: Maybe<ListChargerModelsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListChargerModelsSearchType>;
  sort?: Maybe<ListChargerModelsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargerPowerMeterValue = {
  __typename?: 'ChargerPowerMeterValue';
  chargerLimit?: Maybe<Scalars['Float']['output']>;
  powerMeterValueInWatts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Date']['output']>;
};

export type ChargerPrice = {
  __typename?: 'ChargerPrice';
  chargerType?: Maybe<Scalars['String']['output']>;
  connectorType?: Maybe<Scalars['String']['output']>;
  connectorsPrice?: Maybe<Array<Maybe<Connectors>>>;
  maxSessionFee?: Maybe<Scalars['String']['output']>;
  touPricing?: Maybe<Array<Maybe<TouPrices>>>;
};

export type ChargerPriceConnectorsPriceArgs = {
  input?: InputMaybe<ConnectorPriceInput>;
};

export type ChargerPriceTouPricingArgs = {
  input?: InputMaybe<TouPricingInput>;
};

export type ChargerPricing = {
  __typename?: 'ChargerPricing';
  /** Unique UUID of the Tariff */
  altId: Scalars['ID']['output'];
  chargerGroups?: Maybe<Array<Maybe<ChargerGroup>>>;
  /** Time of use pricing for the Tariff */
  customPricing?: Maybe<Array<TariffCustomPricing>>;
  /** Default pricing for the Tariff */
  defaultPricing?: Maybe<TariffPricing>;
  eventLog?: Maybe<EventLogWithMeta>;
  id?: Maybe<Scalars['ID']['output']>;
  /** Tariff schedule to display for OCPI partners */
  ocpiDescription?: Maybe<Scalars['SanitizeString']['output']>;
  /** Reservation pricing for the Tariff */
  reservationTerm?: Maybe<TariffItemReservationTerm>;
  status?: Maybe<TariffSyncStatus>;
  tariffDescription?: Maybe<Scalars['SanitizeString']['output']>;
  tariffDisplayName?: Maybe<Scalars['SanitizeString']['output']>;
  tariffName: Scalars['SanitizeString']['output'];
  tariffRank?: Maybe<Scalars['Int']['output']>;
};

export type ChargerPricingEventLogArgs = {
  input?: InputMaybe<ListEventLogInput>;
};

export type ChargerUtilizationChart = {
  __typename?: 'ChargerUtilizationChart';
  points: Array<Maybe<ChargerUtilizationPoint>>;
  xLabel: Scalars['String']['output'];
};

export type ChargerUtilizationChartData = {
  __typename?: 'ChargerUtilizationChartData';
  groupedBy: ChargerUtilizationChartGroupEnum;
  totalSiteUtilization: Scalars['Float']['output'];
  utilizationChart: Array<Maybe<ChargerUtilizationChart>>;
};

export enum ChargerUtilizationChartGroupEnum {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
  Year = 'Year',
}

export type ChargerUtilizationChartInput = {
  endDate: Scalars['Date']['input'];
  siteId: Scalars['UUID']['input'];
  startDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};

export type ChargerUtilizationPoint = {
  __typename?: 'ChargerUtilizationPoint';
  name: Scalars['String']['output'];
  utilizationPercentage: Scalars['Float']['output'];
};

export type ChargerUtilizationReport = {
  __typename?: 'ChargerUtilizationReport';
  chargerId: Scalars['String']['output'];
  chargerModel: Scalars['String']['output'];
  siteUtilizationPercentage: Scalars['Float']['output'];
  totalKwh: Scalars['Float']['output'];
  utilizationPercentage: Scalars['Float']['output'];
};

export type ChargerV2 = {
  __typename?: 'ChargerV2';
  additionalEquipment?: Maybe<Scalars['String']['output']>;
  additionalSerial?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  ccReaderType?: Maybe<Scalars['String']['output']>;
  certified?: Maybe<Scalars['Boolean']['output']>;
  chargePointIdentity?: Maybe<Scalars['String']['output']>;
  chargerGroup?: Maybe<ChargerGroup>;
  chargerIsManaged?: Maybe<Scalars['Boolean']['output']>;
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']['output']>;
  chargerStatus?: Maybe<FalconConstraint>;
  cid?: Maybe<Scalars['String']['output']>;
  coBranding?: Maybe<Scalars['String']['output']>;
  communicationMethod?: Maybe<Scalars['String']['output']>;
  connectionDate?: Maybe<Scalars['Date']['output']>;
  connectionUri?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  displayTariffSynced?: Maybe<Scalars['Boolean']['output']>;
  dynamicIp?: Maybe<Scalars['Boolean']['output']>;
  evses?: Maybe<Array<Maybe<EvseV2>>>;
  excludeFromOcpi?: Maybe<Scalars['Boolean']['output']>;
  excluded?: Maybe<Scalars['Boolean']['output']>;
  fieldStationStatus?: Maybe<FieldStationStatus>;
  firstActive?: Maybe<Scalars['Date']['output']>;
  fundingSource?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  identityKey?: Maybe<Scalars['String']['output']>;
  ignoreStatusNotifications?: Maybe<Scalars['Boolean']['output']>;
  installDate?: Maybe<Scalars['Date']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastReceivedCommSignal?: Maybe<Scalars['Date']['output']>;
  lastReportedFirmwareVersion?: Maybe<Scalars['String']['output']>;
  localLoadBalancingEnabled?: Maybe<Scalars['Boolean']['output']>;
  localNetworkMaster?: Maybe<Scalars['Boolean']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  maintenanceComments?: Maybe<Scalars['String']['output']>;
  maxPower?: Maybe<Scalars['Int']['output']>;
  meterSigningSupported?: Maybe<Scalars['Boolean']['output']>;
  modelNotes?: Maybe<Scalars['String']['output']>;
  modemSerialNumber?: Maybe<Scalars['String']['output']>;
  mountType?: Maybe<Scalars['String']['output']>;
  nextMaintenanceDate?: Maybe<Scalars['Date']['output']>;
  nonNetworked?: Maybe<Scalars['Boolean']['output']>;
  productIdentityKeyId?: Maybe<Scalars['Int']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serverSideLocalLoadBalancingEnabled?: Maybe<Scalars['Boolean']['output']>;
  setTariffSynced?: Maybe<Scalars['Boolean']['output']>;
  showInThirdPartyFilters?: Maybe<Scalars['Boolean']['output']>;
  simCardIccid?: Maybe<Scalars['String']['output']>;
  simCardImsi?: Maybe<Scalars['String']['output']>;
  simultaneousChargingEnabled?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<Site>;
  smartChargingEnabled?: Maybe<Scalars['Boolean']['output']>;
  tariffMessageCapable?: Maybe<Scalars['Boolean']['output']>;
  tariffSyncStatus?: Maybe<TariffSyncStatus>;
  teslaAdapter?: Maybe<Scalars['Boolean']['output']>;
  underEaProgram?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  usageStartDate?: Maybe<Scalars['Date']['output']>;
  validateMeterSignature?: Maybe<Scalars['Boolean']['output']>;
  vendorId?: Maybe<Scalars['Int']['output']>;
  warrantyExpirationDate?: Maybe<Scalars['Date']['output']>;
  warrantyStartDate?: Maybe<Scalars['Date']['output']>;
};

export type ChargersConsumptionForExtendData = {
  __typename?: 'ChargersConsumptionForExtendData';
  chargerId: Scalars['String']['output'];
  chargerName: Scalars['String']['output'];
  /** Alt ID of the connector */
  connectorId: Scalars['ID']['output'];
  /** Max power output of the connector in kW */
  connectorMaxOutput: Scalars['Float']['output'];
  /** Status of the connector at the time of the query */
  connectorStatus?: Maybe<Scalars['String']['output']>;
  connectorType?: Maybe<Scalars['String']['output']>;
  evseId: Scalars['String']['output'];
  /** Vendor Id of the last session */
  lastSessionVendorId?: Maybe<Scalars['Int']['output']>;
  /** Flag indicating if the connector is Reservable */
  reservable?: Maybe<Scalars['Boolean']['output']>;
  /** Total sessions in that connection for that datePreset */
  totalSessions: Scalars['Int']['output'];
  /** Total sessions power value dispensed in kWh */
  totalSessionsPower: Scalars['Float']['output'];
  /** Total sessions time in minutes */
  totalSessionsTime: Scalars['String']['output'];
};

export type ChargersForFleetMeta = {
  __typename?: 'ChargersForFleetMeta';
  edges?: Maybe<Array<Maybe<ChargerForFleet>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListChargersForFleetSearchType>;
  sort?: Maybe<ListChargersForFleetSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargersForMobile = {
  __typename?: 'ChargersForMobile';
  altId: Scalars['ID']['output'];
  chargerName?: Maybe<Scalars['String']['output']>;
  chargerStatus?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  openFrom?: Maybe<Scalars['String']['output']>;
  openTo?: Maybe<Scalars['String']['output']>;
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean']['output'];
  site?: Maybe<ChargerForMobileSite>;
};

export type ChargersForMobileInput = {
  /** Required: A float used to calculate the users distance from the site */
  latitude: Scalars['Float']['input'];
  /** Required: A float used to calculate the users distance from the site */
  longitude: Scalars['Float']['input'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ChargersForMobileWithMeta = {
  __typename?: 'ChargersForMobileWithMeta';
  edges?: Maybe<Array<Maybe<ChargersForMobile>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargersV2Sort = {
  chargerGroupName?: InputMaybe<TableSortType>;
  chargerName?: InputMaybe<TableSortType>;
  /**
   * Sort by the highest connector status value, where
   * Charging=1,
   * Available=2,
   * Preparing=3,
   * Finishing=4,
   * Paused=5,
   * Discharging=6,
   * Reserved=7,
   * Unavailable=8,
   * Faulted=9,
   * Unknown=10,
   * and New=11.
   */
  connectorStatusMax?: InputMaybe<TableSortType>;
  /**
   * Sort by the highest connector status value, where
   * New=1,
   * Unknown=2,
   * Faulted=3,
   * Unavailable=4,
   * Reserved=5,
   * Discharging=6,
   * Paused=7,
   * Finishing=8,
   * Preparing=9,
   * Available=10,
   * and Charging=11.
   */
  connectorStatusMin?: InputMaybe<TableSortType>;
  connectorStatusValue?: InputMaybe<TableSortType>;
  modelName?: InputMaybe<TableSortType>;
  siteAddress1?: InputMaybe<TableSortType>;
  siteName?: InputMaybe<TableSortType>;
};

export type ChargersV2WithMeta = {
  __typename?: 'ChargersV2WithMeta';
  edges?: Maybe<Array<Maybe<ChargerV2>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChargersWithMeta = {
  __typename?: 'ChargersWithMeta';
  edges?: Maybe<Array<Maybe<Charger>>>;
  filter?: Maybe<ListChargersFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListChargersSearchType>;
  sort?: Maybe<ListChargersSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum ChargingLevel {
  Dcfc = 'DCFC',
  L2 = 'L2',
}

export enum ChargingProfileStatus {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Error */
  Error = 'ERROR',
  /** Sent */
  Sent = 'SENT',
  /** Validated */
  Validated = 'VALIDATED',
}

export type CheckDriverFirebaseInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CheckDriverPhoneNumberInput = {
  phone: Scalars['SanitizeString']['input'];
};

export type ClientToken = {
  __typename?: 'ClientToken';
  clientToken?: Maybe<Scalars['String']['output']>;
};

export type CompleteAutochargeEnrollmentInput = {
  connectorId: Scalars['ID']['input'];
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

export type Connector = {
  __typename?: 'Connector';
  activePowerInWatts?: Maybe<Scalars['Float']['output']>;
  altId: Scalars['ID']['output'];
  cableCooling?: Maybe<CableCooling>;
  cableLength?: Maybe<CableLength>;
  cableManufacturer?: Maybe<CableManufacturer>;
  chargerConnectorId?: Maybe<Scalars['String']['output']>;
  /** chargingLevel is used to group connectors by how fast they can charge */
  chargingLevel: ChargingLevel;
  /** @deprecated Use chargingProfileStatusConstraint */
  chargingProfileStatus?: Maybe<ChargingProfileStatus>;
  chargingProfileStatusConstraint?: Maybe<FalconConstraint>;
  connectorFormatConstraint?: Maybe<FalconConstraint>;
  connectorIndex?: Maybe<Scalars['Int']['output']>;
  connectorMaxCurrent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use connectorPlacementConstraint */
  connectorPlacement?: Maybe<ConnectorPlacement>;
  connectorPlacementConstraint?: Maybe<FalconConstraint>;
  connectorStatusConstraint?: Maybe<FalconConstraint>;
  connectorType?: Maybe<FalconConstraint>;
  connectorTypeId?: Maybe<Scalars['ID']['output']>;
  energyUtilization?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']['output']>;
  evse?: Maybe<Evse>;
  /** @deprecated Use connectorFormatConstraint */
  format?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  identityKey?: Maybe<Scalars['Int']['output']>;
  inMaintenance?: Maybe<Scalars['Boolean']['output']>;
  isThrottling?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Cleanup */
  lastMeterUpdateTime?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  lastMeterValue?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  legacyConnectorId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']['output']>;
  maxConnectorPower?: Maybe<Scalars['Float']['output']>;
  outputAmperes?: Maybe<Scalars['Int']['output']>;
  outputKw?: Maybe<Scalars['Float']['output']>;
  outputVoltageRange?: Maybe<Scalars['String']['output']>;
  outputVolts?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use outputKw */
  outputWatts?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Cleanup */
  port?: Maybe<Port>;
  powerLimit?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Cleanup */
  powerType?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  requestedPowerLimit?: Maybe<Scalars['Float']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use connectorStatusConstraint */
  status?: Maybe<ConnectorStatus>;
  teslaAdapterInMaintenance?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use teslaConnectorTypeConstraint */
  teslaConnectorType?: Maybe<TeslaConnectorType>;
  teslaConnectorTypeConstraint?: Maybe<FalconConstraint>;
  teslaInstallationDate?: Maybe<Scalars['Date']['output']>;
  teslaSerialNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  type?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use chargerConnectorId */
  uniqueConnectorId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Cleanup */
  vehicleConnectorType?: Maybe<VehicleConnector>;
  /** @deprecated Cleanup */
  vehicleConnectorTypeId?: Maybe<Scalars['ID']['output']>;
  vendorId?: Maybe<Scalars['Int']['output']>;
  wiredPhase?: Maybe<Scalars['Int']['output']>;
};

export type ConnectorFilterInput = {
  connectorTypeId?: InputMaybe<TableIntFilterInput>;
  outputWatts?: InputMaybe<TableIntFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ConnectorFilterType = {
  __typename?: 'ConnectorFilterType';
  connectorTypeId?: Maybe<TableIntFilterType>;
  outputWatts?: Maybe<TableIntFilterType>;
  status?: Maybe<TableStringFilterType>;
};

export type ConnectorForMobile = {
  __typename?: 'ConnectorForMobile';
  altId?: Maybe<Scalars['ID']['output']>;
  connectorName?: Maybe<Scalars['String']['output']>;
  connectorPlacement: ConnectorPlacement;
  connectorStatus?: Maybe<Scalars['String']['output']>;
  connectorType?: Maybe<Scalars['String']['output']>;
  hasTeslaAdapter?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  maxPower?: Maybe<Scalars['Float']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  supportsAutocharge: Scalars['Boolean']['output'];
  vehicleConnectorTypeId?: Maybe<Scalars['Int']['output']>;
};

export type ConnectorInput = {
  altId?: InputMaybe<Scalars['SanitizeString']['input']>;
  cableCooling?: InputMaybe<CableCooling>;
  cableLength?: InputMaybe<CableLength>;
  cableManufacturer?: InputMaybe<CableManufacturer>;
  chargerConnectorId?: InputMaybe<Scalars['String']['input']>;
  chargingProfileStatus?: InputMaybe<ChargingProfileStatus>;
  connectorMaxCurrent?: InputMaybe<Scalars['Float']['input']>;
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  connectorType?: InputMaybe<VehicleConnectorInput>;
  connectorTypeId?: InputMaybe<Scalars['ID']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  format?: InputMaybe<Scalars['SanitizeString']['input']>;
  identityKey?: InputMaybe<Scalars['Int']['input']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  isThrottling?: InputMaybe<Scalars['Boolean']['input']>;
  lastMeterUpdateTime?: InputMaybe<Scalars['SanitizeString']['input']>;
  lastMeterValue?: InputMaybe<Scalars['Int']['input']>;
  legacyConnectorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  maxConnectorPower?: InputMaybe<Scalars['Float']['input']>;
  outputAmperes?: InputMaybe<Scalars['Int']['input']>;
  outputKw?: InputMaybe<Scalars['Float']['input']>;
  outputVoltageRange?: InputMaybe<Scalars['SanitizeString']['input']>;
  outputVolts?: InputMaybe<Scalars['Int']['input']>;
  outputWatts?: InputMaybe<Scalars['Int']['input']>;
  portId?: InputMaybe<Scalars['ID']['input']>;
  powerType?: InputMaybe<Scalars['SanitizeString']['input']>;
  requestedPowerLimit?: InputMaybe<Scalars['Float']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConnectorStatus>;
  teslaAdapterInMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  teslaInstallationDate?: InputMaybe<Scalars['Date']['input']>;
  teslaSerialNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  uniqueConnectorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  wiredPhase?: InputMaybe<Scalars['Int']['input']>;
};

export enum ConnectorPlacement {
  /** Bottom */
  Bottom = 'BOTTOM',
  /** Center */
  Center = 'CENTER',
  /** Left */
  Left = 'LEFT',
  /** Not specified */
  NotSpecified = 'NOT_SPECIFIED',
  /** Other */
  Other = 'OTHER',
  /** Right */
  Right = 'RIGHT',
  /** Top */
  Top = 'TOP',
}

export type ConnectorPriceInput = {
  /** Description of the type which includes DC, L2, Roaming */
  chargerType?: InputMaybe<Scalars['String']['input']>;
  /** All of the connectors available at the charging station */
  connectors?: InputMaybe<Array<InputMaybe<ConnectorsInput>>>;
  /** This is passed to verify if a charger is DC or not in order to search for touPricing */
  isDCCharger?: InputMaybe<Scalars['String']['input']>;
  /** Session fee at the charger */
  maxSessionFee?: InputMaybe<Scalars['String']['input']>;
  /** Used to find Tou pricing */
  planId?: InputMaybe<Scalars['String']['input']>;
  /** Used to find Tou Pricing */
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export enum ConnectorStatus {
  /** Available */
  Available = 'AVAILABLE',
  /** Charging */
  Charging = 'CHARGING',
  /** Discharging */
  Discharging = 'DISCHARGING',
  /** Faulted */
  Faulted = 'FAULTED',
  /** Finishing */
  Finishing = 'FINISHING',
  /** New */
  New = 'NEW',
  /** Paused */
  Paused = 'PAUSED',
  /** Preparing */
  Preparing = 'PREPARING',
  /** Reserved */
  Reserved = 'RESERVED',
  /** Unavailable */
  Unavailable = 'UNAVAILABLE',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type ConnectorV2 = {
  __typename?: 'ConnectorV2';
  altId: Scalars['ID']['output'];
  /** chargingLevel is used to group connectors by how fast they can charge */
  chargingLevel: ChargingLevel;
  connectorIndex?: Maybe<Scalars['Int']['output']>;
  /** Placement of connector on the charger.  Values including LEFT, RIGHT, CENTER, TOP, BOTTOM */
  connectorPlacement?: Maybe<FalconConstraint>;
  connectorStatus?: Maybe<FalconConstraint>;
  connectorType?: Maybe<FalconConstraint>;
  evse?: Maybe<EvseV2>;
  /** @deprecated Use connectorFormatConstraint */
  format?: Maybe<Scalars['String']['output']>;
  identityKey?: Maybe<Scalars['Int']['output']>;
  inMaintenance?: Maybe<Scalars['Boolean']['output']>;
  lastMeterValue?: Maybe<Scalars['Int']['output']>;
  maxConnectorPower?: Maybe<Scalars['Float']['output']>;
  outputAmperes?: Maybe<Scalars['Float']['output']>;
  outputWatts?: Maybe<Scalars['Float']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<FalconConstraint>;
  teslaAdapterInMaintenance?: Maybe<Scalars['Boolean']['output']>;
  teslaConnectorType?: Maybe<FalconConstraint>;
};

export type Connectors = {
  __typename?: 'Connectors';
  /** A string represents the Roaming relationship */
  altText?: Maybe<Scalars['String']['output']>;
  /** A string that represents the fee related to being idle */
  idleFee?: Maybe<Scalars['String']['output']>;
  /** A string that represents the kilowatt hour fee */
  kwhFee?: Maybe<Scalars['String']['output']>;
  /** A string that represents the fee per minute */
  minuteFee?: Maybe<Scalars['String']['output']>;
  /** A string that represents the session Fee related to the connector type */
  sessionFee?: Maybe<Scalars['String']['output']>;
  /** An integer that maps to connectors IDs */
  vehicleConnectorId?: Maybe<Scalars['Int']['output']>;
};

export type ConnectorsInput = {
  /** A string represents the Roaming relationship */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** A string that describes the connector type */
  connectorType?: InputMaybe<Scalars['String']['input']>;
  /** A string that represents the fee related to being idle */
  idleFee?: InputMaybe<Scalars['String']['input']>;
  /** A string that represents the kilowatt hour fee */
  kwhFee?: InputMaybe<Scalars['String']['input']>;
  /** A string that represents the fee per minute */
  minuteFee?: InputMaybe<Scalars['String']['input']>;
  /** A string that represents the session Fee related to the connector type */
  sessionFee?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectorsWithMeta = {
  __typename?: 'ConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<Connector>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  altId?: Maybe<Scalars['ID']['output']>;
  contactName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  workTitle?: Maybe<Scalars['String']['output']>;
};

export type ContactsWithMeta = {
  __typename?: 'ContactsWithMeta';
  edges?: Maybe<Array<Maybe<Contact>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Contract = {
  __typename?: 'Contract';
  account?: Maybe<Account>;
  altId?: Maybe<Scalars['ID']['output']>;
  closureDate?: Maybe<Scalars['Date']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  plan?: Maybe<Plan>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Date']['output']>;
};

export type ContractExpirationInput = {
  monthsFromExp: Scalars['Int']['input'];
};

export type ContractsWithMeta = {
  __typename?: 'ContractsWithMeta';
  edges?: Maybe<Array<Maybe<Contract>>>;
  filter?: Maybe<ListContractsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListContractsSearchType>;
  sort?: Maybe<ListContractsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum CorruptedReason {
  CorruptedByWrongPowerPerHourTransactions = 'CORRUPTED_BY_WRONG_POWER_PER_HOUR_TRANSACTIONS',
  Unknown = 'UNKNOWN',
}

export type Coupon = {
  __typename?: 'Coupon';
  adUrl?: Maybe<Scalars['String']['output']>;
  imgUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateAccountInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  isFcms?: InputMaybe<Scalars['Boolean']['input']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateAccountOwnerFromDriivzInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balance?: InputMaybe<Scalars['Float']['input']>;
  balanceType?: InputMaybe<Scalars['ID']['input']>;
  billingAddress: CreateAddressInput;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  driverType?: InputMaybe<DriverType>;
  email: Scalars['SanitizeString']['input'];
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['SanitizeString']['input'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['SanitizeString']['input'];
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']['input']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  password?: InputMaybe<Scalars['SanitizeString']['input']>;
  phone?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorAccountType?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateAccountOwnerInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balanceType?: InputMaybe<Scalars['ID']['input']>;
  billingAddress: CreateAddressInput;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['SanitizeString']['input'];
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['SanitizeString']['input'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['SanitizeString']['input'];
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']['input']>;
  mailingAddress: CreateAddressInput;
  password?: InputMaybe<Scalars['SanitizeString']['input']>;
  phone?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorAccountType?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateAccountOwnerWithoutPhoneInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAccountVehicleInput = {
  accountId: Scalars['UUID']['input'];
  driverId: Scalars['UUID']['input'];
  isRental?: InputMaybe<Scalars['Boolean']['input']>;
  macAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  media?: InputMaybe<GetMediaInput>;
  nickName?: InputMaybe<Scalars['SanitizeString']['input']>;
  rentalReturnTime?: InputMaybe<Scalars['ISODate']['input']>;
  rentalReturnTimezone?: InputMaybe<Scalars['RentalTimezone']['input']>;
  vehicleId: Scalars['UUID']['input'];
  vin?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateAdHocContractInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Int']['input'];
};

export type CreateAddressInput = {
  address1?: InputMaybe<Scalars['SanitizeString']['input']>;
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateCardInput = {
  brand: Scalars['String']['input'];
  cardTypeId?: InputMaybe<Scalars['Int']['input']>;
  cardVendorId?: InputMaybe<Scalars['ID']['input']>;
  externalNumber: Scalars['String']['input'];
  internalNumber: Scalars['String']['input'];
  nickName?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCardOrderInput = {
  contractId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
};

/** Input for creating a charger group */
export type CreateChargerGroupInput = {
  chargerGroupName: Scalars['SanitizeString']['input'];
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** hostId the charger group can optionally be tagged with */
  hostId?: InputMaybe<Scalars['ID']['input']>;
  /** The pricing region this group belongs to */
  pricingRegionName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

/** Input for creating a charger group plan */
export type CreateChargerGroupPlanInput = {
  chargerGroupName: Scalars['SanitizeString']['input'];
  description: Scalars['SanitizeString']['input'];
  /** hostId the charger group can optionally be tagged with */
  hostId?: InputMaybe<Scalars['ID']['input']>;
  /** The amount of time in months that the plan will be valid */
  planDuration: Scalars['Int']['input'];
};

export type CreateChargerInput = {
  additionalEquipment?: InputMaybe<Scalars['SanitizeString']['input']>;
  additionalSerial?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  authenticationMethodsConstraint?: InputMaybe<Scalars['Int']['input']>;
  authenticationModeId?: InputMaybe<Scalars['Int']['input']>;
  brand?: InputMaybe<Scalars['SanitizeString']['input']>;
  chargePointIdentity?: InputMaybe<Scalars['SanitizeString']['input']>;
  chargerIsManaged?: InputMaybe<Scalars['Boolean']['input']>;
  chargerModelId: Scalars['ID']['input'];
  chargerName: Scalars['SanitizeString']['input'];
  chargerStatusId?: InputMaybe<Scalars['Int']['input']>;
  cid: Scalars['SanitizeString']['input'];
  coBranding?: InputMaybe<Scalars['SanitizeString']['input']>;
  communicationMethod?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectionDate?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectionUri?: InputMaybe<Scalars['SanitizeString']['input']>;
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evseId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evses?: InputMaybe<Array<InputMaybe<EvseInput>>>;
  excludeFromOcpi?: InputMaybe<Scalars['Boolean']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  fieldStationStatus: FieldStationStatus;
  fundingSource?: InputMaybe<Scalars['SanitizeString']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  identityKey?: InputMaybe<Scalars['SanitizeString']['input']>;
  ignoreStatusNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  infrastructureProviderCompanyId?: InputMaybe<Scalars['Int']['input']>;
  ipAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  localLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['ID']['input'];
  locationType: Scalars['SanitizeString']['input'];
  macAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  maintenanceComments?: InputMaybe<Scalars['String']['input']>;
  maintenanceVendorId?: InputMaybe<Scalars['Int']['input']>;
  maxPower?: InputMaybe<Scalars['Int']['input']>;
  meterSigningSupported?: InputMaybe<Scalars['Boolean']['input']>;
  modemSerialNumber?: InputMaybe<Scalars['String']['input']>;
  networkConnectionTypeId?: InputMaybe<Scalars['Int']['input']>;
  nextMaintenanceDate?: InputMaybe<Scalars['Date']['input']>;
  nonNetworked?: InputMaybe<Scalars['Boolean']['input']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  productIdentityKeyId?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['SanitizeString']['input'];
  serverSideLocalLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  showInThirdPartyFilters?: InputMaybe<Scalars['Boolean']['input']>;
  simultaneousChargingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  smartChargingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  usageStartDate?: InputMaybe<Scalars['SanitizeString']['input']>;
  validateMeterSignature?: InputMaybe<Scalars['Boolean']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
  warehouseLocation?: InputMaybe<Scalars['SanitizeString']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
  warrantyLengthId?: InputMaybe<Scalars['Int']['input']>;
  warrantyStartDate?: InputMaybe<Scalars['Date']['input']>;
  warrantyStatusId?: InputMaybe<Scalars['Int']['input']>;
  wirelessConnectionCarrierId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateChargerModelInput = {
  additionalDetails?: InputMaybe<Scalars['SanitizeString']['input']>;
  autoChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
  breakerRating?: InputMaybe<Scalars['Float']['input']>;
  chargerModelEvses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  communicationProtocol?: InputMaybe<Scalars['SanitizeString']['input']>;
  currentThd?: InputMaybe<Scalars['SanitizeString']['input']>;
  dimensions?: InputMaybe<Scalars['SanitizeString']['input']>;
  display?: InputMaybe<Scalars['SanitizeString']['input']>;
  efficiency?: InputMaybe<Scalars['SanitizeString']['input']>;
  evses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  firmwareVersion?: InputMaybe<Scalars['SanitizeString']['input']>;
  ingressProtection?: InputMaybe<Scalars['SanitizeString']['input']>;
  inputAcVoltage?: InputMaybe<InputAcVoltage>;
  interfaceVersion?: InputMaybe<InterfaceVersion>;
  isoIec?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  manufacturer?: InputMaybe<Manufacturer>;
  modelName?: InputMaybe<Scalars['SanitizeString']['input']>;
  modelNotes?: InputMaybe<Scalars['String']['input']>;
  modelSku?: InputMaybe<Scalars['SanitizeString']['input']>;
  mountType?: InputMaybe<Scalars['String']['input']>;
  networkCommunication?: InputMaybe<NetworkCommunication>;
  nfcReader?: InputMaybe<Scalars['Boolean']['input']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  powerFactor?: InputMaybe<Scalars['Float']['input']>;
  powerOutput: Scalars['PowerUnit']['input'];
  simultaneousChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
  smartEnergyProfileSupport?: InputMaybe<Scalars['Boolean']['input']>;
  unitType?: InputMaybe<UnitType>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
  weight?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateContactInput = {
  contactName?: InputMaybe<Scalars['SanitizeString']['input']>;
  email?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['SanitizeString']['input']>;
  workTitle?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateContractInput = {
  accountId: Scalars['ID']['input'];
  closureDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  planId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  statusId: Scalars['ID']['input'];
};

export type CreateCreditForUserInput = {
  /** The amount of credit to grant to the user */
  amount: Scalars['Float']['input'];
  /** The falcon ID of the credit source for which the credit should be created. */
  creditSourceId: Scalars['Int']['input'];
  /** The desired expiration date of the credit (only applies to new credits where the creditSourceId != 0) */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** The account number from the Driivz account (a.k.a. the falcon.accounts.vendor_id) */
  vendorId: Scalars['Int']['input'];
};

export type CreateCreditSourceInput = {
  /** The name of the credit source to be saved into the falcon database. */
  name: Scalars['String']['input'];
};

export type CreateCustomPricingInput = {
  displayDescription1?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayRank?: InputMaybe<Scalars['Int']['input']>;
  maxChargeTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  pricing: CreatePricingInput;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<CreateTariffScheduleInput>;
};

export type CreateDriverInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountVendorId?: InputMaybe<Scalars['ID']['input']>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  legacyPhone?: InputMaybe<Scalars['SanitizeString']['input']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  tokenAndroid?: InputMaybe<Scalars['String']['input']>;
  tokenIos?: InputMaybe<Scalars['String']['input']>;
  vendorDriverStatus?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateExtendHostInput = {
  /** Host ID in the Hosts table of the Extend Host */
  hostId: Scalars['ID']['input'];
  prefix: Scalars['String']['input'];
};

export type CreateExtendUserInput = {
  /** ID stored in firebase for the user */
  firebaseId: Scalars['String']['input'];
  /** IDs of the hosts which are being added to the user */
  hostIds: Array<Scalars['ID']['input']>;
  /** Unique username string */
  username: Scalars['String']['input'];
};

export type CreateFilterInput = {
  criteria?: InputMaybe<Scalars['SanitizeString']['input']>;
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<Scalars['SanitizeString']['input']>;
  values?: InputMaybe<Array<InputMaybe<CreateFilterValueInput>>>;
};

export type CreateFilterValueInput = {
  label?: InputMaybe<Scalars['SanitizeString']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['SanitizeString']['input'];
};

export type CreateFleetUserInput = {
  accountId: Scalars['ID']['input'];
  firebaseId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateHostInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<Array<InputMaybe<CreateContactInput>>>;
  country?: InputMaybe<Scalars['String']['input']>;
  hid?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<InputMaybe<CreatePropertyInput>>>;
  status?: InputMaybe<HostStatus>;
};

export type CreateLogoUploadUrlInput = {
  /** Indicates whether the uploadUrl will be created for a SITE or a HOST */
  bucket: FileUploadBucketEnum;
  /** The name of the file that you intend to upload */
  filename: Scalars['String']['input'];
  /** An optional path under which to store the filename inside the s3 bucket. */
  path?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentCardForMobileInput = {
  cardNumber: Scalars['String']['input'];
  expiryMonth: Scalars['Int']['input'];
  expiryYear: Scalars['Int']['input'];
  nameOnCard: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodType: Scalars['String']['input'];
};

export type CreatePlanInput = {
  badge?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: InputMaybe<Scalars['Int']['input']>;
  cardBrandId?: InputMaybe<Scalars['Int']['input']>;
  /** Value modifier that is an additional component from a base cost to give a new unit cost.  */
  costFactor?: InputMaybe<Scalars['Float']['input']>;
  ctaLabel?: InputMaybe<Scalars['SanitizeString']['input']>;
  defaultAccountPlan?: InputMaybe<Scalars['Boolean']['input']>;
  detailHeading?: InputMaybe<Scalars['SanitizeString']['input']>;
  detailSections?: InputMaybe<Array<InputMaybe<DetailSectionInput>>>;
  detailSessionTimes?: InputMaybe<Array<InputMaybe<DetailSessionTimeInput>>>;
  displayPlanCard?: InputMaybe<Scalars['Boolean']['input']>;
  displayToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  driverRank?: InputMaybe<Scalars['Int']['input']>;
  elements?: InputMaybe<Array<InputMaybe<ElementInput>>>;
  enrollmentClose?: InputMaybe<Scalars['Date']['input']>;
  enrollmentOpen?: InputMaybe<Scalars['Date']['input']>;
  financeCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  intervalLength: Scalars['Int']['input'];
  intervalUnitId: Scalars['Int']['input'];
  isAutoEnrolledInRewards?: InputMaybe<Scalars['Boolean']['input']>;
  legalese?: InputMaybe<Scalars['String']['input']>;
  planCode: Scalars['SanitizeString']['input'];
  planDescription: Scalars['SanitizeString']['input'];
  planDiscount?: InputMaybe<Scalars['Float']['input']>;
  planDisplayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  planDuration?: InputMaybe<Scalars['Int']['input']>;
  planName: Scalars['SanitizeString']['input'];
  planStatusId?: InputMaybe<Scalars['Int']['input']>;
  planSubheading?: InputMaybe<Scalars['SanitizeString']['input']>;
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  rolloverPlanId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
  terminationBehaviorId?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vinRequiredFlag?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePlanTariffInput = {
  /** Charger group altIds tariff will apply to.  Only groups with a type of CHARGER_GROUP are permitted */
  chargerGroupIds: Array<Scalars['ID']['input']>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<CreateCustomPricingInput>>;
  /** Reservation pricing for the Tariff */
  reservationTerm?: InputMaybe<TariffItemReservationTermInput>;
};

export type CreatePlanTariffsInput = {
  /** ID for custom plan.  Only groups with a type of CUSTOM_ACCESS_PLAN are permitted */
  chargerGroupId: Scalars['ID']['input'];
  planTariffs: Array<CreatePlanTariffInput>;
};

export type CreatePricingInput = {
  perKwh?: InputMaybe<Scalars['TariffPrice']['input']>;
  perMinute?: InputMaybe<Scalars['TariffPrice']['input']>;
  perSession?: InputMaybe<Scalars['TariffPrice']['input']>;
};

export type CreatePropertyInput = {
  address1?: InputMaybe<Scalars['SanitizeString']['input']>;
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostId?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['ID']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  pid?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  propertyName?: InputMaybe<Scalars['SanitizeString']['input']>;
  status?: InputMaybe<PropertyStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateReservationForDriverInput = {
  accountId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateReservationInput = {
  connectorId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateSessionInput = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  accountVendorId: Scalars['ID']['input'];
  cardVendorId?: InputMaybe<Scalars['ID']['input']>;
  connectorVendorId: Scalars['ID']['input'];
  corrupted?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  isSessionEnd?: InputMaybe<Scalars['Boolean']['input']>;
  meterEnd?: InputMaybe<Scalars['Int']['input']>;
  meterStart?: InputMaybe<Scalars['Int']['input']>;
  planVendorId?: InputMaybe<Scalars['ID']['input']>;
  startSource: Scalars['String']['input'];
  startTime: Scalars['Date']['input'];
  stopSource?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['ID']['input'];
};

export type CreateSiteImageInput = {
  media?: InputMaybe<GetMediaInput>;
  mediaOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSiteInput = {
  access?: InputMaybe<SiteAccess>;
  accessNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  address1: Scalars['SanitizeString']['input'];
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea: Scalars['SanitizeString']['input'];
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  demandLimitManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['SanitizeString']['input'];
  directions?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostId?: InputMaybe<Scalars['SanitizeString']['input']>;
  hoursOfOperation?: InputMaybe<HoursInput>;
  latitude: Scalars['Float']['input'];
  legacyId?: InputMaybe<Scalars['ID']['input']>;
  locality: Scalars['SanitizeString']['input'];
  locationMarket?: InputMaybe<SiteMarket>;
  locationNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  locationTypeId?: InputMaybe<Scalars['Int']['input']>;
  longitude: Scalars['Float']['input'];
  maxCurrent?: InputMaybe<Scalars['SanitizeString']['input']>;
  meterNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode: Scalars['SanitizeString']['input'];
  property: CreatePropertyInput;
  propertyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  ratedVoltage?: InputMaybe<Scalars['SanitizeString']['input']>;
  retailDemandLimit?: InputMaybe<Scalars['Float']['input']>;
  retailEnergyManagementSafeMode?: InputMaybe<Scalars['Boolean']['input']>;
  sid: Scalars['SanitizeString']['input'];
  siteAccessId?: InputMaybe<Scalars['Int']['input']>;
  siteImages?: InputMaybe<Array<InputMaybe<CreateSiteImageInput>>>;
  siteName: Scalars['SanitizeString']['input'];
  siteStatusId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SiteType>;
  utilityCompany?: InputMaybe<SiteUtilityCompany>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTagInput = {
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  filters?: InputMaybe<Array<InputMaybe<CreateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type CreateTariffConnectorTypeInput = {
  connectorTypeId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
};

export type CreateTariffInput = {
  /** list of chargerGroupIds that will be associated with the Tariff */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<InputMaybe<CreateCustomPricingInput>>>;
  defaultPricing?: InputMaybe<DefaultPricingInput>;
  /** display name for the Tariff for HMI display */
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Option to not wait for downstream integration to vendor/charger. You can check on the status async via the tariff status property. This can be helpful in avoiding timeouts */
  integrateAsync?: InputMaybe<Scalars['Boolean']['input']>;
  /** name for the Tariff */
  name?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Tariff schedule to display for OCPI partners */
  ocpiDescription?: InputMaybe<Scalars['SanitizeString']['input']>;
  reservationTerm?: InputMaybe<TariffItemReservationTermInput>;
};

export type CreateTariffScheduleInput = {
  /** Days schedule applies to unabbreviated */
  daysOfWeek: Array<Day>;
  /** End time for schedule as HH:MM */
  endTime: Scalars['String']['input'];
  /** Start time for schedule as HH:MM */
  startTime: Scalars['String']['input'];
};

export type CreateUploadsInput = {
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  userRole?: InputMaybe<UserRole>;
  username: Scalars['String']['input'];
};

export type CreateVehicleInput = {
  connectorRefArray: Array<Scalars['Int']['input']>;
  makeRefId: Scalars['Int']['input'];
  modelRefId: Scalars['Int']['input'];
  modelYear: Scalars['Int']['input'];
  /** Compatible with us as Charge Point Operator for Plug and Charge */
  plugAndChargeCpoCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Supports Plug and Charge technology */
  plugAndChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateVehicleMakeInput = {
  manufacturer: Scalars['String']['input'];
  vendorValue?: InputMaybe<Scalars['String']['input']>;
  vpicId?: InputMaybe<Scalars['Int']['input']>;
  vpicValue?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVehicleModelInput = {
  makeRefId: Scalars['Int']['input'];
  modelName: Scalars['String']['input'];
  vendorValue?: InputMaybe<Scalars['String']['input']>;
  vpicId?: InputMaybe<Scalars['Int']['input']>;
  vpicValue?: InputMaybe<Scalars['String']['input']>;
};

export type Credit = {
  __typename?: 'Credit';
  allocatedCredit?: Maybe<Scalars['Float']['output']>;
  expiresOn?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  remainingCredit?: Maybe<Scalars['Float']['output']>;
  usedCredit?: Maybe<Scalars['Float']['output']>;
};

export type CreditBucket = {
  __typename?: 'CreditBucket';
  /** The ID of the falcon.credit_bucket that was created. */
  id?: Maybe<Scalars['ID']['output']>;
};

export type CreditCardTransaction = {
  __typename?: 'CreditCardTransaction';
  altId: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  ccLast4Digits?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  creditCardTransactionItems: Array<CreditCardTransactionItem>;
  currency?: Maybe<Scalars['String']['output']>;
  debit: Scalars['Boolean']['output'];
  maxPower?: Maybe<Scalars['String']['output']>;
  paymentTxId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  powerLoss?: Maybe<Scalars['Boolean']['output']>;
  session?: Maybe<SessionV2>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  settlement?: Maybe<Scalars['String']['output']>;
  settlementTime?: Maybe<Scalars['Date']['output']>;
  tax: Scalars['Float']['output'];
};

export type CreditCardTransactionItem = {
  __typename?: 'CreditCardTransactionItem';
  altId: Scalars['ID']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  creditCardTransactionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  productTypeId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum CreditExpPolicy {
  /** Carry Over */
  CarryOver = 'CARRY_OVER',
  /** Expires */
  Expires = 'EXPIRES',
}

export type CreditSource = {
  __typename?: 'CreditSource';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreditsWithMeta = {
  __typename?: 'CreditsWithMeta';
  edges?: Maybe<Array<Maybe<Credit>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CurrentUserResponse = {
  __typename?: 'CurrentUserResponse';
  driver?: Maybe<Driver>;
  user?: Maybe<User>;
};

export type CustomHourInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  timeslots?: InputMaybe<Array<InputMaybe<TimeSlotInput>>>;
};

export type CustomHourType = {
  __typename?: 'CustomHourType';
  closed?: Maybe<Scalars['Boolean']['output']>;
  timeslots?: Maybe<Array<Maybe<TimeSlotType>>>;
};

export type CustomPricingInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  displayDescription1?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayRank?: InputMaybe<Scalars['Int']['input']>;
  maxChargeTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  pricing: PricingInput;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<TariffScheduleInput>;
};

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeactivateCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']['input']>;
  cardIds: Array<Scalars['UUID']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input for deactivating a charger group plan */
export type DeactivateChargerGroupPlanForExtendInput = {
  /** Charger group plan altId */
  altId: Scalars['ID']['input'];
};

export type DefaultPricingInput = {
  displayDescription1?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayRank?: InputMaybe<Scalars['Int']['input']>;
  pricing?: InputMaybe<CreatePricingInput>;
};

export type DefaultPricingInputV2 = {
  displayDescription1?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayRank?: InputMaybe<Scalars['Int']['input']>;
  pricing?: InputMaybe<PricingInput>;
};

export type DeleteAccountVehicleInput = {
  altId: Scalars['UUID']['input'];
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input for deleting a charger group */
export type DeleteChargerGroupInput = {
  /** Charger group altId */
  altId: Scalars['ID']['input'];
};

export type DeleteChargerInput = {
  altId: Scalars['ID']['input'];
};

export type DeleteDriverInput = {
  altId: Scalars['ID']['input'];
};

export type DeleteExtendHostInput = {
  /** Unique ID of the Extend Host */
  altId: Scalars['ID']['input'];
};

export type DeleteExtendUserHostsInput = {
  /** Unique UUID of the user */
  altId: Scalars['ID']['input'];
  /** IDs of the hosts which are being removed from the user */
  hostIds: Array<Scalars['ID']['input']>;
};

export type DeleteHostInput = {
  altId: Scalars['ID']['input'];
};

export type DeletePaymentMethodForMobileInput = {
  altId: Scalars['ID']['input'];
};

export type DeletePaymentMethodInput = {
  vendorId: Scalars['ID']['input'];
};

export type DeleteTagInput = {
  altId: Scalars['ID']['input'];
};

export type DeleteTariffInput = {
  altId: Scalars['ID']['input'];
};

export type DeleteVehicleIdCardInput = {
  /** Alt ID of the card */
  altId: Scalars['ID']['input'];
};

export type DeleteVehicleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteVehicleMakeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteVehicleModelInput = {
  id: Scalars['ID']['input'];
};

export type DetailSection = {
  __typename?: 'DetailSection';
  header?: Maybe<Scalars['SanitizeString']['output']>;
  items?: Maybe<Array<Maybe<Scalars['SanitizeString']['output']>>>;
};

export type DetailSectionInput = {
  header?: InputMaybe<Scalars['SanitizeString']['input']>;
  items?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']['input']>>>;
};

export type DetailSessionTime = {
  __typename?: 'DetailSessionTime';
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['Time']['output']>;
  interval?: Maybe<Scalars['SanitizeString']['output']>;
  startTime?: Maybe<Scalars['Time']['output']>;
};

export type DetailSessionTimeInput = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['Time']['input']>;
  interval?: InputMaybe<Scalars['SanitizeString']['input']>;
  startTime?: InputMaybe<Scalars['Time']['input']>;
};

export type DisassociateAccountVehicleInput = {
  altId: Scalars['UUID']['input'];
};

export type DriivzAccount = {
  __typename?: 'DriivzAccount';
  accountStatus?: Maybe<Scalars['String']['output']>;
  accountVendorId?: Maybe<Scalars['Int']['output']>;
  balanceType?: Maybe<Scalars['String']['output']>;
  billingAddress1?: Maybe<Scalars['String']['output']>;
  billingAddress2?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingLocality?: Maybe<Scalars['String']['output']>;
  billingPostalCode?: Maybe<Scalars['String']['output']>;
  billingRegion?: Maybe<Scalars['String']['output']>;
  cardExternalNumber?: Maybe<Scalars['String']['output']>;
  cardInternalNumber?: Maybe<Scalars['String']['output']>;
  cardVendorId?: Maybe<Scalars['Int']['output']>;
  driverType?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingLocality?: Maybe<Scalars['String']['output']>;
  shippingPostalCode?: Maybe<Scalars['String']['output']>;
  vendorAccountType?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['Int']['output']>;
};

export type DriivzEntityInput = {
  activation_date?: InputMaybe<Scalars['String']['input']>;
  billing_plan_id?: InputMaybe<Scalars['ID']['input']>;
  contract_id?: InputMaybe<Scalars['ID']['input']>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  external_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  paying_entity_customer_id?: InputMaybe<Scalars['Int']['input']>;
  stopped_on?: InputMaybe<Scalars['String']['input']>;
};

export type DriivzTagInput = {
  vendorId: Scalars['ID']['input'];
};

export type Driver = {
  __typename?: 'Driver';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId?: Maybe<Scalars['ID']['output']>;
  cards?: Maybe<CardsWithMeta>;
  /** contactId is used to track the contact with the emarsys sdk */
  contactId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  driverStatus?: Maybe<FalconConstraint>;
  driverType?: Maybe<DriverType>;
  email?: Maybe<Scalars['String']['output']>;
  firebaseId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasPendingAutochargeEnrollment: Scalars['Boolean']['output'];
  hasValidPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inGoodStanding?: Maybe<InGoodStanding>;
  invited?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  legacyPhone?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<DriverMetrics>;
  notificationPreferences?: Maybe<NotificationPreferencesWithMeta>;
  /** notification preferences for operator portal */
  notificationPreferencesForOp?: Maybe<NotificationPreferencesWithMeta>;
  optInPromotions?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['Phone']['output']>;
  tokenAndroid?: Maybe<Scalars['String']['output']>;
  tokenIos?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Date']['output']>;
};

export type DriverAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type DriverCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type DriverNotificationPreferencesArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type DriverNotificationPreferencesForOpArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type DriverFavoriteSite = {
  __typename?: 'DriverFavoriteSite';
  altId: Scalars['UUID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  driverId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  siteId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DriverMetrics = {
  __typename?: 'DriverMetrics';
  CO2Offset?: Maybe<Scalars['Float']['output']>;
  DCFCSessions?: Maybe<Scalars['Int']['output']>;
  EVMilesPowered?: Maybe<Scalars['Float']['output']>;
  GHGAvoided?: Maybe<Scalars['Float']['output']>;
  L2Sessions?: Maybe<Scalars['Int']['output']>;
  favoriteCharger?: Maybe<Scalars['String']['output']>;
  kWh?: Maybe<Scalars['Float']['output']>;
  totalSessions?: Maybe<Scalars['Int']['output']>;
};

export type DriverRankInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  driverRank?: InputMaybe<Scalars['Int']['input']>;
};

export type DriverRankUpdateResponse = {
  __typename?: 'DriverRankUpdateResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum DriverType {
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export type DriversWithMeta = {
  __typename?: 'DriversWithMeta';
  edges?: Maybe<Array<Maybe<Driver>>>;
  filter?: Maybe<ListDriversFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListDriversSearchType>;
  sort?: Maybe<ListDriversSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ElectricVehicle = {
  __typename?: 'ElectricVehicle';
  altId?: Maybe<Scalars['ID']['output']>;
  make?: Maybe<VehicleMake>;
  model?: Maybe<VehicleModel>;
  year?: Maybe<Scalars['String']['output']>;
};

export type ElectricVehiclesWithMeta = {
  __typename?: 'ElectricVehiclesWithMeta';
  edges?: Maybe<Array<Maybe<ElectricVehicle>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Element = {
  __typename?: 'Element';
  altId?: Maybe<Scalars['ID']['output']>;
  connectorType?: Maybe<VehicleConnector>;
  day?: Maybe<Day>;
  discount?: Maybe<Scalars['Float']['output']>;
  endTime?: Maybe<Scalars['Time']['output']>;
  maxDuration?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['Time']['output']>;
};

export type ElementInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  connectorTypeId: Scalars['ID']['input'];
  day: Day;
  discount: Scalars['Float']['input'];
  endTime: Scalars['Time']['input'];
  maxDuration?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Time']['input'];
};

export type ElementsWithMeta = {
  __typename?: 'ElementsWithMeta';
  edges?: Maybe<Array<Maybe<Element>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum EmailProvider {
  Emarsys = 'EMARSYS',
  Mandrill = 'MANDRILL',
}

export type EmailRecipient = {
  emailAddress: Scalars['SanitizeString']['input'];
  mergeVars?: InputMaybe<Scalars['JSON']['input']>;
};

export type EnrollDriverInput = {
  action: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  oobCode?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type EnrollExistingUserInput = {
  endAccountId: Scalars['ID']['input'];
  startAccountId: Scalars['ID']['input'];
};

export type EnrollUserInRewardsInput = {
  /** The account vendorId */
  accountNumber: Scalars['ID']['input'];
  /** The driver email address */
  email: Scalars['String']['input'];
  /** The drivers first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** A boolean indicating whether or not the driver opts in to rewards */
  optIn: Scalars['Boolean']['input'];
};

export type Entity = ChargerPricing;

export enum EvcStatus {
  ChangeAvailability = 'CHANGE_AVAILABILITY',
  ChangeConfiguration = 'CHANGE_CONFIGURATION',
  DataTransfer = 'DATA_TRANSFER',
  GetConfiguration = 'GET_CONFIGURATION',
  GetDiagnostic = 'GET_DIAGNOSTIC',
  ResetStation = 'RESET_STATION',
  StartCharge = 'START_CHARGE',
  StopCharge = 'STOP_CHARGE',
  UnlockConnector = 'UNLOCK_CONNECTOR',
  UpdateFirmware = 'UPDATE_FIRMWARE',
}

export enum EvcStatusInput {
  ChangeAvailability = 'CHANGE_AVAILABILITY',
  ChangeConfiguration = 'CHANGE_CONFIGURATION',
  DataTransfer = 'DATA_TRANSFER',
  GetConfiguration = 'GET_CONFIGURATION',
  GetDiagnostic = 'GET_DIAGNOSTIC',
  ResetStation = 'RESET_STATION',
  StartCharge = 'START_CHARGE',
  StopCharge = 'STOP_CHARGE',
  UnlockConnector = 'UNLOCK_CONNECTOR',
  UpdateFirmware = 'UPDATE_FIRMWARE',
}

export type EventLog = {
  __typename?: 'EventLog';
  entityData?: Maybe<Entity>;
  entityId?: Maybe<Scalars['Int']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['Date']['output']>;
  eventText?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<FalconConstraint>;
  eventTypeId?: Maybe<Scalars['Int']['output']>;
  jsonData?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<ExtendUser>;
};

export enum EventLogSort {
  EntityIdAsc = 'ENTITY_ID_ASC',
  EntityIdDesc = 'ENTITY_ID_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventDateAsc = 'EVENT_DATE_ASC',
  EventDateDesc = 'EVENT_DATE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  TariffNameAsc = 'TARIFF_NAME_ASC',
  TariffNameDesc = 'TARIFF_NAME_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

export type EventLogWithMeta = {
  __typename?: 'EventLogWithMeta';
  edges?: Maybe<Array<Maybe<EventLog>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EvgoAccessQrCode = {
  __typename?: 'EvgoAccessQRCode';
  credential?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EvgoSitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int']['input'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']['input']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']['input']>;
  plugAndCharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Evse = {
  __typename?: 'Evse';
  altId?: Maybe<Scalars['ID']['output']>;
  charger?: Maybe<Charger>;
  connectors?: Maybe<ConnectorsWithMeta>;
  evseId?: Maybe<Scalars['String']['output']>;
};

export type EvseForMobile = {
  __typename?: 'EvseForMobile';
  altId?: Maybe<Scalars['ID']['output']>;
  connectors?: Maybe<Array<Maybe<ConnectorForMobile>>>;
};

export type EvseInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  connectors: Array<InputMaybe<ConnectorInput>>;
  evseId: Scalars['String']['input'];
};

export type EvseV2 = {
  __typename?: 'EvseV2';
  altId?: Maybe<Scalars['ID']['output']>;
  charger?: Maybe<ChargerV2>;
  connectors?: Maybe<Array<Maybe<ConnectorV2>>>;
  ocpiId?: Maybe<Scalars['String']['output']>;
};

export type EvsesWithMeta = {
  __typename?: 'EvsesWithMeta';
  edges?: Maybe<Array<Maybe<Evse>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ExtendCalculatedHostPlan = {
  __typename?: 'ExtendCalculatedHostPlan';
  planId: Scalars['Int']['output'];
};

export type ExtendHmiDisplayLabels = {
  __typename?: 'ExtendHmiDisplayLabels';
  altId: Scalars['ID']['output'];
  column1Description1?: Maybe<Scalars['SanitizeString']['output']>;
  column1Description2?: Maybe<Scalars['SanitizeString']['output']>;
  column1Title?: Maybe<Scalars['SanitizeString']['output']>;
  column2Description1?: Maybe<Scalars['SanitizeString']['output']>;
  column2Description2?: Maybe<Scalars['SanitizeString']['output']>;
  column2Title?: Maybe<Scalars['SanitizeString']['output']>;
  column3Description1?: Maybe<Scalars['SanitizeString']['output']>;
  column3Description2?: Maybe<Scalars['SanitizeString']['output']>;
  column3Title?: Maybe<Scalars['SanitizeString']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  /** Host that the labels will apply to */
  hostId: Scalars['ID']['output'];
  row1Description1?: Maybe<Scalars['SanitizeString']['output']>;
  row1Description2?: Maybe<Scalars['SanitizeString']['output']>;
  row1Description3?: Maybe<Scalars['SanitizeString']['output']>;
  row1Title?: Maybe<Scalars['SanitizeString']['output']>;
  row2Description1?: Maybe<Scalars['SanitizeString']['output']>;
  row2Description2?: Maybe<Scalars['SanitizeString']['output']>;
  row2Description3?: Maybe<Scalars['SanitizeString']['output']>;
  row2Title?: Maybe<Scalars['SanitizeString']['output']>;
  row3Description1?: Maybe<Scalars['SanitizeString']['output']>;
  row3Description2?: Maybe<Scalars['SanitizeString']['output']>;
  row3Description3?: Maybe<Scalars['SanitizeString']['output']>;
  row3Title?: Maybe<Scalars['SanitizeString']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ExtendHost = {
  __typename?: 'ExtendHost';
  /** UUID of the Extend Host */
  altId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Host associated to the Extend Host */
  host: Host;
  prefix?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** Driivz host folder ID - used to create charger groups */
  vendorFolderId: Scalars['Int']['output'];
};

export type ExtendHostsWithMeta = {
  __typename?: 'ExtendHostsWithMeta';
  edges?: Maybe<Array<ExtendHost>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ExtendUser = {
  __typename?: 'ExtendUser';
  /** Unique UUID of the user */
  altId: Scalars['ID']['output'];
  /** Timestamp when the user was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Timestamp when the user was deleted */
  deletedAt?: Maybe<Scalars['Date']['output']>;
  /** ID stored in firebase for the user */
  firebaseId: Scalars['String']['output'];
  /** Hosts the user is associated to */
  hosts: HostsWithMeta;
  /** Unique ID of the user */
  id?: Maybe<Scalars['ID']['output']>;
  /** Timestamp when the user was last updated */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** Unique username string */
  username: Scalars['String']['output'];
};

export type ExtendUsersWithMeta = {
  __typename?: 'ExtendUsersWithMeta';
  edges?: Maybe<Array<Maybe<ExtendUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FalconConstraint = {
  __typename?: 'FalconConstraint';
  columnName?: Maybe<Scalars['String']['output']>;
  columnText?: Maybe<Scalars['String']['output']>;
  columnValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  tableName?: Maybe<Scalars['String']['output']>;
};

export type FalconConstraintsWithMeta = {
  __typename?: 'FalconConstraintsWithMeta';
  edges?: Maybe<Array<Maybe<FalconConstraint>>>;
  filter?: Maybe<ListFalconConstraintsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<ListFalconConstraintsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum FieldStationStatus {
  /** Connected */
  Connected = 'CONNECTED',
  /** In Stock */
  InStock = 'IN_STOCK',
  /** Ordered */
  Ordered = 'ORDERED',
  /** Provisioned */
  Provisioned = 'PROVISIONED',
  /** Ready for Installation */
  ReadyForInstallation = 'READY_FOR_INSTALLATION',
  /** Site Testing */
  SiteTesting = 'SITE_TESTING',
  /** Warehouse Testing */
  WarehouseTesting = 'WAREHOUSE_TESTING',
}

export enum FileUploadBucketEnum {
  /** Indicates that this is a MOBILE Logo for an entire Host */
  MobileHostLogoBucket = 'MOBILE_HOST_LOGO_BUCKET',
  /** Inidcates that this is a MOBILE Logo for a specific Site */
  MobileSiteLogoBucket = 'MOBILE_SITE_LOGO_BUCKET',
}

export type Filter = {
  __typename?: 'Filter';
  criteria?: Maybe<Scalars['String']['output']>;
  exclude?: Maybe<Scalars['Boolean']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<FilterValue>>>;
};

export type FilterValue = {
  __typename?: 'FilterValue';
  label?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FirebaseAccount = {
  __typename?: 'FirebaseAccount';
  customClaims?: Maybe<FirebaseAccountCustomClaims>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<FirebaseAccountMetaData>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tokensValidAfterTime?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

export type FirebaseAccountCustomClaims = {
  __typename?: 'FirebaseAccountCustomClaims';
  altId?: Maybe<Scalars['String']['output']>;
  driverType?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<UserRoles>;
  vendorSynced?: Maybe<Scalars['Boolean']['output']>;
};

export type FirebaseAccountMetaData = {
  __typename?: 'FirebaseAccountMetaData';
  creationTime?: Maybe<Scalars['String']['output']>;
  lastSignInTime?: Maybe<Scalars['String']['output']>;
};

export type FirebaseUserForExtend = {
  __typename?: 'FirebaseUserForExtend';
  firebaseId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type FleetSiteCharger = {
  __typename?: 'FleetSiteCharger';
  accountAltId: Scalars['ID']['output'];
  charger?: Maybe<Charger>;
  chargerAltId: Scalars['ID']['output'];
};

export type FleetVehicle = {
  __typename?: 'FleetVehicle';
  activityStatus?: Maybe<ActivityStatus>;
  activityStatusLabel?: Maybe<ActivityStatusLabel>;
  altId: Scalars['ID']['output'];
  card: Card;
  currentSession?: Maybe<Session>;
  sessions?: Maybe<SessionsWithMeta>;
};

export type FleetVehicleSessionsArgs = {
  input?: InputMaybe<ListSessionsInput>;
};

export type GeographicalRegion = {
  __typename?: 'GeographicalRegion';
  abbreviation?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeographicalRegionsWithMeta = {
  __typename?: 'GeographicalRegionsWithMeta';
  edges?: Maybe<Array<Maybe<GeographicalRegion>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GetAccountInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetAccountVehicleByVinInput = {
  vin: Scalars['SanitizeString']['input'];
};

export type GetAccountVehicleInput = {
  altId: Scalars['UUID']['input'];
  paranoid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetAccountVehicleVinStatusInput = {
  vin: Scalars['SanitizeString']['input'];
};

export type GetActiveSessionInput = {
  connectorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetAdHocContractInput = {
  accountId: Scalars['ID']['input'];
};

export type GetAggregatedPowerMeterValuesForFleetInput = {
  aggregateIntervalMinutes: Scalars['Int']['input'];
  from: Scalars['Date']['input'];
  siteAltId: Scalars['ID']['input'];
  until: Scalars['Date']['input'];
};

export type GetCardInput = {
  altId?: InputMaybe<Scalars['UUID']['input']>;
  externalNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GetChargerAggregatedPowerMeterValuesForFleetInput = {
  aggregateIntervalMinutes: Scalars['Int']['input'];
  chargerAltId: Scalars['ID']['input'];
  from: Scalars['Date']['input'];
  siteAltId: Scalars['ID']['input'];
  until: Scalars['Date']['input'];
};

export type GetChargerGroupInput = {
  altId: Scalars['ID']['input'];
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetChargerInput = {
  altId: Scalars['ID']['input'];
};

export type GetChargerModelInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type GetChargerPricingForMobileInput = {
  /** Driivz charger Id */
  chargerId: Scalars['ID']['input'];
};

export type GetConnectorInput = {
  altId: Scalars['ID']['input'];
};

export type GetContractInput = {
  altId: Scalars['ID']['input'];
};

export type GetCouponInput = {
  adType: Scalars['String']['input'];
  stationId?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCreditCardTransactionInput = {
  creditCardTransactionId: Scalars['ID']['input'];
};

export type GetDriverInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  paranoid?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetEvgoAccessQrCodeInput = {
  accountVendorId: Scalars['Int']['input'];
  siteVendorId: Scalars['Int']['input'];
};

export type GetExtendUserInput = {
  /** Unique UUID of the user */
  altId?: InputMaybe<Scalars['ID']['input']>;
  /** Firebase ID of the user */
  firebaseId?: InputMaybe<Scalars['String']['input']>;
};

export type GetFirebaseUserForExtendInput = {
  email: Scalars['String']['input'];
};

export type GetHostInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  hid?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
};

/** Input type for GetInvoiceForMobile */
export type GetInvoiceInput = {
  /** A number indicating the Driivz ID of the invoice */
  invoiceId: Scalars['Int']['input'];
};

export type GetMediaInput = {
  altId: Scalars['UUID']['input'];
};

export type GetPlanForMobileInput = {
  /** altId used to retrieve plans */
  altId: Scalars['ID']['input'];
  /** user's current latitude value in float */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** user's current longitude value in float */
  long?: InputMaybe<Scalars['Float']['input']>;
};

export type GetPlanInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  planCode?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type GetPortInput = {
  altId: Scalars['ID']['input'];
};

export type GetPropertyInput = {
  pid: Scalars['SanitizeString']['input'];
};

export type GetRewardsUserInput = {
  /** @deprecated This will be decoded from token.  Leaving the argument so not to break clients, but this argument will do nothing and will be removed in the future.  Tracking removal status here: PP-810. */
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  trackLogin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetSalesforceHostInput = {
  hid?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type GetSalesforcePropertyInput = {
  hid: Scalars['SanitizeString']['input'];
  pid: Scalars['SanitizeString']['input'];
};

export type GetSessionForFleetInput = {
  altId: Scalars['ID']['input'];
};

export type GetSessionInput = {
  sessionId: Scalars['ID']['input'];
};

export type GetSessionSummaryByPlanInput = {
  startDate: Scalars['Date']['input'];
};

export type GetSessionValidationForMobileInput = {
  /** The connectorId of the charger for which a session creation attempt was made. */
  connectorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetSessionsConsumptionForExtendInput = {
  aggregateBy: SessionAggregation;
  /** Filter the timeseries data by charger groups, if not send will return for all charger groups */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter the timeseries data by chargers, if not send will return for all chargers */
  chargerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datePreset?: InputMaybe<SessionDatePreset>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter the timeseries data by plans, if not send will return for all plans */
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter the timeseries data by chargers in these sites, if not send will return for all sites */
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter the timeseries data by transaction type, if not send will return only for the default eXtend+ types */
  transactionTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GetSessionsConsumptionForExtendResponse = {
  __typename?: 'GetSessionsConsumptionForExtendResponse';
  endDate: Scalars['Date']['output'];
  startDate: Scalars['Date']['output'];
  timeSeries?: Maybe<Array<Maybe<SessionsConsumptionForExtendData>>>;
};

export type GetSiteForExtendInput = {
  /** altId of the site */
  altId: Scalars['ID']['input'];
};

export type GetSiteImageInput = {
  altId: Scalars['ID']['input'];
};

export type GetSiteInput = {
  altId: Scalars['ID']['input'];
};

export type GetStatementCurrentInput = {
  /** A string parameter indicating the currency type to be shown on the returned statement */
  currency: Scalars['String']['input'];
};

export type GetStatementInput = {
  /** An Id parameter required to find statements in Driivz */
  invoiceId: Scalars['ID']['input'];
};

export type GetTagInput = {
  altId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type GetTariffInput = {
  altId: Scalars['ID']['input'];
};

export type GetTariffLogsInput = {
  chargerIdentityKeys?: InputMaybe<Array<Scalars['SanitizeString']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Default and maximum limit is 50 */
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<TariffLogsSort>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GetTransactionInput = {
  transactionId: Scalars['ID']['input'];
};

export type GetVehicleByVinInput = {
  vin: Scalars['SanitizeString']['input'];
};

export type GetWarehouseInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  warehouseName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type Host = {
  __typename?: 'Host';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  contacts?: Maybe<ContactsWithMeta>;
  country?: Maybe<Scalars['String']['output']>;
  /** Flag to indicate if host is using Custom Plans */
  customPlansEnabled?: Maybe<Scalars['Boolean']['output']>;
  defaultBillingTariffCode?: Maybe<Scalars['String']['output']>;
  defaultHostServicePlanCode?: Maybe<Scalars['String']['output']>;
  extendHost?: Maybe<ExtendHost>;
  extendUsers?: Maybe<ExtendUsersWithMeta>;
  hid?: Maybe<Scalars['String']['output']>;
  hostName?: Maybe<Scalars['String']['output']>;
  hostStatus?: Maybe<FalconConstraint>;
  id?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['ID']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<PropertiesWithMeta>;
  /** @deprecated Use hostStatus */
  status?: Maybe<HostStatus>;
  timezone?: Maybe<Timezone>;
  vendorId?: Maybe<Scalars['String']['output']>;
  vendorSyncedAt?: Maybe<Scalars['Date']['output']>;
};

export type HostContactsArgs = {
  input?: InputMaybe<ListContactsInput>;
};

export type HostExtendUsersArgs = {
  input?: InputMaybe<ListExtendUsersInput>;
};

export type HostPropertiesArgs = {
  input?: InputMaybe<ListPropertiesInput>;
};

export type HostFilterInput = {
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
};

export type HostFilterType = {
  __typename?: 'HostFilterType';
  hid?: Maybe<TableStringFilterType>;
  hostName?: Maybe<TableStringFilterType>;
};

export enum HostStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Closed */
  Closed = 'CLOSED',
  /** Closed Customer Request */
  ClosedCustomerRequest = 'CLOSED_CUSTOMER_REQUEST',
  /** Closed Delinquency */
  ClosedDelinquency = 'CLOSED_DELINQUENCY',
  /** Deleted */
  Deleted = 'DELETED',
  /** Invited */
  Invited = 'INVITED',
  /** In 3DS Validation */
  In_3DsValidation = 'IN_3DS_VALIDATION',
  /** Pre-registered */
  PreRegistered = 'PRE_REGISTERED',
  /** Registered */
  Registered = 'REGISTERED',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Suspended Delinquency */
  SuspendedDelinquency = 'SUSPENDED_DELINQUENCY',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type HostsWithMeta = {
  __typename?: 'HostsWithMeta';
  edges?: Maybe<Array<Maybe<Host>>>;
  filter?: Maybe<ListHostFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListHostSearchType>;
  sort?: Maybe<ListHostSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type HoursInput = {
  custom?: InputMaybe<Scalars['Boolean']['input']>;
  friday?: InputMaybe<CustomHourInput>;
  from?: InputMaybe<Scalars['SanitizeString']['input']>;
  monday?: InputMaybe<CustomHourInput>;
  saturday?: InputMaybe<CustomHourInput>;
  sunday?: InputMaybe<CustomHourInput>;
  thursday?: InputMaybe<CustomHourInput>;
  to?: InputMaybe<Scalars['SanitizeString']['input']>;
  tuesday?: InputMaybe<CustomHourInput>;
  wednesday?: InputMaybe<CustomHourInput>;
};

export type HoursType = {
  __typename?: 'HoursType';
  custom?: Maybe<Scalars['Boolean']['output']>;
  friday?: Maybe<CustomHourType>;
  from?: Maybe<Scalars['String']['output']>;
  monday?: Maybe<CustomHourType>;
  saturday?: Maybe<CustomHourType>;
  sunday?: Maybe<CustomHourType>;
  thursday?: Maybe<CustomHourType>;
  to?: Maybe<Scalars['String']['output']>;
  tuesday?: Maybe<CustomHourType>;
  wednesday?: Maybe<CustomHourType>;
};

export type ImportAccountOwnerInput = {
  accountOwners: Array<CreateAccountOwnerFromDriivzInput>;
};

export type ImportAccountVehicleInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};

export type ImportAccountVehiclesInput = {
  accountVehicles?: InputMaybe<Array<InputMaybe<ImportAccountVehicleInput>>>;
};

export type ImportCardsInput = {
  cards?: InputMaybe<Array<InputMaybe<UpsertCardInput>>>;
};

export type ImportContractItemInput = {
  accountId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  planId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  vendorId: Scalars['ID']['input'];
  vendorStatus?: InputMaybe<Scalars['String']['input']>;
};

export type ImportContractsInput = {
  contracts: Array<ImportContractItemInput>;
};

export type ImportHostInput = {
  address1?: InputMaybe<Scalars['SanitizeString']['input']>;
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  hid: Scalars['SanitizeString']['input'];
  hostName: Scalars['SanitizeString']['input'];
  legacyId?: InputMaybe<Scalars['ID']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  status?: InputMaybe<HostStatus>;
};

export type ImportPaymentMethodsInput = {
  paymentMethods: Array<UpsertPaymentMethodInput>;
};

export type ImportPropertyInput = {
  address1?: InputMaybe<Scalars['SanitizeString']['input']>;
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostId: Scalars['ID']['input'];
  legacyId?: InputMaybe<Scalars['ID']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  pid: Scalars['SanitizeString']['input'];
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  propertyName: Scalars['SanitizeString']['input'];
  status?: InputMaybe<PropertyStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type ImportVehiclesInput = {
  vehicleModels?: InputMaybe<Array<InputMaybe<VehicleInput>>>;
};

export type InGoodStanding = {
  __typename?: 'InGoodStanding';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export enum InputAcVoltage {
  /** 120V Single Phase */
  SinglePhase_120V = 'SINGLE_PHASE_120V',
  /** 208/240 Single Phase */
  SinglePhase_208_240 = 'SINGLE_PHASE_208_240',
  /** 208V/120V Three Phase 3 Wire */
  ThreePhase_3Wire_208V_120V = 'THREE_PHASE_3_WIRE_208V_120V',
  /** 480V/277V Three Phase 3 Wire */
  ThreePhase_3Wire_480V_277V = 'THREE_PHASE_3_WIRE_480V_277V',
  /** 480V/277V Three Phase 4 Wire */
  ThreePhase_4Wire_480V_277V = 'THREE_PHASE_4_WIRE_480V_277V',
}

export enum InterfaceVersion {
  /** 2 Button */
  Button_2 = 'BUTTON_2',
  /** 3 Button */
  Button_3 = 'BUTTON_3',
  /** No buttons */
  NoButton = 'NO_BUTTON',
}

export enum IntervalUnit {
  /** Day */
  Day = 'DAY',
  /** Month */
  Month = 'MONTH',
  /** Week */
  Week = 'WEEK',
  /** Year */
  Year = 'YEAR',
}

export type InviteDriverInput = {
  altId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
};

/** An invoice of billed transactions */
export type Invoice = {
  __typename?: 'Invoice';
  /** A string identifying where the authorization for this invoice was initiated */
  authorization?: Maybe<Scalars['String']['output']>;
  /** The amount of tax billed on the invoice */
  billingTax?: Maybe<Scalars['Float']['output']>;
  /** Charger details for the charger used in the invoice transactions */
  charger?: Maybe<Charger>;
  /** A confirmation number for the paid invoice */
  confirmationNumber?: Maybe<Scalars['String']['output']>;
  /** The date on which the current invoice was created */
  createdOn?: Maybe<Scalars['Date']['output']>;
  /** The amount of credits applied to the invoice */
  creditApplied?: Maybe<Scalars['Float']['output']>;
  /** The credit card number which was used for payment */
  creditCardNumber?: Maybe<Scalars['String']['output']>;
  /** The type of the credit card used for payment */
  creditCardType?: Maybe<Scalars['String']['output']>;
  /** The name of the driver associated with the invoice */
  driverName?: Maybe<Scalars['String']['output']>;
  /** Session end time */
  endTime?: Maybe<Scalars['Date']['output']>;
  /** The amount of energy delivered in kWh */
  energyDeliveredKw?: Maybe<Scalars['Float']['output']>;
  /** A string defining which invoice category applies to the current invoice */
  invoiceCategory?: Maybe<Scalars['String']['output']>;
  /** A number indicating the Driivz ID of the invoice */
  invoiceId?: Maybe<Scalars['Int']['output']>;
  /** A number indicating the invoiceNumber of the current invoice */
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  /** Transaction line item details */
  lineItems?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  /** Network operator details describing the hostName of the applicable network operator */
  networkOperator?: Maybe<NetworkOperator>;
  /** The date on which the current invoice was paid */
  paidOn?: Maybe<Scalars['Date']['output']>;
  /** Max power level */
  powerLevel?: Maybe<Scalars['Float']['output']>;
  program?: Maybe<PaymentMethodProgram>;
  /** The duration of the session billed on the current invoice Format: (HH:MM:SS.MS) */
  sessionDuration?: Maybe<Scalars['Time']['output']>;
  /** An id for identifying the charge session related to this invoice */
  sessionId?: Maybe<Scalars['String']['output']>;
  /** Site details for the location where the invoice transactions took place */
  site?: Maybe<Site>;
  /** Session start time */
  startTime?: Maybe<Scalars['Date']['output']>;
  /** Session stop reason */
  stopReason?: Maybe<Scalars['String']['output']>;
  /** The sum total of the lineItem.amounts */
  subtotal?: Maybe<Scalars['Float']['output']>;
  /** The total amount paid by the customer */
  totalPaid?: Maybe<Scalars['Float']['output']>;
};

/** Transaction line item details */
export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  /** The total cost of this transaction */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Comments on the transaction */
  comments?: Maybe<Scalars['String']['output']>;
  /** The type of transaction */
  productType?: Maybe<Scalars['String']['output']>;
  /** How many units were billed under this transaction */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** The cost of each unit under this transaction */
  unitPrice?: Maybe<Scalars['String']['output']>;
};

/** return type for ListInvoicesForMobile */
export type InvoicesWithMeta = {
  __typename?: 'InvoicesWithMeta';
  edges?: Maybe<Array<Maybe<Invoice>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type LambdaEvent = {
  __typename?: 'LambdaEvent';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type LambdaEventInput = {
  after?: InputMaybe<DriivzEntityInput>;
  before?: InputMaybe<DriivzEntityInput>;
  operation?: InputMaybe<Scalars['String']['input']>;
};

export enum LastSessionMeterValueUnitOfMeasurment {
  Percent = 'PERCENT',
  Wh = 'WH',
}

export type ListAccountVehiclesFilterInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
};

export type ListAccountVehiclesInput = {
  filter?: InputMaybe<ListAccountVehiclesFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paranoid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListAccountsFilterInput = {
  accountStatus?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
};

export type ListAccountsInput = {
  filter?: InputMaybe<ListAccountsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListAccountsSortInput>;
};

export type ListAccountsSortInput = {
  accountStatus?: InputMaybe<TableSortInput>;
  firstName?: InputMaybe<TableSortInput>;
};

export type ListAccountsSortType = {
  __typename?: 'ListAccountsSortType';
  accountStatus?: Maybe<TableSortType>;
  evcId?: Maybe<TableSortType>;
  firstName?: Maybe<TableSortType>;
};

export type ListCardOrdersFilterInput = {
  account_altId?: InputMaybe<TableStringFilterInput>;
  brand?: InputMaybe<TableStringFilterInput>;
  driver_altId?: InputMaybe<TableStringFilterInput>;
  orderStatus?: InputMaybe<TableStringFilterInput>;
};

export type ListCardOrdersFilterType = {
  __typename?: 'ListCardOrdersFilterType';
  account_altId?: Maybe<TableStringFilterType>;
  brand?: Maybe<TableStringFilterType>;
  driver_altId?: Maybe<TableStringFilterType>;
  orderStatus?: Maybe<TableStringFilterType>;
};

export type ListCardOrdersForMobileInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListCardOrdersInput = {
  filter?: InputMaybe<ListCardOrdersFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListCardOrdersSearchInput>;
  sort?: InputMaybe<ListCardOrdersSortInput>;
};

export type ListCardOrdersSearchInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
  account_billingAddress_address1?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_locality?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_postalCode?: InputMaybe<TableStringFilterInput>;
  driver_firstName?: InputMaybe<TableStringFilterInput>;
  driver_lastName?: InputMaybe<TableStringFilterInput>;
};

export type ListCardOrdersSearchType = {
  __typename?: 'ListCardOrdersSearchType';
  accountId?: Maybe<TableIntFilterType>;
  account_billingAddress_address1?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  account_billingAddress_locality?: Maybe<TableStringFilterType>;
  account_billingAddress_postalCode?: Maybe<TableStringFilterType>;
  driver_firstName?: Maybe<TableStringFilterType>;
  driver_lastName?: Maybe<TableStringFilterType>;
};

export type ListCardOrdersSortInput = {
  driver_firstName?: InputMaybe<TableSortInput>;
  driver_lastName?: InputMaybe<TableSortInput>;
};

export type ListCardOrdersSortType = {
  __typename?: 'ListCardOrdersSortType';
  driver_firstName?: Maybe<TableSortType>;
  driver_lastName?: Maybe<TableSortType>;
};

export type ListCardsFilterInput = {
  account_altId?: InputMaybe<TableStringFilterInput>;
  brand?: InputMaybe<TableStringFilterInput>;
  cardStatus?: InputMaybe<TableStringFilterInput>;
  cardTypeId?: InputMaybe<TableIntFilterInput>;
  driver_altId?: InputMaybe<TableStringFilterInput>;
};

export type ListCardsFilterType = {
  __typename?: 'ListCardsFilterType';
  account_altId?: Maybe<TableStringFilterType>;
  brand?: Maybe<TableStringFilterType>;
  cardStatus?: Maybe<TableStringFilterType>;
  cardTypeId?: Maybe<TableStringFilterType>;
  driver_altId?: Maybe<TableStringFilterType>;
};

export type ListCardsInput = {
  filter?: InputMaybe<ListCardsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListCardsSearchInput>;
  sort?: InputMaybe<ListCardsSortInput>;
};

export type ListCardsSearchInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
  driver_firstName?: InputMaybe<TableStringFilterInput>;
  driver_lastName?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
  externalNumber?: InputMaybe<TableStringFilterInput>;
  internalNumber?: InputMaybe<TableStringFilterInput>;
};

export type ListCardsSearchType = {
  __typename?: 'ListCardsSearchType';
  accountId?: Maybe<TableIntFilterType>;
  driver_firstName?: Maybe<TableStringFilterType>;
  driver_lastName?: Maybe<TableStringFilterType>;
  evcId?: Maybe<TableStringFilterType>;
  externalNumber?: Maybe<TableStringFilterType>;
  internalNumber?: Maybe<TableStringFilterType>;
};

export type ListCardsSortInput = {
  driver_email?: InputMaybe<TableSortInput>;
  driver_firstName?: InputMaybe<TableSortInput>;
  driver_lastName?: InputMaybe<TableSortInput>;
  driver_phone?: InputMaybe<TableSortInput>;
  externalNumber?: InputMaybe<TableSortInput>;
  internalNumber?: InputMaybe<TableSortInput>;
  nickName?: InputMaybe<TableSortInput>;
};

export type ListCardsSortType = {
  __typename?: 'ListCardsSortType';
  driver_email?: Maybe<TableSortType>;
  driver_firstName?: Maybe<TableSortType>;
  driver_lastName?: Maybe<TableSortType>;
  driver_phone?: Maybe<TableSortType>;
  externalNumber?: Maybe<TableSortType>;
  internalNumber?: Maybe<TableSortType>;
  nickName?: Maybe<TableSortType>;
};

export type ListChargerGroupsFilterInput = {
  /** Name of the charger group to filter on */
  chargerGroupName?: InputMaybe<TableStringFilterInput>;
  /** Group type to filter on.  401 - CHARGER_PRICE, 402 - CUSTOM_ACCESS_PLAN */
  chargerGroupTypeId?: InputMaybe<TableIntFilterInput>;
  /** Description of the charger group to filter on */
  description?: InputMaybe<TableStringFilterInput>;
  /** ID of the charger group's host */
  hostId?: InputMaybe<TableIntFilterInput>;
};

export type ListChargerGroupsInput = {
  filter?: InputMaybe<ListChargerGroupsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListChargerGroupsFilterInput>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Array<InputMaybe<ChargerGroupsV2Sort>>>;
};

export type ListChargerModelsFilterInput = {
  manufacturer?: InputMaybe<TableStringFilterInput>;
};

export type ListChargerModelsFilterType = {
  __typename?: 'ListChargerModelsFilterType';
  manufacturer?: Maybe<TableStringFilterType>;
};

export type ListChargerModelsInput = {
  filter?: InputMaybe<ListChargerModelsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListChargerModelsSearchInput>;
  sort?: InputMaybe<ListChargerModelsSortInput>;
};

export type ListChargerModelsSearchInput = {
  modelName?: InputMaybe<TableStringFilterInput>;
};

export type ListChargerModelsSearchType = {
  __typename?: 'ListChargerModelsSearchType';
  modelName?: Maybe<TableStringFilterType>;
};

export type ListChargerModelsSortInput = {
  altId?: InputMaybe<TableSortInput>;
  manufacturer?: InputMaybe<TableSortInput>;
  modelName?: InputMaybe<TableSortInput>;
  unitType?: InputMaybe<TableSortInput>;
};

export type ListChargerModelsSortType = {
  __typename?: 'ListChargerModelsSortType';
  manufacturer?: Maybe<TableSortType>;
  modelName?: Maybe<TableSortType>;
  unitType?: Maybe<TableSortType>;
};

export type ListChargerUtilization = {
  __typename?: 'ListChargerUtilization';
  edges?: Maybe<Array<Maybe<ChargerUtilizationReport>>>;
  endDate: Scalars['Date']['output'];
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['Date']['output'];
  timeZone: Scalars['String']['output'];
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListChargerUtilizationInput = {
  endDate: Scalars['Date']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['UUID']['input'];
  startDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};

/** Filter the consumption data in that datePreset, for all chargers in these site altIds, return all chargers if siteIds not sent */
export type ListChargersConsumptionForExtendInput = {
  chargerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datePreset?: InputMaybe<SessionDatePreset>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ListChargersFilterInput = {
  and?: InputMaybe<ChargerFilterInput>;
  chargerModel_altId?: InputMaybe<TableStringFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  evses_connectors_connectorStatusId?: InputMaybe<TableIntFilterInput>;
  evses_connectors_connectorTypeId?: InputMaybe<TableIntFilterInput>;
  fieldStationStatus?: InputMaybe<TableStringFilterInput>;
  isReservable?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<ChargerFilterInput>;
  port?: InputMaybe<ListPortsRootFilterInput>;
  radius?: InputMaybe<ListChargersRadiusInput>;
  site_postalCode?: InputMaybe<TableStringFilterInput>;
  site_siteName?: InputMaybe<TableStringFilterInput>;
  tagId?: InputMaybe<TableStringFilterInput>;
  tags?: InputMaybe<TableFilterInput>;
};

export type ListChargersFilterType = {
  __typename?: 'ListChargersFilterType';
  chargerModel_altId?: Maybe<TableStringFilterType>;
  fieldStationStatus?: Maybe<TableStringFilterType>;
  site_postalCode?: Maybe<TableStringFilterType>;
  site_siteName?: Maybe<TableStringFilterType>;
  tags?: Maybe<TableFilterType>;
};

export type ListChargersForFleetInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListChargersForFleetSearchInput>;
  siteId: Scalars['ID']['input'];
  sort?: InputMaybe<ListChargersForFleetSortInput>;
};

export type ListChargersForFleetSearchInput = {
  chargers_chargerName?: InputMaybe<TableStringFilterInput>;
  evses_connectors_connectorTypeConstraint_columnValue?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersForFleetSearchType = {
  __typename?: 'ListChargersForFleetSearchType';
  chargers_chargerName?: Maybe<TableStringFilterType>;
  evses_connectors_connectorTypeConstraint_columnValue?: Maybe<TableStringFilterType>;
};

export type ListChargersForFleetSortInput = {
  chargerName?: InputMaybe<TableSortInput>;
};

export type ListChargersForFleetSortType = {
  __typename?: 'ListChargersForFleetSortType';
  chargerName?: Maybe<TableSortType>;
};

export type ListChargersForMobileInput = {
  connectorTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  distance: Scalars['Int']['input'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO station supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Float']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  longitude: Scalars['Float']['input'];
  search: Scalars['String']['input'];
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ListChargersInput = {
  filter?: InputMaybe<ListChargersFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListChargersSearchInput>;
  sort?: InputMaybe<ListChargersSortInput>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListChargersRadiusInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type ListChargersRootFilterInput = {
  and?: InputMaybe<ChargerFilterInput>;
  or?: InputMaybe<ChargerFilterInput>;
  port?: InputMaybe<ListPortsRootFilterInput>;
};

export type ListChargersRootFilterType = {
  __typename?: 'ListChargersRootFilterType';
  and?: Maybe<ChargerFilterType>;
  or?: Maybe<ChargerFilterType>;
  port?: Maybe<ListPortsRootFilterType>;
};

export type ListChargersSearchInput = {
  chargerModel_modelName?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  cid?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
  site_address1?: InputMaybe<TableStringFilterInput>;
  site_administrativeArea?: InputMaybe<TableStringFilterInput>;
  site_locality?: InputMaybe<TableStringFilterInput>;
  site_postalCode?: InputMaybe<TableStringFilterInput>;
  site_property_propertyName?: InputMaybe<TableStringFilterInput>;
  site_siteName?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersSearchType = {
  __typename?: 'ListChargersSearchType';
  chargerModel_modelName?: Maybe<TableStringFilterType>;
  chargerName?: Maybe<TableStringFilterType>;
  cid?: Maybe<TableStringFilterType>;
  site_address1?: Maybe<TableStringFilterType>;
  site_administrativeArea?: Maybe<TableStringFilterType>;
  site_locality?: Maybe<TableStringFilterType>;
  site_postalCode?: Maybe<TableStringFilterType>;
  site_property_propertyName?: Maybe<TableStringFilterType>;
  site_siteName?: Maybe<TableStringFilterType>;
};

export type ListChargersSortForExtendInput = {
  chargerModel_modelName?: InputMaybe<TableSortInput>;
  chargerName?: InputMaybe<TableSortInput>;
  charging?: InputMaybe<TableSortInput>;
  site_address1?: InputMaybe<TableSortInput>;
  site_siteName?: InputMaybe<TableSortInput>;
};

export type ListChargersSortInput = {
  chargerModel_modelName?: InputMaybe<TableSortInput>;
  chargerName?: InputMaybe<TableSortInput>;
  cid?: InputMaybe<TableSortInput>;
  fieldStationStatus?: InputMaybe<TableSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
};

export type ListChargersSortType = {
  __typename?: 'ListChargersSortType';
  chargerModel_modelName?: Maybe<TableSortType>;
  chargerName?: Maybe<TableSortType>;
  cid?: Maybe<TableSortType>;
  fieldStationStatus?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
};

export type ListChargersV2FilterInput = {
  chargerGroupName?: InputMaybe<TableStringFilterInput>;
  chargerIsManaged?: InputMaybe<TableBooleanFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  connectorIsReservable?: InputMaybe<TableBooleanFilterInput>;
  connectorStatusId?: InputMaybe<TableIntFilterInput>;
  connectorStatusMax?: InputMaybe<TableStringFilterInput>;
  connectorStatusMin?: InputMaybe<TableStringFilterInput>;
  connectorStatusValue?: InputMaybe<TableStringFilterInput>;
  modelName?: InputMaybe<TableStringFilterInput>;
  siteAddress1?: InputMaybe<TableStringFilterInput>;
  siteAdministrativeArea?: InputMaybe<TableStringFilterInput>;
  siteLocality?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  sitePostalCode?: InputMaybe<TableStringFilterInput>;
  tariffId?: InputMaybe<TableIntFilterInput>;
  vendorId?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersV2Input = {
  filter?: InputMaybe<ListChargersV2FilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListChargersV2FilterInput>;
  sort?: InputMaybe<ChargersV2Sort>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListConnectorsFilterInput = {
  and?: InputMaybe<ConnectorFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<ConnectorFilterInput>;
};

export type ListConnectorsInput = {
  filter?: InputMaybe<ListConnectorsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListConnectorsSortInput>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListConnectorsRootFilterInput = {
  and?: InputMaybe<ConnectorFilterInput>;
  or?: InputMaybe<ConnectorFilterInput>;
};

export type ListConnectorsRootFilterType = {
  __typename?: 'ListConnectorsRootFilterType';
  and?: Maybe<ConnectorFilterType>;
  or?: Maybe<ConnectorFilterType>;
};

export type ListConnectorsSortInput = {
  altId?: InputMaybe<TableSortInput>;
};

export type ListContactsFilterInput = {
  contactName?: InputMaybe<TableStringFilterInput>;
};

export type ListContactsInput = {
  filter?: InputMaybe<ListContactsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListContactsSortInput>;
};

export type ListContactsSortInput = {
  contactName?: InputMaybe<TableSortInput>;
};

export type ListContractsFilterInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  statusId?: InputMaybe<TableIntFilterInput>;
};

export type ListContractsFilterType = {
  __typename?: 'ListContractsFilterType';
  accountId?: Maybe<TableStringFilterType>;
  planId?: Maybe<TableStringFilterType>;
  statusId?: Maybe<TableIntFilterType>;
};

export type ListContractsInput = {
  filter?: InputMaybe<ListContractsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListContractsSearchInput>;
  sort?: InputMaybe<ListContractsSortInput>;
};

export type ListContractsSearchInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  statusId?: InputMaybe<TableStringFilterInput>;
};

export type ListContractsSearchType = {
  __typename?: 'ListContractsSearchType';
  accountId?: Maybe<TableStringFilterType>;
  planId?: Maybe<TableStringFilterType>;
  statusId?: Maybe<TableIntFilterType>;
};

export type ListContractsSortInput = {
  statusId?: InputMaybe<TableSortInput>;
};

export type ListContractsSortType = {
  __typename?: 'ListContractsSortType';
  statusId?: Maybe<TableSortType>;
};

export type ListCreditsInput = {
  accountId: Scalars['ID']['input'];
};

export type ListDriversFilterInput = {
  account_accountStatus?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  driverStatusId?: InputMaybe<TableStringFilterInput>;
};

export type ListDriversFilterType = {
  __typename?: 'ListDriversFilterType';
  account_accountStatus?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  driverStatusId?: Maybe<TableStringFilterType>;
};

export type ListDriversInput = {
  filter?: InputMaybe<ListDriversFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paranoid?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<ListDriversSearchInput>;
  searchAndFilter?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<ListDriversSortInput>;
};

export type ListDriversSearchInput = {
  accountVehicles_vin?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_address1?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_address2?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_locality?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_postalCode?: InputMaybe<TableStringFilterInput>;
  account_id?: InputMaybe<TableIntFilterInput>;
  email?: InputMaybe<TableStringFilterInput>;
  firstName?: InputMaybe<TableStringFilterInput>;
  lastName?: InputMaybe<TableStringFilterInput>;
  phone?: InputMaybe<TableStringFilterInput>;
};

export type ListDriversSearchType = {
  __typename?: 'ListDriversSearchType';
  account_billingAddress_address1?: Maybe<TableStringFilterType>;
  account_billingAddress_address2?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  account_billingAddress_locality?: Maybe<TableStringFilterType>;
  account_billingAddress_postalCode?: Maybe<TableStringFilterType>;
  account_id?: Maybe<TableIntFilterType>;
  email?: Maybe<TableStringFilterType>;
  firstName?: Maybe<TableStringFilterType>;
  lastName?: Maybe<TableStringFilterType>;
  phone?: Maybe<TableStringFilterType>;
};

export type ListDriversSortInput = {
  account_accountStatus?: InputMaybe<TableSortInput>;
  account_balance?: InputMaybe<TableSortInput>;
  account_billingAddress_address1?: InputMaybe<TableSortInput>;
  account_id?: InputMaybe<TableSortInput>;
  driverStatusId?: InputMaybe<TableSortInput>;
  email?: InputMaybe<TableSortInput>;
  firstName?: InputMaybe<TableSortInput>;
  lastName?: InputMaybe<TableSortInput>;
};

export type ListDriversSortType = {
  __typename?: 'ListDriversSortType';
  account_accountStatus?: Maybe<TableSortType>;
  account_balance?: Maybe<TableSortType>;
  account_billingAddress_address1?: Maybe<TableSortType>;
  account_id?: Maybe<TableSortType>;
  driverStatusId?: Maybe<TableSortType>;
  email?: Maybe<TableSortType>;
  firstName?: Maybe<TableSortType>;
  lastName?: Maybe<TableSortType>;
};

export type ListElectricVehiclesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListElectricVehiclesSearchInput>;
  sort?: InputMaybe<ListElectricVehiclesSortInput>;
};

export type ListElectricVehiclesSearchInput = {
  vehicleMake_manufacturer?: InputMaybe<TableStringFilterInput>;
  vehicleModelYear_ModelYear?: InputMaybe<TableStringFilterInput>;
  vehicleModel_modelName?: InputMaybe<TableStringFilterInput>;
};

export type ListElectricVehiclesSortInput = {
  make_manufacturer?: InputMaybe<TableSortInput>;
  vehicleMake_manufacturer?: InputMaybe<TableSortInput>;
  vehicleModelYear_ModelYear?: InputMaybe<TableSortInput>;
  vehicleModel_modelName?: InputMaybe<TableSortInput>;
};

export type ListElementsInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListEventLogFilterInput = {
  entityId?: InputMaybe<TableIntFilterInput>;
  entityType?: InputMaybe<TableStringFilterInput>;
  eventDate?: InputMaybe<TableDateFilterInput>;
  eventType?: InputMaybe<TableStringFilterInput>;
  eventTypeId?: InputMaybe<TableIntFilterInput>;
  username?: InputMaybe<TableStringFilterInput>;
};

export type ListEventLogInput = {
  filter?: InputMaybe<ListEventLogFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListEventLogFilterInput>;
  sort?: InputMaybe<Array<InputMaybe<EventLogSort>>>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListEventLogSortInput = {
  entityId?: InputMaybe<TableSortInput>;
  entityType?: InputMaybe<TableSortInput>;
  eventDate?: InputMaybe<TableSortInput>;
  eventType?: InputMaybe<TableSortInput>;
  username?: InputMaybe<TableSortInput>;
};

export type ListExtendHostsInput = {
  /** Page you would like to request */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Size of page you would like to request */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListExtendUsersInput = {
  /** Page you would like to request */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Size of page you would like to request */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Total number of results returned */
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFalconConstraintsFilterInput = {
  columnName: TableStringFilterInput;
  columnText?: InputMaybe<TableStringFilterInput>;
  columnValue?: InputMaybe<TableStringFilterInput>;
  tableName: TableStringFilterInput;
};

export type ListFalconConstraintsFilterType = {
  __typename?: 'ListFalconConstraintsFilterType';
  columnName?: Maybe<TableStringFilterType>;
  columnText?: Maybe<TableStringFilterType>;
  columnValue?: Maybe<TableStringFilterType>;
  tableName?: Maybe<TableStringFilterType>;
};

export type ListFalconConstraintsInput = {
  filter?: InputMaybe<ListFalconConstraintsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListFalconConstraintsSortInput>;
};

export type ListFalconConstraintsSortInput = {
  columnText?: InputMaybe<TableSortInput>;
  id?: InputMaybe<TableSortInput>;
  orderBy?: InputMaybe<TableSortInput>;
};

export type ListFalconConstraintsSortType = {
  __typename?: 'ListFalconConstraintsSortType';
  columnText?: Maybe<TableSortType>;
  id?: Maybe<TableSortType>;
  orderBy?: Maybe<TableSortType>;
};

export type ListFavoriteSitesInput = {
  /** Driver Alt Id required for admin requests */
  driverAltId?: InputMaybe<Scalars['UUID']['input']>;
  /** A float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** A float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
};

export type ListGeographicalRegionsFilterInput = {
  abbreviation?: InputMaybe<TableStringFilterInput>;
  country?: InputMaybe<TableStringFilterInput>;
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListGeographicalRegionsInput = {
  filter?: InputMaybe<ListGeographicalRegionsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListGeographicalRegionsSortInput>;
};

export type ListGeographicalRegionsSortInput = {
  abbreviation?: InputMaybe<TableSortInput>;
  name?: InputMaybe<TableSortInput>;
};

export type ListHostFilterInput = {
  hostStatusId?: InputMaybe<TableIntFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListHostFilterType = {
  __typename?: 'ListHostFilterType';
  status?: Maybe<TableStringFilterType>;
};

export type ListHostSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
};

export type ListHostSearchType = {
  __typename?: 'ListHostSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  hid?: Maybe<TableStringFilterType>;
  hostName?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
};

export type ListHostSortInput = {
  altId?: InputMaybe<TableSortInput>;
  hid?: InputMaybe<TableSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  status?: InputMaybe<TableSortInput>;
};

export type ListHostSortType = {
  __typename?: 'ListHostSortType';
  altId?: Maybe<TableSortType>;
  hid?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  status?: Maybe<TableSortType>;
};

export type ListHostsInput = {
  filter?: InputMaybe<ListHostFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListHostSearchInput>;
  sort?: InputMaybe<ListHostSortInput>;
};

export type ListHostsRootFilterInput = {
  and?: InputMaybe<HostFilterInput>;
  or?: InputMaybe<HostFilterInput>;
};

export type ListHostsRootFilterType = {
  __typename?: 'ListHostsRootFilterType';
  and?: Maybe<HostFilterType>;
  or?: Maybe<HostFilterType>;
};

/** Input type for ListInvoicesForMobile */
export type ListInvoicesInput = {
  /** The cardID to further limit results to invoices associated with a specific card */
  cardId?: InputMaybe<Scalars['ID']['input']>;
  /** The end of the date range in which to look for invoices to list; If NULL, uses server's current Date as default */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** The page number of results to request; page 0 is the first page */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** The number of results to include per page. Default is 9999 */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  /** The beginning of the date range in which to look for invoices to list */
  startDate: Scalars['Date']['input'];
};

export type ListNotificationGroupsFilterInput = {
  groupName?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationGroupsFilterType = {
  __typename?: 'ListNotificationGroupsFilterType';
  groupName?: Maybe<TableStringFilterType>;
};

export type ListNotificationGroupsInput = {
  filter?: InputMaybe<ListNotificationGroupsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListNotificationGroupsSearchInput>;
  sort?: InputMaybe<ListNotificationGroupsSortInput>;
};

export type ListNotificationGroupsSearchInput = {
  groupName?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationGroupsSearchType = {
  __typename?: 'ListNotificationGroupsSearchType';
  groupName?: Maybe<TableStringFilterType>;
};

export type ListNotificationGroupsSortInput = {
  groupName?: InputMaybe<TableSortInput>;
  sortOrder?: InputMaybe<TableSortInput>;
};

export type ListNotificationGroupsSortType = {
  __typename?: 'ListNotificationGroupsSortType';
  groupName?: Maybe<TableSortType>;
  sortOrder?: Maybe<TableSortType>;
};

export type ListNotificationItemsFilterInput = {
  driverLevel?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationItemsFilterType = {
  __typename?: 'ListNotificationItemsFilterType';
  driverLevel?: Maybe<TableStringFilterType>;
};

export type ListNotificationItemsInput = {
  filter?: InputMaybe<ListNotificationItemsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListNotificationItemsSearchInput>;
  sort?: InputMaybe<ListNotificationItemsSortInput>;
};

export type ListNotificationItemsSearchInput = {
  driverLevel?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationItemsSearchType = {
  __typename?: 'ListNotificationItemsSearchType';
  driverLevel?: Maybe<TableStringFilterType>;
};

export type ListNotificationItemsSortInput = {
  groupName?: InputMaybe<TableSortInput>;
  sortOrder?: InputMaybe<TableSortInput>;
};

export type ListNotificationItemsSortType = {
  __typename?: 'ListNotificationItemsSortType';
  groupName?: Maybe<TableSortType>;
  sortOrder?: Maybe<TableSortType>;
};

export type ListNotificationLogsFilterInput = {
  driverAltId?: InputMaybe<Scalars['String']['input']>;
  notificationItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListNotificationLogsInput = {
  filter?: InputMaybe<ListNotificationLogsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListNotificationLogsSortInput>;
};

export type ListNotificationLogsSortInput = {
  createdAt?: InputMaybe<TableSortInput>;
  sentOn?: InputMaybe<TableSortInput>;
};

export type ListNotificationLogsSortType = {
  __typename?: 'ListNotificationLogsSortType';
  sentOn?: Maybe<TableSortInput>;
};

export type ListNotificationPreferencesFilterInput = {
  emailOn?: InputMaybe<TableBooleanFilterInput>;
  pushOn?: InputMaybe<TableBooleanFilterInput>;
  smsOn?: InputMaybe<TableBooleanFilterInput>;
};

export type ListNotificationPreferencesFilterType = {
  __typename?: 'ListNotificationPreferencesFilterType';
  emailOn?: Maybe<TableBooleanFilterType>;
  pushOn?: Maybe<TableBooleanFilterType>;
  smsOn?: Maybe<TableBooleanFilterType>;
};

export type ListNotificationPreferencesInput = {
  filter?: InputMaybe<ListNotificationPreferencesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListNotificationPreferencesSearchInput>;
  sort?: InputMaybe<ListNotificationPreferencesSortInput>;
};

export type ListNotificationPreferencesSearchInput = {
  emailOn?: InputMaybe<TableBooleanFilterInput>;
  pushOn?: InputMaybe<TableBooleanFilterInput>;
  smsOn?: InputMaybe<TableBooleanFilterInput>;
};

export type ListNotificationPreferencesSearchType = {
  __typename?: 'ListNotificationPreferencesSearchType';
  emailOn?: Maybe<TableBooleanFilterType>;
  pushOn?: Maybe<TableBooleanFilterType>;
  smsOn?: Maybe<TableBooleanFilterType>;
};

export type ListNotificationPreferencesSortInput = {
  createdAt?: InputMaybe<TableSortInput>;
  updatedAt?: InputMaybe<TableSortInput>;
};

export type ListNotificationPreferencesSortType = {
  __typename?: 'ListNotificationPreferencesSortType';
  createdAt?: Maybe<TableSortType>;
  updatedAt?: Maybe<TableSortType>;
};

export type ListPaymentMethodsForMobileInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPlansFilterInput = {
  /** Array of plan altIds used to filter a list of plans */
  altId?: InputMaybe<TableStringFilterInput>;
  /** Boolean flag used to filter plans that display their card to users */
  displayPlanCard?: InputMaybe<TableBooleanFilterInput>;
  /** Boolean flag used to filter plans that are displayed to customers */
  displayToCustomer?: InputMaybe<TableBooleanFilterInput>;
  /** Boolean flag used to filter plans that are displayed to logged out users */
  displayToLoggedOutCustomer?: InputMaybe<TableBooleanFilterInput>;
  /** Value used to order plans displayed to mobile users */
  driverRank?: InputMaybe<TableIntFilterInput>;
  planStatusId?: InputMaybe<TableIntFilterInput>;
  tags?: InputMaybe<TableStringFilterInput>;
};

export type ListPlansInput = {
  filter?: InputMaybe<ListPlansFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListPlansSearchInput>;
  sort?: InputMaybe<ListPlansSortInput>;
};

export type ListPlansSearchInput = {
  planGroupName?: InputMaybe<TableStringFilterInput>;
  planName?: InputMaybe<TableStringFilterInput>;
};

export type ListPlansSearchType = {
  __typename?: 'ListPlansSearchType';
  planName?: Maybe<TableStringFilterType>;
};

export type ListPlansSortInput = {
  createdAt?: InputMaybe<TableSortInput>;
  driverRank?: InputMaybe<TableSortInput>;
  planDisplayName?: InputMaybe<TableSortInput>;
  planName?: InputMaybe<TableSortInput>;
};

export type ListPlansSortType = {
  __typename?: 'ListPlansSortType';
  createdAt?: Maybe<TableSortInput>;
  planDescription?: Maybe<TableSortInput>;
  planDisplayName?: Maybe<TableSortInput>;
  planName?: Maybe<TableSortInput>;
};

export type ListPortsFilterInput = {
  and?: InputMaybe<PortFilterInput>;
  connector?: InputMaybe<ListConnectorsRootFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<PortFilterInput>;
};

export type ListPortsInput = {
  filter?: InputMaybe<ListPortsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListPortsSearchInput>;
  sort?: InputMaybe<ListPortsSortInput>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPortsRootFilterInput = {
  and?: InputMaybe<PortFilterInput>;
  connector?: InputMaybe<ListConnectorsRootFilterInput>;
  or?: InputMaybe<PortFilterInput>;
};

export type ListPortsRootFilterType = {
  __typename?: 'ListPortsRootFilterType';
  and?: Maybe<PortFilterType>;
  connector?: Maybe<ListConnectorsRootFilterType>;
  or?: Maybe<PortFilterType>;
};

export type ListPortsSearchInput = {
  evcId?: InputMaybe<TableStringFilterInput>;
};

export type ListPortsSortInput = {
  chargeBoxIdentity?: InputMaybe<TableSortInput>;
};

export type ListProductsInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListProductsSearchInput>;
  sort?: InputMaybe<ListProductsSortInput>;
};

export type ListProductsSearchInput = {
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListProductsSearchType = {
  __typename?: 'ListProductsSearchType';
  name?: Maybe<TableStringFilterType>;
};

export type ListProductsSortInput = {
  createdAt?: InputMaybe<TableStringFilterInput>;
  description?: InputMaybe<TableStringFilterInput>;
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListProductsSortType = {
  __typename?: 'ListProductsSortType';
  createdAt?: Maybe<TableSortInput>;
  description?: Maybe<TableSortInput>;
  name?: Maybe<TableSortInput>;
};

export type ListPropertiesFilterInput = {
  host_hid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListPropertiesFilterType = {
  __typename?: 'ListPropertiesFilterType';
  status?: Maybe<TableStringFilterType>;
};

export type ListPropertiesInput = {
  filter?: InputMaybe<ListPropertiesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListPropertiesSearchInput>;
  sort?: InputMaybe<ListPropertiesSortInput>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPropertiesRootFilterInput = {
  and?: InputMaybe<PropertyFilterInput>;
  host?: InputMaybe<ListHostsRootFilterInput>;
  or?: InputMaybe<PropertyFilterInput>;
};

export type ListPropertiesRootFilterType = {
  __typename?: 'ListPropertiesRootFilterType';
  and?: Maybe<PropertyFilterType>;
  host?: Maybe<ListHostsRootFilterType>;
  or?: Maybe<PropertyFilterType>;
};

export type ListPropertiesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  pid?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListPropertiesSearchType = {
  __typename?: 'ListPropertiesSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  pid?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListPropertiesSortInput = {
  altId?: InputMaybe<TableSortInput>;
  host?: InputMaybe<ListHostSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  pid?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
  status?: InputMaybe<TableSortInput>;
};

export type ListPropertiesSortType = {
  __typename?: 'ListPropertiesSortType';
  altId?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  pid?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
  status?: Maybe<TableSortType>;
};

export type ListReservationTermsInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  siteId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ListReservationsByDriverInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type ListReservationsInput = {
  /** ID of the account to look up reservations for */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the card to filter reservations for; Required for listReservationsByCard */
  cardId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the charger to look up reservations for */
  chargerId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the connector to look up reservations for; Required for listAvailableSlots query */
  connectorId?: InputMaybe<Scalars['ID']['input']>;
  /** The end range of reservations to look up */
  endDate: Scalars['Date']['input'];
  /** The beginning range of reservations to look up */
  startDate: Scalars['Date']['input'];
};

export type ListSalesforceHostContactsFilterInput = {
  contactName?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostContactsInput = {
  filter?: InputMaybe<ListSalesforceHostContactsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListSalesforceHostContactsSortInput>;
};

export type ListSalesforceHostContactsSortInput = {
  contactName?: InputMaybe<TableSortInput>;
};

export type ListSalesforceHostsFilterInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostsInput = {
  filter?: InputMaybe<ListSalesforceHostsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListSalesforceHostsSearchInput>;
  sort?: InputMaybe<ListSalesforceHostsSortInput>;
};

export type ListSalesforceHostsSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  address2?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostsSortInput = {
  hid?: InputMaybe<TableSortInput>;
};

export type ListSalesforceHostsType = {
  __typename?: 'ListSalesforceHostsType';
  hostName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesFilterInput = {
  hid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforcePropertiesFilterType = {
  __typename?: 'ListSalesforcePropertiesFilterType';
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesInput = {
  filter?: InputMaybe<ListSalesforcePropertiesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListSalesforcePropertiesSearchInput>;
  sort?: InputMaybe<ListSalesforcePropertiesSortInput>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSalesforcePropertiesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  pid?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforcePropertiesSearchType = {
  __typename?: 'ListSalesforcePropertiesSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  pid?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesSortInput = {
  hostName?: InputMaybe<TableSortInput>;
  pid?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
};

export type ListSalesforcePropertiesSortType = {
  __typename?: 'ListSalesforcePropertiesSortType';
  hostName?: Maybe<TableSortType>;
  pid?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
};

export type ListSessionsFilterInput = {
  card_altId?: InputMaybe<TableStringFilterInput>;
  connector_evse_charger_altId?: InputMaybe<TableStringFilterInput>;
  connector_evse_charger_locationId?: InputMaybe<TableStringFilterInput>;
  endTime?: InputMaybe<TableTimestampRangeFilterInput>;
  sessionId?: InputMaybe<TableStringFilterInput>;
  sessionStartOn?: InputMaybe<TableTimestampRangeFilterInput>;
  sessionStoppedOn?: InputMaybe<TableTimestampRangeFilterInput>;
  startSource?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableTimestampRangeFilterInput>;
};

export type ListSessionsFilterType = {
  __typename?: 'ListSessionsFilterType';
  card_altId?: Maybe<TableStringFilterType>;
  connector_evse_charger_locationId?: Maybe<TableStringFilterType>;
  sessionId?: Maybe<TableStringFilterType>;
  sessionStartOn?: Maybe<TableTimestampRangeFilterType>;
  sessionStoppedOn?: Maybe<TableTimestampRangeFilterType>;
  startSource?: Maybe<TableStringFilterType>;
};

export type ListSessionsInput = {
  filter?: InputMaybe<ListSessionsFilterInput>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<ListSessionsSearchInput>;
  sort?: InputMaybe<ListSessionsSortInput>;
};

export type ListSessionsSearchInput = {
  card_externalNumber?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  sessionId?: InputMaybe<TableStringFilterInput>;
};

export type ListSessionsSearchType = {
  __typename?: 'ListSessionsSearchType';
  chargerId?: Maybe<TableStringFilterType>;
  sessionId?: Maybe<TableStringFilterType>;
};

export type ListSessionsSortInput = {
  sessionId?: InputMaybe<TableSortInput>;
};

export type ListSessionsV2FilterInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  altId?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  corrupted?: InputMaybe<TableBooleanFilterInput>;
  driverId?: InputMaybe<TableStringFilterInput>;
  endTime?: InputMaybe<TableDateFilterInput>;
  endTime2?: InputMaybe<TableDateFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  planName?: InputMaybe<TableStringFilterInput>;
  siteId?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableDateFilterInput>;
  startTime2?: InputMaybe<TableDateFilterInput>;
  transactionTypeId?: InputMaybe<TableStringFilterInput>;
  vendorIdStartsWith?: InputMaybe<Scalars['String']['input']>;
};

export type ListSessionsV2Input = {
  filter?: InputMaybe<ListSessionsV2FilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListSessionsV2FilterInput>;
  sort?: InputMaybe<Array<InputMaybe<SessionsV2Sort>>>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSiteFilterType = {
  __typename?: 'ListSiteFilterType';
  and?: Maybe<SiteFilterType>;
  charger?: Maybe<ListChargersRootFilterType>;
  distance?: Maybe<Scalars['Float']['output']>;
  distinct?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  neLat?: Maybe<Scalars['Float']['output']>;
  neLng?: Maybe<Scalars['Float']['output']>;
  or?: Maybe<SiteFilterType>;
  property?: Maybe<ListPropertiesRootFilterType>;
  siteStatusId?: Maybe<TableStringFilterType>;
  swLat?: Maybe<Scalars['Float']['output']>;
  swLng?: Maybe<Scalars['Float']['output']>;
};

export type ListSiteImagesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['ID']['input'];
};

export type ListSiteSearchType = {
  __typename?: 'ListSiteSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  property_host_hostName?: Maybe<TableStringFilterType>;
  sid?: Maybe<TableStringFilterType>;
  siteName?: Maybe<TableStringFilterType>;
  vendorId?: Maybe<TableStringFilterType>;
};

export type ListSitesConsumptionForExtendData = {
  __typename?: 'ListSitesConsumptionForExtendData';
  /** Address of that site */
  address?: Maybe<Scalars['String']['output']>;
  /** State of that site */
  administrativeArea?: Maybe<Scalars['String']['output']>;
  /** Average kWh dispensed */
  averageSessionPower: Scalars['Float']['output'];
  /** Average session time in HH:MM:SS format */
  averageSessionTime?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
  siteLatitude?: Maybe<Scalars['Float']['output']>;
  siteLongitude?: Maybe<Scalars['Float']['output']>;
  siteName: Scalars['String']['output'];
  siteStatus: Scalars['String']['output'];
  /** Total chargers in that site, in that time interval */
  totalChargers: Scalars['Int']['output'];
  /** Total sessions in that site, in that time interval */
  totalSessions: Scalars['Int']['output'];
  /** Total cost value of all the sessions in $, in that site, in that time interval */
  totalSessionsCost: Scalars['Float']['output'];
  /** Total power value dispensed in all the sessions in kWh, in that site, in that time interval */
  totalSessionsPower: Scalars['Float']['output'];
  /** Total session time to second precision */
  totalSessionsTime?: Maybe<Scalars['String']['output']>;
};

/** Filter the session consumption data in that datePreset, for all sessions in these site altIds */
export type ListSitesConsumptionForExtendFilterInput = {
  /** Filter the timeseries data by charger groups, if not send will return for all charger groups */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datePreset?: InputMaybe<SessionDatePreset>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter the timeseries data by plans, if not send will return for all plans */
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter the sessions in these sites, if not send will return for all sites */
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter the timeseries data by transaction type, if not send will return only for the default eXtend+ types */
  transactionTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ListSitesConsumptionForExtendInput = {
  filter: ListSitesConsumptionForExtendFilterInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSitesConsumptionForExtendWithMeta = {
  __typename?: 'ListSitesConsumptionForExtendWithMeta';
  edges?: Maybe<Array<ListSitesConsumptionForExtendData>>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type ListSitesFilterInput = {
  and?: InputMaybe<SiteFilterInput>;
  charger?: InputMaybe<ListChargersRootFilterInput>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  neLat?: InputMaybe<Scalars['Float']['input']>;
  neLng?: InputMaybe<Scalars['Float']['input']>;
  or?: InputMaybe<SiteFilterInput>;
  property?: InputMaybe<ListPropertiesRootFilterInput>;
  siteStatusId?: InputMaybe<TableIntFilterInput>;
  swLat?: InputMaybe<Scalars['Float']['input']>;
  swLng?: InputMaybe<Scalars['Float']['input']>;
};

export type ListSitesForMobileInput = {
  /** An array of integers used to filter by connector types. */
  connectorTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int']['input'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']['input']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']['input']>;
  /** DEPRECATED - no longer used in listSitesForMobile */
  outputWattsMax?: InputMaybe<Scalars['Int']['input']>;
  /** DEPRECATED - no longer used in listSitesForMobile */
  outputWattsMin?: InputMaybe<Scalars['Int']['input']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ListSitesInput = {
  filter?: InputMaybe<ListSitesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListSitesSearchInput>;
  sort?: InputMaybe<ListSitesSortInput>;
};

export type ListSitesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  displayName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  property_host_hostName?: InputMaybe<TableStringFilterInput>;
  sid?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  vendorId?: InputMaybe<TableStringFilterInput>;
};

export type ListSitesSortInput = {
  distance?: InputMaybe<TableSortInput>;
  property?: InputMaybe<ListPropertiesSortInput>;
  sid?: InputMaybe<TableSortInput>;
  siteName?: InputMaybe<TableSortInput>;
  siteStatusId?: InputMaybe<TableSortInput>;
};

export type ListSitesSortType = {
  __typename?: 'ListSitesSortType';
  hostName?: Maybe<TableSortType>;
  sid?: Maybe<TableSortType>;
  siteName?: Maybe<TableSortType>;
  siteStatusId?: Maybe<TableSortType>;
};

export type ListStatementsInput = {
  /** The end date in ISO format */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** The start date in ISO format */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ListTagsFilterInput = {
  description?: InputMaybe<TableStringFilterInput>;
  tagName?: InputMaybe<TableStringFilterInput>;
};

export type ListTagsInput = {
  filter?: InputMaybe<ListTagsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListTagsSearchInput>;
  sort?: InputMaybe<ListTagsSortInput>;
};

export type ListTagsSearchInput = {
  tagName?: InputMaybe<TableStringFilterInput>;
};

export type ListTagsSearchType = {
  __typename?: 'ListTagsSearchType';
  tagName?: Maybe<TableStringFilterType>;
};

export type ListTagsSortInput = {
  tagName?: InputMaybe<TableSortInput>;
};

export type ListTagsSortType = {
  __typename?: 'ListTagsSortType';
  tagName?: Maybe<Scalars['String']['output']>;
};

export type ListTariffConnectorTypesInput = {
  tariffId: Scalars['ID']['input'];
};

export type ListTariffsFilterInput = {
  hostId?: InputMaybe<TableIntFilterInput>;
};

export type ListTariffsInput = {
  filter?: InputMaybe<ListTariffsFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<TariffsSort>>>;
};

export type ListTariffsSearchType = {
  __typename?: 'ListTariffsSearchType';
  tariffDescription?: Maybe<TableStringFilterType>;
  /** @deprecated replaced by ChargerPricing types */
  tariffName?: Maybe<TableStringFilterType>;
};

export type ListTariffsSortInput = {
  altId?: InputMaybe<TableSortInput>;
  createdAt?: InputMaybe<TableSortInput>;
  tariffDescription?: InputMaybe<TableSortInput>;
  tariffName?: InputMaybe<TableSortInput>;
  tariffRank?: InputMaybe<TableSortInput>;
};

export type ListTariffsSortType = {
  __typename?: 'ListTariffsSortType';
  /** @deprecated replaced by ChargerPricing types */
  altId?: Maybe<TableSortType>;
  createdAt?: Maybe<TableSortType>;
  tariffDescription?: Maybe<TableSortType>;
  tariffName?: Maybe<TableSortType>;
  tariffRank?: Maybe<TableSortType>;
};

export type ListTransactionsForMobileInput = {
  cardId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['Date']['input'];
};

export type ListTransactionsInput = {
  accountId: Scalars['ID']['input'];
  cardId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate: Scalars['Date']['input'];
};

export type ListTransactionsV2FilterInput = {
  altId?: InputMaybe<TableStringFilterInput>;
  chargerGroupId?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  createdAt?: InputMaybe<TableDateFilterInput>;
  createdAt2?: InputMaybe<TableDateFilterInput>;
  driverId?: InputMaybe<TableIntFilterInput>;
  endTime?: InputMaybe<TableDateFilterInput>;
  endTime2?: InputMaybe<TableDateFilterInput>;
  endTimeLocal?: InputMaybe<TableDateFilterInput>;
  id?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  planName?: InputMaybe<TableStringFilterInput>;
  siteId?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableDateFilterInput>;
  startTime2?: InputMaybe<TableDateFilterInput>;
  startTimeLocal?: InputMaybe<TableDateFilterInput>;
  transactionTypeId?: InputMaybe<TableStringFilterInput>;
  updatedAt?: InputMaybe<TableDateFilterInput>;
  updatedAt2?: InputMaybe<TableDateFilterInput>;
  vendorId?: InputMaybe<TableIntFilterInput>;
};

export type ListTransactionsV2Input = {
  filter?: InputMaybe<ListTransactionsV2FilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListTransactionsV2FilterInput>;
  sort?: InputMaybe<Array<InputMaybe<TransactionsV2Sort>>>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type ListUnassociatedChargersForFleetInput = {
  siteId: Scalars['ID']['input'];
};

export type ListUploadsFilterInput = {
  url?: InputMaybe<TableStringFilterInput>;
};

export type ListUploadsInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ListUploadsFilterInput>;
};

export type ListVehicleConnectorsInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListVehiclesInput = {
  fuzzySearch?: InputMaybe<Scalars['SanitizeString']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListWarehousesFilterInput = {
  warehouseName?: InputMaybe<TableStringFilterInput>;
};

export type ListWarehousesInput = {
  filter?: InputMaybe<ListWarehousesFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ListWarehousesSortInput>;
};

export type ListWarehousesSortInput = {
  warehouseName?: InputMaybe<TableSortInput>;
};

export type ListWarehousesSortType = {
  __typename?: 'ListWarehousesSortType';
  warehouseName?: Maybe<TableSortInput>;
};

export type LogoUploadUrl = {
  __typename?: 'LogoUploadUrl';
  filename: Scalars['String']['output'];
  hiddenFormFieldsJSON: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum MaintenanceVendor {
  /** BTC */
  Btc = 'BTC',
  /** MaxGen */
  Maxgen = 'MAXGEN',
}

export type MandrillResponse = {
  __typename?: 'MandrillResponse';
  mandrillId: Scalars['ID']['output'];
  recipientEmail: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum Manufacturer {
  /** ABB */
  Abb = 'ABB',
  /** AV */
  Av = 'AV',
  Bosch = 'BOSCH',
  /** BTC */
  Btc = 'BTC',
  /** Delta */
  Delta = 'DELTA',
  Efacec = 'EFACEC',
  /** LiteOn */
  Liteon = 'LITEON',
  /** Nissan */
  Nissan = 'NISSAN',
  Semaconnect = 'SEMACONNECT',
  /** Signet */
  Signet = 'SIGNET',
  /** SPX */
  Spx = 'SPX',
}

export type Media = {
  __typename?: 'Media';
  altId: Scalars['UUID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MobileTransaction = {
  __typename?: 'MobileTransaction';
  authorization?: Maybe<Scalars['String']['output']>;
  billingTax?: Maybe<Scalars['Float']['output']>;
  charger?: Maybe<Charger>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  driverName?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Date']['output']>;
  energyDeliveredKw?: Maybe<Scalars['Float']['output']>;
  lineItems?: Maybe<Array<Maybe<MobileTransactionLineItem>>>;
  networkOperator?: Maybe<NetworkOperator>;
  powerLevel?: Maybe<Scalars['Float']['output']>;
  sessionDuration?: Maybe<Scalars['Time']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Site>;
  startTime?: Maybe<Scalars['Date']['output']>;
  stopReason?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  transactionCategory?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type MobileTransactionLineItem = {
  __typename?: 'MobileTransactionLineItem';
  amount?: Maybe<Scalars['Float']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['String']['output']>;
};

export type MobileTransactionsWithMeta = {
  __typename?: 'MobileTransactionsWithMeta';
  edges?: Maybe<Array<Maybe<MobileTransaction>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCard?: Maybe<Scalars['Void']['output']>;
  activateCardForMobile?: Maybe<Scalars['Void']['output']>;
  activateCards?: Maybe<Array<Maybe<Card>>>;
  /** Mutation for adding chargers to a charger group */
  addChargersToChargerGroup?: Maybe<ChargerGroup>;
  addFavoriteSite?: Maybe<DriverFavoriteSite>;
  assignCard?: Maybe<Card>;
  assignCardForMobile?: Maybe<Card>;
  assignCards?: Maybe<Array<Maybe<Card>>>;
  authenticateUser: AuthResponse;
  cancelAutochargeEnrollment: AccountVehicle;
  cancelInviteDriver: Driver;
  /** cancel a reservation for the current authenticated driver */
  cancelReservation?: Maybe<Reservation>;
  /** cancel a reservation for a specified driver */
  cancelReservationForDriver?: Maybe<Reservation>;
  checkDriverFirebase?: Maybe<Scalars['Void']['output']>;
  checkDriverPhoneNumber: PhoneNumberCheck;
  completeAutochargeEnrollment?: Maybe<Scalars['Void']['output']>;
  createAccount?: Maybe<Account>;
  createAccountOwner: Driver;
  createAccountOwnerWithoutPhone: Driver;
  createAccountVehicle?: Maybe<AccountVehicle>;
  createAdHocContract?: Maybe<AdHocContract>;
  createCard?: Maybe<Card>;
  createCardOrder?: Maybe<Scalars['Void']['output']>;
  createCharger: Charger;
  /** Mutation for creating a charger group */
  createChargerGroup?: Maybe<ChargerGroup>;
  /** Mutation for creating a charger group plan */
  createChargerGroupPlan?: Maybe<ChargerGroup>;
  createChargerModel: ChargerModel;
  createClientToken: ClientToken;
  createContact?: Maybe<Contact>;
  createContract?: Maybe<Contract>;
  createCreditForUser?: Maybe<CreditBucket>;
  createCreditSource?: Maybe<CreditSource>;
  /** This is to create tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  createDriivzTag?: Maybe<Tag>;
  createDriver: Driver;
  createDriverForMobile: Driver;
  /** Flag a plan as requiring custom calculated transactions by connector pricing */
  createExtendCalculatedHostPlan?: Maybe<Success>;
  createExtendHost: ExtendHost;
  createExtendUser?: Maybe<ExtendUser>;
  createFleetUser: User;
  createHost: Host;
  /** Create an s3 pre-signed upload url which will accept png images */
  createLogoUploadUrl: LogoUploadUrl;
  createPaymentCardForMobile?: Maybe<Scalars['Void']['output']>;
  createPlan?: Maybe<Plan>;
  createPlanTariff?: Maybe<Plan>;
  /** Mutation to create a custom plan's tariffs.  This will be deprecated in a future iteration.  updatePlanTariffs already handles both update and create */
  createPlanTariffs: ChargerGroup;
  createProperty: Property;
  /** create a reservation for the current authenticated driver */
  createReservation?: Maybe<Reservation>;
  /** create a reservation for a specified driver */
  createReservationForDriver?: Maybe<Reservation>;
  createSession?: Maybe<Session>;
  createSite: Site;
  createTag?: Maybe<Tag>;
  /** Mutation to create a tariff */
  createTariff: ChargerPricing;
  createTariffConnectorType?: Maybe<TariffConnectorTypes>;
  createUpload: Media;
  createUploads: Array<Maybe<Media>>;
  createUser: User;
  createVehicle?: Maybe<VehicleV2>;
  createVehicleMake?: Maybe<VehicleMakeV2>;
  createVehicleModel?: Maybe<VehicleModelV2>;
  deactivateCards?: Maybe<Array<Maybe<Card>>>;
  deactivateChargerGroupPlanForExtend?: Maybe<ChargerGroup>;
  deleteAccountVehicle?: Maybe<Success>;
  deleteCharger: Charger;
  /** Mutation for deleting a charger group */
  deleteChargerGroup?: Maybe<ChargerGroup>;
  /** This is to delete tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  deleteDriivzTag?: Maybe<Scalars['Void']['output']>;
  deleteDriver?: Maybe<Scalars['Void']['output']>;
  /** Remove a plan as requiring custom calculated transactions by connector pricing */
  deleteExtendCalculatedHostPlan?: Maybe<Success>;
  deleteExtendHost?: Maybe<Success>;
  deleteExtendUserHosts?: Maybe<ExtendUser>;
  deleteHost: Host;
  deletePaymentMethod?: Maybe<Scalars['Void']['output']>;
  deletePaymentMethodForMobile?: Maybe<Scalars['Void']['output']>;
  deletePlanTariff?: Maybe<Scalars['Void']['output']>;
  deleteTag?: Maybe<Tag>;
  /** Delete a tariff */
  deleteTariff?: Maybe<Scalars['Void']['output']>;
  deleteVehicle?: Maybe<VehicleV2>;
  deleteVehicleIdCard?: Maybe<Success>;
  deleteVehicleMake?: Maybe<VehicleMakeV2>;
  deleteVehicleModel?: Maybe<VehicleModelV2>;
  disassociateAccountVehicle?: Maybe<Success>;
  enrollDriver: Success;
  enrollExistingUsers?: Maybe<Array<Maybe<Reward>>>;
  enrollNewUsers?: Maybe<Array<Maybe<Reward>>>;
  enrollThirdPartyWallet?: Maybe<Scalars['Void']['output']>;
  enrollUserInRewards?: Maybe<Scalars['Void']['output']>;
  handleAccountEvent?: Maybe<Scalars['Boolean']['output']>;
  handleAccountStatusEvent?: Maybe<Scalars['Boolean']['output']>;
  handleCardEvent?: Maybe<Scalars['Boolean']['output']>;
  handleContractEvent?: Maybe<Scalars['Boolean']['output']>;
  handlePaymentMethodEvent?: Maybe<Scalars['Boolean']['output']>;
  handleSessionEvent?: Maybe<Scalars['Boolean']['output']>;
  handleSessionMeterEvent?: Maybe<Scalars['Boolean']['output']>;
  importAccountDrivers: Scalars['Int']['output'];
  importAccountOwner: Scalars['Int']['output'];
  importAccountVehicles?: Maybe<Scalars['Int']['output']>;
  importCards?: Maybe<Scalars['Int']['output']>;
  importContracts?: Maybe<Scalars['Int']['output']>;
  importHost: Host;
  importPaymentMethods?: Maybe<Scalars['Int']['output']>;
  importProperty: Property;
  importVehicles?: Maybe<Array<Maybe<ElectricVehicle>>>;
  inviteDriver: MandrillResponse;
  /** @deprecated Cleanup - use optInPromotions instead! */
  optIn?: Maybe<OptIn>;
  optInPromotions?: Maybe<Scalars['Void']['output']>;
  reassignCard?: Maybe<Card>;
  refreshTags?: Maybe<Array<Maybe<Charger>>>;
  registerAccountOwner: Driver;
  reinstateDriver?: Maybe<Scalars['Void']['output']>;
  /** Mutation for removing chargers from a charger group */
  removeChargersFromChargerGroup?: Maybe<ChargerGroup>;
  removeDriver?: Maybe<Scalars['Void']['output']>;
  removeFavoriteSite?: Maybe<Scalars['Void']['output']>;
  replaceCard?: Maybe<Card>;
  reserveCards?: Maybe<Array<Maybe<Card>>>;
  resetChargerForExtend?: Maybe<Scalars['Void']['output']>;
  resetDriverPassword: MandrillResponse;
  resetNonDriverPassword?: Maybe<Success>;
  /** Remove a phone number from all databases, freeing the number to be used again */
  reusePhoneNumber?: Maybe<Scalars['Void']['output']>;
  root?: Maybe<Scalars['SanitizeString']['output']>;
  sendAddPaymentMethodEmail?: Maybe<Scalars['Void']['output']>;
  sendCardOrderNotifications?: Maybe<Scalars['Void']['output']>;
  sendContractNotifications?: Maybe<Scalars['Void']['output']>;
  sendDisplayTariff: Array<SendTariffResponse>;
  sendEmailMessage?: Maybe<Success>;
  sendEndSessionTariff: Array<SendTariffResponse>;
  sendNewBillingStatementNotification?: Maybe<Scalars['Void']['output']>;
  sendPasswordResetEmail?: Maybe<Success>;
  sendPaymentMethodNotifications?: Maybe<SendPaymentMethodNotificationsResponse>;
  sendRewardsConfirmationEmail?: Maybe<SendRewardsConfirmationEmailReturn>;
  sendSessionTariff: Array<SendTariffResponse>;
  sendSetTariff: Array<SendTariffResponse>;
  sendTransactionSms?: Maybe<Scalars['Void']['output']>;
  sessionStartRequest?: Maybe<SessionStartResponse>;
  setChargingProfile: SetChargingProfileStatusResponse;
  /** Mutation to set demand limit for retail demand management */
  setDemandLimit: Site;
  setPrimaryCardForMobile?: Maybe<Scalars['Void']['output']>;
  startAutochargeEnrollment: AccountVehicle;
  startCharge?: Maybe<Scalars['Void']['output']>;
  startChargeForFleet?: Maybe<Scalars['Void']['output']>;
  startChargeForMobile?: Maybe<Scalars['Void']['output']>;
  stopCharge?: Maybe<Scalars['Void']['output']>;
  stopChargeForMobile?: Maybe<Scalars['Void']['output']>;
  suspendCards?: Maybe<Array<Maybe<Card>>>;
  syncAssignCard?: Maybe<Card>;
  syncChargerModelConnector?: Maybe<ChargerModelConnector>;
  syncChargerStatus: Charger;
  syncConnector?: Maybe<Connector>;
  syncContract?: Maybe<Contract>;
  syncCreateAccountOwner: Driver;
  syncCreateAdHocContract?: Maybe<AdHocContract>;
  syncCreateCardOrder?: Maybe<CardOrder>;
  syncCreateCharger: Charger;
  syncCreateChargerModel: ChargerModel;
  syncCreateContract?: Maybe<Contract>;
  syncCreateDriver?: Maybe<Driver>;
  syncCreateHost: Host;
  syncCreateProperty: Property;
  syncCreateSite: Site;
  syncPasswordReset: Scalars['String']['output'];
  syncPlan?: Maybe<Plan>;
  syncSite: Site;
  syncUpdateAccount: Account;
  syncUpdateAccountOwner: Driver;
  syncUpdateCharger: Charger;
  syncUpdateContract?: Maybe<Contract>;
  syncUpdateDriver?: Maybe<Driver>;
  syncUser?: Maybe<Scalars['Void']['output']>;
  /** Mutation to toggle demand limit for retail demand management */
  toggleDemandLimit: Site;
  toggleREMSafeMode: Site;
  unassignCards?: Maybe<Array<Maybe<Card>>>;
  unenrollThirdPartyWallet?: Maybe<Scalars['Void']['output']>;
  updateAccount?: Maybe<Account>;
  updateAccountOwner: Driver;
  updateAccountVehicle?: Maybe<AccountVehicle>;
  updateAdHocContract?: Maybe<AdHocContract>;
  updateCard?: Maybe<Card>;
  updateCardOrders?: Maybe<Array<Maybe<CardOrder>>>;
  updateCardStatus?: Maybe<Card>;
  updateCharger: Charger;
  /** Mutation for updating a charger group and assing chargers to it */
  updateChargerGroup?: Maybe<ChargerGroup>;
  /** Mutation for updating a charger group plan */
  updateChargerGroupPlan?: Maybe<ChargerGroup>;
  updateChargerModel: ChargerModel;
  updateChargerModelConnector?: Maybe<ChargerModelConnector>;
  updateConnector?: Maybe<Connector>;
  updateConnectorPowerLimit?: Maybe<Connector>;
  /** Mutation to allow updating a connectors ability to be reserved */
  updateConnectorReservable?: Maybe<ConnectorV2>;
  updateContract?: Maybe<Contract>;
  /** This is to update tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  updateDriivzTag?: Maybe<Tag>;
  updateDriver: Driver;
  updateExtendUserHosts?: Maybe<ExtendUser>;
  updateFleetUser: User;
  updateHost: Host;
  updatePassword?: Maybe<Scalars['Void']['output']>;
  updatePlan?: Maybe<Plan>;
  updatePlanDriverRank?: Maybe<DriverRankUpdateResponse>;
  updatePlanTariff?: Maybe<Plan>;
  /** Mutation to update a custom plan's tariffs */
  updatePlanTariffs: ChargerGroup;
  updatePricingRunStatus?: Maybe<Success>;
  updateProperty: Property;
  updateRewardsUser?: Maybe<Array<Maybe<RewardsUser>>>;
  updateSession?: Maybe<Session>;
  updateSite: Site;
  /** Mutation to update a site */
  updateSiteForExtend: Site;
  updateSiteReex: Site;
  updateTag?: Maybe<Tag>;
  /** Mutation to update a tariff */
  updateTariff: ChargerPricing;
  updateUser: User;
  updateVehicle?: Maybe<VehicleV2>;
  updateVehicleMake?: Maybe<VehicleMakeV2>;
  updateVehicleModel?: Maybe<VehicleModelV2>;
  upsertCard?: Maybe<Card>;
  upsertCardOrder?: Maybe<CardOrder>;
  upsertExtendHmiDisplayLabels: ExtendHmiDisplayLabels;
  upsertPaymentMethod?: Maybe<Scalars['Void']['output']>;
  upsertSessionMeter?: Maybe<SessionMeter>;
  /** Create or update a tariff */
  upsertTariff: UpsertTariffResponse;
  validateAddress: Address;
  verifyDriverEmail: MandrillResponse;
};

export type MutationActivateCardArgs = {
  input: ActivateCardInput;
};

export type MutationActivateCardForMobileArgs = {
  input: ActivateCardForMobileInput;
};

export type MutationActivateCardsArgs = {
  input: ActivateCardsInput;
};

export type MutationAddChargersToChargerGroupArgs = {
  input: AddChargersToChargerGroupInput;
};

export type MutationAddFavoriteSiteArgs = {
  input: AddFavoriteSiteInput;
};

export type MutationAssignCardArgs = {
  input?: InputMaybe<AssignCardInput>;
};

export type MutationAssignCardForMobileArgs = {
  input?: InputMaybe<AssignCardForMobileInput>;
};

export type MutationAssignCardsArgs = {
  input: AssignCardsInput;
};

export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};

export type MutationCancelAutochargeEnrollmentArgs = {
  input: CancelAutochargeEnrollmentInput;
};

export type MutationCancelInviteDriverArgs = {
  input?: InputMaybe<CancelInviteDriverInput>;
};

export type MutationCancelReservationArgs = {
  input: CancelReservationInput;
};

export type MutationCancelReservationForDriverArgs = {
  input: CancelReservationForDriverInput;
};

export type MutationCheckDriverFirebaseArgs = {
  input: CheckDriverFirebaseInput;
};

export type MutationCheckDriverPhoneNumberArgs = {
  input: CheckDriverPhoneNumberInput;
};

export type MutationCompleteAutochargeEnrollmentArgs = {
  input: CompleteAutochargeEnrollmentInput;
};

export type MutationCreateAccountArgs = {
  input?: InputMaybe<CreateAccountInput>;
};

export type MutationCreateAccountOwnerArgs = {
  input?: InputMaybe<CreateAccountOwnerInput>;
};

export type MutationCreateAccountOwnerWithoutPhoneArgs = {
  input?: InputMaybe<CreateAccountOwnerWithoutPhoneInput>;
};

export type MutationCreateAccountVehicleArgs = {
  input?: InputMaybe<CreateAccountVehicleInput>;
};

export type MutationCreateAdHocContractArgs = {
  input: CreateAdHocContractInput;
};

export type MutationCreateCardArgs = {
  input: CreateCardInput;
};

export type MutationCreateCardOrderArgs = {
  input: CreateCardOrderInput;
};

export type MutationCreateChargerArgs = {
  input?: InputMaybe<CreateChargerInput>;
};

export type MutationCreateChargerGroupArgs = {
  input: CreateChargerGroupInput;
};

export type MutationCreateChargerGroupPlanArgs = {
  input: CreateChargerGroupPlanInput;
};

export type MutationCreateChargerModelArgs = {
  input?: InputMaybe<CreateChargerModelInput>;
};

export type MutationCreateContactArgs = {
  input?: InputMaybe<CreateContactInput>;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateCreditForUserArgs = {
  input: CreateCreditForUserInput;
};

export type MutationCreateCreditSourceArgs = {
  input: CreateCreditSourceInput;
};

export type MutationCreateDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationCreateDriverArgs = {
  input?: InputMaybe<CreateDriverInput>;
};

export type MutationCreateDriverForMobileArgs = {
  input?: InputMaybe<CreateDriverInput>;
};

export type MutationCreateExtendCalculatedHostPlanArgs = {
  planId: Scalars['Int']['input'];
};

export type MutationCreateExtendHostArgs = {
  input: CreateExtendHostInput;
};

export type MutationCreateExtendUserArgs = {
  input?: InputMaybe<CreateExtendUserInput>;
};

export type MutationCreateFleetUserArgs = {
  input: CreateFleetUserInput;
};

export type MutationCreateHostArgs = {
  input?: InputMaybe<CreateHostInput>;
};

export type MutationCreateLogoUploadUrlArgs = {
  input?: InputMaybe<CreateLogoUploadUrlInput>;
};

export type MutationCreatePaymentCardForMobileArgs = {
  input: CreatePaymentCardForMobileInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreatePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationCreatePlanTariffsArgs = {
  input: CreatePlanTariffsInput;
};

export type MutationCreatePropertyArgs = {
  input?: InputMaybe<CreatePropertyInput>;
};

export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};

export type MutationCreateReservationForDriverArgs = {
  input: CreateReservationForDriverInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationCreateSiteArgs = {
  input?: InputMaybe<CreateSiteInput>;
};

export type MutationCreateTagArgs = {
  input?: InputMaybe<CreateTagInput>;
};

export type MutationCreateTariffArgs = {
  input: CreateTariffInput;
};

export type MutationCreateTariffConnectorTypeArgs = {
  input: CreateTariffConnectorTypeInput;
};

export type MutationCreateUploadArgs = {
  input: Scalars['Upload']['input'];
};

export type MutationCreateUploadsArgs = {
  input?: InputMaybe<CreateUploadsInput>;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput;
};

export type MutationCreateVehicleMakeArgs = {
  input: CreateVehicleMakeInput;
};

export type MutationCreateVehicleModelArgs = {
  input: CreateVehicleModelInput;
};

export type MutationDeactivateCardsArgs = {
  input: DeactivateCardsInput;
};

export type MutationDeactivateChargerGroupPlanForExtendArgs = {
  input: DeactivateChargerGroupPlanForExtendInput;
};

export type MutationDeleteAccountVehicleArgs = {
  input?: InputMaybe<DeleteAccountVehicleInput>;
};

export type MutationDeleteChargerArgs = {
  input?: InputMaybe<DeleteChargerInput>;
};

export type MutationDeleteChargerGroupArgs = {
  input: DeleteChargerGroupInput;
};

export type MutationDeleteDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationDeleteDriverArgs = {
  input?: InputMaybe<DeleteDriverInput>;
};

export type MutationDeleteExtendCalculatedHostPlanArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteExtendHostArgs = {
  input: DeleteExtendHostInput;
};

export type MutationDeleteExtendUserHostsArgs = {
  input?: InputMaybe<DeleteExtendUserHostsInput>;
};

export type MutationDeleteHostArgs = {
  input?: InputMaybe<DeleteHostInput>;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationDeletePaymentMethodForMobileArgs = {
  input: DeletePaymentMethodForMobileInput;
};

export type MutationDeletePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationDeleteTagArgs = {
  input?: InputMaybe<DeleteTagInput>;
};

export type MutationDeleteTariffArgs = {
  input: DeleteTariffInput;
};

export type MutationDeleteVehicleArgs = {
  input: DeleteVehicleInput;
};

export type MutationDeleteVehicleIdCardArgs = {
  input: DeleteVehicleIdCardInput;
};

export type MutationDeleteVehicleMakeArgs = {
  input: DeleteVehicleMakeInput;
};

export type MutationDeleteVehicleModelArgs = {
  input: DeleteVehicleModelInput;
};

export type MutationDisassociateAccountVehicleArgs = {
  input?: InputMaybe<DisassociateAccountVehicleInput>;
};

export type MutationEnrollDriverArgs = {
  input: EnrollDriverInput;
};

export type MutationEnrollExistingUsersArgs = {
  input: EnrollExistingUserInput;
};

export type MutationEnrollThirdPartyWalletArgs = {
  input: ThirdPartyWalletInput;
};

export type MutationEnrollUserInRewardsArgs = {
  input: EnrollUserInRewardsInput;
};

export type MutationHandleAccountEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleAccountStatusEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleCardEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleContractEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandlePaymentMethodEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleSessionEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleSessionMeterEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationImportAccountDriversArgs = {
  input?: InputMaybe<ImportAccountOwnerInput>;
};

export type MutationImportAccountOwnerArgs = {
  input?: InputMaybe<ImportAccountOwnerInput>;
};

export type MutationImportAccountVehiclesArgs = {
  input: ImportAccountVehiclesInput;
};

export type MutationImportCardsArgs = {
  input: ImportCardsInput;
};

export type MutationImportContractsArgs = {
  input: ImportContractsInput;
};

export type MutationImportHostArgs = {
  input?: InputMaybe<ImportHostInput>;
};

export type MutationImportPaymentMethodsArgs = {
  input: ImportPaymentMethodsInput;
};

export type MutationImportPropertyArgs = {
  input?: InputMaybe<ImportPropertyInput>;
};

export type MutationImportVehiclesArgs = {
  input: ImportVehiclesInput;
};

export type MutationInviteDriverArgs = {
  input?: InputMaybe<InviteDriverInput>;
};

export type MutationOptInArgs = {
  input?: InputMaybe<OptInInput>;
};

export type MutationOptInPromotionsArgs = {
  input?: InputMaybe<OptInPromotionsInput>;
};

export type MutationReassignCardArgs = {
  input: ReassignCardInput;
};

export type MutationRefreshTagsArgs = {
  input?: InputMaybe<RefreshTagsInput>;
};

export type MutationRegisterAccountOwnerArgs = {
  input?: InputMaybe<RegisterAccountOwnerInput>;
};

export type MutationReinstateDriverArgs = {
  input: ReinstateDriverInput;
};

export type MutationRemoveChargersFromChargerGroupArgs = {
  input: RemoveChargersFromChargerGroupInput;
};

export type MutationRemoveDriverArgs = {
  input?: InputMaybe<RemoveDriverInput>;
};

export type MutationRemoveFavoriteSiteArgs = {
  input: AddFavoriteSiteInput;
};

export type MutationReplaceCardArgs = {
  input: ReplaceCardInput;
};

export type MutationReserveCardsArgs = {
  input: ReserveCardsInput;
};

export type MutationResetChargerForExtendArgs = {
  input: ResetChargerForExtendInput;
};

export type MutationResetDriverPasswordArgs = {
  input?: InputMaybe<ResetDriverPasswordInput>;
};

export type MutationResetNonDriverPasswordArgs = {
  input: ResetNonDriverPasswordInput;
};

export type MutationReusePhoneNumberArgs = {
  input: ReusePhoneNumberInput;
};

export type MutationSendAddPaymentMethodEmailArgs = {
  input: SendAddPaymentMethodEmailInput;
};

export type MutationSendCardOrderNotificationsArgs = {
  input: CardOrderNotificationInput;
};

export type MutationSendContractNotificationsArgs = {
  input: ContractExpirationInput;
};

export type MutationSendDisplayTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendEmailMessageArgs = {
  input?: InputMaybe<SendEmailMessageInput>;
};

export type MutationSendEndSessionTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendNewBillingStatementNotificationArgs = {
  input: BillingStatementNotificationInput;
};

export type MutationSendPasswordResetEmailArgs = {
  input?: InputMaybe<SendPasswordResetInput>;
};

export type MutationSendPaymentMethodNotificationsArgs = {
  input: PaymentMethodExpirationInput;
};

export type MutationSendRewardsConfirmationEmailArgs = {
  input: SendRewardsConfirmationEmailInput;
};

export type MutationSendSessionTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendSetTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendTransactionSmsArgs = {
  input: SendTransactionSmsInput;
};

export type MutationSessionStartRequestArgs = {
  input: SessionStartInput;
};

export type MutationSetChargingProfileArgs = {
  input?: InputMaybe<SetChargingProfileInput>;
};

export type MutationSetDemandLimitArgs = {
  input: SetDemandLimitInput;
};

export type MutationSetPrimaryCardForMobileArgs = {
  input: SetPrimaryCardForMobileInput;
};

export type MutationStartAutochargeEnrollmentArgs = {
  input: StartAutochargeEnrollmentInput;
};

export type MutationStartChargeArgs = {
  input?: InputMaybe<StartChargeInput>;
};

export type MutationStartChargeForFleetArgs = {
  input?: InputMaybe<StartChargeFleetInput>;
};

export type MutationStartChargeForMobileArgs = {
  input?: InputMaybe<StartChargeForMobileInput>;
};

export type MutationStopChargeArgs = {
  input: StopChargeInput;
};

export type MutationStopChargeForMobileArgs = {
  input: StopChargeForMobileInput;
};

export type MutationSuspendCardsArgs = {
  input: SuspendCardsInput;
};

export type MutationSyncAssignCardArgs = {
  input?: InputMaybe<SyncAssignCardInput>;
};

export type MutationSyncChargerModelConnectorArgs = {
  input?: InputMaybe<SyncConnectorInput>;
};

export type MutationSyncChargerStatusArgs = {
  input?: InputMaybe<SyncChargerStatusInput>;
};

export type MutationSyncConnectorArgs = {
  input?: InputMaybe<SyncConnectorInput>;
};

export type MutationSyncContractArgs = {
  input: SyncContractInput;
};

export type MutationSyncCreateAccountOwnerArgs = {
  input?: InputMaybe<SyncCreateAccountOwnerInput>;
};

export type MutationSyncCreateAdHocContractArgs = {
  input: SyncCreateAdHocContractInput;
};

export type MutationSyncCreateCardOrderArgs = {
  input: SyncCreateCardOrderInput;
};

export type MutationSyncCreateChargerArgs = {
  input?: InputMaybe<SyncCreateChargerInput>;
};

export type MutationSyncCreateChargerModelArgs = {
  input?: InputMaybe<SyncCreateChargerModelInput>;
};

export type MutationSyncCreateContractArgs = {
  input: SyncCreateContractInput;
};

export type MutationSyncCreateDriverArgs = {
  input: SyncDriverInput;
};

export type MutationSyncCreateHostArgs = {
  input?: InputMaybe<SyncCreateHostInput>;
};

export type MutationSyncCreatePropertyArgs = {
  input?: InputMaybe<SyncCreatePropertyInput>;
};

export type MutationSyncCreateSiteArgs = {
  input?: InputMaybe<SyncCreateSiteInput>;
};

export type MutationSyncPasswordResetArgs = {
  input: SyncPasswordResetInput;
};

export type MutationSyncPlanArgs = {
  input: SyncPlanInput;
};

export type MutationSyncSiteArgs = {
  input?: InputMaybe<SyncSiteInput>;
};

export type MutationSyncUpdateAccountArgs = {
  input?: InputMaybe<SyncUpdateAccountInput>;
};

export type MutationSyncUpdateAccountOwnerArgs = {
  input?: InputMaybe<SyncUpdateAccountOwnerInput>;
};

export type MutationSyncUpdateChargerArgs = {
  input?: InputMaybe<SyncUpdateChargerInput>;
};

export type MutationSyncUpdateContractArgs = {
  input: SyncUpdateContractInput;
};

export type MutationSyncUpdateDriverArgs = {
  input: SyncDriverInput;
};

export type MutationSyncUserArgs = {
  email: Scalars['String']['input'];
  setPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationToggleDemandLimitArgs = {
  input: ToggleDemandLimitInput;
};

export type MutationToggleRemSafeModeArgs = {
  input: ToggleRemSafeModeInput;
};

export type MutationUnassignCardsArgs = {
  input: UnassignCardsInput;
};

export type MutationUnenrollThirdPartyWalletArgs = {
  input: ThirdPartyWalletInput;
};

export type MutationUpdateAccountArgs = {
  input?: InputMaybe<UpdateAccountInput>;
};

export type MutationUpdateAccountOwnerArgs = {
  input?: InputMaybe<UpdateAccountOwnerInput>;
};

export type MutationUpdateAccountVehicleArgs = {
  input?: InputMaybe<UpdateAccountVehicleInput>;
};

export type MutationUpdateAdHocContractArgs = {
  input: UpdateAdHocContractInput;
};

export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};

export type MutationUpdateCardOrdersArgs = {
  input: UpdateCardOrdersInput;
};

export type MutationUpdateCardStatusArgs = {
  input?: InputMaybe<UpdateCardStatusInput>;
};

export type MutationUpdateChargerArgs = {
  input?: InputMaybe<UpdateChargerInput>;
};

export type MutationUpdateChargerGroupArgs = {
  input: UpdateChargerGroupInput;
};

export type MutationUpdateChargerGroupPlanArgs = {
  input: UpdateChargerGroupPlanInput;
};

export type MutationUpdateChargerModelArgs = {
  input?: InputMaybe<UpdateChargerModelInput>;
};

export type MutationUpdateChargerModelConnectorArgs = {
  input?: InputMaybe<UpdateConnectorInput>;
};

export type MutationUpdateConnectorArgs = {
  input?: InputMaybe<UpdateConnectorInput>;
};

export type MutationUpdateConnectorPowerLimitArgs = {
  input?: InputMaybe<UpdateConnectorPowerLimitInput>;
};

export type MutationUpdateConnectorReservableArgs = {
  input?: InputMaybe<UpdateConnectorReservableInput>;
};

export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

export type MutationUpdateDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationUpdateDriverArgs = {
  input?: InputMaybe<UpdateDriverInput>;
};

export type MutationUpdateExtendUserHostsArgs = {
  input?: InputMaybe<UpdateExtendUserHostsInput>;
};

export type MutationUpdateFleetUserArgs = {
  input: UpdateFleetUserInput;
};

export type MutationUpdateHostArgs = {
  input?: InputMaybe<UpdateHostInput>;
};

export type MutationUpdatePasswordArgs = {
  input?: InputMaybe<UpdatePasswordInput>;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePlanDriverRankArgs = {
  input: Array<DriverRankInput>;
};

export type MutationUpdatePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationUpdatePlanTariffsArgs = {
  input: UpdatePlanTariffsInput;
};

export type MutationUpdatePricingRunStatusArgs = {
  input: UpdatePricingRunStatusInput;
};

export type MutationUpdatePropertyArgs = {
  input?: InputMaybe<UpdatePropertyInput>;
};

export type MutationUpdateRewardsUserArgs = {
  input: UpdateRewardsUserInput;
};

export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

export type MutationUpdateSiteArgs = {
  input?: InputMaybe<UpdateSiteInput>;
};

export type MutationUpdateSiteForExtendArgs = {
  input?: InputMaybe<UpdateSiteForExtendInput>;
};

export type MutationUpdateSiteReexArgs = {
  input: UpdateSiteReexInput;
};

export type MutationUpdateTagArgs = {
  input?: InputMaybe<UpdateTagInput>;
};

export type MutationUpdateTariffArgs = {
  input: UpdateTariffInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};

export type MutationUpdateVehicleMakeArgs = {
  input: UpdateVehicleMakeInput;
};

export type MutationUpdateVehicleModelArgs = {
  input: UpdateVehicleModelInput;
};

export type MutationUpsertCardArgs = {
  input: UpsertCardInput;
};

export type MutationUpsertCardOrderArgs = {
  input: UpsertCardOrderInput;
};

export type MutationUpsertExtendHmiDisplayLabelsArgs = {
  input: UpsertExtendHmiDisplayLabelsInput;
};

export type MutationUpsertPaymentMethodArgs = {
  input: UpsertPaymentMethodInput;
};

export type MutationUpsertSessionMeterArgs = {
  input: UpsertSessionMeterInput;
};

export type MutationUpsertTariffArgs = {
  input: TariffInput;
};

export type MutationValidateAddressArgs = {
  input?: InputMaybe<ValidateAddressInput>;
};

export type MutationVerifyDriverEmailArgs = {
  input: VerifyDriverEmailInput;
};

export enum NetworkCommunication {
  /** Ethernet */
  Ethernet = 'ETHERNET',
  /** 4G LTE */
  FourGLte = 'FOUR_G_LTE',
  /** NB-IoT */
  NbIot = 'NB_IOT',
  /** Non-Networked */
  NonNetworked = 'NON_NETWORKED',
  /** 3G */
  ThreeG = 'THREE_G',
  /** WiFi */
  Wifi = 'WIFI',
  /** ZigBee */
  Zigbee = 'ZIGBEE',
}

export type NetworkOperator = {
  __typename?: 'NetworkOperator';
  hostId?: Maybe<Scalars['Int']['output']>;
  hostName?: Maybe<Scalars['String']['output']>;
};

/** An object containing the id and name of the network */
export type NetworkOperatorType = {
  __typename?: 'NetworkOperatorType';
  networkOperatorId?: Maybe<Scalars['Int']['output']>;
  networkOperatorName?: Maybe<Scalars['String']['output']>;
};

export type NetworkOperatorWithMeta = {
  __typename?: 'NetworkOperatorWithMeta';
  edges?: Maybe<Array<Maybe<NetworkOperator>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  groupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notificationItems?: Maybe<NotificationItemsWithMeta>;
  sortOrder?: Maybe<Scalars['String']['output']>;
};

export type NotificationGroupNotificationItemsArgs = {
  input?: InputMaybe<ListNotificationItemsInput>;
};

export type NotificationGroupsWithMeta = {
  __typename?: 'NotificationGroupsWithMeta';
  edges?: Maybe<Array<Maybe<NotificationGroup>>>;
  filter?: Maybe<ListNotificationGroupsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListNotificationGroupsSearchType>;
  sort?: Maybe<ListNotificationGroupsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type NotificationItem = {
  __typename?: 'NotificationItem';
  driverLevel?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notificationDesc?: Maybe<Scalars['String']['output']>;
  notificationGroup?: Maybe<NotificationGroup>;
  notificationName?: Maybe<Scalars['String']['output']>;
  onePrefRequired?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type NotificationItemsWithMeta = {
  __typename?: 'NotificationItemsWithMeta';
  edges?: Maybe<Array<Maybe<NotificationItem>>>;
  filter?: Maybe<ListNotificationItemsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListNotificationItemsSearchType>;
  sort?: Maybe<ListNotificationItemsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Represents a log entry for a notification. */
export type NotificationLog = {
  __typename?: 'NotificationLog';
  /** Timestamp when the log entry was created. */
  createdAt: Scalars['Date']['output'];
  /** Driver for the notification. */
  driver?: Maybe<Driver>;
  /** Indicates if email notifications were enabled and sent. */
  isEmailEnabled: Scalars['Boolean']['output'];
  /** Indicates if push notifications were enabled and sent. */
  isPushEnabled: Scalars['Boolean']['output'];
  /** Indicates if sms notifications were enabled and sent. */
  isSmsEnabled: Scalars['Boolean']['output'];
  /** Identifier for the notification item. */
  notificationItemId: Scalars['Int']['output'];
  /** Identifier for the reference. */
  referenceId?: Maybe<Scalars['Int']['output']>;
  /** Name of the reference table. */
  referenceTable?: Maybe<Scalars['String']['output']>;
  /** Identifier for the reference vendor (used to reference records in Driivz when data is not available in Falcon). */
  referenceVendorId?: Maybe<Scalars['Int']['output']>;
  /** Timestamp when the notification was sent. */
  sentOn?: Maybe<Scalars['Date']['output']>;
  /** Timestamp when the log entry was last updated. */
  updatedAt: Scalars['Date']['output'];
};

export type NotificationLogsWithMeta = {
  __typename?: 'NotificationLogsWithMeta';
  edges?: Maybe<Array<Maybe<NotificationLog>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<ListNotificationLogsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  createdAt?: Maybe<Scalars['Date']['output']>;
  driverId?: Maybe<Scalars['ID']['output']>;
  emailOn?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notificationItemId?: Maybe<Scalars['ID']['output']>;
  pushOn?: Maybe<Scalars['Boolean']['output']>;
  smsOn?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type NotificationPreferencesWithMeta = {
  __typename?: 'NotificationPreferencesWithMeta';
  edges?: Maybe<Array<Maybe<NotificationPreference>>>;
  filter?: Maybe<ListNotificationPreferencesFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListNotificationPreferencesSearchType>;
  sort?: Maybe<ListNotificationPreferencesSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OptIn = {
  __typename?: 'OptIn';
  /** @deprecated Cleanup */
  insert_id?: Maybe<Scalars['Int']['output']>;
};

export type OptInInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  mobile_phone?: InputMaybe<Scalars['String']['input']>;
  promotion_box?: InputMaybe<Scalars['Int']['input']>;
  promotion_copy?: InputMaybe<Scalars['String']['input']>;
};

export type OptInPromotions = {
  __typename?: 'OptInPromotions';
  insertId?: Maybe<Scalars['Int']['output']>;
};

export type OptInPromotionsInput = {
  /** The email address for the user opting to receive promotional communications */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The phone number for the user opting to receive promotional communications */
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  /** A number indicating whether the user opts in or out (1 = opt-in and 0 = opt-out) */
  promotionBox?: InputMaybe<Scalars['Int']['input']>;
  /** A string describing the subscription terms for the promotional communications */
  promotionCopy?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  account?: Maybe<Account>;
  altId?: Maybe<Scalars['ID']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  expiryYear?: Maybe<Scalars['Int']['output']>;
  nameOnCard?: Maybe<Scalars['String']['output']>;
  paymentMethodType?: Maybe<FalconConstraint>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  program?: Maybe<PaymentMethodProgram>;
};

export type PaymentMethodExpirationInput = {
  monthsFromExp: Scalars['Int']['input'];
};

export type PaymentMethodProgram = {
  __typename?: 'PaymentMethodProgram';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PaymentMethodsWithMeta = {
  __typename?: 'PaymentMethodsWithMeta';
  edges?: Maybe<Array<Maybe<PaymentMethod>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PdfStatement = {
  __typename?: 'PdfStatement';
  /** @deprecated Use statement instead. */
  base64statement?: Maybe<Scalars['String']['output']>;
  /** A buffer string encoded to base64 (e.g. 'JVBERi0xLjQKJeLjz9M...') */
  statement?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use statement instead. */
  url?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberCheck = {
  __typename?: 'PhoneNumberCheck';
  associatedEmail?: Maybe<Scalars['String']['output']>;
  phoneNumberAvailable?: Maybe<Scalars['Boolean']['output']>;
};

export type Plan = {
  __typename?: 'Plan';
  allowRoaming?: Maybe<Scalars['Boolean']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  badge?: Maybe<Scalars['SanitizeString']['output']>;
  balanceType?: Maybe<BalanceType>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: Maybe<Scalars['Int']['output']>;
  cardBrand?: Maybe<Scalars['SanitizeString']['output']>;
  cardBrandId?: Maybe<Scalars['Int']['output']>;
  chargerGroup?: Maybe<ChargerGroup>;
  /** @deprecated This is not a one to one relationship.  Use contracts. */
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  costFactor?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  ctaLabel?: Maybe<Scalars['SanitizeString']['output']>;
  defaultAccountPlan?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  detailHeading?: Maybe<Scalars['SanitizeString']['output']>;
  detailSections?: Maybe<Array<Maybe<DetailSection>>>;
  detailSessionTimes?: Maybe<Array<Maybe<DetailSessionTime>>>;
  displayPlanCard?: Maybe<Scalars['Boolean']['output']>;
  displayToCustomer?: Maybe<Scalars['Boolean']['output']>;
  displayToLoggedOutUser?: Maybe<Scalars['Boolean']['output']>;
  driverRank?: Maybe<Scalars['Int']['output']>;
  elements?: Maybe<ElementsWithMeta>;
  enrollmentClose?: Maybe<Scalars['Date']['output']>;
  enrollmentOpen?: Maybe<Scalars['Date']['output']>;
  financeCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  intervalLength?: Maybe<Scalars['Int']['output']>;
  intervalUnit?: Maybe<Scalars['String']['output']>;
  intervalUnitId?: Maybe<Scalars['Int']['output']>;
  isAutoEnrolledInRewards?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  legalese?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  planCode?: Maybe<Scalars['SanitizeString']['output']>;
  planDescription?: Maybe<Scalars['SanitizeString']['output']>;
  planDiscount?: Maybe<Scalars['Float']['output']>;
  planDisplayName?: Maybe<Scalars['SanitizeString']['output']>;
  planDuration?: Maybe<Scalars['Int']['output']>;
  planGroupName?: Maybe<Scalars['SanitizeString']['output']>;
  planName?: Maybe<Scalars['SanitizeString']['output']>;
  planStatus?: Maybe<Scalars['String']['output']>;
  planStatusId?: Maybe<Scalars['Int']['output']>;
  planSubheading?: Maybe<Scalars['SanitizeString']['output']>;
  products?: Maybe<ProductsWithMeta>;
  recurringCost?: Maybe<Scalars['Float']['output']>;
  rolloverPlan?: Maybe<Plan>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  tariffs?: Maybe<TariffWithMeta>;
  template?: Maybe<Scalars['SanitizeString']['output']>;
  templateId?: Maybe<Scalars['Int']['output']>;
  terminationBehavior?: Maybe<Scalars['String']['output']>;
  terminationBehaviorId?: Maybe<Scalars['Int']['output']>;
  touRegionWithPricing?: Maybe<Array<Maybe<TouRegionWithPricing>>>;
  touRegionWithRangePricing?: Maybe<TouRegionWithRangePricing>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vinRequiredFlag?: Maybe<Scalars['Boolean']['output']>;
};

export type PlanContractsArgs = {
  altId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlanElementsArgs = {
  input?: InputMaybe<ListElementsInput>;
};

export type PlanProductsArgs = {
  input?: InputMaybe<ListProductsInput>;
};

export type PlanTariffsArgs = {
  input?: InputMaybe<ListTariffsInput>;
};

export type PlanTouRegionWithPricingArgs = {
  input?: InputMaybe<TouRegionWithPricingInput>;
};

export type PlanTouRegionWithRangePricingArgs = {
  input?: InputMaybe<TouRegionWithRangePricingInput>;
};

export type PlanCredit = {
  __typename?: 'PlanCredit';
  altId?: Maybe<Scalars['ID']['output']>;
  creditAmount?: Maybe<Scalars['Float']['output']>;
  creditExpPolicy?: Maybe<Scalars['String']['output']>;
  creditExpPolicyId?: Maybe<Scalars['Int']['output']>;
  creditReoccurrence?: Maybe<Scalars['String']['output']>;
  creditReoccurrenceId?: Maybe<Scalars['Int']['output']>;
  oneTimeCreditAmount?: Maybe<Scalars['Float']['output']>;
};

export type PlanCreditInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  creditExpPolicyId?: InputMaybe<Scalars['Int']['input']>;
  creditReoccurrenceId?: InputMaybe<Scalars['Int']['input']>;
  oneTimeCreditAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type PlanEnrollmentsForExtend = {
  __typename?: 'PlanEnrollmentsForExtend';
  planName: Scalars['String']['output'];
  /** Total number of contracts assigned to plan */
  totalEnrollments: Scalars['Int']['output'];
};

export enum PlanStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  altId?: Maybe<Scalars['ID']['output']>;
  subscriptionFee?: Maybe<Scalars['Float']['output']>;
  subscriptionReoccurrence?: Maybe<Scalars['String']['output']>;
  subscriptionReoccurrenceId?: Maybe<Scalars['Int']['output']>;
};

export type PlanSubscriptionInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  subscriptionFee: Scalars['Float']['input'];
  subscriptionReoccurrence?: InputMaybe<Scalars['String']['input']>;
  subscriptionReoccurrenceId?: InputMaybe<Scalars['Int']['input']>;
};

export type PlanTag = {
  __typename?: 'PlanTag';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  plan?: Maybe<PlanV2>;
  tag?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PlanTariff = {
  __typename?: 'PlanTariff';
  altId: Scalars['ID']['output'];
  /** The charger groups this tariff applies to */
  chargerGroups: Array<ChargerGroup>;
  customPricing?: Maybe<Array<TariffCustomPricing>>;
  /** The plans that relate to the tariff */
  planTariffTags: Array<Maybe<PlanTariffTag>>;
  /** Reservation pricing for the tariff */
  reservationTerm?: Maybe<TariffItemReservationTerm>;
  status?: Maybe<TariffSyncStatus>;
  tariffDescription?: Maybe<Scalars['SanitizeString']['output']>;
  tariffName: Scalars['SanitizeString']['output'];
  tariffRank?: Maybe<Scalars['Int']['output']>;
};

export type PlanTariffInput = {
  vendorId: Scalars['ID']['input'];
};

export type PlanTariffTag = {
  __typename?: 'PlanTariffTag';
  planChargerGroup?: Maybe<ChargerGroup>;
  planId: Scalars['ID']['output'];
};

export type PlanV2 = {
  __typename?: 'PlanV2';
  altId?: Maybe<Scalars['ID']['output']>;
  chargerGroup?: Maybe<ChargerGroup>;
  planCode?: Maybe<Scalars['String']['output']>;
  planDescription?: Maybe<Scalars['String']['output']>;
  planDisplayName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planStatus?: Maybe<FalconConstraint>;
  planSubheading?: Maybe<Scalars['String']['output']>;
};

export type PlanWithMeta = {
  __typename?: 'PlanWithMeta';
  edges?: Maybe<Array<Maybe<Plan>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListPlansSearchType>;
  sort?: Maybe<ListPlansSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Port = {
  __typename?: 'Port';
  accessType?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  chargeBoxIdentity?: Maybe<Scalars['String']['output']>;
  concurrentChargingSupported?: Maybe<Scalars['Boolean']['output']>;
  connectors?: Maybe<ConnectorsWithMeta>;
  connectors2?: Maybe<ConnectorsWithMeta>;
  evcId?: Maybe<Scalars['String']['output']>;
  handicapAccessible?: Maybe<Scalars['Boolean']['output']>;
  lastHeartbeat?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  powerLevel?: Maybe<Scalars['String']['output']>;
  qrCode?: Maybe<Scalars['String']['output']>;
};

export type PortConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type PortConnectors2Args = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type PortFilterInput = {
  powerLevel?: InputMaybe<TableStringFilterInput>;
};

export type PortFilterType = {
  __typename?: 'PortFilterType';
  powerLevel?: Maybe<TableStringFilterType>;
};

export type PortInput = {
  accessType?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  chargeBoxIdentity?: InputMaybe<Scalars['SanitizeString']['input']>;
  concurrentChargingSupported?: InputMaybe<Scalars['Boolean']['input']>;
  connectors: Array<InputMaybe<ConnectorInput>>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  handicapAccessible?: InputMaybe<Scalars['Boolean']['input']>;
  lastHeartbeat?: InputMaybe<Scalars['SanitizeString']['input']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']['input']>;
  phone?: InputMaybe<Scalars['SanitizeString']['input']>;
  powerLevel?: InputMaybe<Scalars['SanitizeString']['input']>;
  qrCode?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type PortsWithMeta = {
  __typename?: 'PortsWithMeta';
  edges?: Maybe<Array<Maybe<Port>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum PowerLevel {
  /** Level 1 */
  Level_1 = 'LEVEL_1',
  /** Level 2 */
  Level_2 = 'LEVEL_2',
  /** Level 3 */
  Level_3 = 'LEVEL_3',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type PowerMeterValue = {
  __typename?: 'PowerMeterValue';
  powerMeterValueInWatts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Date']['output']>;
};

export type Price = {
  __typename?: 'Price';
  billingPlanCode?: Maybe<Scalars['String']['output']>;
  billingPlanDescription?: Maybe<Scalars['String']['output']>;
  billingPlanId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fixed?: Maybe<Scalars['Float']['output']>;
  initialFreeTime?: Maybe<Scalars['Int']['output']>;
  kwh?: Maybe<Scalars['Float']['output']>;
  perHour?: Maybe<Scalars['Float']['output']>;
  perMinute?: Maybe<Scalars['Float']['output']>;
  plugInPerHour?: Maybe<Scalars['Float']['output']>;
  plugInPerMinute?: Maybe<Scalars['Float']['output']>;
  reservation?: Maybe<Scalars['Float']['output']>;
  transaction?: Maybe<Scalars['Float']['output']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  altId: Scalars['ID']['output'];
  perKwh?: Maybe<Scalars['TariffPrice']['output']>;
  perMinute?: Maybe<Scalars['TariffPrice']['output']>;
  perSession?: Maybe<Scalars['TariffPrice']['output']>;
};

export type PricingInput = {
  perKwh?: InputMaybe<Scalars['TariffPrice']['input']>;
  perMinute?: InputMaybe<Scalars['TariffPrice']['input']>;
  perSession?: InputMaybe<Scalars['TariffPrice']['input']>;
};

export enum PricingRunStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PendingReview = 'PENDING_REVIEW',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Staged = 'STAGED',
  Validated = 'VALIDATED',
}

export type PricingServiceHealthCheck = {
  __typename?: 'PricingServiceHealthCheck';
  status: Scalars['String']['output'];
  up: Scalars['Boolean']['output'];
};

export type Product = {
  __typename?: 'Product';
  altId?: Maybe<Scalars['ID']['output']>;
  credit?: Maybe<PlanCredit>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  productTypeId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<PlanSubscription>;
};

export type ProductInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  credit?: InputMaybe<PlanCreditInput>;
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  name?: InputMaybe<Scalars['SanitizeString']['input']>;
  productTypeId?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<PlanSubscriptionInput>;
};

export type ProductsWithMeta = {
  __typename?: 'ProductsWithMeta';
  edges?: Maybe<Array<Maybe<Product>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListProductsSearchType>;
  sort?: Maybe<ListProductsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PromotionForMobile = {
  __typename?: 'PromotionForMobile';
  code: Scalars['String']['output'];
  plan?: Maybe<Plan>;
  planCode?: Maybe<Scalars['String']['output']>;
};

export type PropertiesWithMeta = {
  __typename?: 'PropertiesWithMeta';
  edges?: Maybe<Array<Maybe<Property>>>;
  filter?: Maybe<ListPropertiesFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListPropertiesSearchType>;
  sort?: Maybe<ListPropertiesSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Property = {
  __typename?: 'Property';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Host>;
  latitude?: Maybe<Scalars['Float']['output']>;
  legacyId?: Maybe<Scalars['ID']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  pid?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<FalconConstraint>;
  sites?: Maybe<SitesWithMeta>;
  /** @deprecated Use propertyStatus */
  status?: Maybe<PropertyStatus>;
  timezone?: Maybe<Timezone>;
  vendorId?: Maybe<Scalars['String']['output']>;
};

export type PropertySitesArgs = {
  input?: InputMaybe<ListSitesInput>;
};

export type PropertyFilterInput = {
  pid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type PropertyFilterType = {
  __typename?: 'PropertyFilterType';
  pid?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export enum PropertyStatus {
  /** Contract Expired */
  ContractExpired = 'CONTRACT_EXPIRED',
  /** Contract Terminated */
  ContractTerminated = 'CONTRACT_TERMINATED',
  /** Decommissioned */
  Decommissioned = 'DECOMMISSIONED',
  /** Denetworked */
  Denetworked = 'DENETWORKED',
  /** Planned */
  Planned = 'PLANNED',
  Provisioned = 'PROVISIONED',
  /** Ready For Provisioning */
  ReadyForProvisioning = 'READY_FOR_PROVISIONING',
  /** Scheduled For Decommissioning */
  ScheduledForDecommissioning = 'SCHEDULED_FOR_DECOMMISSIONING',
}

export type Publish = {
  type: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  chargersForMobile?: Maybe<ChargersForMobileWithMeta>;
  getAccount?: Maybe<Account>;
  getAccountVehicle?: Maybe<AccountVehicle>;
  getAccountVehicleByVin?: Maybe<AccountVehicle>;
  getAccountVehicleVinStatus: AccountVehicleVinStatus;
  getActiveSession?: Maybe<Session>;
  /** Query to return summary data for active sessions at the given sites */
  getActiveSessionsTotals?: Maybe<ActiveSessionsTotals>;
  getAdHocContract?: Maybe<AdHocContract>;
  getAggregatedPowerMeterValuesForFleet?: Maybe<AggregatedMeterValues>;
  getCard?: Maybe<Card>;
  getCharger?: Maybe<Charger>;
  getChargerAggregatedPowerMeterValuesForFleet?: Maybe<ChargerAggregateMeterValues>;
  /** Query to get a Charger Group by altId */
  getChargerGroup?: Maybe<ChargerGroup>;
  getChargerModel?: Maybe<ChargerModel>;
  getChargerModelConnector?: Maybe<ChargerModelConnector>;
  getChargerPricingForMobile?: Maybe<ChargerPrice>;
  getChargerUtilizationChartData?: Maybe<ChargerUtilizationChartData>;
  /** @deprecated use getCharger and request energyUtilization field */
  getChargerWithEnergyUtilization?: Maybe<Charger>;
  getConnector?: Maybe<Connector>;
  getContract?: Maybe<Contract>;
  getCoupon?: Maybe<Coupon>;
  getCreditCardTransaction?: Maybe<CreditCardTransaction>;
  getCurrentDriver?: Maybe<Driver>;
  getCurrentUser: CurrentUserResponse;
  getDriver?: Maybe<Driver>;
  getEvgoAccessQRCode?: Maybe<EvgoAccessQrCode>;
  getEvgoSitesForMobile?: Maybe<SitesForMobileWithMeta>;
  /** Get calculated plans for current extend host */
  getExtendCalculatedHostPlans: Array<ExtendCalculatedHostPlan>;
  getExtendUser?: Maybe<ExtendUser>;
  getFirebaseUserForExtend: FirebaseUserForExtend;
  getFleetUser: User;
  getHost?: Maybe<Host>;
  /** Get a single invoice for the current user based on a given invoiceId */
  getInvoiceForMobile?: Maybe<Invoice>;
  /** Query to return the last session for a given driver */
  getLastSessionForDriver?: Maybe<Session>;
  getPlan?: Maybe<Plan>;
  getPlanEnrollmentsForExtend: Array<PlanEnrollmentsForExtend>;
  getPlanForMobile?: Maybe<Plan>;
  getPort?: Maybe<Port>;
  getProperty?: Maybe<Property>;
  getRewardsTotalForMobile?: Maybe<Rewards>;
  getRewardsUser?: Maybe<RewardsUser>;
  getRoamingSitesForMobile?: Maybe<SitesForMobileWithMeta>;
  getSalesforceHost?: Maybe<SalesforceHost>;
  getSalesforceProperty?: Maybe<SalesforceProperty>;
  getSession?: Maybe<Session>;
  getSessionForFleet?: Maybe<Session>;
  getSessionSummaryByPlan?: Maybe<Array<SessionSummaryByPlan>>;
  getSessionValidationForMobile?: Maybe<SessionValidation>;
  /** Query to return a timestamp between the date interval or date preset sent, summing the consumption and income of chargers */
  getSessionsConsumptionForExtend?: Maybe<GetSessionsConsumptionForExtendResponse>;
  getSite?: Maybe<Site>;
  /** Query to get a site by altId */
  getSiteForExtend?: Maybe<Site>;
  getSiteForMobile?: Maybe<Site>;
  getSitesByAltIdForMobile?: Maybe<SitesForMobileWithMeta>;
  /** Query to get a list of sites */
  getSitesForExtend?: Maybe<Array<Maybe<Site>>>;
  getStatement?: Maybe<PdfStatement>;
  getStatementCurrent?: Maybe<PdfStatement>;
  getTag?: Maybe<Tag>;
  /** Gets a single tariff */
  getTariff: ChargerPricing;
  getTariffLogs: Array<TariffLog>;
  getTransaction?: Maybe<Transaction>;
  getTransactionForMobile?: Maybe<MobileTransaction>;
  getVehicleByVin?: Maybe<Vehicle>;
  getWarehouse?: Maybe<Warehouse>;
  listAccountVehicles?: Maybe<AccountVehiclesWithMeta>;
  listAccounts?: Maybe<AccountsWithMeta>;
  listAvailableSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  listCardOrders?: Maybe<CardOrdersWithMeta>;
  listCardOrdersForMobile?: Maybe<CardOrdersWithMeta>;
  listCards?: Maybe<CardsWithMeta>;
  listCardsForMobile?: Maybe<CardsWithMeta>;
  /** Query to list charger groups */
  listChargerGroups?: Maybe<ChargerGroupsWithMeta>;
  listChargerModelConnectors?: Maybe<ChargerModelConnectorsWithMeta>;
  listChargerModelOptions?: Maybe<Array<Maybe<ChargerModel>>>;
  listChargerModelPorts?: Maybe<ChargerModelPortsWithMeta>;
  listChargerModels?: Maybe<ChargerModelsWithMeta>;
  listChargerUtilization?: Maybe<ListChargerUtilization>;
  listChargers?: Maybe<ChargersWithMeta>;
  /** Query to return a list of chargers, by sites and their consumption data by date preset */
  listChargersConsumptionForExtend?: Maybe<Array<ChargersConsumptionForExtendData>>;
  listChargersForFleet?: Maybe<ChargersForFleetMeta>;
  listChargersForMobile?: Maybe<ChargersWithMeta>;
  /** Dynamic list chargers query */
  listChargersV2?: Maybe<ChargersV2WithMeta>;
  listConnectorAssociations?: Maybe<Array<Maybe<ConnectorAssociation>>>;
  listConnectors?: Maybe<ConnectorsWithMeta>;
  listConnectors2?: Maybe<ConnectorsWithMeta>;
  listContacts?: Maybe<ContactsWithMeta>;
  listContracts?: Maybe<ContractsWithMeta>;
  listCredits?: Maybe<CreditsWithMeta>;
  listDrivers?: Maybe<DriversWithMeta>;
  listElectricVehicles?: Maybe<ElectricVehiclesWithMeta>;
  listEventLog?: Maybe<EventLogWithMeta>;
  listExtendHosts?: Maybe<ExtendHostsWithMeta>;
  listExtendUsers?: Maybe<ExtendUsersWithMeta>;
  listExtendUsersForHost?: Maybe<ExtendUsersWithMeta>;
  listFalconConstraints?: Maybe<FalconConstraintsWithMeta>;
  listFavoriteSites?: Maybe<SitesForMobileWithMeta>;
  listGeographicalRegions?: Maybe<GeographicalRegionsWithMeta>;
  listHosts?: Maybe<HostsWithMeta>;
  listHostsForExtend?: Maybe<HostsWithMeta>;
  /** Get a list of invoices for the current user within a given date range, with the optional ability to limit results by cardId */
  listInvoicesForMobile?: Maybe<InvoicesWithMeta>;
  /** A query that fetches a list of partner network operators to populate filters on client */
  listNetworkOperators?: Maybe<NetworkOperatorWithMeta>;
  listNotificationGroups?: Maybe<NotificationGroupsWithMeta>;
  /** Fetches notification logs based on provided criteria. */
  listNotificationLogs?: Maybe<NotificationLogsWithMeta>;
  listPaymentMethodsForMobile?: Maybe<PaymentMethodsWithMeta>;
  listPlans?: Maybe<PlanWithMeta>;
  listPlansForMobile?: Maybe<PlanWithMeta>;
  listPorts?: Maybe<PortsWithMeta>;
  listPorts2?: Maybe<PortsWithMeta>;
  listProperties?: Maybe<PropertiesWithMeta>;
  listReservationTerms?: Maybe<ReservationTermsWithMeta>;
  listReservations?: Maybe<ReservationsWithMeta>;
  /** list reservations for the current authenticated driver */
  listReservationsByDriver?: Maybe<ReservationsWithMeta>;
  listSalesforceHostContacts?: Maybe<SalesforceHostContactsWithMeta>;
  listSalesforceHosts?: Maybe<SalesforceHostsWithMeta>;
  listSalesforceProperties?: Maybe<SalesforcePropertiesWithMeta>;
  listSessions?: Maybe<SessionsWithMeta>;
  listSessionsV2?: Maybe<SessionsV2WithMeta>;
  listSiteImages?: Maybe<SiteImagesWithMeta>;
  listSiteOptions?: Maybe<Array<Maybe<Site>>>;
  listSites?: Maybe<SitesWithMeta>;
  listSitesConsumptionForExtend: ListSitesConsumptionForExtendWithMeta;
  listSitesForMobile?: Maybe<SitesWithMeta>;
  listStatements?: Maybe<StatementsWithMeta>;
  listTags?: Maybe<TagsWithMeta>;
  listTariffConnectorTypes?: Maybe<TariffConnectorTypesWithMeta>;
  /** Query to fetch tariffs */
  listTariffs: TariffsWithMeta;
  listTouRegions?: Maybe<Array<Maybe<TouRegion>>>;
  listTransactions?: Maybe<TransactionsWithMeta>;
  listTransactionsForMobile?: Maybe<MobileTransactionsWithMeta>;
  listTransactionsV2?: Maybe<TransactionsV2WithMeta>;
  listUnassociatedChargersForFleet?: Maybe<UnassociatedChargersForFleetWithMeta>;
  listUploads?: Maybe<UploadsWithMeta>;
  listVehicleConnectors?: Maybe<VehicleConnectorsWithMeta>;
  listVehicleMakes?: Maybe<Array<Maybe<VehicleMakeV2>>>;
  listVehicleModels?: Maybe<Array<Maybe<VehicleModelV2>>>;
  listVehicles?: Maybe<VehiclesWithMeta>;
  listVehiclesV2?: Maybe<Array<Maybe<VehicleV2>>>;
  listWarehouseOptions?: Maybe<Array<Maybe<Warehouse>>>;
  listWarehouses?: Maybe<WarehousesWithMeta>;
  /** A health check for the pricing-service subgraph */
  pricingServiceHealthCheck: PricingServiceHealthCheck;
  root?: Maybe<Scalars['SanitizeString']['output']>;
  searchFirebaseAndDriivzAndFalconAccounts?: Maybe<AccountSearchResult>;
  siteForMobile?: Maybe<SiteForMobile>;
  /** @deprecated No longer supported. */
  sitesForMobile?: Maybe<SitesForMobileWithMeta>;
  sitesStatusForMobile?: Maybe<Array<SitesStatusForMobile>>;
  validateTag?: Maybe<Tag>;
};

export type QueryChargersForMobileArgs = {
  input?: InputMaybe<ChargersForMobileInput>;
};

export type QueryGetAccountArgs = {
  input?: InputMaybe<GetAccountInput>;
};

export type QueryGetAccountVehicleArgs = {
  input?: InputMaybe<GetAccountVehicleInput>;
};

export type QueryGetAccountVehicleByVinArgs = {
  input: GetAccountVehicleByVinInput;
};

export type QueryGetAccountVehicleVinStatusArgs = {
  input: GetAccountVehicleVinStatusInput;
};

export type QueryGetActiveSessionArgs = {
  input: GetActiveSessionInput;
};

export type QueryGetActiveSessionsTotalsArgs = {
  input: ActiveSessionsTotalsInput;
};

export type QueryGetAdHocContractArgs = {
  input: GetAdHocContractInput;
};

export type QueryGetAggregatedPowerMeterValuesForFleetArgs = {
  input: GetAggregatedPowerMeterValuesForFleetInput;
};

export type QueryGetCardArgs = {
  input?: InputMaybe<GetCardInput>;
};

export type QueryGetChargerArgs = {
  input?: InputMaybe<GetChargerInput>;
};

export type QueryGetChargerAggregatedPowerMeterValuesForFleetArgs = {
  input?: InputMaybe<GetChargerAggregatedPowerMeterValuesForFleetInput>;
};

export type QueryGetChargerGroupArgs = {
  input: GetChargerGroupInput;
};

export type QueryGetChargerModelArgs = {
  input?: InputMaybe<GetChargerModelInput>;
};

export type QueryGetChargerModelConnectorArgs = {
  input?: InputMaybe<GetConnectorInput>;
};

export type QueryGetChargerPricingForMobileArgs = {
  input?: InputMaybe<GetChargerPricingForMobileInput>;
};

export type QueryGetChargerUtilizationChartDataArgs = {
  input: ChargerUtilizationChartInput;
};

export type QueryGetChargerWithEnergyUtilizationArgs = {
  input?: InputMaybe<GetChargerInput>;
};

export type QueryGetConnectorArgs = {
  input?: InputMaybe<GetConnectorInput>;
};

export type QueryGetContractArgs = {
  input: GetContractInput;
};

export type QueryGetCouponArgs = {
  input?: InputMaybe<GetCouponInput>;
};

export type QueryGetCreditCardTransactionArgs = {
  input: GetCreditCardTransactionInput;
};

export type QueryGetDriverArgs = {
  input?: InputMaybe<GetDriverInput>;
};

export type QueryGetEvgoAccessQrCodeArgs = {
  input?: InputMaybe<GetEvgoAccessQrCodeInput>;
};

export type QueryGetEvgoSitesForMobileArgs = {
  input: EvgoSitesForMobileInput;
};

export type QueryGetExtendUserArgs = {
  input?: InputMaybe<GetExtendUserInput>;
};

export type QueryGetFirebaseUserForExtendArgs = {
  input: GetFirebaseUserForExtendInput;
};

export type QueryGetHostArgs = {
  input?: InputMaybe<GetHostInput>;
};

export type QueryGetInvoiceForMobileArgs = {
  input?: InputMaybe<GetInvoiceInput>;
};

export type QueryGetPlanArgs = {
  input?: InputMaybe<GetPlanInput>;
};

export type QueryGetPlanForMobileArgs = {
  input?: InputMaybe<GetPlanForMobileInput>;
};

export type QueryGetPortArgs = {
  input?: InputMaybe<GetPortInput>;
};

export type QueryGetPropertyArgs = {
  input?: InputMaybe<GetPropertyInput>;
};

export type QueryGetRewardsUserArgs = {
  input: GetRewardsUserInput;
};

export type QueryGetRoamingSitesForMobileArgs = {
  input: RoamingSitesForMobileInput;
};

export type QueryGetSalesforceHostArgs = {
  input?: InputMaybe<GetSalesforceHostInput>;
};

export type QueryGetSalesforcePropertyArgs = {
  input?: InputMaybe<GetSalesforcePropertyInput>;
};

export type QueryGetSessionArgs = {
  input: GetSessionInput;
};

export type QueryGetSessionForFleetArgs = {
  input: GetSessionForFleetInput;
};

export type QueryGetSessionSummaryByPlanArgs = {
  input?: InputMaybe<GetSessionSummaryByPlanInput>;
};

export type QueryGetSessionValidationForMobileArgs = {
  input?: InputMaybe<GetSessionValidationForMobileInput>;
};

export type QueryGetSessionsConsumptionForExtendArgs = {
  input: GetSessionsConsumptionForExtendInput;
};

export type QueryGetSiteArgs = {
  input?: InputMaybe<GetSiteInput>;
};

export type QueryGetSiteForExtendArgs = {
  input?: InputMaybe<GetSiteForExtendInput>;
};

export type QueryGetSiteForMobileArgs = {
  input?: InputMaybe<GetSiteInput>;
};

export type QueryGetSitesByAltIdForMobileArgs = {
  input: SitesByAltIdInput;
};

export type QueryGetStatementArgs = {
  input: GetStatementInput;
};

export type QueryGetStatementCurrentArgs = {
  input?: InputMaybe<GetStatementCurrentInput>;
};

export type QueryGetTagArgs = {
  input?: InputMaybe<GetTagInput>;
};

export type QueryGetTariffArgs = {
  input: GetTariffInput;
};

export type QueryGetTariffLogsArgs = {
  input: GetTariffLogsInput;
};

export type QueryGetTransactionArgs = {
  input: GetTransactionInput;
};

export type QueryGetTransactionForMobileArgs = {
  input: GetTransactionInput;
};

export type QueryGetVehicleByVinArgs = {
  input: GetVehicleByVinInput;
};

export type QueryGetWarehouseArgs = {
  input?: InputMaybe<GetWarehouseInput>;
};

export type QueryListAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type QueryListAccountsArgs = {
  input?: InputMaybe<ListAccountsInput>;
};

export type QueryListAvailableSlotsArgs = {
  input?: InputMaybe<ListReservationsInput>;
};

export type QueryListCardOrdersArgs = {
  input?: InputMaybe<ListCardOrdersInput>;
};

export type QueryListCardOrdersForMobileArgs = {
  input?: InputMaybe<ListCardOrdersForMobileInput>;
};

export type QueryListCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type QueryListCardsForMobileArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type QueryListChargerGroupsArgs = {
  input?: InputMaybe<ListChargerGroupsInput>;
};

export type QueryListChargerModelConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListChargerModelPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListChargerModelsArgs = {
  input?: InputMaybe<ListChargerModelsInput>;
};

export type QueryListChargerUtilizationArgs = {
  input?: InputMaybe<ListChargerUtilizationInput>;
};

export type QueryListChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type QueryListChargersConsumptionForExtendArgs = {
  input: ListChargersConsumptionForExtendInput;
};

export type QueryListChargersForFleetArgs = {
  input?: InputMaybe<ListChargersForFleetInput>;
};

export type QueryListChargersForMobileArgs = {
  input?: InputMaybe<ListChargersForMobileInput>;
};

export type QueryListChargersV2Args = {
  input?: InputMaybe<ListChargersV2Input>;
};

export type QueryListConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListConnectors2Args = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListContactsArgs = {
  input?: InputMaybe<ListContactsInput>;
};

export type QueryListContractsArgs = {
  input?: InputMaybe<ListContractsInput>;
};

export type QueryListCreditsArgs = {
  input?: InputMaybe<ListCreditsInput>;
};

export type QueryListDriversArgs = {
  input?: InputMaybe<ListDriversInput>;
};

export type QueryListElectricVehiclesArgs = {
  input?: InputMaybe<ListElectricVehiclesInput>;
};

export type QueryListEventLogArgs = {
  input: ListEventLogInput;
};

export type QueryListExtendHostsArgs = {
  input?: InputMaybe<ListExtendHostsInput>;
};

export type QueryListExtendUsersArgs = {
  input?: InputMaybe<ListExtendUsersInput>;
};

export type QueryListExtendUsersForHostArgs = {
  input?: InputMaybe<ListExtendUsersInput>;
};

export type QueryListFalconConstraintsArgs = {
  input?: InputMaybe<ListFalconConstraintsInput>;
};

export type QueryListFavoriteSitesArgs = {
  input: ListFavoriteSitesInput;
};

export type QueryListGeographicalRegionsArgs = {
  input?: InputMaybe<ListGeographicalRegionsInput>;
};

export type QueryListHostsArgs = {
  input?: InputMaybe<ListHostsInput>;
};

export type QueryListHostsForExtendArgs = {
  input?: InputMaybe<ListHostsInput>;
};

export type QueryListInvoicesForMobileArgs = {
  input?: InputMaybe<ListInvoicesInput>;
};

export type QueryListNotificationGroupsArgs = {
  input?: InputMaybe<ListNotificationGroupsInput>;
};

export type QueryListNotificationLogsArgs = {
  input: ListNotificationLogsInput;
};

export type QueryListPaymentMethodsForMobileArgs = {
  input?: InputMaybe<ListPaymentMethodsForMobileInput>;
};

export type QueryListPlansArgs = {
  input?: InputMaybe<ListPlansInput>;
};

export type QueryListPlansForMobileArgs = {
  input?: InputMaybe<ListPlansInput>;
};

export type QueryListPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListPorts2Args = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListPropertiesArgs = {
  input?: InputMaybe<ListPropertiesInput>;
};

export type QueryListReservationTermsArgs = {
  input?: InputMaybe<ListReservationTermsInput>;
};

export type QueryListReservationsArgs = {
  input?: InputMaybe<ListReservationsInput>;
};

export type QueryListReservationsByDriverArgs = {
  input: ListReservationsByDriverInput;
};

export type QueryListSalesforceHostContactsArgs = {
  input?: InputMaybe<ListSalesforceHostContactsInput>;
};

export type QueryListSalesforceHostsArgs = {
  input?: InputMaybe<ListSalesforceHostsInput>;
};

export type QueryListSalesforcePropertiesArgs = {
  input?: InputMaybe<ListSalesforcePropertiesInput>;
};

export type QueryListSessionsArgs = {
  input?: InputMaybe<ListSessionsInput>;
};

export type QueryListSessionsV2Args = {
  input?: InputMaybe<ListSessionsV2Input>;
};

export type QueryListSiteImagesArgs = {
  input?: InputMaybe<ListSiteImagesInput>;
};

export type QueryListSitesArgs = {
  input?: InputMaybe<ListSitesInput>;
};

export type QueryListSitesConsumptionForExtendArgs = {
  input: ListSitesConsumptionForExtendInput;
};

export type QueryListSitesForMobileArgs = {
  input: ListSitesForMobileInput;
};

export type QueryListStatementsArgs = {
  input?: InputMaybe<ListStatementsInput>;
};

export type QueryListTagsArgs = {
  input?: InputMaybe<ListTagsInput>;
};

export type QueryListTariffConnectorTypesArgs = {
  input?: InputMaybe<ListTariffConnectorTypesInput>;
};

export type QueryListTariffsArgs = {
  input: ListTariffsInput;
};

export type QueryListTransactionsArgs = {
  input?: InputMaybe<ListTransactionsInput>;
};

export type QueryListTransactionsForMobileArgs = {
  input?: InputMaybe<ListTransactionsForMobileInput>;
};

export type QueryListTransactionsV2Args = {
  input?: InputMaybe<ListTransactionsV2Input>;
};

export type QueryListUnassociatedChargersForFleetArgs = {
  input?: InputMaybe<ListUnassociatedChargersForFleetInput>;
};

export type QueryListUploadsArgs = {
  input?: InputMaybe<ListUploadsInput>;
};

export type QueryListVehicleConnectorsArgs = {
  input?: InputMaybe<ListVehicleConnectorsInput>;
};

export type QueryListVehiclesArgs = {
  input?: InputMaybe<ListVehiclesInput>;
};

export type QueryListWarehousesArgs = {
  input?: InputMaybe<ListWarehousesInput>;
};

export type QuerySearchFirebaseAndDriivzAndFalconAccountsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySiteForMobileArgs = {
  input: SiteForMobileInput;
};

export type QuerySitesForMobileArgs = {
  input: SitesForMobileInput;
};

export type QuerySitesStatusForMobileArgs = {
  input: SitesStatusForMobileInput;
};

export type QueryValidateTagArgs = {
  input?: InputMaybe<ValidateTagInput>;
};

export type RawReqJsonData = {
  __typename?: 'RawReqJsonData';
  data: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
  vendorId: Scalars['String']['output'];
};

export type RawRequestJson = {
  __typename?: 'RawRequestJson';
  data: RawReqJsonData;
  id: Scalars['String']['output'];
};

export type RawResponseJson = {
  __typename?: 'RawResponseJson';
  /** Nullable in the case of no response from the charger */
  response?: Maybe<Scalars['String']['output']>;
};

export type ReassignCardInput = {
  altId: Scalars['UUID']['input'];
  driverId: Scalars['UUID']['input'];
};

/** EVgo Renew Data */
export type Reex = {
  __typename?: 'Reex';
  date: Scalars['Date']['output'];
  outcome: ReexOutcome;
};

/** EVgo Renew Outcome */
export enum ReexOutcome {
  /** Remove */
  Remove = 'REMOVE',
  /** Replace */
  Replace = 'REPLACE',
}

export type RefreshTagsInput = {
  chargers: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RegisterAccountOwnerInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress: CreateAddressInput;
  email: Scalars['SanitizeString']['input'];
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firebaseId: Scalars['SanitizeString']['input'];
  firstName: Scalars['SanitizeString']['input'];
  lastName: Scalars['SanitizeString']['input'];
  mailingAddress: CreateAddressInput;
  optInPromotions?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['SanitizeString']['input'];
  subscriptionCopy?: InputMaybe<Scalars['String']['input']>;
  tokenAndroid?: InputMaybe<Scalars['SanitizeString']['input']>;
  tokenIos?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type ReinstateDriverInput = {
  /** Driver altId */
  altId: Scalars['String']['input'];
  /** New email to set for the driver */
  setEmail?: InputMaybe<Scalars['String']['input']>;
  /** New first name to set for the driver */
  setFirstName?: InputMaybe<Scalars['String']['input']>;
  /** New last name to set for the driver */
  setLastName?: InputMaybe<Scalars['String']['input']>;
  /** New phone to set for the driver */
  setPhone?: InputMaybe<Scalars['String']['input']>;
};

/** Input for removing chargers from a charger group */
export type RemoveChargersFromChargerGroupInput = {
  altId: Scalars['ID']['input'];
  /** List of charger altIds to remove to the charger group */
  chargerIds: Array<Scalars['ID']['input']>;
  /** Option to not wait for downstream tariff integration to vendor/charger. You can check on the status async via the tariff status property. This can be helpful in avoiding timeouts */
  integrateTariffAsync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RemoveDriverInput = {
  altId: Scalars['ID']['input'];
};

export type RemoveFavoriteSiteInput = {
  siteVendorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum Reoccurrence {
  /** One Time */
  OneTime = 'ONE_TIME',
  /** Reoccurring */
  Reoccurring = 'REOCCURRING',
}

export type ReplaceCardInput = {
  accountId: Scalars['UUID']['input'];
  altId: Scalars['UUID']['input'];
  driverId: Scalars['UUID']['input'];
};

export type Reservation = {
  __typename?: 'Reservation';
  address?: Maybe<Address>;
  chargerName?: Maybe<Scalars['String']['output']>;
  connectorId?: Maybe<Scalars['ID']['output']>;
  driverId?: Maybe<Scalars['ID']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  reservationId?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ReservationTerm = {
  __typename?: 'ReservationTerm';
  cancellationFee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  noShowFee?: Maybe<Scalars['Float']['output']>;
  reservationFee?: Maybe<Scalars['Float']['output']>;
  reservationType?: Maybe<Scalars['String']['output']>;
};

export type ReservationTermInput = {
  /** Fee for making a reservation */
  fee: Scalars['TariffPrice']['input'];
  /** Fee for missing a reservation */
  noShowFee: Scalars['TariffPrice']['input'];
};

export type ReservationTermsWithMeta = {
  __typename?: 'ReservationTermsWithMeta';
  edges?: Maybe<Array<Maybe<ReservationTerm>>>;
};

export type ReservationsWithMeta = {
  __typename?: 'ReservationsWithMeta';
  edges?: Maybe<Array<Maybe<Reservation>>>;
};

export type ReserveCardsInput = {
  cardIds: Array<Scalars['UUID']['input']>;
};

export type ResetChargerForExtendInput = {
  chargerId: Scalars['ID']['input'];
};

export type ResetDriverPasswordInput = {
  altId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
};

export type ResetNonDriverPasswordInput = {
  oobCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ReusePhoneNumberInput = {
  /** Phone number to be removed from all databases */
  phone: Scalars['SanitizeString']['input'];
};

export type Reward = {
  __typename?: 'Reward';
  accountNumber?: Maybe<Scalars['ID']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  rewardsGroup?: Maybe<Scalars['Int']['output']>;
};

export type Rewards = {
  __typename?: 'Rewards';
  total?: Maybe<Scalars['Float']['output']>;
};

export type RewardsUser = {
  __typename?: 'RewardsUser';
  accountNumber?: Maybe<Scalars['ID']['output']>;
  pointsBalance?: Maybe<Scalars['Float']['output']>;
};

export enum RfidSystemSupported {
  /** ISO/IEC 14443 Type A/B */
  IsoIec_14443TypeAB = 'ISO_IEC_14443_TYPE_A_B',
  /** NFC Reader */
  NfcReader = 'NFC_READER',
}

export type RoamingSitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int']['input'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds: Array<Scalars['Int']['input']>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']['input']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']['input']>;
  plugAndCharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SalesforceHost = {
  __typename?: 'SalesforceHost';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  hid?: Maybe<Scalars['String']['output']>;
  hostName?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  salesforceHostContacts?: Maybe<SalesforceHostContactsWithMeta>;
  salesforceProperties?: Maybe<SalesforcePropertiesWithMeta>;
};

export type SalesforceHostSalesforceHostContactsArgs = {
  input?: InputMaybe<ListSalesforceHostContactsInput>;
};

export type SalesforceHostSalesforcePropertiesArgs = {
  input?: InputMaybe<ListSalesforcePropertiesInput>;
};

export type SalesforceHostContact = {
  __typename?: 'SalesforceHostContact';
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hid?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  workTitle?: Maybe<Scalars['String']['output']>;
};

export type SalesforceHostContactsWithMeta = {
  __typename?: 'SalesforceHostContactsWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceHostContact>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesforceHostsWithMeta = {
  __typename?: 'SalesforceHostsWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceHost>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListSalesforceHostsType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesforcePropertiesWithMeta = {
  __typename?: 'SalesforcePropertiesWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceProperty>>>;
  filter?: Maybe<ListSalesforcePropertiesFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListSalesforcePropertiesSearchType>;
  sort?: Maybe<ListSalesforcePropertiesSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesforceProperty = {
  __typename?: 'SalesforceProperty';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  evcId?: Maybe<Scalars['String']['output']>;
  hid?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  pid?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  salesforceHost?: Maybe<SalesforceHost>;
};

export type SalesforcePropertySalesforceHostArgs = {
  input?: InputMaybe<GetSalesforceHostInput>;
};

export enum SalesforcePropertyStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type SendAddPaymentMethodEmailInput = {
  vendorIds: Array<Scalars['ID']['input']>;
};

export type SendEmailMessageInput = {
  /** This value is optional, but should be set to true if you need to add the recipients to Emarsys contact database. */
  createEmarsysContact?: InputMaybe<Scalars['Boolean']['input']>;
  fromAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  fromName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Unique identifier that references the provider template id. It can be Emarsy's event trigger id or Mandrill's template name for example */
  messageId: Scalars['SanitizeString']['input'];
  provider?: InputMaybe<EmailProvider>;
  recipients: Array<EmailRecipient>;
  replyToAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  subject?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type SendPasswordResetInput = {
  /** Identifies which environment the email is in reference to. This mitigates the issue with a single Emarsys environment */
  baseUrl?: InputMaybe<Scalars['SanitizeString']['input']>;
  emailAddress: Scalars['SanitizeString']['input'];
  recipientName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Identify in what service you're trying to reset your password. This could be used to send a custom email template if necessary. */
  service: AuthServiceType;
};

export type SendPaymentMethodNotificationsResponse = {
  __typename?: 'SendPaymentMethodNotificationsResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SendRewardsConfirmationEmailInput = {
  accountNumber?: InputMaybe<Scalars['Int']['input']>;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
};

export type SendRewardsConfirmationEmailReturn = {
  __typename?: 'SendRewardsConfirmationEmailReturn';
  mandrillId?: Maybe<Scalars['String']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SendTariffInput = {
  addressObj: TariffAddressObj;
  force?: InputMaybe<Scalars['Boolean']['input']>;
  identityKey: Scalars['String']['input'];
  last_updated?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['JSON']['input'];
};

export type SendTariffResponse = {
  __typename?: 'SendTariffResponse';
  /** accepted, true when charger accepted, false when rejected and null on no response. */
  accepted?: Maybe<Scalars['Boolean']['output']>;
  identityKey: Scalars['String']['output'];
};

export type SendTransactionSmsInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['ID']['input'];
};

export type Session = {
  __typename?: 'Session';
  /** Indication of whether the session was accepted to start or not */
  accepted?: Maybe<Scalars['Boolean']['output']>;
  /** Account used for the session */
  account?: Maybe<Account>;
  /** Unique identifier for the session */
  altId: Scalars['ID']['output'];
  /** Indication whether the user has opted in for autocharge when starting a charging session */
  autochargeOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** Battery level of the vehicle after a session has completed */
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** Card used to start the session */
  card?: Maybe<Card>;
  /** Number of the RFID card used to start a session */
  cardNumber?: Maybe<Scalars['String']['output']>;
  /** Charger used during the session */
  charger?: Maybe<SessionCharger>;
  /** Connector used for the session */
  connector?: Maybe<Connector>;
  connectorFleet?: Maybe<Connector>;
  /** id field on the connector used during the session */
  connectorId?: Maybe<Scalars['ID']['output']>;
  /** Indication of whether the session was corrupted or not */
  corrupted?: Maybe<Scalars['Boolean']['output']>;
  /** Reasons for session corruption */
  corruptedReasons?: Maybe<Array<Maybe<CorruptedReason>>>;
  /** Monetary information for the session */
  cost?: Maybe<SessionCost>;
  /** Time the session took to complete */
  duration?: Maybe<Scalars['String']['output']>;
  /** Time the session ended */
  endTime?: Maybe<Scalars['Date']['output']>;
  /** Total kWh delivered during the session */
  energyDelivered?: Maybe<Scalars['Float']['output']>;
  /** Indication whether a Mac Address of the vehicle is associated with the session */
  hasMacAddress?: Maybe<Scalars['Boolean']['output']>;
  /** Indication of whether there are extenuating fees for the session or not */
  idleFees?: Maybe<Scalars['Boolean']['output']>;
  /** Database ID of the invoice created from the session */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  /** Number for the invoice as it appears on a statement */
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  /** kWh the vehicle ended with */
  meterEnd?: Maybe<Scalars['Int']['output']>;
  /** kWh the vehicle began with */
  meterStart?: Maybe<Scalars['Int']['output']>;
  /** Plan used for the session */
  plan?: Maybe<Plan>;
  /** kWh readings throughout the session */
  readings?: Maybe<Array<Maybe<SessionReading>>>;
  /** Total time of the session */
  sessionDuration?: Maybe<Scalars['Int']['output']>;
  /** Identification number of the session for session_meters */
  sessionId?: Maybe<Scalars['ID']['output']>;
  /** Indication of whether another session is currently active on the same charger */
  simultaneousChargingActive: Scalars['Boolean']['output'];
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean']['output'];
  /** ID of the Site that the session occurred at -- note, can only be added via getActiveSession query */
  siteId?: Maybe<Scalars['ID']['output']>;
  /** Source from which the session was started such as App or RFID */
  startSource?: Maybe<FalconConstraint>;
  /** Time the session began */
  startTime?: Maybe<Scalars['Date']['output']>;
  /** Source from which the session was stopped such as App or RFID */
  stopSource?: Maybe<FalconConstraint>;
  /** Tariff on the charger used for the session */
  tariff?: Maybe<Tariff>;
  /** Time zone in which the session occurred */
  timeZone?: Maybe<Scalars['String']['output']>;
  /** ID relative to the session in Driivz */
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export enum SessionAggregation {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Week = 'week',
}

export type SessionCharger = {
  __typename?: 'SessionCharger';
  chargerId?: Maybe<Scalars['ID']['output']>;
  chargerName?: Maybe<Scalars['String']['output']>;
  maxPower?: Maybe<Scalars['Float']['output']>;
};

export type SessionCost = {
  __typename?: 'SessionCost';
  billedTime?: Maybe<Scalars['Float']['output']>;
  energyCost?: Maybe<Scalars['Float']['output']>;
  estimated?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  taxRate?: Maybe<Scalars['Float']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
};

export enum SessionDatePreset {
  /** Get last 7 days of data */
  Last_7d = 'last_7d',
  /** Get last 12 months of data */
  Last_12m = 'last_12m',
  /** Get last 30 days of data */
  Last_30d = 'last_30d',
  /** Get today's data */
  Today = 'today',
  /** Get yestarday's data */
  Yesterday = 'yesterday',
}

export type SessionMeter = {
  __typename?: 'SessionMeter';
  altId?: Maybe<Scalars['ID']['output']>;
  connector?: Maybe<Connector>;
  eventDate?: Maybe<Scalars['Date']['output']>;
  meterand?: Maybe<FalconConstraint>;
  session?: Maybe<Session>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type SessionMeterValue = {
  __typename?: 'SessionMeterValue';
  timestamp: Scalars['Date']['output'];
  unitOfMeasure: SessionMeterValueUnitOfMeasurment;
  value: Scalars['Float']['output'];
};

export enum SessionMeterValueUnitOfMeasurment {
  Kwh = 'KWH',
  Wh = 'WH',
}

export type SessionReading = {
  __typename?: 'SessionReading';
  kWhValue?: Maybe<Scalars['Float']['output']>;
  powerValue?: Maybe<Scalars['Float']['output']>;
  socValue?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Date']['output']>;
};

export type SessionSummaryByPlan = {
  __typename?: 'SessionSummaryByPlan';
  planName: Scalars['String']['output'];
  /** Dollar value for transactions against plan */
  powerCostValue: Scalars['Float']['output'];
};

export type SessionV2 = {
  __typename?: 'SessionV2';
  /** Indication of whether the session was accepted to start or not */
  accepted?: Maybe<Scalars['Boolean']['output']>;
  /** Identification number of the session for session_meters */
  altId: Scalars['ID']['output'];
  /** The card used to start the session.  Only available for superAdmin users. */
  card?: Maybe<Card>;
  /** Notes left by an operator */
  comments?: Maybe<Scalars['String']['output']>;
  connector?: Maybe<ConnectorV2>;
  /** Indication of whether the session was corrupted or not */
  corrupted?: Maybe<Scalars['Boolean']['output']>;
  corruptedReasons?: Maybe<Array<Maybe<CorruptedReason>>>;
  cost?: Maybe<Scalars['Float']['output']>;
  /** End time of the session in UTC */
  endTime?: Maybe<Scalars['Date']['output']>;
  /** End time of the session in the site timezone */
  endTimeLocal?: Maybe<Scalars['Date']['output']>;
  /** Total kWh delivered during the session */
  energyDelivered?: Maybe<Scalars['Float']['output']>;
  /** last recorded meter value for the session.  Default unit is Wh (WH enum) */
  lastMeterValue?: Maybe<Scalars['Float']['output']>;
  /** kWh the vehicle ended with */
  meterEnd?: Maybe<Scalars['Int']['output']>;
  /** kWh the vehicle began with */
  meterStart?: Maybe<Scalars['Int']['output']>;
  /** Meter values recorded during session.  Default unit is Wh (WH enum). */
  meterValues: Array<SessionMeterValue>;
  /** Plan used for the session */
  plan?: Maybe<PlanV2>;
  /** Duration of session in minutes */
  sessionDuration?: Maybe<Scalars['Int']['output']>;
  soc_notification_sent?: Maybe<Scalars['Boolean']['output']>;
  startSource?: Maybe<FalconConstraint>;
  /** Start time of the session in UTC */
  startTime?: Maybe<Scalars['Date']['output']>;
  /** Start time of the session in the site timezone */
  startTimeLocal?: Maybe<Scalars['Date']['output']>;
  stopSource?: Maybe<FalconConstraint>;
  transaction?: Maybe<TransactionV2>;
  vendorId: Scalars['ID']['output'];
};

export type SessionV2LastMeterValueArgs = {
  unit?: InputMaybe<LastSessionMeterValueUnitOfMeasurment>;
};

export type SessionV2MeterValuesArgs = {
  units?: InputMaybe<SessionMeterValueUnitOfMeasurment>;
};

/** An object containing information about the validity of a session */
export type SessionValidation = {
  __typename?: 'SessionValidation';
  /** The ID of the current authorizationStatus of the session */
  authorizationStatusId?: Maybe<Scalars['ID']['output']>;
  /** A message string describing the error for the found session */
  message?: Maybe<Scalars['String']['output']>;
  /** A boolean value that is true when a recent session is found for the input connectorId */
  sessionFound?: Maybe<Scalars['Boolean']['output']>;
};

export type SessionsConsumptionForExtendData = {
  __typename?: 'SessionsConsumptionForExtendData';
  powerCostValue: Scalars['Float']['output'];
  powerValue: Scalars['Float']['output'];
  timestamp?: Maybe<Scalars['Date']['output']>;
  totalSessions: Scalars['Int']['output'];
};

export enum SessionsV2Sort {
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KwhAsc = 'KWH_ASC',
  KwhDesc = 'KWH_DESC',
  SiteNameAsc = 'SITE_NAME_ASC',
  SiteNameDesc = 'SITE_NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
}

export type SessionsV2WithMeta = {
  __typename?: 'SessionsV2WithMeta';
  edges?: Maybe<Array<Maybe<SessionV2>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SessionsWithMeta = {
  __typename?: 'SessionsWithMeta';
  edges?: Maybe<Array<Maybe<Session>>>;
  filter?: Maybe<ListSessionsFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListSessionsSearchType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum SetChargeProfileResponseStatus {
  /** Error */
  Error = 'ERROR',
  /** Validated */
  Validated = 'VALIDATED',
}

export type SetChargingProfileInput = {
  /**
   * Optional property to use when setting the charging profile on chargers
   * that allow each session to use the full charging profile when
   * simultaneous charging is in use.  Defaults to false.
   */
  applySimultaneousChargingWorkaround?: InputMaybe<Scalars['Boolean']['input']>;
  connectorId: Scalars['ID']['input'];
  limit: Scalars['Float']['input'];
  reasonDetail: Scalars['String']['input'];
  reasonType: SetChargingProfileReason;
  shouldPublishSuccessAlert?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SetChargingProfileReason {
  ChargerDisabled = 'CHARGER_DISABLED',
  ChargerRebootReceived = 'CHARGER_REBOOT_RECEIVED',
  /** Set throttle to prevent overloading circuit or tripping breaker */
  ManageCircuit = 'MANAGE_CIRCUIT',
  /** Set throttle to prevent excessive demand charges */
  ManageDemand = 'MANAGE_DEMAND',
  /** Set throttle to avoid charging during peak hours when possible */
  ManageTou = 'MANAGE_TOU',
  /**
   * Set throttle to avoid to minimal power level needed to start session so that
   * power can be allocated maximally to active sessions
   */
  SetPreSessionThrottle = 'SET_PRE_SESSION_THROTTLE',
  /** Stop existing throttle on the charger */
  StopThrottle = 'STOP_THROTTLE',
}

export type SetChargingProfileStatusResponse = {
  __typename?: 'SetChargingProfileStatusResponse';
  SetChargeProfileResponseStatus?: Maybe<SetChargeProfileResponseStatus>;
};

export type SetDemandLimitInput = {
  altId: Scalars['UUID']['input'];
  retailDemandLimit: Scalars['Float']['input'];
};

export type SetPrimaryCardForMobileInput = {
  altId: Scalars['ID']['input'];
};

export type Site = {
  __typename?: 'Site';
  /** @deprecated Use siteAccess */
  access?: Maybe<SiteAccess>;
  accessNotes?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  availableChargers?: Maybe<Scalars['Int']['output']>;
  chargerUtilizationPercentage?: Maybe<Scalars['Float']['output']>;
  chargers?: Maybe<ChargersWithMeta>;
  chargersInUse?: Maybe<Scalars['Int']['output']>;
  chargersOffline?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  demandLimitManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  evcId?: Maybe<Scalars['String']['output']>;
  evcSyncedAt?: Maybe<Scalars['Date']['output']>;
  evseCount?: Maybe<Scalars['Int']['output']>;
  hoursOfOperation?: Maybe<HoursType>;
  isEvgoAccess?: Maybe<Scalars['Boolean']['output']>;
  isEvgoAccessAvailable?: Maybe<Scalars['Boolean']['output']>;
  kWhDelivered?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  legacyId?: Maybe<Scalars['ID']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  locationMarket?: Maybe<SiteMarket>;
  locationNotes?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<FalconConstraint>;
  locationTypeId?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxCurrent?: Maybe<Scalars['String']['output']>;
  meterNumber?: Maybe<Scalars['String']['output']>;
  networkOperator?: Maybe<NetworkOperatorType>;
  postalCode?: Maybe<Scalars['String']['output']>;
  property?: Maybe<SiteProperty>;
  ratedVoltage?: Maybe<Scalars['String']['output']>;
  reexDate?: Maybe<Scalars['Date']['output']>;
  reexOutcome?: Maybe<ReexOutcome>;
  retailDemandLimit?: Maybe<Scalars['Float']['output']>;
  retailEnergyManagementSafeMode?: Maybe<Scalars['Boolean']['output']>;
  setupType?: Maybe<FalconConstraint>;
  sid?: Maybe<Scalars['String']['output']>;
  siteAccess?: Maybe<FalconConstraint>;
  siteAccessId?: Maybe<Scalars['Int']['output']>;
  siteImages?: Maybe<SiteImagesWithMeta>;
  siteName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use siteStatusConstraint */
  siteStatus?: Maybe<Scalars['String']['output']>;
  siteStatusConstraint?: Maybe<FalconConstraint>;
  siteStatusId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use locationType */
  siteType?: Maybe<FalconConstraint>;
  timeZone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use locationType */
  type?: Maybe<SiteType>;
  utilityCompany?: Maybe<SiteUtilityCompany>;
  vendorId?: Maybe<Scalars['Int']['output']>;
};

export type SiteKWhDeliveredArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type SiteSiteImagesArgs = {
  input?: InputMaybe<ListSiteImagesInput>;
};

export enum SiteAccess {
  /** Dedicated */
  Dedicated = 'DEDICATED',
  /** Private */
  Private = 'PRIVATE',
  /** Public */
  Public = 'PUBLIC',
  /** Taxi Only */
  TaxiOnly = 'TAXI_ONLY',
  /** Use Site */
  UseSite = 'USE_SITE',
}

export type SiteFilterInput = {
  centerLat?: InputMaybe<Scalars['Float']['input']>;
  centerLng?: InputMaybe<Scalars['Float']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  neLat?: InputMaybe<Scalars['Float']['input']>;
  neLng?: InputMaybe<Scalars['Float']['input']>;
  openSites?: InputMaybe<Scalars['Boolean']['input']>;
  sid?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  siteStatusId?: InputMaybe<TableIntFilterInput>;
  swLat?: InputMaybe<Scalars['Float']['input']>;
  swLng?: InputMaybe<Scalars['Float']['input']>;
};

export type SiteFilterType = {
  __typename?: 'SiteFilterType';
  centerLat?: Maybe<Scalars['Float']['output']>;
  centerLng?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  neLat?: Maybe<Scalars['Float']['output']>;
  neLng?: Maybe<Scalars['Float']['output']>;
  openSites?: Maybe<Scalars['Boolean']['output']>;
  sid?: Maybe<TableStringFilterType>;
  siteName?: Maybe<TableStringFilterType>;
  siteStatusId?: Maybe<TableStringFilterType>;
  swLat?: Maybe<Scalars['Float']['output']>;
  swLng?: Maybe<Scalars['Float']['output']>;
};

export type SiteForMobile = {
  __typename?: 'SiteForMobile';
  access?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  availableChargers?: Maybe<Scalars['Boolean']['output']>;
  chargerStatus?: Maybe<Scalars['String']['output']>;
  chargers?: Maybe<Array<Maybe<ChargerForMobile>>>;
  country?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  isEvgoAccess?: Maybe<Scalars['Boolean']['output']>;
  isEvgoAccessAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Shows whether or not the logged in driver has favorited this site */
  isFavorited?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  locationNotes?: Maybe<Scalars['String']['output']>;
  logoURL?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxConnectorPower?: Maybe<Scalars['Float']['output']>;
  networkName?: Maybe<Scalars['String']['output']>;
  openFrom?: Maybe<Scalars['String']['output']>;
  openTo?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  reex?: Maybe<Reex>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type SiteForMobileInput = {
  /** Required: A string used as a unique identifier for the site */
  altId: Scalars['ID']['input'];
  /** Required: A float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** Required: A float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
};

export type SiteForMobileSummary = {
  __typename?: 'SiteForMobileSummary';
  available?: Maybe<Scalars['Int']['output']>;
  busy?: Maybe<Scalars['Int']['output']>;
  unavailable?: Maybe<Scalars['Int']['output']>;
  unknown?: Maybe<Scalars['Int']['output']>;
};

export type SiteImage = {
  __typename?: 'SiteImage';
  altId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  media?: Maybe<Media>;
  site?: Maybe<Site>;
};

export type SiteImagesWithMeta = {
  __typename?: 'SiteImagesWithMeta';
  edges?: Maybe<Array<Maybe<SiteImage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum SiteMarket {
  /** Location Market 1 */
  LocationMarket_1 = 'LOCATION_MARKET_1',
  /** Location Market 2 */
  LocationMarket_2 = 'LOCATION_MARKET_2',
  /** Location Market 3 */
  LocationMarket_3 = 'LOCATION_MARKET_3',
  /** Location Market 4 */
  LocationMarket_4 = 'LOCATION_MARKET_4',
  /** Location Market 5 */
  LocationMarket_5 = 'LOCATION_MARKET_5',
}

export type SiteProperty = {
  __typename?: 'SiteProperty';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Host>;
  locality?: Maybe<Scalars['String']['output']>;
  pid?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PropertyStatus>;
  vendorId?: Maybe<Scalars['String']['output']>;
};

export enum SiteType {
  /** Commercial */
  Commercial = 'COMMERCIAL',
  /** DSO */
  Dso = 'DSO',
  /** Gas Station */
  GasStation = 'GAS_STATION',
  /** Government */
  Government = 'GOVERNMENT',
  /** Highway */
  Highway = 'HIGHWAY',
  /** Home */
  Home = 'HOME',
  /** Hotel */
  Hotel = 'HOTEL',
  /** Mall */
  Mall = 'MALL',
  /** Parking Lot */
  ParkingLot = 'PARKING_LOT',
  /** Public Parking */
  PublicParking = 'PUBLIC_PARKING',
  /** Work */
  Work = 'WORK',
}

export enum SiteUtilityCompany {
  /** Host Power */
  HostPower = 'HOST_POWER',
  /** Utility Company 1 */
  UtilityCompany_1 = 'UTILITY_COMPANY_1',
  /** Utility Company 2 */
  UtilityCompany_2 = 'UTILITY_COMPANY_2',
  /** Utility Company 3 */
  UtilityCompany_3 = 'UTILITY_COMPANY_3',
  /** Utility Company 4 */
  UtilityCompany_4 = 'UTILITY_COMPANY_4',
}

export type SitesByAltIdInput = {
  /** Array of site vendor ids - this is named altIds to be consistent with the other types in this context */
  altIds: Array<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type SitesForMobile = {
  __typename?: 'SitesForMobile';
  access?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  /** Shows whether or not the logged in driver has favorited this site */
  isFavorited?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  logoURL?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxPower?: Maybe<Array<Maybe<SitesForMobilePowerStatus>>>;
  networkName?: Maybe<Scalars['String']['output']>;
  openFrom?: Maybe<Scalars['String']['output']>;
  openTo?: Maybe<Scalars['String']['output']>;
  plugAndCharge?: Maybe<Scalars['Boolean']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  reex?: Maybe<Reex>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  siteStatus?: Maybe<Scalars['String']['output']>;
  siteSummary?: Maybe<SiteForMobileSummary>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type SitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int']['input'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float']['input'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float']['input'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']['input']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']['input']>;
  plugAndCharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SitesForMobilePowerStatus = {
  __typename?: 'SitesForMobilePowerStatus';
  power?: Maybe<Scalars['Float']['output']>;
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type SitesForMobileWithMeta = {
  __typename?: 'SitesForMobileWithMeta';
  edges?: Maybe<Array<Maybe<SitesForMobile>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SitesStatusForMobile = {
  __typename?: 'SitesStatusForMobile';
  access: Scalars['String']['output'];
  reservable: Scalars['Boolean']['output'];
  siteId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  summary: SiteForMobileSummary;
};

export type SitesStatusForMobileInput = {
  afterIsoDateTime: Scalars['String']['input'];
  siteIds: Array<Scalars['ID']['input']>;
};

export type SitesWithMeta = {
  __typename?: 'SitesWithMeta';
  edges?: Maybe<Array<Maybe<Site>>>;
  filter?: Maybe<ListSiteFilterType>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListSiteSearchType>;
  sort?: Maybe<ListSitesSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StartAutochargeEnrollmentInput = {
  altId: Scalars['UUID']['input'];
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

export type StartChargeFleetInput = {
  cardId: Scalars['Int']['input'];
  cardNumber: Scalars['String']['input'];
  connectorId: Scalars['ID']['input'];
  reason?: InputMaybe<StartTransactionReason>;
};

export type StartChargeForMobileInput = {
  connectorId: Scalars['ID']['input'];
  optInForAutocharge?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<StartTransactionReason>;
};

export type StartChargeInput = {
  connectorId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  optInForAutocharge?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<StartTransactionReason>;
};

export enum StartTransactionReason {
  CardDoesNotWork = 'CARD_DOES_NOT_WORK',
  ChargerWontReleaseTheHandle = 'CHARGER_WONT_RELEASE_THE_HANDLE',
  CourtesyFreeCharge = 'COURTESY_FREE_CHARGE',
  ForgotCard = 'FORGOT_CARD',
  IssueWithCardReader = 'ISSUE_WITH_CARD_READER',
  IssueWithCharger = 'ISSUE_WITH_CHARGER',
  NoCardYet = 'NO_CARD_YET',
  PayPerUse = 'PAY_PER_USE',
}

export type Statement = {
  __typename?: 'Statement';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type StatementsWithMeta = {
  __typename?: 'StatementsWithMeta';
  edges?: Maybe<Array<Maybe<Statement>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type StopChargeForMobileInput = {
  connectorId: Scalars['ID']['input'];
  reason?: InputMaybe<StopTransactionReason>;
};

export type StopChargeInput = {
  connectorId: Scalars['ID']['input'];
  reason?: InputMaybe<StopTransactionReason>;
};

export enum StopTransactionReason {
  BeforeReservation = 'BEFORE_RESERVATION',
  ConcurrentCharging = 'CONCURRENT_CHARGING',
  EmergencyStop = 'EMERGENCY_STOP',
  GireveMeterLimitReached = 'GIREVE_METER_LIMIT_REACHED',
  MaxChargeDurationReached = 'MAX_CHARGE_DURATION_REACHED',
  MaxChargeKwhReached = 'MAX_CHARGE_KWH_REACHED',
}

export type Success = {
  __typename?: 'Success';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SuspendCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']['input']>;
  cardIds: Array<Scalars['UUID']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
};

export type SyncAccountInput = {
  altId: Scalars['ID']['input'];
  evcId: Scalars['String']['input'];
  evcSyncedAt?: InputMaybe<Scalars['Date']['input']>;
  publish: Publish;
  vendorSyncedAt: Scalars['Date']['input'];
};

export type SyncAssignCardInput = {
  altId: Scalars['ID']['input'];
};

export type SyncChargerStatusInput = {
  chargerVendorId: Scalars['ID']['input'];
  connectorStatus: Scalars['String']['input'];
  connectorVendorId: Scalars['ID']['input'];
  vendorSyncedAt: Scalars['Timestamp']['input'];
};

export type SyncConnectorInput = {
  evcId: Scalars['ID']['input'];
  status: ConnectorStatus;
};

export type SyncContractInput = {
  accountVendorId: Scalars['ID']['input'];
  balanceType?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  planVendorId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  vendorId: Scalars['ID']['input'];
  vendorStatus?: InputMaybe<Scalars['String']['input']>;
};

export type SyncCreateAccountOwnerInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreateAdHocContractInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Int']['input'];
};

export type SyncCreateCardOrderInput = {
  altId: Scalars['ID']['input'];
  contractId?: InputMaybe<Scalars['String']['input']>;
};

export type SyncCreateChargerInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreateChargerModelInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreateContractInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreateHostInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreatePropertyInput = {
  altId: Scalars['ID']['input'];
};

export type SyncCreateSiteInput = {
  altId: Scalars['ID']['input'];
};

export type SyncDriverInput = {
  altId: Scalars['ID']['input'];
};

export type SyncPasswordResetInput = {
  id: Scalars['String']['input'];
};

export type SyncPlanInput = {
  vendorId: Scalars['ID']['input'];
};

export type SyncSiteInput = {
  altId: Scalars['ID']['input'];
  evcId: Scalars['SanitizeString']['input'];
  evcSyncedAt: Scalars['Date']['input'];
  publish: Publish;
};

export type SyncUpdateAccountInput = {
  altId: Scalars['ID']['input'];
};

export type SyncUpdateAccountOwnerInput = {
  altId: Scalars['ID']['input'];
};

export type SyncUpdateChargerInput = {
  altId: Scalars['ID']['input'];
};

export type SyncUpdateContractInput = {
  altId: Scalars['ID']['input'];
};

export type TableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableBooleanFilterType = {
  __typename?: 'TableBooleanFilterType';
  eq?: Maybe<Scalars['Boolean']['output']>;
  ne?: Maybe<Scalars['Boolean']['output']>;
};

export type TableDateFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
};

export type TableDateRangeFilterInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type TableDateRangeFilterType = {
  __typename?: 'TableDateRangeFilterType';
  from?: Maybe<Scalars['Date']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type TableFilterInput = {
  or?: InputMaybe<Array<InputMaybe<TableIntFilterInput>>>;
};

export type TableFilterType = {
  __typename?: 'TableFilterType';
  or?: Maybe<Array<Maybe<TableIntFilterType>>>;
};

export type TableFloatFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  like?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  notLike?: InputMaybe<Scalars['Float']['input']>;
};

export type TableIntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  like?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  notLike?: InputMaybe<Scalars['Int']['input']>;
};

export type TableIntFilterType = {
  __typename?: 'TableIntFilterType';
  between?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  contains?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  eq?: Maybe<Scalars['Int']['output']>;
  gt?: Maybe<Scalars['Int']['output']>;
  gte?: Maybe<Scalars['Int']['output']>;
  in?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  like?: Maybe<Scalars['Int']['output']>;
  lt?: Maybe<Scalars['Int']['output']>;
  lte?: Maybe<Scalars['Int']['output']>;
  ne?: Maybe<Scalars['Int']['output']>;
  notLike?: Maybe<Scalars['Int']['output']>;
};

export enum TableSortInput {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TableSortType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TableStringFilterInput = {
  beginsWith?: InputMaybe<Scalars['SanitizeString']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']['input']>>>;
  contains?: InputMaybe<Scalars['SanitizeString']['input']>;
  endsWith?: InputMaybe<Scalars['SanitizeString']['input']>;
  eq?: InputMaybe<Scalars['SanitizeString']['input']>;
  ge?: InputMaybe<Scalars['SanitizeString']['input']>;
  gt?: InputMaybe<Scalars['SanitizeString']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']['input']>>>;
  le?: InputMaybe<Scalars['SanitizeString']['input']>;
  lt?: InputMaybe<Scalars['SanitizeString']['input']>;
  ne?: InputMaybe<Scalars['SanitizeString']['input']>;
  notContains?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type TableStringFilterType = {
  __typename?: 'TableStringFilterType';
  beginsWith?: Maybe<Scalars['String']['output']>;
  between?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contains?: Maybe<Scalars['String']['output']>;
  endsWith?: Maybe<Scalars['String']['output']>;
  eq?: Maybe<Scalars['String']['output']>;
  ge?: Maybe<Scalars['String']['output']>;
  gt?: Maybe<Scalars['String']['output']>;
  iLike?: Maybe<Scalars['String']['output']>;
  in?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  le?: Maybe<Scalars['String']['output']>;
  lt?: Maybe<Scalars['String']['output']>;
  ne?: Maybe<Scalars['String']['output']>;
  notContains?: Maybe<Scalars['String']['output']>;
};

export type TableTimestampRangeFilterInput = {
  from?: InputMaybe<Scalars['Timestamp']['input']>;
  gte?: InputMaybe<Scalars['Timestamp']['input']>;
  to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type TableTimestampRangeFilterType = {
  __typename?: 'TableTimestampRangeFilterType';
  from?: Maybe<Scalars['Timestamp']['output']>;
  to?: Maybe<Scalars['Timestamp']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  altId?: Maybe<Scalars['ID']['output']>;
  chargerGroup: ChargerGroup;
  chargers?: Maybe<ChargersWithMeta>;
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  planTariffs?: Maybe<TariffWithMeta>;
  tagName?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type TagChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type TagsWithMeta = {
  __typename?: 'TagsWithMeta';
  edges?: Maybe<Array<Maybe<Tag>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListTagsSearchType>;
  sort?: Maybe<ListTagsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Tariff = {
  __typename?: 'Tariff';
  /** @deprecated replaced by ChargerPricing type */
  altId: Scalars['ID']['output'];
  connectorTypes?: Maybe<TariffConnectorTypesWithMeta>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  energyFee?: Maybe<Scalars['Float']['output']>;
  eventLog?: Maybe<EventLogWithMeta>;
  /** @deprecated Use maxSessionDurationInMinutes instead */
  maxSessionDuration?: Maybe<Scalars['Int']['output']>;
  maxSessionDurationInMinutes?: Maybe<Scalars['Float']['output']>;
  /** Tariff schedule to display for OCPI partners */
  ocpiDescription?: Maybe<Scalars['SanitizeString']['output']>;
  perMinuteFee?: Maybe<Scalars['Float']['output']>;
  planTags?: Maybe<TagsWithMeta>;
  plans?: Maybe<PlanWithMeta>;
  sessionFee?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<TagsWithMeta>;
  tariffDescription?: Maybe<Scalars['SanitizeString']['output']>;
  tariffItems?: Maybe<Array<Maybe<TariffItem>>>;
  tariffName: Scalars['SanitizeString']['output'];
  tariffRank?: Maybe<Scalars['Int']['output']>;
};

export type TariffEventLogArgs = {
  input?: InputMaybe<ListEventLogInput>;
};

export type TariffAddressObj = {
  address1?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  stateAbbr: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type TariffConnectorTypes = {
  __typename?: 'TariffConnectorTypes';
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tariff?: Maybe<Tariff>;
};

export type TariffConnectorTypesWithMeta = {
  __typename?: 'TariffConnectorTypesWithMeta';
  edges?: Maybe<Array<Maybe<TariffConnectorTypes>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TariffCustomPrices = {
  __typename?: 'TariffCustomPrices';
  perKwh?: Maybe<Scalars['TariffPrice']['output']>;
  perMinute?: Maybe<Scalars['TariffPrice']['output']>;
  perSession?: Maybe<Scalars['TariffPrice']['output']>;
};

export type TariffCustomPricing = {
  __typename?: 'TariffCustomPricing';
  altId: Scalars['ID']['output'];
  displayDescription1?: Maybe<Scalars['SanitizeString']['output']>;
  displayDescription2?: Maybe<Scalars['SanitizeString']['output']>;
  displayName?: Maybe<Scalars['SanitizeString']['output']>;
  displayRank?: Maybe<Scalars['Int']['output']>;
  maxChargeTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  pricing: TariffCustomPrices;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<TariffSchedule>;
};

export type TariffInput = {
  /** altId of the Tariff to update. If not provided, a new tariff will be created */
  altId?: InputMaybe<Scalars['ID']['input']>;
  /** list of chargerGroupIds that will be associated with the Tariff */
  chargerGroupIds: Array<Scalars['ID']['input']>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<CustomPricingInput>>;
  defaultPricing?: InputMaybe<DefaultPricingInputV2>;
  /** display name for the Tariff for HMI display */
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** name for the Tariff */
  name?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Description to display for OCPI partners */
  ocpiDescription?: InputMaybe<Scalars['SanitizeString']['input']>;
  reservationTerm?: InputMaybe<ReservationTermInput>;
};

export type TariffItem = {
  __typename?: 'TariffItem';
  altId: Scalars['ID']['output'];
  perKwh: Scalars['TariffPrice']['output'];
  perMinute: Scalars['TariffPrice']['output'];
  perSession: Scalars['TariffPrice']['output'];
  reservationTerms: Array<TariffItemReservationTerm>;
  tariffItemName: Scalars['String']['output'];
  tariffSchedule?: Maybe<TariffSchedule>;
  tariffSchedules: Array<TariffScheduleOp>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type TariffItemReservationTerm = {
  __typename?: 'TariffItemReservationTerm';
  altId?: Maybe<Scalars['ID']['output']>;
  /** Fee for making a reservation */
  fee?: Maybe<Scalars['Float']['output']>;
  /** Length of allowed reservation in minutes */
  length?: Maybe<Scalars['Float']['output']>;
  /** Fee for missing a reservation */
  noShowFee?: Maybe<Scalars['Float']['output']>;
};

export type TariffItemReservationTermInput = {
  /** Fee for making a reservation */
  fee?: InputMaybe<Scalars['TariffPrice']['input']>;
  /** Fee for missing a reservation */
  noShowFee?: InputMaybe<Scalars['TariffPrice']['input']>;
};

export type TariffLog = {
  __typename?: 'TariffLog';
  accepted: Scalars['Boolean']['output'];
  chargerIdentityKey: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  messageType: TariffLogMessageType;
  rawRequestData: RawRequestJson;
  rawResponseData?: Maybe<RawResponseJson>;
};

export enum TariffLogMessageType {
  DisplayTariff = 'DisplayTariff',
  EndSessionTariff = 'EndSessionTariff',
  SessionTariff = 'SessionTariff',
  SetTariff = 'SetTariff',
}

export type TariffLogsSort = {
  columnName: Scalars['SanitizeString']['input'];
  direction: SortDirection;
};

export type TariffPricing = {
  __typename?: 'TariffPricing';
  displayDescription1?: Maybe<Scalars['SanitizeString']['output']>;
  displayDescription2?: Maybe<Scalars['SanitizeString']['output']>;
  displayName?: Maybe<Scalars['SanitizeString']['output']>;
  displayRank?: Maybe<Scalars['Int']['output']>;
  maxChargeTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  pricing?: Maybe<Pricing>;
};

export type TariffSchedule = {
  __typename?: 'TariffSchedule';
  /** Days schedule applies to */
  daysOfWeek: Array<Day>;
  /** End time for schedule as HH:MM */
  endTime: Scalars['String']['output'];
  scheduleName?: Maybe<Scalars['String']['output']>;
  /** Start time for schedule as HH:MM */
  startTime: Scalars['String']['output'];
};

export type TariffScheduleInput = {
  /** Days schedule applies to unabbreviated */
  daysOfWeek: Array<Day>;
  /** End time for schedule as HH:MM */
  endTime: Scalars['String']['input'];
  /** Start time for schedule as HH:MM */
  startTime: Scalars['String']['input'];
};

export type TariffScheduleOp = {
  __typename?: 'TariffScheduleOP';
  altId: Scalars['ID']['output'];
  dayOfWeek: DayOfWeek;
  endTime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  tariffItem?: Maybe<TariffItem>;
};

export enum TariffSyncStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  NotSynced = 'NOT_SYNCED',
  Synced = 'SYNCED',
}

export type TariffWithMeta = {
  __typename?: 'TariffWithMeta';
  /** @deprecated replaced by ChargerPricing types */
  edges?: Maybe<Array<Maybe<Tariff>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  search?: Maybe<ListTariffsSearchType>;
  sort?: Maybe<ListTariffsSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum TariffsSort {
  TariffDescriptionAsc = 'TARIFF_DESCRIPTION_ASC',
  TariffDescriptionDesc = 'TARIFF_DESCRIPTION_DESC',
  TariffNameAsc = 'TARIFF_NAME_ASC',
  TariffNameDesc = 'TARIFF_NAME_DESC',
  TariffRankAsc = 'TARIFF_RANK_ASC',
  TariffRankDesc = 'TARIFF_RANK_DESC',
}

export type TariffsWithMeta = {
  __typename?: 'TariffsWithMeta';
  edges?: Maybe<Array<ChargerPricing>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum TerminationBehavior {
  /** Expires */
  Expires = 'EXPIRES',
  /** Reoccurs */
  Reoccurs = 'REOCCURS',
  /** Rollover */
  Rollover = 'ROLLOVER',
}

export enum TeslaConnectorType {
  /** Adapter */
  Adapter = 'ADAPTER',
  /** Connector */
  Connector = 'CONNECTOR',
  /** None */
  None = 'NONE',
}

export type ThirdPartyWalletInput = {
  /** account's altId */
  altId: Scalars['ID']['input'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  startTime?: Maybe<Scalars['Date']['output']>;
};

export type TimeSlotInput = {
  from?: InputMaybe<Scalars['SanitizeString']['input']>;
  to?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type TimeSlotType = {
  __typename?: 'TimeSlotType';
  from?: Maybe<Scalars['SanitizeString']['output']>;
  to?: Maybe<Scalars['SanitizeString']['output']>;
};

export type Timezone = {
  __typename?: 'Timezone';
  id?: Maybe<Scalars['ID']['output']>;
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type ToggleDemandLimitInput = {
  altId: Scalars['UUID']['input'];
  demandLimitManagementEnabled: Scalars['Boolean']['input'];
};

export type ToggleRemSafeModeInput = {
  altId: Scalars['UUID']['input'];
  retailEnergyManagementSafeMode: Scalars['Boolean']['input'];
};

export type TouPrices = {
  __typename?: 'TouPrices';
  /** The name of the pricing schedule */
  name?: Maybe<Scalars['String']['output']>;
  /** A number that represents the order of schedule */
  order?: Maybe<Scalars['Int']['output']>;
  /** A string that represents the price in cents */
  price?: Maybe<Scalars['String']['output']>;
  /** A string that represents the pricing per unit */
  priceUnit?: Maybe<Scalars['String']['output']>;
  /** A an array of strings that represents the scheduled time of the price */
  schedule?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TouPricing = {
  __typename?: 'TouPricing';
  /** An int indicating the sort order for which the TouPricingByAggregate object should appear in */
  order?: Maybe<Scalars['Int']['output']>;
  /** A float representing the price per price unit */
  price?: Maybe<Scalars['Float']['output']>;
  /** A string indicating the price tier for time of use */
  priceTier?: Maybe<Scalars['String']['output']>;
  /** An array of strings with time ranges */
  priceTierSchedule?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A string with units used for charging (will most likely be /kWh) */
  priceUnit?: Maybe<Scalars['String']['output']>;
  /** A string indicating the number of reservations */
  reservations?: Maybe<Scalars['String']['output']>;
  /** A float representing the credit amount for the subscription */
  subscriptionCredit?: Maybe<Scalars['Float']['output']>;
  /** A float representing the fee for the subscription */
  subscriptionFee?: Maybe<Scalars['Float']['output']>;
  /** A float representing the fee per transaction */
  transactionFee?: Maybe<Scalars['Float']['output']>;
};

export type TouPricingByRange = {
  __typename?: 'TouPricingByRange';
  /** A float representing the max price per price unit */
  maxPrice?: Maybe<Scalars['Float']['output']>;
  /** A float representing the min price per price unit */
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** An int indicating the sort order for which the TouPricingByAggregate object should appear in */
  order?: Maybe<Scalars['Int']['output']>;
  /** A string indicating the price tier for time of use */
  priceTier?: Maybe<Scalars['String']['output']>;
  /** An array of strings with time ranges */
  priceTierSchedule?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A string with units used for charging (will most likely be /kWh) */
  priceUnit?: Maybe<Scalars['String']['output']>;
  /** A string indicating the number of reservations */
  reservations?: Maybe<Scalars['String']['output']>;
  /** A float representing the credit amount for the subscription */
  subscriptionCredit?: Maybe<Scalars['Float']['output']>;
  /** A float representing the fee for the subscription */
  subscriptionFee?: Maybe<Scalars['Float']['output']>;
  /** A float representing the fee per transaction */
  transactionFee?: Maybe<Scalars['Float']['output']>;
};

export type TouPricingInput = {
  planId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type TouRegion = {
  __typename?: 'TouRegion';
  /** An int representing the region id */
  id?: Maybe<Scalars['Int']['output']>;
  /** A string representing the region name */
  name?: Maybe<Scalars['String']['output']>;
  /** A string representing the region pricing tier */
  tier?: Maybe<Scalars['String']['output']>;
};

export type TouRegionWithPricing = {
  __typename?: 'TouRegionWithPricing';
  /** A boolean used to flag the LA region */
  defaultValue?: Maybe<Scalars['Boolean']['output']>;
  /** A float for the latitude coordinate representing the center of the region */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** A float for the longitude coordinate representing the center of the region */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** A boolean used to flag the region as the nearest to the POI ? */
  nearest?: Maybe<Scalars['Boolean']['output']>;
  /** An int representing the id of the region */
  regionId?: Maybe<Scalars['Int']['output']>;
  /** A string representing the name of the region */
  regionName?: Maybe<Scalars['String']['output']>;
  /** A string representing the tier level for the region */
  tier?: Maybe<Scalars['String']['output']>;
  /** An array of TouPricingByRegion objects nested under the region */
  touPricing?: Maybe<Array<Maybe<TouPricing>>>;
};

export type TouRegionWithPricingInput = {
  /** Required: The ID of the Plan */
  planId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional: The ID of the Region */
  regionId?: InputMaybe<Scalars['Int']['input']>;
};

export type TouRegionWithRangePricing = {
  __typename?: 'TouRegionWithRangePricing';
  /** A boolean used to flag the LA region */
  defaultValue?: Maybe<Scalars['Boolean']['output']>;
  /** A float for the latitude coordinate representing the center of the region */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** A float for the longitude coordinate representing the center of the region */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** A boolean used to flag the region as the nearest to the POI ? */
  nearest?: Maybe<Scalars['Boolean']['output']>;
  /** An int representing the id of the region */
  regionId?: Maybe<Scalars['Int']['output']>;
  /** A string representing the name of the region */
  regionName?: Maybe<Scalars['String']['output']>;
  /** An array of TouPricingByAggregate objects nested under the region */
  touPricingByRange?: Maybe<Array<Maybe<TouPricingByRange>>>;
};

export type TouRegionWithRangePricingInput = {
  /** Required: The ID of the Plan */
  planId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional: The ID of the Region */
  regionId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional: The name of the Region */
  regionName?: InputMaybe<Scalars['String']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  altId: Scalars['ID']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  authorizationType?: Maybe<Scalars['String']['output']>;
  billingPlanProduct?: Maybe<Scalars['String']['output']>;
  billingProductCategoryType?: Maybe<Scalars['String']['output']>;
  billingTransactionStatus?: Maybe<Scalars['String']['output']>;
  ccLast4Digits?: Maybe<Scalars['String']['output']>;
  charger?: Maybe<Charger>;
  chargerId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  debit?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use transactionItems */
  items?: Maybe<Array<Maybe<TransactionItem>>>;
  maxPower?: Maybe<Scalars['String']['output']>;
  paymentTxId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  powerLoss?: Maybe<Scalars['Boolean']['output']>;
  sessionEnd?: Maybe<Scalars['Date']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  sessionStart?: Maybe<Scalars['Date']['output']>;
  settlement?: Maybe<Scalars['String']['output']>;
  settlementTime?: Maybe<Scalars['Date']['output']>;
  siteId?: Maybe<Scalars['Int']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  transactionItems?: Maybe<Array<Maybe<TransactionItem>>>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorNetworkId?: Maybe<Scalars['String']['output']>;
};

export type TransactionItem = {
  __typename?: 'TransactionItem';
  altId?: Maybe<Scalars['ID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  productQuantity?: Maybe<Scalars['Float']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  productTypeId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  transactionId?: Maybe<Scalars['ID']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TransactionItemV2 = {
  __typename?: 'TransactionItemV2';
  altId: Scalars['ID']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  /** Transaction amount supplemented with calculated costs for transactions falling under plans paid for by EVgo */
  calculatedAmount?: Maybe<Scalars['Float']['output']>;
  /** Transaction amount unit price for transactions falling under plans paid for by EVgo */
  calculatedUnitPrice?: Maybe<Scalars['Float']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<FalconConstraint>;
  quantity?: Maybe<Scalars['Float']['output']>;
  taxRate?: Maybe<Scalars['Float']['output']>;
  transaction?: Maybe<TransactionV2>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type TransactionItemsWithMeta = {
  __typename?: 'TransactionItemsWithMeta';
  edges?: Maybe<Array<Maybe<TransactionItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TransactionV2 = {
  __typename?: 'TransactionV2';
  altId: Scalars['ID']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  billingPlanProduct?: Maybe<Scalars['String']['output']>;
  billingProductCategoryType?: Maybe<Scalars['String']['output']>;
  billingTransactionStatus?: Maybe<Scalars['String']['output']>;
  /** Transaction amount supplemented with calculated costs for transactions falling under plans paid for by EVgo */
  calculatedAmount?: Maybe<Scalars['Float']['output']>;
  /** The corresponding tax amount for the calculated amount */
  calculatedTaxAmount?: Maybe<Scalars['Float']['output']>;
  charger?: Maybe<ChargerV2>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  debit?: Maybe<Scalars['Boolean']['output']>;
  /** The driver who initiated the transaction. Only available for superAdmin users. */
  driver?: Maybe<Driver>;
  id: Scalars['ID']['output'];
  /** Indicates the transaction amounts have been supplemented with calculated costs */
  isCalculated?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<TransactionItemV2>>>;
  /** Nayax transaction ID */
  paymentTxId?: Maybe<Scalars['ID']['output']>;
  session?: Maybe<SessionV2>;
  tax?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<FalconConstraint>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export enum TransactionsV2Sort {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CalculatedAmountAsc = 'CALCULATED_AMOUNT_ASC',
  CalculatedAmountDesc = 'CALCULATED_AMOUNT_DESC',
  ChargerNameAsc = 'CHARGER_NAME_ASC',
  ChargerNameDesc = 'CHARGER_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  EndDateLocalAsc = 'END_DATE_LOCAL_ASC',
  EndDateLocalDesc = 'END_DATE_LOCAL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KwhAsc = 'KWH_ASC',
  KwhDesc = 'KWH_DESC',
  PlanNameAsc = 'PLAN_NAME_ASC',
  PlanNameDesc = 'PLAN_NAME_DESC',
  SiteNameAsc = 'SITE_NAME_ASC',
  SiteNameDesc = 'SITE_NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StartDateLocalAsc = 'START_DATE_LOCAL_ASC',
  StartDateLocalDesc = 'START_DATE_LOCAL_DESC',
  TaxAsc = 'TAX_ASC',
  TaxDesc = 'TAX_DESC',
  TaxRateAsc = 'TAX_RATE_ASC',
  TaxRateDesc = 'TAX_RATE_DESC',
  VendorIdAsc = 'VENDOR_ID_ASC',
  VendorIdDesc = 'VENDOR_ID_DESC',
}

export type TransactionsV2WithMeta = {
  __typename?: 'TransactionsV2WithMeta';
  edges?: Maybe<Array<Maybe<TransactionV2>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TransactionsWithMeta = {
  __typename?: 'TransactionsWithMeta';
  edges?: Maybe<Array<Maybe<Transaction>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UnassignCardsInput = {
  cardIds: Array<Scalars['UUID']['input']>;
};

export type UnassociatedChargerForFleet = {
  __typename?: 'UnassociatedChargerForFleet';
  altId: Scalars['ID']['output'];
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['SanitizeString']['output']>;
};

export type UnassociatedChargersForFleetWithMeta = {
  __typename?: 'UnassociatedChargersForFleetWithMeta';
  edges?: Maybe<Array<Maybe<UnassociatedChargerForFleet>>>;
};

export enum UnitType {
  /** All in One */
  AllInOne = 'ALL_IN_ONE',
  /** Cabinet */
  Cabinet = 'CABINET',
  /** Dispenser */
  Dispenser = 'DISPENSER',
}

export type UpdateAccountInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  altId: Scalars['ID']['input'];
  billingAddress?: InputMaybe<UpdateAddressInput>;
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  isFcms?: InputMaybe<Scalars['Boolean']['input']>;
  mailingAddress?: InputMaybe<UpdateAddressInput>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateAccountOwnerInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balanceType?: InputMaybe<Scalars['ID']['input']>;
  billingAddress?: InputMaybe<UpdateAddressInput>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']['input']>;
  mailingAddress?: InputMaybe<UpdateAddressInput>;
  notificationPreferences?: InputMaybe<Array<InputMaybe<UpdateNotificationPreferencesInput>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  vendorAccountType?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateAccountVehicleInput = {
  accountId: Scalars['UUID']['input'];
  altId: Scalars['UUID']['input'];
  autochargeFlag?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
  isRental?: InputMaybe<Scalars['Boolean']['input']>;
  macAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  media?: InputMaybe<GetMediaInput>;
  nickName?: InputMaybe<Scalars['SanitizeString']['input']>;
  rentalReturnTime?: InputMaybe<Scalars['ISODate']['input']>;
  rentalReturnTimezone?: InputMaybe<Scalars['RentalTimezone']['input']>;
  vehicleId: Scalars['UUID']['input'];
  vin?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateAdHocContractInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressInput = {
  address1: Scalars['SanitizeString']['input'];
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateCardInput = {
  altId: Scalars['UUID']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  cardStatus?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['UUID']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateCardOrdersInput = {
  cardOrderIds: Array<Scalars['ID']['input']>;
};

export type UpdateCardStatusInput = {
  cardAltId: Scalars['String']['input'];
  cardStatus: CardStatus;
  comments?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating a charger group */
export type UpdateChargerGroupInput = {
  altId: Scalars['ID']['input'];
  chargerGroupName?: InputMaybe<Scalars['SanitizeString']['input']>;
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** The pricing region this group belongs to */
  pricingRegionName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

/** Input for updating a charger group plan */
export type UpdateChargerGroupPlanInput = {
  altId: Scalars['ID']['input'];
  chargerGroupName: Scalars['SanitizeString']['input'];
  description: Scalars['SanitizeString']['input'];
  /** The amount of time in months that the plan will be valid */
  planDuration: Scalars['Int']['input'];
};

export type UpdateChargerInput = {
  additionalEquipment?: InputMaybe<Scalars['SanitizeString']['input']>;
  additionalSerial?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  authenticationMethodsConstraint?: InputMaybe<Scalars['Int']['input']>;
  authenticationModeId?: InputMaybe<Scalars['Int']['input']>;
  brand?: InputMaybe<Scalars['SanitizeString']['input']>;
  ccReaderType?: InputMaybe<Scalars['SanitizeString']['input']>;
  certified?: InputMaybe<Scalars['Boolean']['input']>;
  chargePointIdentity?: InputMaybe<Scalars['SanitizeString']['input']>;
  chargerGroups?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']['input']>>>;
  chargerHost?: InputMaybe<Scalars['SanitizeString']['input']>;
  chargerIsManaged?: InputMaybe<Scalars['Boolean']['input']>;
  chargerModelId?: InputMaybe<Scalars['ID']['input']>;
  chargerName?: InputMaybe<Scalars['SanitizeString']['input']>;
  chargerStatusId?: InputMaybe<Scalars['Int']['input']>;
  cid?: InputMaybe<Scalars['SanitizeString']['input']>;
  coBranding?: InputMaybe<Scalars['SanitizeString']['input']>;
  communicationMethod?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectionDate?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectionUri?: InputMaybe<Scalars['SanitizeString']['input']>;
  connectorTypes?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']['input']>>>;
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayTariffSynced?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evcId?: InputMaybe<Scalars['ID']['input']>;
  evseId?: InputMaybe<Scalars['SanitizeString']['input']>;
  evses?: InputMaybe<Array<InputMaybe<EvseInput>>>;
  excludeFromOcpi?: InputMaybe<Scalars['Boolean']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  fieldStationStatus?: InputMaybe<FieldStationStatus>;
  firmWareVersion?: InputMaybe<Scalars['SanitizeString']['input']>;
  firstActive?: InputMaybe<Scalars['SanitizeString']['input']>;
  fundingSource?: InputMaybe<Scalars['SanitizeString']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  identityKey?: InputMaybe<Scalars['SanitizeString']['input']>;
  ignoreStatusNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  infrastructureProviderCompanyId?: InputMaybe<Scalars['Int']['input']>;
  installDate?: InputMaybe<Scalars['SanitizeString']['input']>;
  ipAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  lastReportedFirmwareVersion?: InputMaybe<Scalars['SanitizeString']['input']>;
  localLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  localNetworkMaster?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['SanitizeString']['input']>;
  macAddress?: InputMaybe<Scalars['SanitizeString']['input']>;
  maintenanceComments?: InputMaybe<Scalars['String']['input']>;
  maintenanceVendorId?: InputMaybe<Scalars['Int']['input']>;
  maxPower?: InputMaybe<Scalars['Int']['input']>;
  meterSigningSupported?: InputMaybe<Scalars['Boolean']['input']>;
  modelNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  modelNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  modemSerialNumber?: InputMaybe<Scalars['String']['input']>;
  mountType?: InputMaybe<Scalars['SanitizeString']['input']>;
  networkConnectionTypeId?: InputMaybe<Scalars['Int']['input']>;
  nextMaintenanceDate?: InputMaybe<Scalars['Date']['input']>;
  nonNetworked?: InputMaybe<Scalars['Boolean']['input']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  productIdentityKeyId?: InputMaybe<Scalars['Int']['input']>;
  serialNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  serverSideLocalLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  setTariffSynced?: InputMaybe<Scalars['Boolean']['input']>;
  showInThirdPartyFilters?: InputMaybe<Scalars['Boolean']['input']>;
  simCardIccid?: InputMaybe<Scalars['SanitizeString']['input']>;
  simCardImsi?: InputMaybe<Scalars['SanitizeString']['input']>;
  simultaneousChargingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  smartChargingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  tariffMessageCapable?: InputMaybe<Scalars['Boolean']['input']>;
  teslaAdapter?: InputMaybe<Scalars['Boolean']['input']>;
  underEaProgram?: InputMaybe<Scalars['Boolean']['input']>;
  updateVendorId?: InputMaybe<Scalars['Int']['input']>;
  usageStartDate?: InputMaybe<Scalars['SanitizeString']['input']>;
  validateMeterSignature?: InputMaybe<Scalars['Boolean']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
  warehouseLocation?: InputMaybe<Scalars['SanitizeString']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
  warrantyLengthId?: InputMaybe<Scalars['Int']['input']>;
  warrantyStartDate?: InputMaybe<Scalars['Date']['input']>;
  warrantyStatusId?: InputMaybe<Scalars['Int']['input']>;
  wirelessConnectionCarrierId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateChargerModelInput = {
  additionalDetails?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId: Scalars['ID']['input'];
  autoChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
  breakerRating?: InputMaybe<Scalars['Float']['input']>;
  chargerModelEvses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  communicationProtocol?: InputMaybe<Scalars['String']['input']>;
  currentThd?: InputMaybe<Scalars['SanitizeString']['input']>;
  dimensions?: InputMaybe<Scalars['SanitizeString']['input']>;
  display?: InputMaybe<Scalars['SanitizeString']['input']>;
  efficiency?: InputMaybe<Scalars['SanitizeString']['input']>;
  evses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  firmwareVersion?: InputMaybe<Scalars['SanitizeString']['input']>;
  ingressProtection?: InputMaybe<Scalars['SanitizeString']['input']>;
  inputAcVoltage?: InputMaybe<InputAcVoltage>;
  interfaceVersion?: InputMaybe<InterfaceVersion>;
  isoIec?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<Manufacturer>;
  modelName?: InputMaybe<Scalars['SanitizeString']['input']>;
  modelNotes?: InputMaybe<Scalars['String']['input']>;
  modelSku?: InputMaybe<Scalars['SanitizeString']['input']>;
  mountType?: InputMaybe<Scalars['String']['input']>;
  networkCommunication?: InputMaybe<NetworkCommunication>;
  nfcReader?: InputMaybe<Scalars['Boolean']['input']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  powerFactor?: InputMaybe<Scalars['Float']['input']>;
  powerOutput?: InputMaybe<Scalars['PowerUnit']['input']>;
  simultaneousChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
  smartEnergyProfileSupport?: InputMaybe<Scalars['Boolean']['input']>;
  unitType?: InputMaybe<UnitType>;
  weight?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateConnectorInput = {
  altId: Scalars['ID']['input'];
  chargingProfileStatus?: InputMaybe<ChargingProfileStatus>;
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  isThrottling?: InputMaybe<Scalars['Boolean']['input']>;
  requestedPowerLimit?: InputMaybe<Scalars['Float']['input']>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateConnectorPowerLimitInput = {
  altId: Scalars['ID']['input'];
  powerLimit: Scalars['Float']['input'];
  reasonString?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConnectorReservableInput = {
  /** ID of the connector to update */
  altId: Scalars['ID']['input'];
  /** Allows the connector to be reserved */
  reservable: Scalars['Boolean']['input'];
};

export type UpdateContractInput = {
  altId: Scalars['ID']['input'];
  closureDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCustomPricingInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  displayDescription1?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayRank?: InputMaybe<Scalars['Int']['input']>;
  maxChargeTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  pricing: UpdatePricingInput;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<CreateTariffScheduleInput>;
};

export type UpdateDriverInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  driverStatusId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enrolledOn?: InputMaybe<Scalars['Date']['input']>;
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']['input']>;
  notificationPreferences?: InputMaybe<Array<InputMaybe<UpdateNotificationPreferencesInput>>>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  tokenAndroid?: InputMaybe<Scalars['String']['input']>;
  tokenIos?: InputMaybe<Scalars['String']['input']>;
  vendorDriverStatus?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateExtendUserHostsInput = {
  /** Unique UUID of the user */
  altId: Scalars['ID']['input'];
  /** ID stored in firebase for the user */
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the hosts which are being added/updated on the user */
  hostIds: Array<Scalars['ID']['input']>;
  /** Unique username string */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFilterInput = {
  criteria?: InputMaybe<Scalars['SanitizeString']['input']>;
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<Scalars['SanitizeString']['input']>;
  values?: InputMaybe<Array<InputMaybe<UpdateFilterValueInput>>>;
};

export type UpdateFilterValueInput = {
  label?: InputMaybe<Scalars['SanitizeString']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['SanitizeString']['input'];
};

export type UpdateFleetUserInput = {
  accountId: Scalars['ID']['input'];
  firebaseId: Scalars['String']['input'];
};

export type UpdateHostInput = {
  address1?: InputMaybe<Scalars['SanitizeString']['input']>;
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  altId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  customPlansEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hid?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostName?: InputMaybe<Scalars['SanitizeString']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  status?: InputMaybe<HostStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateNotificationPreferencesInput = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  emailOn?: InputMaybe<Scalars['Boolean']['input']>;
  notificationItemId?: InputMaybe<Scalars['ID']['input']>;
  pushOn?: InputMaybe<Scalars['Boolean']['input']>;
  smsOn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePasswordInput = {
  password: Scalars['String']['input'];
};

export type UpdatePlanInput = {
  altId: Scalars['ID']['input'];
  badge?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: InputMaybe<Scalars['Int']['input']>;
  cardBrandId?: InputMaybe<Scalars['Int']['input']>;
  costFactor?: InputMaybe<Scalars['Float']['input']>;
  ctaLabel?: InputMaybe<Scalars['SanitizeString']['input']>;
  defaultAccountPlan?: InputMaybe<Scalars['Boolean']['input']>;
  detailHeading?: InputMaybe<Scalars['SanitizeString']['input']>;
  detailSections?: InputMaybe<Array<InputMaybe<DetailSectionInput>>>;
  detailSessionTimes?: InputMaybe<Array<InputMaybe<DetailSessionTimeInput>>>;
  displayPlanCard?: InputMaybe<Scalars['Boolean']['input']>;
  displayToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  displayToLoggedOutUser?: InputMaybe<Scalars['Boolean']['input']>;
  driverRank?: InputMaybe<Scalars['Int']['input']>;
  elements?: InputMaybe<Array<InputMaybe<ElementInput>>>;
  enrollmentClose?: InputMaybe<Scalars['Date']['input']>;
  enrollmentOpen?: InputMaybe<Scalars['Date']['input']>;
  financeCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  intervalLength?: InputMaybe<Scalars['Int']['input']>;
  intervalUnitId?: InputMaybe<Scalars['Int']['input']>;
  isAutoEnrolledInRewards?: InputMaybe<Scalars['Boolean']['input']>;
  legalese?: InputMaybe<Scalars['String']['input']>;
  planCode?: InputMaybe<Scalars['SanitizeString']['input']>;
  planDescription?: InputMaybe<Scalars['SanitizeString']['input']>;
  planDiscount?: InputMaybe<Scalars['Float']['input']>;
  planDisplayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  planDuration?: InputMaybe<Scalars['Int']['input']>;
  planName?: InputMaybe<Scalars['SanitizeString']['input']>;
  planStatusId?: InputMaybe<Scalars['Int']['input']>;
  planSubheading?: InputMaybe<Scalars['SanitizeString']['input']>;
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  rolloverPlanId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
  terminationBehaviorId?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vinRequiredFlag?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePlanTariffInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  /** Charger group altIds tariff will apply to.  Only groups with a type of CHARGER_GROUP are permitted */
  chargerGroupIds: Array<Scalars['ID']['input']>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<UpdateCustomPricingInput>>;
  /** Reservation pricing for the Tariff */
  reservationTerm?: InputMaybe<TariffItemReservationTermInput>;
};

export type UpdatePlanTariffsInput = {
  /** ID for custom plan.  Only groups with a type of CUSTOM_ACCESS_PLAN are permitted */
  chargerGroupId: Scalars['ID']['input'];
  /** Option to not wait for downstream integration to vendor/charger. You can check on the status async via the tariff status property. This can be helpful in avoiding timeouts */
  integrateAsync?: InputMaybe<Scalars['Boolean']['input']>;
  /** Without this parameter set, any planTariff records you do not provide will be removed */
  keepExistingTariffs?: InputMaybe<Scalars['Boolean']['input']>;
  planTariffs: Array<UpdatePlanTariffInput>;
};

export type UpdatePricingInput = {
  perKwh?: InputMaybe<Scalars['TariffPrice']['input']>;
  perMinute?: InputMaybe<Scalars['TariffPrice']['input']>;
  perSession?: InputMaybe<Scalars['TariffPrice']['input']>;
};

export type UpdatePricingRunStatusInput = {
  /** The altId of the pricing run */
  altId: Scalars['String']['input'];
  /** Status to update the pricing run to */
  status: PricingRunStatus;
};

export type UpdatePropertyInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  host?: InputMaybe<UpdateHostInput>;
  pid: Scalars['SanitizeString']['input'];
  status?: InputMaybe<Scalars['SanitizeString']['input']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateRewardsUserInput = {
  accountNumber: Scalars['ID']['input'];
  firstLogin?: InputMaybe<Scalars['Boolean']['input']>;
  swellEmail?: InputMaybe<Scalars['String']['input']>;
  welcomeEmailSent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSessionInput = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  accountVendorId?: InputMaybe<Scalars['ID']['input']>;
  cardVendorId?: InputMaybe<Scalars['ID']['input']>;
  connectorVendorId?: InputMaybe<Scalars['ID']['input']>;
  corrupted?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  isSessionEnd?: InputMaybe<Scalars['Boolean']['input']>;
  meterEnd?: InputMaybe<Scalars['Int']['input']>;
  meterStart?: InputMaybe<Scalars['Int']['input']>;
  planVendorId?: InputMaybe<Scalars['ID']['input']>;
  startSource?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  stopSource?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['ID']['input'];
};

export type UpdateSiteForExtendInput = {
  /** Notes regarding access to each stall on the site */
  accessNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Unique UUID for the site */
  altId: Scalars['ID']['input'];
  /** Description of the site */
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Directions to the site */
  directions?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Name of the site that should be displayed when rendered elsewhere */
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Notes regarding the site location */
  locationNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Name of the site */
  siteName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateSiteImageInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<GetMediaInput>;
  mediaOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSiteInput = {
  access?: InputMaybe<SiteAccess>;
  accessNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  address1: Scalars['SanitizeString']['input'];
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  administrativeArea: Scalars['SanitizeString']['input'];
  altId: Scalars['ID']['input'];
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  demandLimitManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['SanitizeString']['input'];
  directions?: InputMaybe<Scalars['SanitizeString']['input']>;
  displayName: Scalars['SanitizeString']['input'];
  evcId?: InputMaybe<Scalars['SanitizeString']['input']>;
  hid?: InputMaybe<Scalars['SanitizeString']['input']>;
  hostId?: InputMaybe<Scalars['SanitizeString']['input']>;
  hoursOfOperation?: InputMaybe<HoursInput>;
  latitude: Scalars['Float']['input'];
  locality: Scalars['SanitizeString']['input'];
  locationMarket?: InputMaybe<SiteMarket>;
  locationNotes?: InputMaybe<Scalars['SanitizeString']['input']>;
  locationTypeId?: InputMaybe<Scalars['Int']['input']>;
  longitude: Scalars['Float']['input'];
  maxCurrent?: InputMaybe<Scalars['SanitizeString']['input']>;
  meterNumber?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode: Scalars['SanitizeString']['input'];
  property: UpdatePropertyInput;
  ratedVoltage?: InputMaybe<Scalars['SanitizeString']['input']>;
  retailDemandLimit?: InputMaybe<Scalars['Float']['input']>;
  retailEnergyManagementSafeMode?: InputMaybe<Scalars['Boolean']['input']>;
  sid: Scalars['SanitizeString']['input'];
  siteAccessId?: InputMaybe<Scalars['Int']['input']>;
  siteImages?: InputMaybe<Array<InputMaybe<UpdateSiteImageInput>>>;
  siteName: Scalars['SanitizeString']['input'];
  siteStatusId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SiteType>;
  utilityCompany?: InputMaybe<SiteUtilityCompany>;
};

export type UpdateSiteReexInput = {
  reexDate: Scalars['Date']['input'];
  reexOutcome: ReexOutcome;
  sid: Scalars['String']['input'];
};

export type UpdateTagInput = {
  altId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpdateTariffInput = {
  /** altId of the Tariff to update */
  altId?: InputMaybe<Scalars['ID']['input']>;
  /** list of chargerGroupIds that will be associated with the Tariff */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<UpdateCustomPricingInput>>;
  defaultPricing?: InputMaybe<DefaultPricingInput>;
  /** display name for the Tariff for HMI display */
  displayName?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Option to not wait for downstream integration to vendor/charger. You can check on the status async via the tariff status property. This can be helpful in avoiding timeouts */
  integrateAsync?: InputMaybe<Scalars['Boolean']['input']>;
  /** name for the Tariff */
  name?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** Tariff schedule to display for OCPI partners */
  ocpiDescription?: InputMaybe<Scalars['SanitizeString']['input']>;
  reservationTerm?: InputMaybe<TariffItemReservationTermInput>;
};

export type UpdateUserInput = {
  altId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  evcId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userRole?: InputMaybe<UserRole>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleInput = {
  connectorRefArray?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['ID']['input'];
  makeRefId?: InputMaybe<Scalars['Int']['input']>;
  modelRefId?: InputMaybe<Scalars['Int']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  /** Compatible with us as Charge Point Operator for Plug and Charge */
  plugAndChargeCpoCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Supports Plug and Charge technology */
  plugAndChargeSupported?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVehicleMakeInput = {
  id: Scalars['ID']['input'];
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  vendorValue?: InputMaybe<Scalars['String']['input']>;
  vpicId?: InputMaybe<Scalars['Int']['input']>;
  vpicValue?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleModelInput = {
  id: Scalars['ID']['input'];
  makeRefId?: InputMaybe<Scalars['Int']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  vendorValue?: InputMaybe<Scalars['String']['input']>;
  vpicId?: InputMaybe<Scalars['Int']['input']>;
  vpicValue?: InputMaybe<Scalars['String']['input']>;
};

export type UploadsWithMeta = {
  __typename?: 'UploadsWithMeta';
  edges?: Maybe<Array<Maybe<Media>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UpsertCardInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  altId?: InputMaybe<Scalars['UUID']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  cardStatus?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  externalNumber?: InputMaybe<Scalars['String']['input']>;
  internalNumber?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  vendorCardBrand?: InputMaybe<Scalars['String']['input']>;
  vendorCardStatus?: InputMaybe<Scalars['String']['input']>;
  vendorCardType?: InputMaybe<Scalars['String']['input']>;
  vendorDriverId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpsertCardOrderInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  altId?: InputMaybe<Scalars['UUID']['input']>;
  cardOrderStatusTimestamp?: InputMaybe<Scalars['Date']['input']>;
  cardStatus?: InputMaybe<Scalars['String']['input']>;
  /** Sent from lambda to help determine if the event included a card status change */
  cardStatusChanged?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  vendorCardId?: InputMaybe<Scalars['ID']['input']>;
  vendorCardStatus?: InputMaybe<Scalars['String']['input']>;
  vendorDriverId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpsertExtendHmiDisplayLabelsInput = {
  column1Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  column1Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  column1Title?: InputMaybe<Scalars['SanitizeString']['input']>;
  column2Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  column2Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  column2Title?: InputMaybe<Scalars['SanitizeString']['input']>;
  column3Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  column3Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  column3Title?: InputMaybe<Scalars['SanitizeString']['input']>;
  row1Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  row1Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  row1Description3?: InputMaybe<Scalars['SanitizeString']['input']>;
  row1Title?: InputMaybe<Scalars['SanitizeString']['input']>;
  row2Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  row2Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  row2Description3?: InputMaybe<Scalars['SanitizeString']['input']>;
  row2Title?: InputMaybe<Scalars['SanitizeString']['input']>;
  row3Description1?: InputMaybe<Scalars['SanitizeString']['input']>;
  row3Description2?: InputMaybe<Scalars['SanitizeString']['input']>;
  row3Description3?: InputMaybe<Scalars['SanitizeString']['input']>;
  row3Title?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type UpsertPaymentMethodInput = {
  accountVendorId: Scalars['ID']['input'];
  braintreeCustomerId: Scalars['String']['input'];
  cardNumber: Scalars['String']['input'];
  expired?: InputMaybe<Scalars['Boolean']['input']>;
  expiryMonth: Scalars['Int']['input'];
  expiryYear: Scalars['Int']['input'];
  nameOnCard: Scalars['String']['input'];
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
  vendorId: Scalars['ID']['input'];
  vendorPaymentMethodType: Scalars['String']['input'];
};

export type UpsertSessionMeterInput = {
  connectorVendorId: Scalars['ID']['input'];
  eventDate: Scalars['Date']['input'];
  sessionVendorId: Scalars['ID']['input'];
  value: Scalars['Float']['input'];
  vendorMeterand: Scalars['String']['input'];
};

export type UpsertTariffResponse = {
  __typename?: 'UpsertTariffResponse';
  /** The upserted tariff's UUID */
  altId: Scalars['ID']['output'];
  /** The upserted tariff's ID */
  id: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accountId?: Maybe<Scalars['ID']['output']>;
  altId: Scalars['ID']['output'];
  firebaseId?: Maybe<Scalars['String']['output']>;
  userRole?: Maybe<Array<Maybe<UserRole>>>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum UserRole {
  Driver = 'DRIVER',
  Operator = 'OPERATOR',
}

export type UserRoles = {
  __typename?: 'UserRoles';
  falcon?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fcm?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  operatorPortal?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  portals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  receiptPortal?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ValidateAddressInput = {
  address1: Scalars['SanitizeString']['input'];
  address2?: InputMaybe<Scalars['SanitizeString']['input']>;
  address3?: InputMaybe<Scalars['SanitizeString']['input']>;
  address4?: InputMaybe<Scalars['SanitizeString']['input']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']['input']>;
  country?: InputMaybe<Scalars['SanitizeString']['input']>;
  latitude?: InputMaybe<Scalars['SanitizeString']['input']>;
  locality?: InputMaybe<Scalars['SanitizeString']['input']>;
  longitude?: InputMaybe<Scalars['SanitizeString']['input']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type ValidateTagInput = {
  altId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['SanitizeString']['input']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']['input']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  altId?: Maybe<Scalars['ID']['output']>;
  autochargeStatus: FalconConstraint;
  id?: Maybe<Scalars['ID']['output']>;
  makeModelYear?: Maybe<Scalars['String']['output']>;
  plugAndChargeCpoCompatible: Scalars['Boolean']['output'];
  plugAndChargeSupported: Scalars['Boolean']['output'];
  vehicleConnectors?: Maybe<Array<Maybe<VehicleConnector>>>;
};

export type VehicleConnector = {
  __typename?: 'VehicleConnector';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VehicleConnectorInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleConnectorsWithMeta = {
  __typename?: 'VehicleConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<VehicleConnector>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type VehicleInput = {
  modelName?: InputMaybe<Scalars['String']['input']>;
  vehicleMake?: InputMaybe<VehicleMakeInput>;
  vehicleYears?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

/** DMS */
export type VehicleMake = {
  __typename?: 'VehicleMake';
  altId?: Maybe<Scalars['ID']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
};

export type VehicleMakeInput = {
  makeName?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type VehicleMakeV2 = {
  __typename?: 'VehicleMakeV2';
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  manufacturer: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorValue?: Maybe<Scalars['String']['output']>;
  vpicId?: Maybe<Scalars['Int']['output']>;
  vpicValue?: Maybe<Scalars['String']['output']>;
};

/** DMS */
export type VehicleModel = {
  __typename?: 'VehicleModel';
  /** altId refers to manufacturer_id in DMS */
  altId?: Maybe<Scalars['ID']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  /**
   * vehicleMake will never be returned, based
   * on DB query and Service layer logic
   */
  vehicleMake?: Maybe<VehicleMake>;
};

export type VehicleModelV2 = {
  __typename?: 'VehicleModelV2';
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  makeRefId: Scalars['Int']['output'];
  modelName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorValue?: Maybe<Scalars['String']['output']>;
  vpicId?: Maybe<Scalars['Int']['output']>;
  vpicValue?: Maybe<Scalars['String']['output']>;
};

export type VehicleV2 = {
  __typename?: 'VehicleV2';
  connectorRefArray: Array<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  makeRefId: Scalars['Int']['output'];
  modelRefId: Scalars['Int']['output'];
  modelYear: Scalars['Int']['output'];
  /** Compatible with us as Charge Point Operator for Plug and Charge */
  plugAndChargeCpoCompatible: Scalars['Boolean']['output'];
  /** Supports Plug and Charge technology */
  plugAndChargeSupported: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type VehiclesWithMeta = {
  __typename?: 'VehiclesWithMeta';
  edges?: Maybe<Array<Maybe<Vehicle>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type VerifyDriverEmailInput = {
  altId: Scalars['String']['input'];
};

export type Warehouse = {
  __typename?: 'Warehouse';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  altId?: Maybe<Scalars['ID']['output']>;
  chargers?: Maybe<ChargersWithMeta>;
  country?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type WarehouseChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type WarehousesWithMeta = {
  __typename?: 'WarehousesWithMeta';
  edges?: Maybe<Array<Maybe<Warehouse>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<ListWarehousesSortType>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum WarrantyLength {
  /** 5 yrs */
  FiveYear = 'FIVE_YEAR',
  /** None */
  None = 'NONE',
  /** 1 Yr */
  OneYear = 'ONE_YEAR',
  /** 3 yrs */
  ThreeYear = 'THREE_YEAR',
}

export enum WarrantyStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Extended */
  Extended = 'EXTENDED',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type ConnectorAssociation = {
  __typename?: 'connectorAssociation';
  connectorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  referenceValue?: Maybe<Scalars['String']['output']>;
  vehicleConnectorId?: Maybe<Scalars['Int']['output']>;
};

export type SessionStartInput = {
  actionResult: Scalars['String']['input'];
  eventDate: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  stationId: Scalars['ID']['input'];
  stationSocketId: Scalars['ID']['input'];
};

export type SessionStartResponse = {
  __typename?: 'sessionStartResponse';
  actionResult?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  stationId: Scalars['ID']['output'];
  stationSocketId: Scalars['ID']['output'];
};
