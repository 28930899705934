/* THIS FILE WAS GENERATED, DO NOT MODIFY */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const FullChargerFragmentDoc = gql`
  fragment FullCharger on Charger {
    altId
    additionalEquipment
    additionalSerial
    ccReaderType
    certified
    chargePointIdentity
    chargerIsManaged
    chargerModel {
      altId
      modelName
    }
    chargerName
    chargerStatusId
    cid
    communicationMethod
    connectionUri
    coBranding
    description
    disabled
    equipmentId
    evcSyncedAt
    evses {
      edges {
        altId
        evseId
        connectors {
          edges {
            altId
            uniqueConnectorId
            identityKey
            inMaintenance
            connectorType {
              columnText
            }
          }
        }
      }
    }
    excluded
    fieldStationStatus
    fundingSource
    hidden
    identityKey
    ignoreStatusNotifications
    installDate
    ipAddress
    lastReceivedCommSignal
    lastReportedFirmwareVersion
    localLoadBalancingEnabled
    localNetworkMaster
    macAddress
    maintenanceComments
    maintenanceVendorId
    meterSigningSupported
    modelNotes
    mountType
    nextMaintenanceDate
    nonNetworked
    evseId
    connectionDate
    usageStartDate
    maxPower
    excludeFromOcpi
    authenticationModeId
    networkConnectionTypeId
    wirelessConnectionCarrierId
    serialNumber
    showInThirdPartyFilters
    simultaneousChargingEnabled
    site {
      access
      address1
      address2
      administrativeArea
      altId
      hoursOfOperation {
        custom
        from
        to
        monday {
          closed
          timeslots {
            from
            to
          }
        }
        tuesday {
          closed
          timeslots {
            from
            to
          }
        }
        wednesday {
          closed
          timeslots {
            from
            to
          }
        }
        thursday {
          closed
          timeslots {
            from
            to
          }
        }
        friday {
          closed
          timeslots {
            from
            to
          }
        }
        saturday {
          closed
          timeslots {
            from
            to
          }
        }
        sunday {
          closed
          timeslots {
            from
            to
          }
        }
      }
      latitude
      locality
      locationMarket
      longitude
      postalCode
      siteName
      sid
      timeZone
      type
    }
    smartChargingEnabled
    teslaAdapter
    validateMeterSignature
    tags {
      total
      edges {
        altId
        tagName
      }
    }
    warehouse {
      altId
      warehouseName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
    }
    warrantyLengthId
    warrantyStatusId
    warrantyStartDate
    warrantyExpirationDate
  }
`;
export const ChargersWithMetaFragmentDoc = gql`
  fragment ChargersWithMeta on ChargersWithMeta {
    edges {
      altId
      chargerName
      chargerStatus
      cid
      fieldStationStatus
      displayTariffSynced
      chargerModel {
        altId
        modelName
      }
      evses {
        edges {
          connectors {
            edges {
              chargingLevel
              connectorStatusConstraint {
                columnValue
                columnText
              }
              reservable
            }
          }
        }
      }
      setTariffSynced
      site {
        altId
        siteName
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        latitude
        longitude
        property {
          propertyName
        }
      }
      tariffSyncStatus
    }
    page
    pageSize
    total
  }
`;
export const PlanTariffTagFragmentDoc = gql`
  fragment PlanTariffTag on PlanTariffTag {
    planChargerGroup {
      altId
      chargerGroupName
      status
      deletedAt
    }
  }
`;
export const ChargerGroupForExtendFragmentDoc = gql`
  fragment ChargerGroupForExtend on ChargerGroup {
    altId
    chargerGroupName
    chargerGroupType
    chargerGroupTypeId
    description
    createdAt
    deletedAt
    planDuration
    planId
    status
  }
`;
export const TariffScheduleForExtendFragmentDoc = gql`
  fragment TariffScheduleForExtend on TariffSchedule {
    daysOfWeek
    endTime
    startTime
  }
`;
export const TariffCustomPricingForExtendFragmentDoc = gql`
  fragment TariffCustomPricingForExtend on TariffCustomPricing {
    altId
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
    tariffSchedules {
      ...TariffScheduleForExtend
    }
  }
  ${TariffScheduleForExtendFragmentDoc}
`;
export const PlanTariffForExtendFragmentDoc = gql`
  fragment PlanTariffForExtend on PlanTariff {
    altId
    tariffName
    tariffDescription
    chargerGroups {
      ...ChargerGroupForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffCustomPricingForExtendFragmentDoc}
`;
export const TariffPricingForExtendFragmentDoc = gql`
  fragment TariffPricingForExtend on TariffPricing {
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
  }
`;
export const TariffItemReservationTermForExtendFragmentDoc = gql`
  fragment TariffItemReservationTermForExtend on TariffItemReservationTerm {
    fee
    noShowFee
  }
`;
export const TariffForExtendFragmentDoc = gql`
  fragment TariffForExtend on ChargerPricing {
    id
    altId
    tariffDescription
    tariffDisplayName
    tariffName
    tariffRank
    status
    chargerGroups {
      ...ChargerGroupForExtend
      chargers: chargersLoaded {
        total
      }
    }
    defaultPricing {
      ...TariffPricingForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
    reservationTerm {
      ...TariffItemReservationTermForExtend
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffPricingForExtendFragmentDoc}
  ${TariffCustomPricingForExtendFragmentDoc}
  ${TariffItemReservationTermForExtendFragmentDoc}
`;
export const FullChargerModelFragmentDoc = gql`
  fragment FullChargerModel on ChargerModel {
    altId
    additionalDetails
    autoChargeSupported
    breakerRating
    communicationProtocol
    currentThd
    dimensions
    display
    efficiency
    firmwareVersion
    ingressProtection
    inputAcVoltage
    interfaceVersion
    isoIec
    manufacturer
    modelName
    modelSku
    networkCommunication
    nfcReader
    powerFactor
    powerOutput
    simultaneousChargeSupported
    smartEnergyProfileSupport
    unitType
    weight
    evses {
      edges {
        altId
        connectors {
          edges {
            altId
            connectorMaxCurrent
            connectorType {
              id
              columnText
            }
            cableCooling
            cableLength
            cableManufacturer
            outputVoltageRange
          }
        }
      }
    }
  }
`;
export const FullPlanFragmentDoc = gql`
  fragment FullPlan on Plan {
    altId
    cardBrand
    cardBrandId
    createdAt
    costFactor
    description: planDescription
    displayName: planDisplayName
    subheading: planSubheading
    duration: planDuration
    displayToCustomer
    displayToLoggedOutUser
    displayPlanCard
    driverRank
    isAutoEnrolledInRewards
    elements(input: { pageSize: 9999 }) {
      edges {
        day
        discount
        endTime
        maxDuration
        startTime
        connectorType {
          id
        }
      }
    }
    enrollmentClosed: enrollmentClose
    enrollmentOpen
    financeCode
    intervalLength
    intervalUnit
    intervalUnitId
    planCode
    planName
    badge
    balanceTypeId
    ctaLabel
    discount: planDiscount
    templateId
    products(input: { pageSize: 9999 }) {
      edges {
        productType
        productTypeId
        credit {
          creditAmount
          creditExpPolicy
          creditExpPolicyId
          creditReoccurrence
          creditReoccurrenceId
          creditOneTimeAmount: oneTimeCreditAmount
        }
        subscription {
          subscriptionFee
          subscriptionReoccurrence
          subscriptionReoccurrenceId
        }
      }
    }
    requireVin: vinRequiredFlag
    rolloverPlan {
      altId
    }
    status: planStatus
    planStatusId
    pageHeader: detailHeading
    detailSections {
      header
      items
    }
    detailSessionTimes {
      duration
      interval
      startTime
      endTime
    }
    legalese
    terminationBehavior
    terminationBehaviorId
  }
`;
export const FullSiteFragmentDoc = gql`
  fragment FullSite on Site {
    address1
    address2
    locality
    administrativeArea
    postalCode
    country
    chargers {
      edges {
        cid
        altId
        chargerName
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          country
          postalCode
          property {
            propertyName
          }
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        propertyName
        fieldStationStatus
      }
    }
    displayName
    altId
    latitude
    locationNotes
    locationTypeId
    longitude
    siteName
    timeZone
    property {
      altId
      pid
      propertyName
      host {
        altId
        hid
      }
    }
    hoursOfOperation {
      custom
      from
      to
      monday {
        closed
        timeslots {
          from
          to
        }
      }
      tuesday {
        closed
        timeslots {
          from
          to
        }
      }
      wednesday {
        closed
        timeslots {
          from
          to
        }
      }
      thursday {
        closed
        timeslots {
          from
          to
        }
      }
      friday {
        closed
        timeslots {
          from
          to
        }
      }
      saturday {
        closed
        timeslots {
          from
          to
        }
      }
      sunday {
        closed
        timeslots {
          from
          to
        }
      }
    }
    access
    accessNotes
    locationMarket
    directions
    utilityCompany
    type
    sid
    siteAccessId
    siteImages {
      edges {
        media {
          createdAt
          altId
          url
        }
      }
    }
    demandLimitManagementEnabled
    retailDemandLimit
    siteStatusId
    meterNumber
    ratedVoltage
    maxCurrent
  }
`;
export const CancelAutochargeEnrollmentDocument = gql`
  mutation cancelAutochargeEnrollment($input: CancelAutochargeEnrollmentInput!) {
    cancelAutochargeEnrollment(input: $input) {
      driverId
    }
  }
`;
export type CancelAutochargeEnrollmentMutationFn = Apollo.MutationFunction<
  Types.CancelAutochargeEnrollmentMutation,
  Types.CancelAutochargeEnrollmentMutationVariables
>;

/**
 * __useCancelAutochargeEnrollmentMutation__
 *
 * To run a mutation, you first call `useCancelAutochargeEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutochargeEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutochargeEnrollmentMutation, { data, loading, error }] = useCancelAutochargeEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAutochargeEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CancelAutochargeEnrollmentMutation,
    Types.CancelAutochargeEnrollmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CancelAutochargeEnrollmentMutation,
    Types.CancelAutochargeEnrollmentMutationVariables
  >(CancelAutochargeEnrollmentDocument, options);
}
export type CancelAutochargeEnrollmentMutationHookResult = ReturnType<typeof useCancelAutochargeEnrollmentMutation>;
export type CancelAutochargeEnrollmentMutationResult = Apollo.MutationResult<Types.CancelAutochargeEnrollmentMutation>;
export type CancelAutochargeEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  Types.CancelAutochargeEnrollmentMutation,
  Types.CancelAutochargeEnrollmentMutationVariables
>;
export const CreateAccountOwnerDocument = gql`
  mutation createAccountOwner($input: CreateAccountOwnerInput) {
    createAccountOwner(input: $input) {
      altId
      account {
        altId
      }
    }
  }
`;
export type CreateAccountOwnerMutationFn = Apollo.MutationFunction<
  Types.CreateAccountOwnerMutation,
  Types.CreateAccountOwnerMutationVariables
>;

/**
 * __useCreateAccountOwnerMutation__
 *
 * To run a mutation, you first call `useCreateAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountOwnerMutation, { data, loading, error }] = useCreateAccountOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateAccountOwnerMutation, Types.CreateAccountOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateAccountOwnerMutation, Types.CreateAccountOwnerMutationVariables>(
    CreateAccountOwnerDocument,
    options,
  );
}
export type CreateAccountOwnerMutationHookResult = ReturnType<typeof useCreateAccountOwnerMutation>;
export type CreateAccountOwnerMutationResult = Apollo.MutationResult<Types.CreateAccountOwnerMutation>;
export type CreateAccountOwnerMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAccountOwnerMutation,
  Types.CreateAccountOwnerMutationVariables
>;
export const DeleteAccountVehicleDocument = gql`
  mutation deleteAccountVehicle($input: DeleteAccountVehicleInput!) {
    deleteAccountVehicle(input: $input) {
      success
    }
  }
`;
export type DeleteAccountVehicleMutationFn = Apollo.MutationFunction<
  Types.DeleteAccountVehicleMutation,
  Types.DeleteAccountVehicleMutationVariables
>;

/**
 * __useDeleteAccountVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountVehicleMutation, { data, loading, error }] = useDeleteAccountVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteAccountVehicleMutation,
    Types.DeleteAccountVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteAccountVehicleMutation, Types.DeleteAccountVehicleMutationVariables>(
    DeleteAccountVehicleDocument,
    options,
  );
}
export type DeleteAccountVehicleMutationHookResult = ReturnType<typeof useDeleteAccountVehicleMutation>;
export type DeleteAccountVehicleMutationResult = Apollo.MutationResult<Types.DeleteAccountVehicleMutation>;
export type DeleteAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAccountVehicleMutation,
  Types.DeleteAccountVehicleMutationVariables
>;
export const DisassociateAccountVehicleDocument = gql`
  mutation disassociateAccountVehicle($input: DisassociateAccountVehicleInput!) {
    disassociateAccountVehicle(input: $input) {
      success
    }
  }
`;
export type DisassociateAccountVehicleMutationFn = Apollo.MutationFunction<
  Types.DisassociateAccountVehicleMutation,
  Types.DisassociateAccountVehicleMutationVariables
>;

/**
 * __useDisassociateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useDisassociateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateAccountVehicleMutation, { data, loading, error }] = useDisassociateAccountVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DisassociateAccountVehicleMutation,
    Types.DisassociateAccountVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DisassociateAccountVehicleMutation,
    Types.DisassociateAccountVehicleMutationVariables
  >(DisassociateAccountVehicleDocument, options);
}
export type DisassociateAccountVehicleMutationHookResult = ReturnType<typeof useDisassociateAccountVehicleMutation>;
export type DisassociateAccountVehicleMutationResult = Apollo.MutationResult<Types.DisassociateAccountVehicleMutation>;
export type DisassociateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  Types.DisassociateAccountVehicleMutation,
  Types.DisassociateAccountVehicleMutationVariables
>;
export const GetAccountDocument = gql`
  query getAccount(
    $accountInput: GetAccountInput
    $accountVehiclesInput: ListAccountVehiclesInput
    $cardsInput: ListCardsInput
    $driversInput: ListDriversInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getAccount(input: $accountInput) {
      altId
      id
      enrolledOn
      accountStatus
      accountType
      vendorId
      updatedAt
      drivers(input: $driversInput) {
        total
        edges {
          accountId
          id
          altId
          accountId
          firstName
          lastName
          email
          phone
          deletedAt
          driverType
          driverStatus {
            id
            columnText
          }
          firebaseId
          createdAt
          accountVehicles(input: $accountVehiclesInput) {
            total
            edges {
              altId
              nickName
              vin
              macAddress
              autochargeStatus {
                id
                columnText
                columnValue
              }
              deletedAt
              vehicle {
                altId
                makeModelYear
                vehicleConnectors {
                  id
                  connectorName: name
                }
              }
            }
          }
          notificationPreferencesForOp(input: $preferencesInput) {
            edges {
              driverId
              notificationItemId
              emailOn
              smsOn
              pushOn
            }
          }
        }
      }
      cards(input: $cardsInput) {
        total
        edges {
          altId
          brand
          cardStatus
          cardTypeId
          externalNumber
          internalNumber
          updatedAt
          createdAt
          driver {
            altId
            firstName
            lastName
          }
          account {
            accountStatus
          }
        }
      }
      cardOrders {
        total
        edges {
          orderStatus
          orderedAt
          fulfilledAt
          driver {
            email
          }
        }
      }
      contracts(input: { pageSize: 999 }) {
        total
        edges {
          altId
          plan {
            altId
            planName
            terminationBehavior
          }
          startDate
          endDate
          closureDate
          status {
            id
            columnText
          }
        }
      }
      mailingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *      accountVehiclesInput: // value for 'accountVehiclesInput'
 *      cardsInput: // value for 'cardsInput'
 *      driversInput: // value for 'driversInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetAccountQuery, Types.GetAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, options);
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountQuery, Types.GetAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, options);
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<Types.GetAccountQuery, Types.GetAccountQueryVariables>;
export const SearchFirebaseAndDriivzAndFalconAccountsDocument = gql`
  query SearchFirebaseAndDriivzAndFalconAccounts($email: String, $phoneNumber: String) {
    searchFirebaseAndDriivzAndFalconAccounts(email: $email, phoneNumber: $phoneNumber) {
      driivz {
        firstName
        lastName
        billingAddress1
        billingAddress2
        billingRegion
        billingLocality
        email
        phone
      }
      falcon {
        firstName
        lastName
        accountId
        email
        phone
        altId
        driverType
        account {
          altId
          billingAddress {
            address1
            address2
            city
            postalCode
            locality
          }
        }
      }
      firebase {
        email
        phoneNumber
      }
    }
  }
`;

/**
 * __useSearchFirebaseAndDriivzAndFalconAccountsQuery__
 *
 * To run a query within a React component, call `useSearchFirebaseAndDriivzAndFalconAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFirebaseAndDriivzAndFalconAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFirebaseAndDriivzAndFalconAccountsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSearchFirebaseAndDriivzAndFalconAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchFirebaseAndDriivzAndFalconAccountsQuery,
    Types.SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchFirebaseAndDriivzAndFalconAccountsQuery,
    Types.SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >(SearchFirebaseAndDriivzAndFalconAccountsDocument, options);
}
export function useSearchFirebaseAndDriivzAndFalconAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchFirebaseAndDriivzAndFalconAccountsQuery,
    Types.SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchFirebaseAndDriivzAndFalconAccountsQuery,
    Types.SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >(SearchFirebaseAndDriivzAndFalconAccountsDocument, options);
}
export type SearchFirebaseAndDriivzAndFalconAccountsQueryHookResult = ReturnType<
  typeof useSearchFirebaseAndDriivzAndFalconAccountsQuery
>;
export type SearchFirebaseAndDriivzAndFalconAccountsLazyQueryHookResult = ReturnType<
  typeof useSearchFirebaseAndDriivzAndFalconAccountsLazyQuery
>;
export type SearchFirebaseAndDriivzAndFalconAccountsQueryResult = Apollo.QueryResult<
  Types.SearchFirebaseAndDriivzAndFalconAccountsQuery,
  Types.SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
>;
export const SyncUserDocument = gql`
  mutation syncUser($email: String!, $setPhoneNumber: String) {
    syncUser(email: $email, setPhoneNumber: $setPhoneNumber)
  }
`;
export type SyncUserMutationFn = Apollo.MutationFunction<Types.SyncUserMutation, Types.SyncUserMutationVariables>;

/**
 * __useSyncUserMutation__
 *
 * To run a mutation, you first call `useSyncUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUserMutation, { data, loading, error }] = useSyncUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      setPhoneNumber: // value for 'setPhoneNumber'
 *   },
 * });
 */
export function useSyncUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SyncUserMutation, Types.SyncUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SyncUserMutation, Types.SyncUserMutationVariables>(SyncUserDocument, options);
}
export type SyncUserMutationHookResult = ReturnType<typeof useSyncUserMutation>;
export type SyncUserMutationResult = Apollo.MutationResult<Types.SyncUserMutation>;
export type SyncUserMutationOptions = Apollo.BaseMutationOptions<
  Types.SyncUserMutation,
  Types.SyncUserMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($input: UpdateAccountInput) {
    account: updateAccount(input: $input) {
      altId
      evcId
      mailingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      enrolledOn
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  Types.UpdateAccountMutation,
  Types.UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateAccountMutation, Types.UpdateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateAccountMutation, Types.UpdateAccountMutationVariables>(
    UpdateAccountDocument,
    options,
  );
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<Types.UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAccountMutation,
  Types.UpdateAccountMutationVariables
>;
export const UpdateAccountOwnerDocument = gql`
  mutation updateAccountOwner(
    $accountInput: UpdateAccountOwnerInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateAccountOwner(input: $accountInput) {
      altId
      firstName
      lastName
      phone
      email
      driverType
      driverStatus {
        id
        columnText
      }
      account {
        altId
        enrolledOn
        vendorId
        vendorAccountType {
          id
          columnText
        }
        optInPromotions
        mailingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
        billingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
      }
      createdAt
      updatedAt
      deletedAt
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
export type UpdateAccountOwnerMutationFn = Apollo.MutationFunction<
  Types.UpdateAccountOwnerMutation,
  Types.UpdateAccountOwnerMutationVariables
>;

/**
 * __useUpdateAccountOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountOwnerMutation, { data, loading, error }] = useUpdateAccountOwnerMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useUpdateAccountOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateAccountOwnerMutation, Types.UpdateAccountOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateAccountOwnerMutation, Types.UpdateAccountOwnerMutationVariables>(
    UpdateAccountOwnerDocument,
    options,
  );
}
export type UpdateAccountOwnerMutationHookResult = ReturnType<typeof useUpdateAccountOwnerMutation>;
export type UpdateAccountOwnerMutationResult = Apollo.MutationResult<Types.UpdateAccountOwnerMutation>;
export type UpdateAccountOwnerMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAccountOwnerMutation,
  Types.UpdateAccountOwnerMutationVariables
>;
export const UpdateAccountVehicleDocument = gql`
  mutation updateAccountVehicle($accountVehicleInput: UpdateAccountVehicleInput) {
    updateAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
      }
      nickName
      vin
    }
  }
`;
export type UpdateAccountVehicleMutationFn = Apollo.MutationFunction<
  Types.UpdateAccountVehicleMutation,
  Types.UpdateAccountVehicleMutationVariables
>;

/**
 * __useUpdateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountVehicleMutation, { data, loading, error }] = useUpdateAccountVehicleMutation({
 *   variables: {
 *      accountVehicleInput: // value for 'accountVehicleInput'
 *   },
 * });
 */
export function useUpdateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAccountVehicleMutation,
    Types.UpdateAccountVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateAccountVehicleMutation, Types.UpdateAccountVehicleMutationVariables>(
    UpdateAccountVehicleDocument,
    options,
  );
}
export type UpdateAccountVehicleMutationHookResult = ReturnType<typeof useUpdateAccountVehicleMutation>;
export type UpdateAccountVehicleMutationResult = Apollo.MutationResult<Types.UpdateAccountVehicleMutation>;
export type UpdateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAccountVehicleMutation,
  Types.UpdateAccountVehicleMutationVariables
>;
export const ValidateAddressDocument = gql`
  mutation validateAddress($addressInput: ValidateAddressInput) {
    validateAddress(input: $addressInput) {
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
      latitude
      longitude
    }
  }
`;
export type ValidateAddressMutationFn = Apollo.MutationFunction<
  Types.ValidateAddressMutation,
  Types.ValidateAddressMutationVariables
>;

/**
 * __useValidateAddressMutation__
 *
 * To run a mutation, you first call `useValidateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAddressMutation, { data, loading, error }] = useValidateAddressMutation({
 *   variables: {
 *      addressInput: // value for 'addressInput'
 *   },
 * });
 */
export function useValidateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ValidateAddressMutation, Types.ValidateAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ValidateAddressMutation, Types.ValidateAddressMutationVariables>(
    ValidateAddressDocument,
    options,
  );
}
export type ValidateAddressMutationHookResult = ReturnType<typeof useValidateAddressMutation>;
export type ValidateAddressMutationResult = Apollo.MutationResult<Types.ValidateAddressMutation>;
export type ValidateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.ValidateAddressMutation,
  Types.ValidateAddressMutationVariables
>;
export const ActivateCardsDocument = gql`
  mutation activateCards($input: ActivateCardsInput!) {
    activateCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ActivateCardsMutationFn = Apollo.MutationFunction<
  Types.ActivateCardsMutation,
  Types.ActivateCardsMutationVariables
>;

/**
 * __useActivateCardsMutation__
 *
 * To run a mutation, you first call `useActivateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardsMutation, { data, loading, error }] = useActivateCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ActivateCardsMutation, Types.ActivateCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ActivateCardsMutation, Types.ActivateCardsMutationVariables>(
    ActivateCardsDocument,
    options,
  );
}
export type ActivateCardsMutationHookResult = ReturnType<typeof useActivateCardsMutation>;
export type ActivateCardsMutationResult = Apollo.MutationResult<Types.ActivateCardsMutation>;
export type ActivateCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.ActivateCardsMutation,
  Types.ActivateCardsMutationVariables
>;
export const AssignCardsDocument = gql`
  mutation assignCards($input: AssignCardsInput!) {
    assignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type AssignCardsMutationFn = Apollo.MutationFunction<
  Types.AssignCardsMutation,
  Types.AssignCardsMutationVariables
>;

/**
 * __useAssignCardsMutation__
 *
 * To run a mutation, you first call `useAssignCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCardsMutation, { data, loading, error }] = useAssignCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AssignCardsMutation, Types.AssignCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AssignCardsMutation, Types.AssignCardsMutationVariables>(
    AssignCardsDocument,
    options,
  );
}
export type AssignCardsMutationHookResult = ReturnType<typeof useAssignCardsMutation>;
export type AssignCardsMutationResult = Apollo.MutationResult<Types.AssignCardsMutation>;
export type AssignCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.AssignCardsMutation,
  Types.AssignCardsMutationVariables
>;
export const CreateCardDocument = gql`
  mutation createCard($input: CreateCardInput!) {
    createCard(input: $input) {
      altId
      cardStatus
      activatedAt
    }
  }
`;
export type CreateCardMutationFn = Apollo.MutationFunction<Types.CreateCardMutation, Types.CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateCardMutation, Types.CreateCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateCardMutation, Types.CreateCardMutationVariables>(CreateCardDocument, options);
}
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<Types.CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCardMutation,
  Types.CreateCardMutationVariables
>;
export const DeactivateCardsDocument = gql`
  mutation deactivateCards($input: DeactivateCardsInput!) {
    deactivateCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type DeactivateCardsMutationFn = Apollo.MutationFunction<
  Types.DeactivateCardsMutation,
  Types.DeactivateCardsMutationVariables
>;

/**
 * __useDeactivateCardsMutation__
 *
 * To run a mutation, you first call `useDeactivateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCardsMutation, { data, loading, error }] = useDeactivateCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeactivateCardsMutation, Types.DeactivateCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeactivateCardsMutation, Types.DeactivateCardsMutationVariables>(
    DeactivateCardsDocument,
    options,
  );
}
export type DeactivateCardsMutationHookResult = ReturnType<typeof useDeactivateCardsMutation>;
export type DeactivateCardsMutationResult = Apollo.MutationResult<Types.DeactivateCardsMutation>;
export type DeactivateCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.DeactivateCardsMutation,
  Types.DeactivateCardsMutationVariables
>;
export const DeleteVehicleIdCardDocument = gql`
  mutation deleteVehicleIdCard($input: DeleteVehicleIdCardInput!) {
    deleteVehicleIdCard(input: $input) {
      success
    }
  }
`;
export type DeleteVehicleIdCardMutationFn = Apollo.MutationFunction<
  Types.DeleteVehicleIdCardMutation,
  Types.DeleteVehicleIdCardMutationVariables
>;

/**
 * __useDeleteVehicleIdCardMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleIdCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleIdCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleIdCardMutation, { data, loading, error }] = useDeleteVehicleIdCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVehicleIdCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteVehicleIdCardMutation,
    Types.DeleteVehicleIdCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteVehicleIdCardMutation, Types.DeleteVehicleIdCardMutationVariables>(
    DeleteVehicleIdCardDocument,
    options,
  );
}
export type DeleteVehicleIdCardMutationHookResult = ReturnType<typeof useDeleteVehicleIdCardMutation>;
export type DeleteVehicleIdCardMutationResult = Apollo.MutationResult<Types.DeleteVehicleIdCardMutation>;
export type DeleteVehicleIdCardMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteVehicleIdCardMutation,
  Types.DeleteVehicleIdCardMutationVariables
>;
export const ListCardOrdersDocument = gql`
  query listCardOrders($input: ListCardOrdersInput) {
    listCardOrders(input: $input) {
      total
      page
      pageSize
      filter {
        orderStatus {
          in
        }
      }
      search {
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        account_billingAddress_address1 {
          iLike
        }
        account_billingAddress_locality {
          iLike
        }
        account_billingAddress_administrativeArea {
          iLike
        }
        account_billingAddress_postalCode {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        orderStatus
        orderedAt
        fulfilledAt
        driver {
          firstName
          lastName
        }
        account {
          altId
          id
          billingAddress {
            address1
            address2
            locality
            administrativeArea
            postalCode
          }
        }
      }
    }
  }
`;

/**
 * __useListCardOrdersQuery__
 *
 * To run a query within a React component, call `useListCardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCardOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCardOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCardOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListCardOrdersQuery, Types.ListCardOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListCardOrdersQuery, Types.ListCardOrdersQueryVariables>(
    ListCardOrdersDocument,
    options,
  );
}
export function useListCardOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCardOrdersQuery, Types.ListCardOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListCardOrdersQuery, Types.ListCardOrdersQueryVariables>(
    ListCardOrdersDocument,
    options,
  );
}
export type ListCardOrdersQueryHookResult = ReturnType<typeof useListCardOrdersQuery>;
export type ListCardOrdersLazyQueryHookResult = ReturnType<typeof useListCardOrdersLazyQuery>;
export type ListCardOrdersQueryResult = Apollo.QueryResult<
  Types.ListCardOrdersQuery,
  Types.ListCardOrdersQueryVariables
>;
export const ListCardsDocument = gql`
  query listCards($input: ListCardsInput) {
    listCards(input: $input) {
      total
      page
      pageSize
      filter {
        cardStatus {
          in
        }
        brand {
          in
        }
      }
      search {
        externalNumber {
          iLike
        }
        internalNumber {
          iLike
        }
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        internalNumber
        externalNumber
        brand
        cardStatus
        cardTypeId
        account {
          id
          altId
        }
        driver {
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useListCardsQuery__
 *
 * To run a query within a React component, call `useListCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListCardsQuery, Types.ListCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListCardsQuery, Types.ListCardsQueryVariables>(ListCardsDocument, options);
}
export function useListCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCardsQuery, Types.ListCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListCardsQuery, Types.ListCardsQueryVariables>(ListCardsDocument, options);
}
export type ListCardsQueryHookResult = ReturnType<typeof useListCardsQuery>;
export type ListCardsLazyQueryHookResult = ReturnType<typeof useListCardsLazyQuery>;
export type ListCardsQueryResult = Apollo.QueryResult<Types.ListCardsQuery, Types.ListCardsQueryVariables>;
export const ReassignCardDocument = gql`
  mutation ReassignCard($input: ReassignCardInput!) {
    reassignCard(input: $input) {
      altId
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ReassignCardMutationFn = Apollo.MutationFunction<
  Types.ReassignCardMutation,
  Types.ReassignCardMutationVariables
>;

/**
 * __useReassignCardMutation__
 *
 * To run a mutation, you first call `useReassignCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignCardMutation, { data, loading, error }] = useReassignCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReassignCardMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ReassignCardMutation, Types.ReassignCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ReassignCardMutation, Types.ReassignCardMutationVariables>(
    ReassignCardDocument,
    options,
  );
}
export type ReassignCardMutationHookResult = ReturnType<typeof useReassignCardMutation>;
export type ReassignCardMutationResult = Apollo.MutationResult<Types.ReassignCardMutation>;
export type ReassignCardMutationOptions = Apollo.BaseMutationOptions<
  Types.ReassignCardMutation,
  Types.ReassignCardMutationVariables
>;
export const ReserveCardsDocument = gql`
  mutation reserveCards($input: ReserveCardsInput!) {
    reserveCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ReserveCardsMutationFn = Apollo.MutationFunction<
  Types.ReserveCardsMutation,
  Types.ReserveCardsMutationVariables
>;

/**
 * __useReserveCardsMutation__
 *
 * To run a mutation, you first call `useReserveCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCardsMutation, { data, loading, error }] = useReserveCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ReserveCardsMutation, Types.ReserveCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ReserveCardsMutation, Types.ReserveCardsMutationVariables>(
    ReserveCardsDocument,
    options,
  );
}
export type ReserveCardsMutationHookResult = ReturnType<typeof useReserveCardsMutation>;
export type ReserveCardsMutationResult = Apollo.MutationResult<Types.ReserveCardsMutation>;
export type ReserveCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.ReserveCardsMutation,
  Types.ReserveCardsMutationVariables
>;
export const SuspendCardsDocument = gql`
  mutation suspendCards($input: SuspendCardsInput!) {
    suspendCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type SuspendCardsMutationFn = Apollo.MutationFunction<
  Types.SuspendCardsMutation,
  Types.SuspendCardsMutationVariables
>;

/**
 * __useSuspendCardsMutation__
 *
 * To run a mutation, you first call `useSuspendCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendCardsMutation, { data, loading, error }] = useSuspendCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspendCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SuspendCardsMutation, Types.SuspendCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SuspendCardsMutation, Types.SuspendCardsMutationVariables>(
    SuspendCardsDocument,
    options,
  );
}
export type SuspendCardsMutationHookResult = ReturnType<typeof useSuspendCardsMutation>;
export type SuspendCardsMutationResult = Apollo.MutationResult<Types.SuspendCardsMutation>;
export type SuspendCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.SuspendCardsMutation,
  Types.SuspendCardsMutationVariables
>;
export const UnassignCardsDocument = gql`
  mutation unassignCards($input: UnassignCardsInput!) {
    unassignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type UnassignCardsMutationFn = Apollo.MutationFunction<
  Types.UnassignCardsMutation,
  Types.UnassignCardsMutationVariables
>;

/**
 * __useUnassignCardsMutation__
 *
 * To run a mutation, you first call `useUnassignCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCardsMutation, { data, loading, error }] = useUnassignCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnassignCardsMutation, Types.UnassignCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UnassignCardsMutation, Types.UnassignCardsMutationVariables>(
    UnassignCardsDocument,
    options,
  );
}
export type UnassignCardsMutationHookResult = ReturnType<typeof useUnassignCardsMutation>;
export type UnassignCardsMutationResult = Apollo.MutationResult<Types.UnassignCardsMutation>;
export type UnassignCardsMutationOptions = Apollo.BaseMutationOptions<
  Types.UnassignCardsMutation,
  Types.UnassignCardsMutationVariables
>;
export const UpdateCardOrdersDocument = gql`
  mutation updateCardOrders($input: UpdateCardOrdersInput!) {
    updateCardOrders(input: $input) {
      altId
      orderStatus
    }
  }
`;
export type UpdateCardOrdersMutationFn = Apollo.MutationFunction<
  Types.UpdateCardOrdersMutation,
  Types.UpdateCardOrdersMutationVariables
>;

/**
 * __useUpdateCardOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateCardOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardOrdersMutation, { data, loading, error }] = useUpdateCardOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateCardOrdersMutation, Types.UpdateCardOrdersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateCardOrdersMutation, Types.UpdateCardOrdersMutationVariables>(
    UpdateCardOrdersDocument,
    options,
  );
}
export type UpdateCardOrdersMutationHookResult = ReturnType<typeof useUpdateCardOrdersMutation>;
export type UpdateCardOrdersMutationResult = Apollo.MutationResult<Types.UpdateCardOrdersMutation>;
export type UpdateCardOrdersMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCardOrdersMutation,
  Types.UpdateCardOrdersMutationVariables
>;
export const CreateChargerDocument = gql`
  mutation createCharger($chargerInput: CreateChargerInput) {
    createCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;
export type CreateChargerMutationFn = Apollo.MutationFunction<
  Types.CreateChargerMutation,
  Types.CreateChargerMutationVariables
>;

/**
 * __useCreateChargerMutation__
 *
 * To run a mutation, you first call `useCreateChargerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerMutation, { data, loading, error }] = useCreateChargerMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useCreateChargerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateChargerMutation, Types.CreateChargerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateChargerMutation, Types.CreateChargerMutationVariables>(
    CreateChargerDocument,
    options,
  );
}
export type CreateChargerMutationHookResult = ReturnType<typeof useCreateChargerMutation>;
export type CreateChargerMutationResult = Apollo.MutationResult<Types.CreateChargerMutation>;
export type CreateChargerMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateChargerMutation,
  Types.CreateChargerMutationVariables
>;
export const GetChargerDocument = gql`
  query getCharger($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;

/**
 * __useGetChargerQuery__
 *
 * To run a query within a React component, call `useGetChargerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerQuery({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useGetChargerQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetChargerQuery, Types.GetChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChargerQuery, Types.GetChargerQueryVariables>(GetChargerDocument, options);
}
export function useGetChargerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChargerQuery, Types.GetChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetChargerQuery, Types.GetChargerQueryVariables>(GetChargerDocument, options);
}
export type GetChargerQueryHookResult = ReturnType<typeof useGetChargerQuery>;
export type GetChargerLazyQueryHookResult = ReturnType<typeof useGetChargerLazyQuery>;
export type GetChargerQueryResult = Apollo.QueryResult<Types.GetChargerQuery, Types.GetChargerQueryVariables>;
export const ListChargersDocument = gql`
  query listChargers($chargersInput: ListChargersInput) {
    listChargers(input: $chargersInput) {
      edges {
        altId
        chargerName
        cid
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          latitude
          longitude
          property {
            propertyName
          }
        }
        warehouse {
          altId
          warehouseName
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        fieldStationStatus
        propertyName
        chargerModel_modelName
      }
      filter {
        chargerModel_altId {
          in
        }
        fieldStationStatus {
          in
        }
        tags {
          or {
            contains
          }
        }
      }
    }
  }
`;

/**
 * __useListChargersQuery__
 *
 * To run a query within a React component, call `useListChargersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersQuery({
 *   variables: {
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useListChargersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListChargersQuery, Types.ListChargersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargersQuery, Types.ListChargersQueryVariables>(ListChargersDocument, options);
}
export function useListChargersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListChargersQuery, Types.ListChargersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargersQuery, Types.ListChargersQueryVariables>(ListChargersDocument, options);
}
export type ListChargersQueryHookResult = ReturnType<typeof useListChargersQuery>;
export type ListChargersLazyQueryHookResult = ReturnType<typeof useListChargersLazyQuery>;
export type ListChargersQueryResult = Apollo.QueryResult<Types.ListChargersQuery, Types.ListChargersQueryVariables>;
export const UpdateConnectorReservableDocument = gql`
  mutation UpdateConnectorReservable($input: UpdateConnectorReservableInput!) {
    updateConnectorReservable(input: $input) {
      altId
      reservable
    }
  }
`;
export type UpdateConnectorReservableMutationFn = Apollo.MutationFunction<
  Types.UpdateConnectorReservableMutation,
  Types.UpdateConnectorReservableMutationVariables
>;

/**
 * __useUpdateConnectorReservableMutation__
 *
 * To run a mutation, you first call `useUpdateConnectorReservableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectorReservableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectorReservableMutation, { data, loading, error }] = useUpdateConnectorReservableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectorReservableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateConnectorReservableMutation,
    Types.UpdateConnectorReservableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateConnectorReservableMutation, Types.UpdateConnectorReservableMutationVariables>(
    UpdateConnectorReservableDocument,
    options,
  );
}
export type UpdateConnectorReservableMutationHookResult = ReturnType<typeof useUpdateConnectorReservableMutation>;
export type UpdateConnectorReservableMutationResult = Apollo.MutationResult<Types.UpdateConnectorReservableMutation>;
export type UpdateConnectorReservableMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateConnectorReservableMutation,
  Types.UpdateConnectorReservableMutationVariables
>;
export const StartChargeDocument = gql`
  mutation startCharge($input: StartChargeInput!) {
    startCharge(input: $input)
  }
`;
export type StartChargeMutationFn = Apollo.MutationFunction<
  Types.StartChargeMutation,
  Types.StartChargeMutationVariables
>;

/**
 * __useStartChargeMutation__
 *
 * To run a mutation, you first call `useStartChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChargeMutation, { data, loading, error }] = useStartChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.StartChargeMutation, Types.StartChargeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.StartChargeMutation, Types.StartChargeMutationVariables>(
    StartChargeDocument,
    options,
  );
}
export type StartChargeMutationHookResult = ReturnType<typeof useStartChargeMutation>;
export type StartChargeMutationResult = Apollo.MutationResult<Types.StartChargeMutation>;
export type StartChargeMutationOptions = Apollo.BaseMutationOptions<
  Types.StartChargeMutation,
  Types.StartChargeMutationVariables
>;
export const StopChargeDocument = gql`
  mutation stopCharge($input: StopChargeInput!) {
    stopCharge(input: $input)
  }
`;
export type StopChargeMutationFn = Apollo.MutationFunction<Types.StopChargeMutation, Types.StopChargeMutationVariables>;

/**
 * __useStopChargeMutation__
 *
 * To run a mutation, you first call `useStopChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopChargeMutation, { data, loading, error }] = useStopChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.StopChargeMutation, Types.StopChargeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.StopChargeMutation, Types.StopChargeMutationVariables>(StopChargeDocument, options);
}
export type StopChargeMutationHookResult = ReturnType<typeof useStopChargeMutation>;
export type StopChargeMutationResult = Apollo.MutationResult<Types.StopChargeMutation>;
export type StopChargeMutationOptions = Apollo.BaseMutationOptions<
  Types.StopChargeMutation,
  Types.StopChargeMutationVariables
>;
export const UpdateChargerDocument = gql`
  mutation UpdateCharger($chargerInput: UpdateChargerInput) {
    updateCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;
export type UpdateChargerMutationFn = Apollo.MutationFunction<
  Types.UpdateChargerMutation,
  Types.UpdateChargerMutationVariables
>;

/**
 * __useUpdateChargerMutation__
 *
 * To run a mutation, you first call `useUpdateChargerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerMutation, { data, loading, error }] = useUpdateChargerMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useUpdateChargerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateChargerMutation, Types.UpdateChargerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateChargerMutation, Types.UpdateChargerMutationVariables>(
    UpdateChargerDocument,
    options,
  );
}
export type UpdateChargerMutationHookResult = ReturnType<typeof useUpdateChargerMutation>;
export type UpdateChargerMutationResult = Apollo.MutationResult<Types.UpdateChargerMutation>;
export type UpdateChargerMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateChargerMutation,
  Types.UpdateChargerMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($contactInput: CreateContactInput) {
    createContact(input: $contactInput) {
      altId
      contactName
      workTitle
      email
      phone
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  Types.CreateContactMutation,
  Types.CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateContactMutation, Types.CreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateContactMutation, Types.CreateContactMutationVariables>(
    CreateContactDocument,
    options,
  );
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<Types.CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateContactMutation,
  Types.CreateContactMutationVariables
>;
export const CreateContractDocument = gql`
  mutation createContract($contractInput: CreateContractInput!) {
    createContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
export type CreateContractMutationFn = Apollo.MutationFunction<
  Types.CreateContractMutation,
  Types.CreateContractMutationVariables
>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      contractInput: // value for 'contractInput'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateContractMutation, Types.CreateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateContractMutation, Types.CreateContractMutationVariables>(
    CreateContractDocument,
    options,
  );
}
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<Types.CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateContractMutation,
  Types.CreateContractMutationVariables
>;
export const GetContractDocument = gql`
  query getContract($input: GetContractInput!) {
    getContract(input: $input) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetContractQuery, Types.GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetContractQuery, Types.GetContractQueryVariables>(GetContractDocument, options);
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractQuery, Types.GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetContractQuery, Types.GetContractQueryVariables>(GetContractDocument, options);
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = Apollo.QueryResult<Types.GetContractQuery, Types.GetContractQueryVariables>;
export const ListContractsDocument = gql`
  query ListContracts($input: ListContractsInput) {
    listContracts(input: $input) {
      total
      page
      pageSize
      filter {
        accountId {
          iLike
        }
      }
      sort {
        statusId
      }
      search {
        accountId {
          iLike
        }
      }
      edges {
        altId
        account {
          altId
        }
        plan {
          altId
        }
        startDate
        endDate
        closureDate
        status {
          columnText
        }
      }
    }
  }
`;

/**
 * __useListContractsQuery__
 *
 * To run a query within a React component, call `useListContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListContractsQuery, Types.ListContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListContractsQuery, Types.ListContractsQueryVariables>(ListContractsDocument, options);
}
export function useListContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListContractsQuery, Types.ListContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListContractsQuery, Types.ListContractsQueryVariables>(
    ListContractsDocument,
    options,
  );
}
export type ListContractsQueryHookResult = ReturnType<typeof useListContractsQuery>;
export type ListContractsLazyQueryHookResult = ReturnType<typeof useListContractsLazyQuery>;
export type ListContractsQueryResult = Apollo.QueryResult<Types.ListContractsQuery, Types.ListContractsQueryVariables>;
export const UpdateContractDocument = gql`
  mutation updateContract($contractInput: UpdateContractInput!) {
    updateContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<
  Types.UpdateContractMutation,
  Types.UpdateContractMutationVariables
>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      contractInput: // value for 'contractInput'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>(
    UpdateContractDocument,
    options,
  );
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<Types.UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateContractMutation,
  Types.UpdateContractMutationVariables
>;
export const CreateDriverDocument = gql`
  mutation createDriver($input: CreateDriverInput) {
    createDriver(input: $input) {
      firstName
      lastName
      driverType
      phone
      email
    }
  }
`;
export type CreateDriverMutationFn = Apollo.MutationFunction<
  Types.CreateDriverMutation,
  Types.CreateDriverMutationVariables
>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateDriverMutation, Types.CreateDriverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDriverMutation, Types.CreateDriverMutationVariables>(
    CreateDriverDocument,
    options,
  );
}
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<Types.CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDriverMutation,
  Types.CreateDriverMutationVariables
>;
export const GetDriverDocument = gql`
  query getDriver(
    $input: GetDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getDriver(input: $input) {
      id
      altId
      firstName
      lastName
      phone
      email
      createdAt
      updatedAt
      deletedAt
      driverStatus {
        id
        columnText
      }
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        accountStatus
        accountType
        contracts {
          total
          edges {
            altId
            plan {
              altId
              planName
            }
            startDate
            endDate
            closureDate
            status {
              columnText
            }
          }
        }
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;

/**
 * __useGetDriverQuery__
 *
 * To run a query within a React component, call `useGetDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cardsInput: // value for 'cardsInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useGetDriverQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetDriverQuery, Types.GetDriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetDriverQuery, Types.GetDriverQueryVariables>(GetDriverDocument, options);
}
export function useGetDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDriverQuery, Types.GetDriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetDriverQuery, Types.GetDriverQueryVariables>(GetDriverDocument, options);
}
export type GetDriverQueryHookResult = ReturnType<typeof useGetDriverQuery>;
export type GetDriverLazyQueryHookResult = ReturnType<typeof useGetDriverLazyQuery>;
export type GetDriverQueryResult = Apollo.QueryResult<Types.GetDriverQuery, Types.GetDriverQueryVariables>;
export const ListDriversDocument = gql`
  query listDrivers($driversInput: ListDriversInput) {
    listDrivers(input: $driversInput) {
      edges {
        altId
        email
        firstName
        lastName
        driverType
        driverStatus {
          id
          columnValue
        }
        account {
          altId
          id
          accountStatus
          billingAddress {
            address1
            address2
            locality
            administrativeArea
            postalCode
            country
          }
        }
      }
      page
      pageSize
      filter {
        driverStatusId {
          in
        }
        account_billingAddress_administrativeArea {
          in
        }
        account_accountStatus {
          in
        }
      }
      search {
        account_id {
          eq
        }
        account_billingAddress_address1 {
          iLike
        }
        email {
          iLike
        }
        firstName {
          iLike
        }
        lastName {
          iLike
        }
      }
      sort {
        account_id
        account_balance
        account_billingAddress_address1
        account_accountStatus
        driverStatusId
        email
        firstName
        lastName
      }
      total
    }
  }
`;

/**
 * __useListDriversQuery__
 *
 * To run a query within a React component, call `useListDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDriversQuery({
 *   variables: {
 *      driversInput: // value for 'driversInput'
 *   },
 * });
 */
export function useListDriversQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListDriversQuery, Types.ListDriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListDriversQuery, Types.ListDriversQueryVariables>(ListDriversDocument, options);
}
export function useListDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListDriversQuery, Types.ListDriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListDriversQuery, Types.ListDriversQueryVariables>(ListDriversDocument, options);
}
export type ListDriversQueryHookResult = ReturnType<typeof useListDriversQuery>;
export type ListDriversLazyQueryHookResult = ReturnType<typeof useListDriversLazyQuery>;
export type ListDriversQueryResult = Apollo.QueryResult<Types.ListDriversQuery, Types.ListDriversQueryVariables>;
export const ResetDriverPasswordDocument = gql`
  mutation resetDriverPassword($driverInput: ResetDriverPasswordInput) {
    resetDriverPassword(input: $driverInput) {
      mandrillId
      recipientEmail
      status
    }
  }
`;
export type ResetDriverPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetDriverPasswordMutation,
  Types.ResetDriverPasswordMutationVariables
>;

/**
 * __useResetDriverPasswordMutation__
 *
 * To run a mutation, you first call `useResetDriverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDriverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDriverPasswordMutation, { data, loading, error }] = useResetDriverPasswordMutation({
 *   variables: {
 *      driverInput: // value for 'driverInput'
 *   },
 * });
 */
export function useResetDriverPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetDriverPasswordMutation,
    Types.ResetDriverPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ResetDriverPasswordMutation, Types.ResetDriverPasswordMutationVariables>(
    ResetDriverPasswordDocument,
    options,
  );
}
export type ResetDriverPasswordMutationHookResult = ReturnType<typeof useResetDriverPasswordMutation>;
export type ResetDriverPasswordMutationResult = Apollo.MutationResult<Types.ResetDriverPasswordMutation>;
export type ResetDriverPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetDriverPasswordMutation,
  Types.ResetDriverPasswordMutationVariables
>;
export const UpdateDriverDocument = gql`
  mutation updateDriver(
    $driverInput: UpdateDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateDriver(input: $driverInput) {
      id
      altId
      firstName
      lastName
      phone
      email
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
        accountStatus
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<
  Types.UpdateDriverMutation,
  Types.UpdateDriverMutationVariables
>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      driverInput: // value for 'driverInput'
 *      cardsInput: // value for 'cardsInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useUpdateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateDriverMutation, Types.UpdateDriverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateDriverMutation, Types.UpdateDriverMutationVariables>(
    UpdateDriverDocument,
    options,
  );
}
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<Types.UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDriverMutation,
  Types.UpdateDriverMutationVariables
>;
export const AddChargersToChargerGroupForExtendDocument = gql`
  mutation addChargersToChargerGroupForExtend($input: AddChargersToChargerGroupInput!) {
    addChargersToChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type AddChargersToChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  Types.AddChargersToChargerGroupForExtendMutation,
  Types.AddChargersToChargerGroupForExtendMutationVariables
>;

/**
 * __useAddChargersToChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useAddChargersToChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChargersToChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChargersToChargerGroupForExtendMutation, { data, loading, error }] = useAddChargersToChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChargersToChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddChargersToChargerGroupForExtendMutation,
    Types.AddChargersToChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddChargersToChargerGroupForExtendMutation,
    Types.AddChargersToChargerGroupForExtendMutationVariables
  >(AddChargersToChargerGroupForExtendDocument, options);
}
export type AddChargersToChargerGroupForExtendMutationHookResult = ReturnType<
  typeof useAddChargersToChargerGroupForExtendMutation
>;
export type AddChargersToChargerGroupForExtendMutationResult =
  Apollo.MutationResult<Types.AddChargersToChargerGroupForExtendMutation>;
export type AddChargersToChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.AddChargersToChargerGroupForExtendMutation,
  Types.AddChargersToChargerGroupForExtendMutationVariables
>;
export const CreateChargerPriceGroupForExtendDocument = gql`
  mutation createChargerPriceGroupForExtend($input: CreateChargerGroupInput!) {
    createChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreateChargerPriceGroupForExtendMutationFn = Apollo.MutationFunction<
  Types.CreateChargerPriceGroupForExtendMutation,
  Types.CreateChargerPriceGroupForExtendMutationVariables
>;

/**
 * __useCreateChargerPriceGroupForExtendMutation__
 *
 * To run a mutation, you first call `useCreateChargerPriceGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerPriceGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerPriceGroupForExtendMutation, { data, loading, error }] = useCreateChargerPriceGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargerPriceGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateChargerPriceGroupForExtendMutation,
    Types.CreateChargerPriceGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateChargerPriceGroupForExtendMutation,
    Types.CreateChargerPriceGroupForExtendMutationVariables
  >(CreateChargerPriceGroupForExtendDocument, options);
}
export type CreateChargerPriceGroupForExtendMutationHookResult = ReturnType<
  typeof useCreateChargerPriceGroupForExtendMutation
>;
export type CreateChargerPriceGroupForExtendMutationResult =
  Apollo.MutationResult<Types.CreateChargerPriceGroupForExtendMutation>;
export type CreateChargerPriceGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateChargerPriceGroupForExtendMutation,
  Types.CreateChargerPriceGroupForExtendMutationVariables
>;
export const CreateChargerGroupPlanForExtendDocument = gql`
  mutation createChargerGroupPlanForExtend($input: CreateChargerGroupPlanInput!) {
    createChargerGroupPlan(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  Types.CreateChargerGroupPlanForExtendMutation,
  Types.CreateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useCreateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useCreateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerGroupPlanForExtendMutation, { data, loading, error }] = useCreateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateChargerGroupPlanForExtendMutation,
    Types.CreateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateChargerGroupPlanForExtendMutation,
    Types.CreateChargerGroupPlanForExtendMutationVariables
  >(CreateChargerGroupPlanForExtendDocument, options);
}
export type CreateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useCreateChargerGroupPlanForExtendMutation
>;
export type CreateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<Types.CreateChargerGroupPlanForExtendMutation>;
export type CreateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateChargerGroupPlanForExtendMutation,
  Types.CreateChargerGroupPlanForExtendMutationVariables
>;
export const CreateExtendHostDocument = gql`
  mutation createExtendHost($input: CreateExtendHostInput!) {
    createExtendHost(input: $input) {
      altId
      createdAt
      host {
        altId
        hostName
        hid
        status
        vendorId
      }
    }
  }
`;
export type CreateExtendHostMutationFn = Apollo.MutationFunction<
  Types.CreateExtendHostMutation,
  Types.CreateExtendHostMutationVariables
>;

/**
 * __useCreateExtendHostMutation__
 *
 * To run a mutation, you first call `useCreateExtendHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtendHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtendHostMutation, { data, loading, error }] = useCreateExtendHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExtendHostMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateExtendHostMutation, Types.CreateExtendHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateExtendHostMutation, Types.CreateExtendHostMutationVariables>(
    CreateExtendHostDocument,
    options,
  );
}
export type CreateExtendHostMutationHookResult = ReturnType<typeof useCreateExtendHostMutation>;
export type CreateExtendHostMutationResult = Apollo.MutationResult<Types.CreateExtendHostMutation>;
export type CreateExtendHostMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateExtendHostMutation,
  Types.CreateExtendHostMutationVariables
>;
export const CreateExtendUserDocument = gql`
  mutation createExtendUser($extenduserInput: CreateExtendUserInput) {
    createExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
export type CreateExtendUserMutationFn = Apollo.MutationFunction<
  Types.CreateExtendUserMutation,
  Types.CreateExtendUserMutationVariables
>;

/**
 * __useCreateExtendUserMutation__
 *
 * To run a mutation, you first call `useCreateExtendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtendUserMutation, { data, loading, error }] = useCreateExtendUserMutation({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useCreateExtendUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateExtendUserMutation, Types.CreateExtendUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateExtendUserMutation, Types.CreateExtendUserMutationVariables>(
    CreateExtendUserDocument,
    options,
  );
}
export type CreateExtendUserMutationHookResult = ReturnType<typeof useCreateExtendUserMutation>;
export type CreateExtendUserMutationResult = Apollo.MutationResult<Types.CreateExtendUserMutation>;
export type CreateExtendUserMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateExtendUserMutation,
  Types.CreateExtendUserMutationVariables
>;
export const CreatePlanTariffsForExtendDocument = gql`
  mutation createPlanTariffsForExtend($input: CreatePlanTariffsInput!) {
    createPlanTariffs(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreatePlanTariffsForExtendMutationFn = Apollo.MutationFunction<
  Types.CreatePlanTariffsForExtendMutation,
  Types.CreatePlanTariffsForExtendMutationVariables
>;

/**
 * __useCreatePlanTariffsForExtendMutation__
 *
 * To run a mutation, you first call `useCreatePlanTariffsForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanTariffsForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanTariffsForExtendMutation, { data, loading, error }] = useCreatePlanTariffsForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanTariffsForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePlanTariffsForExtendMutation,
    Types.CreatePlanTariffsForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreatePlanTariffsForExtendMutation,
    Types.CreatePlanTariffsForExtendMutationVariables
  >(CreatePlanTariffsForExtendDocument, options);
}
export type CreatePlanTariffsForExtendMutationHookResult = ReturnType<typeof useCreatePlanTariffsForExtendMutation>;
export type CreatePlanTariffsForExtendMutationResult = Apollo.MutationResult<Types.CreatePlanTariffsForExtendMutation>;
export type CreatePlanTariffsForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePlanTariffsForExtendMutation,
  Types.CreatePlanTariffsForExtendMutationVariables
>;
export const DeactivateChargerGroupPlanForExtendDocument = gql`
  mutation deactivateChargerGroupPlanForExtend($input: DeactivateChargerGroupPlanForExtendInput!) {
    deactivateChargerGroupPlanForExtend(input: $input) {
      altId
    }
  }
`;
export type DeactivateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  Types.DeactivateChargerGroupPlanForExtendMutation,
  Types.DeactivateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useDeactivateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useDeactivateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateChargerGroupPlanForExtendMutation, { data, loading, error }] = useDeactivateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeactivateChargerGroupPlanForExtendMutation,
    Types.DeactivateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeactivateChargerGroupPlanForExtendMutation,
    Types.DeactivateChargerGroupPlanForExtendMutationVariables
  >(DeactivateChargerGroupPlanForExtendDocument, options);
}
export type DeactivateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useDeactivateChargerGroupPlanForExtendMutation
>;
export type DeactivateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<Types.DeactivateChargerGroupPlanForExtendMutation>;
export type DeactivateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.DeactivateChargerGroupPlanForExtendMutation,
  Types.DeactivateChargerGroupPlanForExtendMutationVariables
>;
export const DeleteChargerGroupForExtendDocument = gql`
  mutation deleteChargerGroupForExtend($input: DeleteChargerGroupInput!) {
    deleteChargerGroup(input: $input) {
      altId
      status
    }
  }
`;
export type DeleteChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  Types.DeleteChargerGroupForExtendMutation,
  Types.DeleteChargerGroupForExtendMutationVariables
>;

/**
 * __useDeleteChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useDeleteChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargerGroupForExtendMutation, { data, loading, error }] = useDeleteChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteChargerGroupForExtendMutation,
    Types.DeleteChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteChargerGroupForExtendMutation,
    Types.DeleteChargerGroupForExtendMutationVariables
  >(DeleteChargerGroupForExtendDocument, options);
}
export type DeleteChargerGroupForExtendMutationHookResult = ReturnType<typeof useDeleteChargerGroupForExtendMutation>;
export type DeleteChargerGroupForExtendMutationResult =
  Apollo.MutationResult<Types.DeleteChargerGroupForExtendMutation>;
export type DeleteChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteChargerGroupForExtendMutation,
  Types.DeleteChargerGroupForExtendMutationVariables
>;
export const DeleteExtendHostDocument = gql`
  mutation deleteExtendHost($input: DeleteExtendHostInput!) {
    deleteExtendHost(input: $input) {
      success
    }
  }
`;
export type DeleteExtendHostMutationFn = Apollo.MutationFunction<
  Types.DeleteExtendHostMutation,
  Types.DeleteExtendHostMutationVariables
>;

/**
 * __useDeleteExtendHostMutation__
 *
 * To run a mutation, you first call `useDeleteExtendHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtendHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtendHostMutation, { data, loading, error }] = useDeleteExtendHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExtendHostMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteExtendHostMutation, Types.DeleteExtendHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteExtendHostMutation, Types.DeleteExtendHostMutationVariables>(
    DeleteExtendHostDocument,
    options,
  );
}
export type DeleteExtendHostMutationHookResult = ReturnType<typeof useDeleteExtendHostMutation>;
export type DeleteExtendHostMutationResult = Apollo.MutationResult<Types.DeleteExtendHostMutation>;
export type DeleteExtendHostMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteExtendHostMutation,
  Types.DeleteExtendHostMutationVariables
>;
export const DeleteExtendUserHostsDocument = gql`
  mutation deleteExtendUserHosts($deleteExtendUserHostsInput: DeleteExtendUserHostsInput) {
    deleteExtendUserHosts(input: $deleteExtendUserHostsInput) {
      altId
    }
  }
`;
export type DeleteExtendUserHostsMutationFn = Apollo.MutationFunction<
  Types.DeleteExtendUserHostsMutation,
  Types.DeleteExtendUserHostsMutationVariables
>;

/**
 * __useDeleteExtendUserHostsMutation__
 *
 * To run a mutation, you first call `useDeleteExtendUserHostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtendUserHostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtendUserHostsMutation, { data, loading, error }] = useDeleteExtendUserHostsMutation({
 *   variables: {
 *      deleteExtendUserHostsInput: // value for 'deleteExtendUserHostsInput'
 *   },
 * });
 */
export function useDeleteExtendUserHostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteExtendUserHostsMutation,
    Types.DeleteExtendUserHostsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteExtendUserHostsMutation, Types.DeleteExtendUserHostsMutationVariables>(
    DeleteExtendUserHostsDocument,
    options,
  );
}
export type DeleteExtendUserHostsMutationHookResult = ReturnType<typeof useDeleteExtendUserHostsMutation>;
export type DeleteExtendUserHostsMutationResult = Apollo.MutationResult<Types.DeleteExtendUserHostsMutation>;
export type DeleteExtendUserHostsMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteExtendUserHostsMutation,
  Types.DeleteExtendUserHostsMutationVariables
>;
export const DeleteTariffForExtendDocument = gql`
  mutation deleteTariffForExtend($input: DeleteTariffInput!) {
    deleteTariff(input: $input)
  }
`;
export type DeleteTariffForExtendMutationFn = Apollo.MutationFunction<
  Types.DeleteTariffForExtendMutation,
  Types.DeleteTariffForExtendMutationVariables
>;

/**
 * __useDeleteTariffForExtendMutation__
 *
 * To run a mutation, you first call `useDeleteTariffForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTariffForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTariffForExtendMutation, { data, loading, error }] = useDeleteTariffForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTariffForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTariffForExtendMutation,
    Types.DeleteTariffForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteTariffForExtendMutation, Types.DeleteTariffForExtendMutationVariables>(
    DeleteTariffForExtendDocument,
    options,
  );
}
export type DeleteTariffForExtendMutationHookResult = ReturnType<typeof useDeleteTariffForExtendMutation>;
export type DeleteTariffForExtendMutationResult = Apollo.MutationResult<Types.DeleteTariffForExtendMutation>;
export type DeleteTariffForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTariffForExtendMutation,
  Types.DeleteTariffForExtendMutationVariables
>;
export const GetActiveSessionsTotalsDocument = gql`
  query getActiveSessionsTotals($input: ActiveSessionsTotalsInput!) {
    getActiveSessionsTotals(input: $input) {
      totalKwh
    }
  }
`;

/**
 * __useGetActiveSessionsTotalsQuery__
 *
 * To run a query within a React component, call `useGetActiveSessionsTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSessionsTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSessionsTotalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActiveSessionsTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetActiveSessionsTotalsQuery, Types.GetActiveSessionsTotalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetActiveSessionsTotalsQuery, Types.GetActiveSessionsTotalsQueryVariables>(
    GetActiveSessionsTotalsDocument,
    options,
  );
}
export function useGetActiveSessionsTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetActiveSessionsTotalsQuery,
    Types.GetActiveSessionsTotalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetActiveSessionsTotalsQuery, Types.GetActiveSessionsTotalsQueryVariables>(
    GetActiveSessionsTotalsDocument,
    options,
  );
}
export type GetActiveSessionsTotalsQueryHookResult = ReturnType<typeof useGetActiveSessionsTotalsQuery>;
export type GetActiveSessionsTotalsLazyQueryHookResult = ReturnType<typeof useGetActiveSessionsTotalsLazyQuery>;
export type GetActiveSessionsTotalsQueryResult = Apollo.QueryResult<
  Types.GetActiveSessionsTotalsQuery,
  Types.GetActiveSessionsTotalsQueryVariables
>;
export const GetChargerExtendDocument = gql`
  query getChargerExtend($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      altId
      chargerName
      serialNumber
      chargerId
      setTariffSynced
      displayTariffSynced
      tariffSyncStatus
      chargerModel {
        modelName
        firmwareVersion
        breakerRating
        simultaneousChargeSupported
      }
      site {
        altId
        siteName
      }
      evses {
        edges {
          connectors {
            edges {
              altId
              connectorType {
                columnText
              }
              cableLength
              connectorMaxCurrent
              maxConnectorPower
              energyUtilization
              connectorStatusConstraint {
                columnValue
                columnText
              }
              outputVoltageRange
              reservable
            }
          }
          evseId
        }
      }
    }
  }
`;

/**
 * __useGetChargerExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerExtendQuery({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useGetChargerExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetChargerExtendQuery, Types.GetChargerExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChargerExtendQuery, Types.GetChargerExtendQueryVariables>(
    GetChargerExtendDocument,
    options,
  );
}
export function useGetChargerExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChargerExtendQuery, Types.GetChargerExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetChargerExtendQuery, Types.GetChargerExtendQueryVariables>(
    GetChargerExtendDocument,
    options,
  );
}
export type GetChargerExtendQueryHookResult = ReturnType<typeof useGetChargerExtendQuery>;
export type GetChargerExtendLazyQueryHookResult = ReturnType<typeof useGetChargerExtendLazyQuery>;
export type GetChargerExtendQueryResult = Apollo.QueryResult<
  Types.GetChargerExtendQuery,
  Types.GetChargerExtendQueryVariables
>;
export const GetChargerGroupForExtendDocument = gql`
  query getChargerGroupForExtend($input: GetChargerGroupInput!) {
    getChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;

/**
 * __useGetChargerGroupForExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetChargerGroupForExtendQuery,
    Types.GetChargerGroupForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChargerGroupForExtendQuery, Types.GetChargerGroupForExtendQueryVariables>(
    GetChargerGroupForExtendDocument,
    options,
  );
}
export function useGetChargerGroupForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetChargerGroupForExtendQuery,
    Types.GetChargerGroupForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetChargerGroupForExtendQuery, Types.GetChargerGroupForExtendQueryVariables>(
    GetChargerGroupForExtendDocument,
    options,
  );
}
export type GetChargerGroupForExtendQueryHookResult = ReturnType<typeof useGetChargerGroupForExtendQuery>;
export type GetChargerGroupForExtendLazyQueryHookResult = ReturnType<typeof useGetChargerGroupForExtendLazyQuery>;
export type GetChargerGroupForExtendQueryResult = Apollo.QueryResult<
  Types.GetChargerGroupForExtendQuery,
  Types.GetChargerGroupForExtendQueryVariables
>;
export const GetChargerGroupPlansForExtendDocument = gql`
  query getChargerGroupPlansForExtend($input: GetChargerGroupInput!) {
    getChargerGroup(input: $input) {
      planTariffTags {
        planChargerGroup {
          altId
          chargerGroupName
          chargerGroupType
          chargerGroupTypeId
          description
          createdAt
          deletedAt
          planDuration
          planId
          status
        }
      }
    }
  }
`;

/**
 * __useGetChargerGroupPlansForExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupPlansForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupPlansForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupPlansForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupPlansForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetChargerGroupPlansForExtendQuery,
    Types.GetChargerGroupPlansForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChargerGroupPlansForExtendQuery, Types.GetChargerGroupPlansForExtendQueryVariables>(
    GetChargerGroupPlansForExtendDocument,
    options,
  );
}
export function useGetChargerGroupPlansForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetChargerGroupPlansForExtendQuery,
    Types.GetChargerGroupPlansForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetChargerGroupPlansForExtendQuery,
    Types.GetChargerGroupPlansForExtendQueryVariables
  >(GetChargerGroupPlansForExtendDocument, options);
}
export type GetChargerGroupPlansForExtendQueryHookResult = ReturnType<typeof useGetChargerGroupPlansForExtendQuery>;
export type GetChargerGroupPlansForExtendLazyQueryHookResult = ReturnType<
  typeof useGetChargerGroupPlansForExtendLazyQuery
>;
export type GetChargerGroupPlansForExtendQueryResult = Apollo.QueryResult<
  Types.GetChargerGroupPlansForExtendQuery,
  Types.GetChargerGroupPlansForExtendQueryVariables
>;
export const GetChargerGroupsForChargerPriceDocument = gql`
  query getChargerGroupsForChargerPrice($input: GetTariffInput!) {
    getTariff(input: $input) {
      chargerGroups {
        altId
        chargerGroupName
      }
    }
  }
`;

/**
 * __useGetChargerGroupsForChargerPriceQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupsForChargerPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupsForChargerPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupsForChargerPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupsForChargerPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetChargerGroupsForChargerPriceQuery,
    Types.GetChargerGroupsForChargerPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetChargerGroupsForChargerPriceQuery,
    Types.GetChargerGroupsForChargerPriceQueryVariables
  >(GetChargerGroupsForChargerPriceDocument, options);
}
export function useGetChargerGroupsForChargerPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetChargerGroupsForChargerPriceQuery,
    Types.GetChargerGroupsForChargerPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetChargerGroupsForChargerPriceQuery,
    Types.GetChargerGroupsForChargerPriceQueryVariables
  >(GetChargerGroupsForChargerPriceDocument, options);
}
export type GetChargerGroupsForChargerPriceQueryHookResult = ReturnType<typeof useGetChargerGroupsForChargerPriceQuery>;
export type GetChargerGroupsForChargerPriceLazyQueryHookResult = ReturnType<
  typeof useGetChargerGroupsForChargerPriceLazyQuery
>;
export type GetChargerGroupsForChargerPriceQueryResult = Apollo.QueryResult<
  Types.GetChargerGroupsForChargerPriceQuery,
  Types.GetChargerGroupsForChargerPriceQueryVariables
>;
export const GetExtendCalculatedHostPlansDocument = gql`
  query getExtendCalculatedHostPlans {
    getExtendCalculatedHostPlans {
      planId
    }
  }
`;

/**
 * __useGetExtendCalculatedHostPlansQuery__
 *
 * To run a query within a React component, call `useGetExtendCalculatedHostPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendCalculatedHostPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendCalculatedHostPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtendCalculatedHostPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetExtendCalculatedHostPlansQuery,
    Types.GetExtendCalculatedHostPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetExtendCalculatedHostPlansQuery, Types.GetExtendCalculatedHostPlansQueryVariables>(
    GetExtendCalculatedHostPlansDocument,
    options,
  );
}
export function useGetExtendCalculatedHostPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetExtendCalculatedHostPlansQuery,
    Types.GetExtendCalculatedHostPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetExtendCalculatedHostPlansQuery, Types.GetExtendCalculatedHostPlansQueryVariables>(
    GetExtendCalculatedHostPlansDocument,
    options,
  );
}
export type GetExtendCalculatedHostPlansQueryHookResult = ReturnType<typeof useGetExtendCalculatedHostPlansQuery>;
export type GetExtendCalculatedHostPlansLazyQueryHookResult = ReturnType<
  typeof useGetExtendCalculatedHostPlansLazyQuery
>;
export type GetExtendCalculatedHostPlansQueryResult = Apollo.QueryResult<
  Types.GetExtendCalculatedHostPlansQuery,
  Types.GetExtendCalculatedHostPlansQueryVariables
>;
export const GetExtendUserDocument = gql`
  query getExtendUser($extenduserInput: GetExtendUserInput) {
    getExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          id
          altId
          hostName
          customPlansEnabled
        }
      }
    }
  }
`;

/**
 * __useGetExtendUserQuery__
 *
 * To run a query within a React component, call `useGetExtendUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendUserQuery({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useGetExtendUserQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetExtendUserQuery, Types.GetExtendUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetExtendUserQuery, Types.GetExtendUserQueryVariables>(GetExtendUserDocument, options);
}
export function useGetExtendUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExtendUserQuery, Types.GetExtendUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetExtendUserQuery, Types.GetExtendUserQueryVariables>(
    GetExtendUserDocument,
    options,
  );
}
export type GetExtendUserQueryHookResult = ReturnType<typeof useGetExtendUserQuery>;
export type GetExtendUserLazyQueryHookResult = ReturnType<typeof useGetExtendUserLazyQuery>;
export type GetExtendUserQueryResult = Apollo.QueryResult<Types.GetExtendUserQuery, Types.GetExtendUserQueryVariables>;
export const GetFirebaseUserForExtendDocument = gql`
  query getFirebaseUserForExtend($input: GetFirebaseUserForExtendInput!) {
    getFirebaseUserForExtend(input: $input) {
      username
      firebaseId
    }
  }
`;

/**
 * __useGetFirebaseUserForExtendQuery__
 *
 * To run a query within a React component, call `useGetFirebaseUserForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseUserForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirebaseUserForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFirebaseUserForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetFirebaseUserForExtendQuery,
    Types.GetFirebaseUserForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetFirebaseUserForExtendQuery, Types.GetFirebaseUserForExtendQueryVariables>(
    GetFirebaseUserForExtendDocument,
    options,
  );
}
export function useGetFirebaseUserForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetFirebaseUserForExtendQuery,
    Types.GetFirebaseUserForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetFirebaseUserForExtendQuery, Types.GetFirebaseUserForExtendQueryVariables>(
    GetFirebaseUserForExtendDocument,
    options,
  );
}
export type GetFirebaseUserForExtendQueryHookResult = ReturnType<typeof useGetFirebaseUserForExtendQuery>;
export type GetFirebaseUserForExtendLazyQueryHookResult = ReturnType<typeof useGetFirebaseUserForExtendLazyQuery>;
export type GetFirebaseUserForExtendQueryResult = Apollo.QueryResult<
  Types.GetFirebaseUserForExtendQuery,
  Types.GetFirebaseUserForExtendQueryVariables
>;
export const GetPlanEnrollmentsForExtendDocument = gql`
  query getPlanEnrollmentsForExtend {
    getPlanEnrollmentsForExtend {
      planName
      totalEnrollments
    }
  }
`;

/**
 * __useGetPlanEnrollmentsForExtendQuery__
 *
 * To run a query within a React component, call `useGetPlanEnrollmentsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanEnrollmentsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanEnrollmentsForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanEnrollmentsForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetPlanEnrollmentsForExtendQuery,
    Types.GetPlanEnrollmentsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetPlanEnrollmentsForExtendQuery, Types.GetPlanEnrollmentsForExtendQueryVariables>(
    GetPlanEnrollmentsForExtendDocument,
    options,
  );
}
export function useGetPlanEnrollmentsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetPlanEnrollmentsForExtendQuery,
    Types.GetPlanEnrollmentsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetPlanEnrollmentsForExtendQuery, Types.GetPlanEnrollmentsForExtendQueryVariables>(
    GetPlanEnrollmentsForExtendDocument,
    options,
  );
}
export type GetPlanEnrollmentsForExtendQueryHookResult = ReturnType<typeof useGetPlanEnrollmentsForExtendQuery>;
export type GetPlanEnrollmentsForExtendLazyQueryHookResult = ReturnType<typeof useGetPlanEnrollmentsForExtendLazyQuery>;
export type GetPlanEnrollmentsForExtendQueryResult = Apollo.QueryResult<
  Types.GetPlanEnrollmentsForExtendQuery,
  Types.GetPlanEnrollmentsForExtendQueryVariables
>;
export const GetCustomPlanBreakdownDocument = gql`
  query getCustomPlanBreakdown($input: GetSessionSummaryByPlanInput) {
    getSessionSummaryByPlan(input: $input) {
      planName
      powerCostValue
    }
  }
`;

/**
 * __useGetCustomPlanBreakdownQuery__
 *
 * To run a query within a React component, call `useGetCustomPlanBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomPlanBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomPlanBreakdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomPlanBreakdownQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetCustomPlanBreakdownQuery, Types.GetCustomPlanBreakdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetCustomPlanBreakdownQuery, Types.GetCustomPlanBreakdownQueryVariables>(
    GetCustomPlanBreakdownDocument,
    options,
  );
}
export function useGetCustomPlanBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomPlanBreakdownQuery,
    Types.GetCustomPlanBreakdownQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetCustomPlanBreakdownQuery, Types.GetCustomPlanBreakdownQueryVariables>(
    GetCustomPlanBreakdownDocument,
    options,
  );
}
export type GetCustomPlanBreakdownQueryHookResult = ReturnType<typeof useGetCustomPlanBreakdownQuery>;
export type GetCustomPlanBreakdownLazyQueryHookResult = ReturnType<typeof useGetCustomPlanBreakdownLazyQuery>;
export type GetCustomPlanBreakdownQueryResult = Apollo.QueryResult<
  Types.GetCustomPlanBreakdownQuery,
  Types.GetCustomPlanBreakdownQueryVariables
>;
export const GetSessionsConsumptionForExtendDocument = gql`
  query GetSessionsConsumptionForExtend($input: GetSessionsConsumptionForExtendInput!) {
    getSessionsConsumptionForExtend(input: $input) {
      timeSeries {
        totalSessions
        powerCostValue
        powerValue
        timestamp
      }
      startDate
      endDate
    }
  }
`;

/**
 * __useGetSessionsConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useGetSessionsConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSessionsConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetSessionsConsumptionForExtendQuery,
    Types.GetSessionsConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetSessionsConsumptionForExtendQuery,
    Types.GetSessionsConsumptionForExtendQueryVariables
  >(GetSessionsConsumptionForExtendDocument, options);
}
export function useGetSessionsConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSessionsConsumptionForExtendQuery,
    Types.GetSessionsConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetSessionsConsumptionForExtendQuery,
    Types.GetSessionsConsumptionForExtendQueryVariables
  >(GetSessionsConsumptionForExtendDocument, options);
}
export type GetSessionsConsumptionForExtendQueryHookResult = ReturnType<typeof useGetSessionsConsumptionForExtendQuery>;
export type GetSessionsConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useGetSessionsConsumptionForExtendLazyQuery
>;
export type GetSessionsConsumptionForExtendQueryResult = Apollo.QueryResult<
  Types.GetSessionsConsumptionForExtendQuery,
  Types.GetSessionsConsumptionForExtendQueryVariables
>;
export const GetSitesEvseCountDocument = gql`
  query GetSitesEvseCount {
    getSitesForExtend {
      evseCount
    }
  }
`;

/**
 * __useGetSitesEvseCountQuery__
 *
 * To run a query within a React component, call `useGetSitesEvseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesEvseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesEvseCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesEvseCountQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSitesEvseCountQuery, Types.GetSitesEvseCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSitesEvseCountQuery, Types.GetSitesEvseCountQueryVariables>(
    GetSitesEvseCountDocument,
    options,
  );
}
export function useGetSitesEvseCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSitesEvseCountQuery, Types.GetSitesEvseCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSitesEvseCountQuery, Types.GetSitesEvseCountQueryVariables>(
    GetSitesEvseCountDocument,
    options,
  );
}
export type GetSitesEvseCountQueryHookResult = ReturnType<typeof useGetSitesEvseCountQuery>;
export type GetSitesEvseCountLazyQueryHookResult = ReturnType<typeof useGetSitesEvseCountLazyQuery>;
export type GetSitesEvseCountQueryResult = Apollo.QueryResult<
  Types.GetSitesEvseCountQuery,
  Types.GetSitesEvseCountQueryVariables
>;
export const GetSiteForExtendDocument = gql`
  query getSiteForExtend($siteInput: GetSiteForExtendInput) {
    getSiteForExtend(input: $siteInput) {
      altId
      displayName
      directions
      address1
      address2
      locality
      administrativeArea
      postalCode
      timeZone
    }
  }
`;

/**
 * __useGetSiteForExtendQuery__
 *
 * To run a query within a React component, call `useGetSiteForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteForExtendQuery({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useGetSiteForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSiteForExtendQuery, Types.GetSiteForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSiteForExtendQuery, Types.GetSiteForExtendQueryVariables>(
    GetSiteForExtendDocument,
    options,
  );
}
export function useGetSiteForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSiteForExtendQuery, Types.GetSiteForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSiteForExtendQuery, Types.GetSiteForExtendQueryVariables>(
    GetSiteForExtendDocument,
    options,
  );
}
export type GetSiteForExtendQueryHookResult = ReturnType<typeof useGetSiteForExtendQuery>;
export type GetSiteForExtendLazyQueryHookResult = ReturnType<typeof useGetSiteForExtendLazyQuery>;
export type GetSiteForExtendQueryResult = Apollo.QueryResult<
  Types.GetSiteForExtendQuery,
  Types.GetSiteForExtendQueryVariables
>;
export const GetSitesForExtendDocument = gql`
  query GetSitesForExtend {
    getSitesForExtend {
      altId
      siteName
      siteStatusId
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      evseCount
      timeZone
      chargers {
        edges {
          altId
          chargerName
        }
        total
      }
    }
  }
`;

/**
 * __useGetSitesForExtendQuery__
 *
 * To run a query within a React component, call `useGetSitesForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSitesForExtendQuery, Types.GetSitesForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSitesForExtendQuery, Types.GetSitesForExtendQueryVariables>(
    GetSitesForExtendDocument,
    options,
  );
}
export function useGetSitesForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSitesForExtendQuery, Types.GetSitesForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSitesForExtendQuery, Types.GetSitesForExtendQueryVariables>(
    GetSitesForExtendDocument,
    options,
  );
}
export type GetSitesForExtendQueryHookResult = ReturnType<typeof useGetSitesForExtendQuery>;
export type GetSitesForExtendLazyQueryHookResult = ReturnType<typeof useGetSitesForExtendLazyQuery>;
export type GetSitesForExtendQueryResult = Apollo.QueryResult<
  Types.GetSitesForExtendQuery,
  Types.GetSitesForExtendQueryVariables
>;
export const GetSitesShallowForExtendDocument = gql`
  query GetSitesShallowForExtend {
    getSitesForExtend {
      siteName
      postalCode
    }
  }
`;

/**
 * __useGetSitesShallowForExtendQuery__
 *
 * To run a query within a React component, call `useGetSitesShallowForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesShallowForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesShallowForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesShallowForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetSitesShallowForExtendQuery,
    Types.GetSitesShallowForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSitesShallowForExtendQuery, Types.GetSitesShallowForExtendQueryVariables>(
    GetSitesShallowForExtendDocument,
    options,
  );
}
export function useGetSitesShallowForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSitesShallowForExtendQuery,
    Types.GetSitesShallowForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSitesShallowForExtendQuery, Types.GetSitesShallowForExtendQueryVariables>(
    GetSitesShallowForExtendDocument,
    options,
  );
}
export type GetSitesShallowForExtendQueryHookResult = ReturnType<typeof useGetSitesShallowForExtendQuery>;
export type GetSitesShallowForExtendLazyQueryHookResult = ReturnType<typeof useGetSitesShallowForExtendLazyQuery>;
export type GetSitesShallowForExtendQueryResult = Apollo.QueryResult<
  Types.GetSitesShallowForExtendQuery,
  Types.GetSitesShallowForExtendQueryVariables
>;
export const GetTariffForExtendDocument = gql`
  query getTariffForExtend($input: GetTariffInput!) {
    getTariff(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useGetTariffForExtendQuery__
 *
 * To run a query within a React component, call `useGetTariffForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTariffForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetTariffForExtendQuery, Types.GetTariffForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetTariffForExtendQuery, Types.GetTariffForExtendQueryVariables>(
    GetTariffForExtendDocument,
    options,
  );
}
export function useGetTariffForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTariffForExtendQuery, Types.GetTariffForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetTariffForExtendQuery, Types.GetTariffForExtendQueryVariables>(
    GetTariffForExtendDocument,
    options,
  );
}
export type GetTariffForExtendQueryHookResult = ReturnType<typeof useGetTariffForExtendQuery>;
export type GetTariffForExtendLazyQueryHookResult = ReturnType<typeof useGetTariffForExtendLazyQuery>;
export type GetTariffForExtendQueryResult = Apollo.QueryResult<
  Types.GetTariffForExtendQuery,
  Types.GetTariffForExtendQueryVariables
>;
export const ListChargerGroupsForExtendDocument = gql`
  query listChargerGroupsForExtend($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        ...ChargerGroupForExtend
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargerGroupsForExtendQuery,
    Types.ListChargerGroupsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargerGroupsForExtendQuery, Types.ListChargerGroupsForExtendQueryVariables>(
    ListChargerGroupsForExtendDocument,
    options,
  );
}
export function useListChargerGroupsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerGroupsForExtendQuery,
    Types.ListChargerGroupsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargerGroupsForExtendQuery, Types.ListChargerGroupsForExtendQueryVariables>(
    ListChargerGroupsForExtendDocument,
    options,
  );
}
export type ListChargerGroupsForExtendQueryHookResult = ReturnType<typeof useListChargerGroupsForExtendQuery>;
export type ListChargerGroupsForExtendLazyQueryHookResult = ReturnType<typeof useListChargerGroupsForExtendLazyQuery>;
export type ListChargerGroupsForExtendQueryResult = Apollo.QueryResult<
  Types.ListChargerGroupsForExtendQuery,
  Types.ListChargerGroupsForExtendQueryVariables
>;
export const ListChargerGroupsForChargerPricesDocument = gql`
  query listChargerGroupsForChargerPrices($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        ...ChargerGroupForExtend
        tariff {
          altId
        }
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsForChargerPricesQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsForChargerPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsForChargerPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsForChargerPricesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsForChargerPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargerGroupsForChargerPricesQuery,
    Types.ListChargerGroupsForChargerPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListChargerGroupsForChargerPricesQuery,
    Types.ListChargerGroupsForChargerPricesQueryVariables
  >(ListChargerGroupsForChargerPricesDocument, options);
}
export function useListChargerGroupsForChargerPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerGroupsForChargerPricesQuery,
    Types.ListChargerGroupsForChargerPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListChargerGroupsForChargerPricesQuery,
    Types.ListChargerGroupsForChargerPricesQueryVariables
  >(ListChargerGroupsForChargerPricesDocument, options);
}
export type ListChargerGroupsForChargerPricesQueryHookResult = ReturnType<
  typeof useListChargerGroupsForChargerPricesQuery
>;
export type ListChargerGroupsForChargerPricesLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsForChargerPricesLazyQuery
>;
export type ListChargerGroupsForChargerPricesQueryResult = Apollo.QueryResult<
  Types.ListChargerGroupsForChargerPricesQuery,
  Types.ListChargerGroupsForChargerPricesQueryVariables
>;
export const ListChargerGroupsPlanTariffTagsForExtendDocument = gql`
  query listChargerGroupsPlanTariffTagsForExtend($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        altId
        chargerGroupName
        planTariffTags {
          ...PlanTariffTag
        }
      }
    }
  }
  ${PlanTariffTagFragmentDoc}
`;

/**
 * __useListChargerGroupsPlanTariffTagsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsPlanTariffTagsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsPlanTariffTagsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsPlanTariffTagsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsPlanTariffTagsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargerGroupsPlanTariffTagsForExtendQuery,
    Types.ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListChargerGroupsPlanTariffTagsForExtendQuery,
    Types.ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >(ListChargerGroupsPlanTariffTagsForExtendDocument, options);
}
export function useListChargerGroupsPlanTariffTagsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerGroupsPlanTariffTagsForExtendQuery,
    Types.ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListChargerGroupsPlanTariffTagsForExtendQuery,
    Types.ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >(ListChargerGroupsPlanTariffTagsForExtendDocument, options);
}
export type ListChargerGroupsPlanTariffTagsForExtendQueryHookResult = ReturnType<
  typeof useListChargerGroupsPlanTariffTagsForExtendQuery
>;
export type ListChargerGroupsPlanTariffTagsForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsPlanTariffTagsForExtendLazyQuery
>;
export type ListChargerGroupsPlanTariffTagsForExtendQueryResult = Apollo.QueryResult<
  Types.ListChargerGroupsPlanTariffTagsForExtendQuery,
  Types.ListChargerGroupsPlanTariffTagsForExtendQueryVariables
>;
export const ListChargerGroupsTariffsForExtendDocument = gql`
  query listChargerGroupsTariffsForExtend($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        altId
        tariff {
          ...TariffForExtend
        }
      }
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsTariffsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsTariffsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsTariffsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsTariffsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsTariffsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargerGroupsTariffsForExtendQuery,
    Types.ListChargerGroupsTariffsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListChargerGroupsTariffsForExtendQuery,
    Types.ListChargerGroupsTariffsForExtendQueryVariables
  >(ListChargerGroupsTariffsForExtendDocument, options);
}
export function useListChargerGroupsTariffsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerGroupsTariffsForExtendQuery,
    Types.ListChargerGroupsTariffsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListChargerGroupsTariffsForExtendQuery,
    Types.ListChargerGroupsTariffsForExtendQueryVariables
  >(ListChargerGroupsTariffsForExtendDocument, options);
}
export type ListChargerGroupsTariffsForExtendQueryHookResult = ReturnType<
  typeof useListChargerGroupsTariffsForExtendQuery
>;
export type ListChargerGroupsTariffsForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsTariffsForExtendLazyQuery
>;
export type ListChargerGroupsTariffsForExtendQueryResult = Apollo.QueryResult<
  Types.ListChargerGroupsTariffsForExtendQuery,
  Types.ListChargerGroupsTariffsForExtendQueryVariables
>;
export const ListChargerPricesEventLogDocument = gql`
  query listChargerPricesEventLog($input: ListEventLogInput!) {
    listEventLog(input: $input) {
      total
      edges {
        user {
          username
        }
        eventText
        eventDate
        entityType
        eventTypeId
        jsonData
        entityId
        eventType {
          id
          columnName
          columnValue
          columnText
        }
        entityData {
          ... on ChargerPricing {
            tariffName
          }
        }
      }
    }
  }
`;

/**
 * __useListChargerPricesEventLogQuery__
 *
 * To run a query within a React component, call `useListChargerPricesEventLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerPricesEventLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerPricesEventLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerPricesEventLogQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargerPricesEventLogQuery,
    Types.ListChargerPricesEventLogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargerPricesEventLogQuery, Types.ListChargerPricesEventLogQueryVariables>(
    ListChargerPricesEventLogDocument,
    options,
  );
}
export function useListChargerPricesEventLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerPricesEventLogQuery,
    Types.ListChargerPricesEventLogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargerPricesEventLogQuery, Types.ListChargerPricesEventLogQueryVariables>(
    ListChargerPricesEventLogDocument,
    options,
  );
}
export type ListChargerPricesEventLogQueryHookResult = ReturnType<typeof useListChargerPricesEventLogQuery>;
export type ListChargerPricesEventLogLazyQueryHookResult = ReturnType<typeof useListChargerPricesEventLogLazyQuery>;
export type ListChargerPricesEventLogQueryResult = Apollo.QueryResult<
  Types.ListChargerPricesEventLogQuery,
  Types.ListChargerPricesEventLogQueryVariables
>;
export const ListChargersConsumptionForExtendDocument = gql`
  query listChargersConsumptionForExtend($input: ListChargersConsumptionForExtendInput!) {
    listChargersConsumptionForExtend(input: $input) {
      chargerId
      chargerName
      connectorMaxOutput
      connectorStatus
      connectorType
      connectorId
      evseId
      totalSessions
      totalSessionsPower
      totalSessionsTime
      lastSessionVendorId
      reservable
    }
  }
`;

/**
 * __useListChargersConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useListChargersConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListChargersConsumptionForExtendQuery,
    Types.ListChargersConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListChargersConsumptionForExtendQuery,
    Types.ListChargersConsumptionForExtendQueryVariables
  >(ListChargersConsumptionForExtendDocument, options);
}
export function useListChargersConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargersConsumptionForExtendQuery,
    Types.ListChargersConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListChargersConsumptionForExtendQuery,
    Types.ListChargersConsumptionForExtendQueryVariables
  >(ListChargersConsumptionForExtendDocument, options);
}
export type ListChargersConsumptionForExtendQueryHookResult = ReturnType<
  typeof useListChargersConsumptionForExtendQuery
>;
export type ListChargersConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargersConsumptionForExtendLazyQuery
>;
export type ListChargersConsumptionForExtendQueryResult = Apollo.QueryResult<
  Types.ListChargersConsumptionForExtendQuery,
  Types.ListChargersConsumptionForExtendQueryVariables
>;
export const ListChargersV2Document = gql`
  query listChargersV2($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
      edges {
        altId
        setTariffSynced
        displayTariffSynced
        tariffSyncStatus
        chargerGroup {
          altId
          chargerGroupName
        }
        chargerStatus {
          columnText
        }
        evses {
          connectors {
            reservable
            chargingLevel
            connectorStatus {
              columnText
            }
          }
        }
        chargerName
        chargerModel {
          modelName
        }
        site {
          address1
          address2
          postalCode
          administrativeArea
          siteName
          locality
          country
        }
      }
    }
  }
`;

/**
 * __useListChargersV2Query__
 *
 * To run a query within a React component, call `useListChargersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListChargersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersV2Query(
  baseOptions?: Apollo.QueryHookOptions<Types.ListChargersV2Query, Types.ListChargersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargersV2Query, Types.ListChargersV2QueryVariables>(
    ListChargersV2Document,
    options,
  );
}
export function useListChargersV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListChargersV2Query, Types.ListChargersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargersV2Query, Types.ListChargersV2QueryVariables>(
    ListChargersV2Document,
    options,
  );
}
export type ListChargersV2QueryHookResult = ReturnType<typeof useListChargersV2Query>;
export type ListChargersV2LazyQueryHookResult = ReturnType<typeof useListChargersV2LazyQuery>;
export type ListChargersV2QueryResult = Apollo.QueryResult<
  Types.ListChargersV2Query,
  Types.ListChargersV2QueryVariables
>;
export const ListChargersV2ConnectorsDocument = gql`
  query listChargersV2Connectors($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
      edges {
        evses {
          connectors {
            connectorStatus {
              columnText
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListChargersV2ConnectorsQuery__
 *
 * To run a query within a React component, call `useListChargersV2ConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersV2ConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersV2ConnectorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersV2ConnectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListChargersV2ConnectorsQuery,
    Types.ListChargersV2ConnectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargersV2ConnectorsQuery, Types.ListChargersV2ConnectorsQueryVariables>(
    ListChargersV2ConnectorsDocument,
    options,
  );
}
export function useListChargersV2ConnectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargersV2ConnectorsQuery,
    Types.ListChargersV2ConnectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargersV2ConnectorsQuery, Types.ListChargersV2ConnectorsQueryVariables>(
    ListChargersV2ConnectorsDocument,
    options,
  );
}
export type ListChargersV2ConnectorsQueryHookResult = ReturnType<typeof useListChargersV2ConnectorsQuery>;
export type ListChargersV2ConnectorsLazyQueryHookResult = ReturnType<typeof useListChargersV2ConnectorsLazyQuery>;
export type ListChargersV2ConnectorsQueryResult = Apollo.QueryResult<
  Types.ListChargersV2ConnectorsQuery,
  Types.ListChargersV2ConnectorsQueryVariables
>;
export const ListChargersV2ShallowDocument = gql`
  query listChargersV2Shallow($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
    }
  }
`;

/**
 * __useListChargersV2ShallowQuery__
 *
 * To run a query within a React component, call `useListChargersV2ShallowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersV2ShallowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersV2ShallowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersV2ShallowQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListChargersV2ShallowQuery, Types.ListChargersV2ShallowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargersV2ShallowQuery, Types.ListChargersV2ShallowQueryVariables>(
    ListChargersV2ShallowDocument,
    options,
  );
}
export function useListChargersV2ShallowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargersV2ShallowQuery,
    Types.ListChargersV2ShallowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargersV2ShallowQuery, Types.ListChargersV2ShallowQueryVariables>(
    ListChargersV2ShallowDocument,
    options,
  );
}
export type ListChargersV2ShallowQueryHookResult = ReturnType<typeof useListChargersV2ShallowQuery>;
export type ListChargersV2ShallowLazyQueryHookResult = ReturnType<typeof useListChargersV2ShallowLazyQuery>;
export type ListChargersV2ShallowQueryResult = Apollo.QueryResult<
  Types.ListChargersV2ShallowQuery,
  Types.ListChargersV2ShallowQueryVariables
>;
export const ListExtendHostsDocument = gql`
  query listExtendHosts($input: ListExtendHostsInput) {
    listExtendHosts(input: $input) {
      total
      edges {
        altId
        createdAt
        prefix
        host {
          altId
          hostName
          hid
          status
          vendorId
        }
      }
    }
  }
`;

/**
 * __useListExtendHostsQuery__
 *
 * To run a query within a React component, call `useListExtendHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendHostsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExtendHostsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListExtendHostsQuery, Types.ListExtendHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListExtendHostsQuery, Types.ListExtendHostsQueryVariables>(
    ListExtendHostsDocument,
    options,
  );
}
export function useListExtendHostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListExtendHostsQuery, Types.ListExtendHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListExtendHostsQuery, Types.ListExtendHostsQueryVariables>(
    ListExtendHostsDocument,
    options,
  );
}
export type ListExtendHostsQueryHookResult = ReturnType<typeof useListExtendHostsQuery>;
export type ListExtendHostsLazyQueryHookResult = ReturnType<typeof useListExtendHostsLazyQuery>;
export type ListExtendHostsQueryResult = Apollo.QueryResult<
  Types.ListExtendHostsQuery,
  Types.ListExtendHostsQueryVariables
>;
export const ListExtendUsersDocument = gql`
  query listExtendUsers($input: ListExtendUsersInput) {
    listExtendUsers(input: $input) {
      total
      edges {
        altId
        username
        firebaseId
        createdAt
        hosts {
          total
          edges {
            altId
            hostName
          }
        }
      }
    }
  }
`;

/**
 * __useListExtendUsersQuery__
 *
 * To run a query within a React component, call `useListExtendUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExtendUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListExtendUsersQuery, Types.ListExtendUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListExtendUsersQuery, Types.ListExtendUsersQueryVariables>(
    ListExtendUsersDocument,
    options,
  );
}
export function useListExtendUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListExtendUsersQuery, Types.ListExtendUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListExtendUsersQuery, Types.ListExtendUsersQueryVariables>(
    ListExtendUsersDocument,
    options,
  );
}
export type ListExtendUsersQueryHookResult = ReturnType<typeof useListExtendUsersQuery>;
export type ListExtendUsersLazyQueryHookResult = ReturnType<typeof useListExtendUsersLazyQuery>;
export type ListExtendUsersQueryResult = Apollo.QueryResult<
  Types.ListExtendUsersQuery,
  Types.ListExtendUsersQueryVariables
>;
export const ListExtendUsersForHostDocument = gql`
  query listExtendUsersForHost($input: ListExtendUsersInput!) {
    listExtendUsersForHost(input: $input) {
      edges {
        altId
        username
      }
    }
  }
`;

/**
 * __useListExtendUsersForHostQuery__
 *
 * To run a query within a React component, call `useListExtendUsersForHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendUsersForHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendUsersForHostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExtendUsersForHostQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListExtendUsersForHostQuery, Types.ListExtendUsersForHostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListExtendUsersForHostQuery, Types.ListExtendUsersForHostQueryVariables>(
    ListExtendUsersForHostDocument,
    options,
  );
}
export function useListExtendUsersForHostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListExtendUsersForHostQuery,
    Types.ListExtendUsersForHostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListExtendUsersForHostQuery, Types.ListExtendUsersForHostQueryVariables>(
    ListExtendUsersForHostDocument,
    options,
  );
}
export type ListExtendUsersForHostQueryHookResult = ReturnType<typeof useListExtendUsersForHostQuery>;
export type ListExtendUsersForHostLazyQueryHookResult = ReturnType<typeof useListExtendUsersForHostLazyQuery>;
export type ListExtendUsersForHostQueryResult = Apollo.QueryResult<
  Types.ListExtendUsersForHostQuery,
  Types.ListExtendUsersForHostQueryVariables
>;
export const ListSessionForDashboardLiveSessionsDocument = gql`
  query listSessionForDashboardLiveSessions($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        meterStart
        lastMeterValue
        connector {
          evse {
            charger {
              altId
              site {
                sid
                siteName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListSessionForDashboardLiveSessionsQuery__
 *
 * To run a query within a React component, call `useListSessionForDashboardLiveSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionForDashboardLiveSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionForDashboardLiveSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionForDashboardLiveSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListSessionForDashboardLiveSessionsQuery,
    Types.ListSessionForDashboardLiveSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListSessionForDashboardLiveSessionsQuery,
    Types.ListSessionForDashboardLiveSessionsQueryVariables
  >(ListSessionForDashboardLiveSessionsDocument, options);
}
export function useListSessionForDashboardLiveSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSessionForDashboardLiveSessionsQuery,
    Types.ListSessionForDashboardLiveSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListSessionForDashboardLiveSessionsQuery,
    Types.ListSessionForDashboardLiveSessionsQueryVariables
  >(ListSessionForDashboardLiveSessionsDocument, options);
}
export type ListSessionForDashboardLiveSessionsQueryHookResult = ReturnType<
  typeof useListSessionForDashboardLiveSessionsQuery
>;
export type ListSessionForDashboardLiveSessionsLazyQueryHookResult = ReturnType<
  typeof useListSessionForDashboardLiveSessionsLazyQuery
>;
export type ListSessionForDashboardLiveSessionsQueryResult = Apollo.QueryResult<
  Types.ListSessionForDashboardLiveSessionsQuery,
  Types.ListSessionForDashboardLiveSessionsQueryVariables
>;
export const ListSessionsForMapDocument = gql`
  query listSessionsForMap($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        connector {
          evse {
            charger {
              site {
                latitude
                longitude
                administrativeArea
                siteName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListSessionsForMapQuery__
 *
 * To run a query within a React component, call `useListSessionsForMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsForMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsForMapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsForMapQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListSessionsForMapQuery, Types.ListSessionsForMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSessionsForMapQuery, Types.ListSessionsForMapQueryVariables>(
    ListSessionsForMapDocument,
    options,
  );
}
export function useListSessionsForMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSessionsForMapQuery, Types.ListSessionsForMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSessionsForMapQuery, Types.ListSessionsForMapQueryVariables>(
    ListSessionsForMapDocument,
    options,
  );
}
export type ListSessionsForMapQueryHookResult = ReturnType<typeof useListSessionsForMapQuery>;
export type ListSessionsForMapLazyQueryHookResult = ReturnType<typeof useListSessionsForMapLazyQuery>;
export type ListSessionsForMapQueryResult = Apollo.QueryResult<
  Types.ListSessionsForMapQuery,
  Types.ListSessionsForMapQueryVariables
>;
export const ListSessionsV2ExtendDocument = gql`
  query listSessionsV2Extend($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        altId
        vendorId
        connector {
          connectorType {
            columnText
          }
          evse {
            charger {
              chargerName
              site {
                siteName
                timeZone
              }
            }
          }
        }
        plan {
          planName
          chargerGroup {
            chargerGroupName
            altId
          }
        }
        stopSource {
          columnText
        }
        startSource {
          columnText
        }
        startTime
        endTime
        meterStart
        lastMeterValue
        transaction {
          altId
          tax
          type {
            columnText
          }
        }
        cost
      }
    }
  }
`;

/**
 * __useListSessionsV2ExtendQuery__
 *
 * To run a query within a React component, call `useListSessionsV2ExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsV2ExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsV2ExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsV2ExtendQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListSessionsV2ExtendQuery, Types.ListSessionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSessionsV2ExtendQuery, Types.ListSessionsV2ExtendQueryVariables>(
    ListSessionsV2ExtendDocument,
    options,
  );
}
export function useListSessionsV2ExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSessionsV2ExtendQuery, Types.ListSessionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSessionsV2ExtendQuery, Types.ListSessionsV2ExtendQueryVariables>(
    ListSessionsV2ExtendDocument,
    options,
  );
}
export type ListSessionsV2ExtendQueryHookResult = ReturnType<typeof useListSessionsV2ExtendQuery>;
export type ListSessionsV2ExtendLazyQueryHookResult = ReturnType<typeof useListSessionsV2ExtendLazyQuery>;
export type ListSessionsV2ExtendQueryResult = Apollo.QueryResult<
  Types.ListSessionsV2ExtendQuery,
  Types.ListSessionsV2ExtendQueryVariables
>;
export const ListSitesConsumptionForExtendDocument = gql`
  query listSitesConsumptionForExtend($input: ListSitesConsumptionForExtendInput!) {
    listSitesConsumptionForExtend(input: $input) {
      edges {
        address
        siteId
        siteName
        siteStatus
        administrativeArea
        siteLatitude
        siteLongitude
        totalChargers
        totalSessions
        totalSessionsCost
        totalSessionsPower
        averageSessionPower
        totalSessionsTime
        averageSessionTime
      }
      page
      pageSize
    }
  }
`;

/**
 * __useListSitesConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useListSitesConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSitesConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListSitesConsumptionForExtendQuery,
    Types.ListSitesConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSitesConsumptionForExtendQuery, Types.ListSitesConsumptionForExtendQueryVariables>(
    ListSitesConsumptionForExtendDocument,
    options,
  );
}
export function useListSitesConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSitesConsumptionForExtendQuery,
    Types.ListSitesConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListSitesConsumptionForExtendQuery,
    Types.ListSitesConsumptionForExtendQueryVariables
  >(ListSitesConsumptionForExtendDocument, options);
}
export type ListSitesConsumptionForExtendQueryHookResult = ReturnType<typeof useListSitesConsumptionForExtendQuery>;
export type ListSitesConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useListSitesConsumptionForExtendLazyQuery
>;
export type ListSitesConsumptionForExtendQueryResult = Apollo.QueryResult<
  Types.ListSitesConsumptionForExtendQuery,
  Types.ListSitesConsumptionForExtendQueryVariables
>;
export const ListTariffPriceLogDocument = gql`
  query listTariffPriceLog($input: ListTariffsInput!, $eventLogInput: ListEventLogInput!) {
    listTariffs(input: $input) {
      edges {
        altId
        tariffName
        defaultPricing {
          displayName
          pricing {
            perKwh
            perMinute
            perSession
          }
        }
        chargerGroups {
          chargers {
            edges {
              site {
                timeZone
              }
            }
          }
        }
        eventLog(input: $eventLogInput) {
          edges {
            eventDate
            eventText
            eventType {
              id
              columnValue
            }
            eventTypeId
            entityId
            entityType
            jsonData
            user {
              altId
              username
            }
          }
        }
      }
      total
    }
  }
`;

/**
 * __useListTariffPriceLogQuery__
 *
 * To run a query within a React component, call `useListTariffPriceLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTariffPriceLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTariffPriceLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *      eventLogInput: // value for 'eventLogInput'
 *   },
 * });
 */
export function useListTariffPriceLogQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListTariffPriceLogQuery, Types.ListTariffPriceLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTariffPriceLogQuery, Types.ListTariffPriceLogQueryVariables>(
    ListTariffPriceLogDocument,
    options,
  );
}
export function useListTariffPriceLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTariffPriceLogQuery, Types.ListTariffPriceLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTariffPriceLogQuery, Types.ListTariffPriceLogQueryVariables>(
    ListTariffPriceLogDocument,
    options,
  );
}
export type ListTariffPriceLogQueryHookResult = ReturnType<typeof useListTariffPriceLogQuery>;
export type ListTariffPriceLogLazyQueryHookResult = ReturnType<typeof useListTariffPriceLogLazyQuery>;
export type ListTariffPriceLogQueryResult = Apollo.QueryResult<
  Types.ListTariffPriceLogQuery,
  Types.ListTariffPriceLogQueryVariables
>;
export const ListTariffsForExtendDocument = gql`
  query listTariffsForExtend($input: ListTariffsInput!) {
    listTariffs(input: $input) {
      edges {
        ...TariffForExtend
      }
      total
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useListTariffsForExtendQuery__
 *
 * To run a query within a React component, call `useListTariffsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTariffsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTariffsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTariffsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListTariffsForExtendQuery, Types.ListTariffsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTariffsForExtendQuery, Types.ListTariffsForExtendQueryVariables>(
    ListTariffsForExtendDocument,
    options,
  );
}
export function useListTariffsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTariffsForExtendQuery, Types.ListTariffsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTariffsForExtendQuery, Types.ListTariffsForExtendQueryVariables>(
    ListTariffsForExtendDocument,
    options,
  );
}
export type ListTariffsForExtendQueryHookResult = ReturnType<typeof useListTariffsForExtendQuery>;
export type ListTariffsForExtendLazyQueryHookResult = ReturnType<typeof useListTariffsForExtendLazyQuery>;
export type ListTariffsForExtendQueryResult = Apollo.QueryResult<
  Types.ListTariffsForExtendQuery,
  Types.ListTariffsForExtendQueryVariables
>;
export const ListTransactionsV2ExtendDocument = gql`
  query listTransactionsV2Extend($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        items {
          altId
          quantity
          unitPrice
          productType {
            columnText
          }
          taxRate
          calculatedAmount
          calculatedUnitPrice
        }
        session {
          altId
          vendorId
          connector {
            connectorType {
              columnText
            }
            evse {
              charger {
                chargerName
                site {
                  siteName
                  timeZone
                }
              }
            }
          }
          plan {
            planName
            chargerGroup {
              altId
              chargerGroupName
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          startTimeLocal
          endTimeLocal
          meterStart
          meterEnd
          cost
        }
        altId
        tax
        amount
        calculatedAmount
        calculatedTaxAmount
        isCalculated
        type {
          columnText
        }
      }
    }
  }
`;

/**
 * __useListTransactionsV2ExtendQuery__
 *
 * To run a query within a React component, call `useListTransactionsV2ExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsV2ExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsV2ExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionsV2ExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListTransactionsV2ExtendQuery,
    Types.ListTransactionsV2ExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTransactionsV2ExtendQuery, Types.ListTransactionsV2ExtendQueryVariables>(
    ListTransactionsV2ExtendDocument,
    options,
  );
}
export function useListTransactionsV2ExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListTransactionsV2ExtendQuery,
    Types.ListTransactionsV2ExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTransactionsV2ExtendQuery, Types.ListTransactionsV2ExtendQueryVariables>(
    ListTransactionsV2ExtendDocument,
    options,
  );
}
export type ListTransactionsV2ExtendQueryHookResult = ReturnType<typeof useListTransactionsV2ExtendQuery>;
export type ListTransactionsV2ExtendLazyQueryHookResult = ReturnType<typeof useListTransactionsV2ExtendLazyQuery>;
export type ListTransactionsV2ExtendQueryResult = Apollo.QueryResult<
  Types.ListTransactionsV2ExtendQuery,
  Types.ListTransactionsV2ExtendQueryVariables
>;
export const ListTransactionsV2ExtendForExportDocument = gql`
  query listTransactionsV2ExtendForExport($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        paymentTxId
        session {
          altId
          vendorId
          connector {
            connectorType {
              columnText
            }
            evse {
              charger {
                chargerName
                site {
                  siteName
                  timeZone
                }
              }
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          startTimeLocal
          endTimeLocal
          meterStart
          meterEnd
          cost
        }
        altId
        tax
        amount
        calculatedAmount
        calculatedTaxAmount
        isCalculated
        type {
          columnText
        }
      }
    }
  }
`;

/**
 * __useListTransactionsV2ExtendForExportQuery__
 *
 * To run a query within a React component, call `useListTransactionsV2ExtendForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsV2ExtendForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsV2ExtendForExportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionsV2ExtendForExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListTransactionsV2ExtendForExportQuery,
    Types.ListTransactionsV2ExtendForExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListTransactionsV2ExtendForExportQuery,
    Types.ListTransactionsV2ExtendForExportQueryVariables
  >(ListTransactionsV2ExtendForExportDocument, options);
}
export function useListTransactionsV2ExtendForExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListTransactionsV2ExtendForExportQuery,
    Types.ListTransactionsV2ExtendForExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListTransactionsV2ExtendForExportQuery,
    Types.ListTransactionsV2ExtendForExportQueryVariables
  >(ListTransactionsV2ExtendForExportDocument, options);
}
export type ListTransactionsV2ExtendForExportQueryHookResult = ReturnType<
  typeof useListTransactionsV2ExtendForExportQuery
>;
export type ListTransactionsV2ExtendForExportLazyQueryHookResult = ReturnType<
  typeof useListTransactionsV2ExtendForExportLazyQuery
>;
export type ListTransactionsV2ExtendForExportQueryResult = Apollo.QueryResult<
  Types.ListTransactionsV2ExtendForExportQuery,
  Types.ListTransactionsV2ExtendForExportQueryVariables
>;
export const RemoveChargersFromChargerGroupForExtendDocument = gql`
  mutation removeChargersFromChargerGroupForExtend($input: RemoveChargersFromChargerGroupInput!) {
    removeChargersFromChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type RemoveChargersFromChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  Types.RemoveChargersFromChargerGroupForExtendMutation,
  Types.RemoveChargersFromChargerGroupForExtendMutationVariables
>;

/**
 * __useRemoveChargersFromChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useRemoveChargersFromChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChargersFromChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChargersFromChargerGroupForExtendMutation, { data, loading, error }] = useRemoveChargersFromChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChargersFromChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveChargersFromChargerGroupForExtendMutation,
    Types.RemoveChargersFromChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveChargersFromChargerGroupForExtendMutation,
    Types.RemoveChargersFromChargerGroupForExtendMutationVariables
  >(RemoveChargersFromChargerGroupForExtendDocument, options);
}
export type RemoveChargersFromChargerGroupForExtendMutationHookResult = ReturnType<
  typeof useRemoveChargersFromChargerGroupForExtendMutation
>;
export type RemoveChargersFromChargerGroupForExtendMutationResult =
  Apollo.MutationResult<Types.RemoveChargersFromChargerGroupForExtendMutation>;
export type RemoveChargersFromChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.RemoveChargersFromChargerGroupForExtendMutation,
  Types.RemoveChargersFromChargerGroupForExtendMutationVariables
>;
export const ResetChargerForExtendDocument = gql`
  mutation resetChargerForExtend($chargerInput: ResetChargerForExtendInput!) {
    resetChargerForExtend(input: $chargerInput)
  }
`;
export type ResetChargerForExtendMutationFn = Apollo.MutationFunction<
  Types.ResetChargerForExtendMutation,
  Types.ResetChargerForExtendMutationVariables
>;

/**
 * __useResetChargerForExtendMutation__
 *
 * To run a mutation, you first call `useResetChargerForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChargerForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChargerForExtendMutation, { data, loading, error }] = useResetChargerForExtendMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useResetChargerForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetChargerForExtendMutation,
    Types.ResetChargerForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ResetChargerForExtendMutation, Types.ResetChargerForExtendMutationVariables>(
    ResetChargerForExtendDocument,
    options,
  );
}
export type ResetChargerForExtendMutationHookResult = ReturnType<typeof useResetChargerForExtendMutation>;
export type ResetChargerForExtendMutationResult = Apollo.MutationResult<Types.ResetChargerForExtendMutation>;
export type ResetChargerForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetChargerForExtendMutation,
  Types.ResetChargerForExtendMutationVariables
>;
export const UpdateChargerPriceGroupForExtendDocument = gql`
  mutation updateChargerPriceGroupForExtend($input: UpdateChargerGroupInput!) {
    updateChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdateChargerPriceGroupForExtendMutationFn = Apollo.MutationFunction<
  Types.UpdateChargerPriceGroupForExtendMutation,
  Types.UpdateChargerPriceGroupForExtendMutationVariables
>;

/**
 * __useUpdateChargerPriceGroupForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateChargerPriceGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerPriceGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerPriceGroupForExtendMutation, { data, loading, error }] = useUpdateChargerPriceGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChargerPriceGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateChargerPriceGroupForExtendMutation,
    Types.UpdateChargerPriceGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateChargerPriceGroupForExtendMutation,
    Types.UpdateChargerPriceGroupForExtendMutationVariables
  >(UpdateChargerPriceGroupForExtendDocument, options);
}
export type UpdateChargerPriceGroupForExtendMutationHookResult = ReturnType<
  typeof useUpdateChargerPriceGroupForExtendMutation
>;
export type UpdateChargerPriceGroupForExtendMutationResult =
  Apollo.MutationResult<Types.UpdateChargerPriceGroupForExtendMutation>;
export type UpdateChargerPriceGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateChargerPriceGroupForExtendMutation,
  Types.UpdateChargerPriceGroupForExtendMutationVariables
>;
export const UpdateChargerGroupPlanForExtendDocument = gql`
  mutation updateChargerGroupPlanForExtend($input: UpdateChargerGroupPlanInput!) {
    updateChargerGroupPlan(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  Types.UpdateChargerGroupPlanForExtendMutation,
  Types.UpdateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useUpdateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerGroupPlanForExtendMutation, { data, loading, error }] = useUpdateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateChargerGroupPlanForExtendMutation,
    Types.UpdateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateChargerGroupPlanForExtendMutation,
    Types.UpdateChargerGroupPlanForExtendMutationVariables
  >(UpdateChargerGroupPlanForExtendDocument, options);
}
export type UpdateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useUpdateChargerGroupPlanForExtendMutation
>;
export type UpdateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<Types.UpdateChargerGroupPlanForExtendMutation>;
export type UpdateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateChargerGroupPlanForExtendMutation,
  Types.UpdateChargerGroupPlanForExtendMutationVariables
>;
export const UpdateExtendUserHostsDocument = gql`
  mutation updateExtendUserHosts($extenduserInput: UpdateExtendUserHostsInput) {
    updateExtendUserHosts(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
export type UpdateExtendUserHostsMutationFn = Apollo.MutationFunction<
  Types.UpdateExtendUserHostsMutation,
  Types.UpdateExtendUserHostsMutationVariables
>;

/**
 * __useUpdateExtendUserHostsMutation__
 *
 * To run a mutation, you first call `useUpdateExtendUserHostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtendUserHostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtendUserHostsMutation, { data, loading, error }] = useUpdateExtendUserHostsMutation({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useUpdateExtendUserHostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateExtendUserHostsMutation,
    Types.UpdateExtendUserHostsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateExtendUserHostsMutation, Types.UpdateExtendUserHostsMutationVariables>(
    UpdateExtendUserHostsDocument,
    options,
  );
}
export type UpdateExtendUserHostsMutationHookResult = ReturnType<typeof useUpdateExtendUserHostsMutation>;
export type UpdateExtendUserHostsMutationResult = Apollo.MutationResult<Types.UpdateExtendUserHostsMutation>;
export type UpdateExtendUserHostsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateExtendUserHostsMutation,
  Types.UpdateExtendUserHostsMutationVariables
>;
export const UpdatePlanTariffsForExtendDocument = gql`
  mutation updatePlanTariffsForExtend($input: UpdatePlanTariffsInput!) {
    updatePlanTariffs(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdatePlanTariffsForExtendMutationFn = Apollo.MutationFunction<
  Types.UpdatePlanTariffsForExtendMutation,
  Types.UpdatePlanTariffsForExtendMutationVariables
>;

/**
 * __useUpdatePlanTariffsForExtendMutation__
 *
 * To run a mutation, you first call `useUpdatePlanTariffsForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanTariffsForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanTariffsForExtendMutation, { data, loading, error }] = useUpdatePlanTariffsForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanTariffsForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePlanTariffsForExtendMutation,
    Types.UpdatePlanTariffsForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdatePlanTariffsForExtendMutation,
    Types.UpdatePlanTariffsForExtendMutationVariables
  >(UpdatePlanTariffsForExtendDocument, options);
}
export type UpdatePlanTariffsForExtendMutationHookResult = ReturnType<typeof useUpdatePlanTariffsForExtendMutation>;
export type UpdatePlanTariffsForExtendMutationResult = Apollo.MutationResult<Types.UpdatePlanTariffsForExtendMutation>;
export type UpdatePlanTariffsForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePlanTariffsForExtendMutation,
  Types.UpdatePlanTariffsForExtendMutationVariables
>;
export const UpdateSiteForExtendDocument = gql`
  mutation updateSiteForExtend($siteInput: UpdateSiteForExtendInput) {
    updateSiteForExtend(input: $siteInput) {
      altId
      directions
    }
  }
`;
export type UpdateSiteForExtendMutationFn = Apollo.MutationFunction<
  Types.UpdateSiteForExtendMutation,
  Types.UpdateSiteForExtendMutationVariables
>;

/**
 * __useUpdateSiteForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateSiteForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteForExtendMutation, { data, loading, error }] = useUpdateSiteForExtendMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useUpdateSiteForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSiteForExtendMutation,
    Types.UpdateSiteForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSiteForExtendMutation, Types.UpdateSiteForExtendMutationVariables>(
    UpdateSiteForExtendDocument,
    options,
  );
}
export type UpdateSiteForExtendMutationHookResult = ReturnType<typeof useUpdateSiteForExtendMutation>;
export type UpdateSiteForExtendMutationResult = Apollo.MutationResult<Types.UpdateSiteForExtendMutation>;
export type UpdateSiteForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSiteForExtendMutation,
  Types.UpdateSiteForExtendMutationVariables
>;
export const UpsertTariffForExtendDocument = gql`
  mutation upsertTariffForExtend($input: TariffInput!) {
    upsertTariff(input: $input) {
      altId
      id
    }
  }
`;
export type UpsertTariffForExtendMutationFn = Apollo.MutationFunction<
  Types.UpsertTariffForExtendMutation,
  Types.UpsertTariffForExtendMutationVariables
>;

/**
 * __useUpsertTariffForExtendMutation__
 *
 * To run a mutation, you first call `useUpsertTariffForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTariffForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTariffForExtendMutation, { data, loading, error }] = useUpsertTariffForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTariffForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpsertTariffForExtendMutation,
    Types.UpsertTariffForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpsertTariffForExtendMutation, Types.UpsertTariffForExtendMutationVariables>(
    UpsertTariffForExtendDocument,
    options,
  );
}
export type UpsertTariffForExtendMutationHookResult = ReturnType<typeof useUpsertTariffForExtendMutation>;
export type UpsertTariffForExtendMutationResult = Apollo.MutationResult<Types.UpsertTariffForExtendMutation>;
export type UpsertTariffForExtendMutationOptions = Apollo.BaseMutationOptions<
  Types.UpsertTariffForExtendMutation,
  Types.UpsertTariffForExtendMutationVariables
>;
export const ListGeographicalRegionsDocument = gql`
  query listGeographicalRegions($usaInput: ListGeographicalRegionsInput!, $canadaInput: ListGeographicalRegionsInput!) {
    listGeographicalRegionsUsa: listGeographicalRegions(input: $usaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
    listGeographicalRegionsCanada: listGeographicalRegions(input: $canadaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
  }
`;

/**
 * __useListGeographicalRegionsQuery__
 *
 * To run a query within a React component, call `useListGeographicalRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGeographicalRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGeographicalRegionsQuery({
 *   variables: {
 *      usaInput: // value for 'usaInput'
 *      canadaInput: // value for 'canadaInput'
 *   },
 * });
 */
export function useListGeographicalRegionsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ListGeographicalRegionsQuery, Types.ListGeographicalRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListGeographicalRegionsQuery, Types.ListGeographicalRegionsQueryVariables>(
    ListGeographicalRegionsDocument,
    options,
  );
}
export function useListGeographicalRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListGeographicalRegionsQuery,
    Types.ListGeographicalRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListGeographicalRegionsQuery, Types.ListGeographicalRegionsQueryVariables>(
    ListGeographicalRegionsDocument,
    options,
  );
}
export type ListGeographicalRegionsQueryHookResult = ReturnType<typeof useListGeographicalRegionsQuery>;
export type ListGeographicalRegionsLazyQueryHookResult = ReturnType<typeof useListGeographicalRegionsLazyQuery>;
export type ListGeographicalRegionsQueryResult = Apollo.QueryResult<
  Types.ListGeographicalRegionsQuery,
  Types.ListGeographicalRegionsQueryVariables
>;
export const CreateHostDocument = gql`
  mutation createHost($input: CreateHostInput) {
    createHost(input: $input) {
      hid
      altId
      address1
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
export type CreateHostMutationFn = Apollo.MutationFunction<Types.CreateHostMutation, Types.CreateHostMutationVariables>;

/**
 * __useCreateHostMutation__
 *
 * To run a mutation, you first call `useCreateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHostMutation, { data, loading, error }] = useCreateHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHostMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateHostMutation, Types.CreateHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateHostMutation, Types.CreateHostMutationVariables>(CreateHostDocument, options);
}
export type CreateHostMutationHookResult = ReturnType<typeof useCreateHostMutation>;
export type CreateHostMutationResult = Apollo.MutationResult<Types.CreateHostMutation>;
export type CreateHostMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateHostMutation,
  Types.CreateHostMutationVariables
>;
export const GetHostByIdDocument = gql`
  query getHostById(
    $hostInput: GetHostInput
    $propertiesInput: ListPropertiesInput
    $sitesInput: ListSitesInput
    $contactsInput: ListContactsInput
  ) {
    getHost(input: $hostInput) {
      hid
      altId
      hostName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      contacts(input: $contactsInput) {
        edges {
          altId
          contactName
          workTitle
          email
          phone
        }
      }
      properties(input: $propertiesInput) {
        edges {
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
          altId
          propertyName
          pid
          status
          sites(input: $sitesInput) {
            edges {
              chargers {
                total
              }
              altId
              siteName
              address1
              address2
              locality
              administrativeArea
              postalCode
              country
              sid
              displayName
              latitude
              longitude
              access
              siteStatusId
              type
            }
            total
          }
        }
        page
        pageSize
        sort {
          altId
          pid
          propertyName
          hostName
          status
        }
        total
        search {
          pid {
            iLike
          }
          propertyName {
            iLike
          }
          address1 {
            iLike
          }
          locality {
            iLike
          }
          administrativeArea {
            iLike
          }
          postalCode {
            iLike
          }
        }
      }
      status
    }
  }
`;

/**
 * __useGetHostByIdQuery__
 *
 * To run a query within a React component, call `useGetHostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostByIdQuery({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *      contactsInput: // value for 'contactsInput'
 *   },
 * });
 */
export function useGetHostByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetHostByIdQuery, Types.GetHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetHostByIdQuery, Types.GetHostByIdQueryVariables>(GetHostByIdDocument, options);
}
export function useGetHostByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHostByIdQuery, Types.GetHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetHostByIdQuery, Types.GetHostByIdQueryVariables>(GetHostByIdDocument, options);
}
export type GetHostByIdQueryHookResult = ReturnType<typeof useGetHostByIdQuery>;
export type GetHostByIdLazyQueryHookResult = ReturnType<typeof useGetHostByIdLazyQuery>;
export type GetHostByIdQueryResult = Apollo.QueryResult<Types.GetHostByIdQuery, Types.GetHostByIdQueryVariables>;
export const GetHostByNameDocument = gql`
  query getHostByName($input: GetHostInput) {
    getHost(input: $input) {
      altId
    }
  }
`;

/**
 * __useGetHostByNameQuery__
 *
 * To run a query within a React component, call `useGetHostByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHostByNameQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetHostByNameQuery, Types.GetHostByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetHostByNameQuery, Types.GetHostByNameQueryVariables>(GetHostByNameDocument, options);
}
export function useGetHostByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHostByNameQuery, Types.GetHostByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetHostByNameQuery, Types.GetHostByNameQueryVariables>(
    GetHostByNameDocument,
    options,
  );
}
export type GetHostByNameQueryHookResult = ReturnType<typeof useGetHostByNameQuery>;
export type GetHostByNameLazyQueryHookResult = ReturnType<typeof useGetHostByNameLazyQuery>;
export type GetHostByNameQueryResult = Apollo.QueryResult<Types.GetHostByNameQuery, Types.GetHostByNameQueryVariables>;
export const GetHostsTotalDocument = gql`
  query getHostsTotal($input: ListHostsInput) {
    listHosts(input: $input) {
      total
    }
  }
`;

/**
 * __useGetHostsTotalQuery__
 *
 * To run a query within a React component, call `useGetHostsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostsTotalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHostsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetHostsTotalQuery, Types.GetHostsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetHostsTotalQuery, Types.GetHostsTotalQueryVariables>(GetHostsTotalDocument, options);
}
export function useGetHostsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHostsTotalQuery, Types.GetHostsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetHostsTotalQuery, Types.GetHostsTotalQueryVariables>(
    GetHostsTotalDocument,
    options,
  );
}
export type GetHostsTotalQueryHookResult = ReturnType<typeof useGetHostsTotalQuery>;
export type GetHostsTotalLazyQueryHookResult = ReturnType<typeof useGetHostsTotalLazyQuery>;
export type GetHostsTotalQueryResult = Apollo.QueryResult<Types.GetHostsTotalQuery, Types.GetHostsTotalQueryVariables>;
export const GetSalesforceHostByIdDocument = gql`
  query getSalesforceHostById($input: GetSalesforceHostInput) {
    getSalesforceHost(input: $input) {
      address1
      address2
      locality
      hostName
      postalCode
      administrativeArea
      country
      hid
      hostName
      salesforceHostContacts {
        edges {
          contactName
          phone
          email
        }
      }
    }
  }
`;

/**
 * __useGetSalesforceHostByIdQuery__
 *
 * To run a query within a React component, call `useGetSalesforceHostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceHostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceHostByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesforceHostByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSalesforceHostByIdQuery, Types.GetSalesforceHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSalesforceHostByIdQuery, Types.GetSalesforceHostByIdQueryVariables>(
    GetSalesforceHostByIdDocument,
    options,
  );
}
export function useGetSalesforceHostByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSalesforceHostByIdQuery,
    Types.GetSalesforceHostByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSalesforceHostByIdQuery, Types.GetSalesforceHostByIdQueryVariables>(
    GetSalesforceHostByIdDocument,
    options,
  );
}
export type GetSalesforceHostByIdQueryHookResult = ReturnType<typeof useGetSalesforceHostByIdQuery>;
export type GetSalesforceHostByIdLazyQueryHookResult = ReturnType<typeof useGetSalesforceHostByIdLazyQuery>;
export type GetSalesforceHostByIdQueryResult = Apollo.QueryResult<
  Types.GetSalesforceHostByIdQuery,
  Types.GetSalesforceHostByIdQueryVariables
>;
export const ImportHostDocument = gql`
  mutation importHost($hostInput: ImportHostInput) {
    importHost(input: $hostInput) {
      hid
      altId
      address1
      address2
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
export type ImportHostMutationFn = Apollo.MutationFunction<Types.ImportHostMutation, Types.ImportHostMutationVariables>;

/**
 * __useImportHostMutation__
 *
 * To run a mutation, you first call `useImportHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHostMutation, { data, loading, error }] = useImportHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *   },
 * });
 */
export function useImportHostMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ImportHostMutation, Types.ImportHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ImportHostMutation, Types.ImportHostMutationVariables>(ImportHostDocument, options);
}
export type ImportHostMutationHookResult = ReturnType<typeof useImportHostMutation>;
export type ImportHostMutationResult = Apollo.MutationResult<Types.ImportHostMutation>;
export type ImportHostMutationOptions = Apollo.BaseMutationOptions<
  Types.ImportHostMutation,
  Types.ImportHostMutationVariables
>;
export const ListHostsDocument = gql`
  query listHosts($hostsInput: ListHostsInput, $propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listHosts(input: $hostsInput) {
      page
      pageSize
      total
      edges {
        hid
        altId
        hostName
        status
        properties(input: $propertiesInput) {
          edges {
            pid
            altId
            propertyName
            sites(input: $sitesInput) {
              edges {
                chargers {
                  total
                }
              }
              total
            }
          }
        }
      }
      sort {
        altId
        hid
        hostName
        status
      }
      filter {
        status {
          in
        }
      }
      search {
        hid {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListHostsQuery__
 *
 * To run a query within a React component, call `useListHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHostsQuery({
 *   variables: {
 *      hostsInput: // value for 'hostsInput'
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListHostsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListHostsQuery, Types.ListHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListHostsQuery, Types.ListHostsQueryVariables>(ListHostsDocument, options);
}
export function useListHostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListHostsQuery, Types.ListHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListHostsQuery, Types.ListHostsQueryVariables>(ListHostsDocument, options);
}
export type ListHostsQueryHookResult = ReturnType<typeof useListHostsQuery>;
export type ListHostsLazyQueryHookResult = ReturnType<typeof useListHostsLazyQuery>;
export type ListHostsQueryResult = Apollo.QueryResult<Types.ListHostsQuery, Types.ListHostsQueryVariables>;
export const ListSalesforceHostContactsDocument = gql`
  query listSalesforceHostContacts($input: ListSalesforceHostContactsInput) {
    listSalesforceHostContacts(input: $input) {
      page
      pageSize
      total
      edges {
        contactName
        phone
        email
        workTitle
      }
    }
  }
`;

/**
 * __useListSalesforceHostContactsQuery__
 *
 * To run a query within a React component, call `useListSalesforceHostContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesforceHostContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesforceHostContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSalesforceHostContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListSalesforceHostContactsQuery,
    Types.ListSalesforceHostContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSalesforceHostContactsQuery, Types.ListSalesforceHostContactsQueryVariables>(
    ListSalesforceHostContactsDocument,
    options,
  );
}
export function useListSalesforceHostContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSalesforceHostContactsQuery,
    Types.ListSalesforceHostContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSalesforceHostContactsQuery, Types.ListSalesforceHostContactsQueryVariables>(
    ListSalesforceHostContactsDocument,
    options,
  );
}
export type ListSalesforceHostContactsQueryHookResult = ReturnType<typeof useListSalesforceHostContactsQuery>;
export type ListSalesforceHostContactsLazyQueryHookResult = ReturnType<typeof useListSalesforceHostContactsLazyQuery>;
export type ListSalesforceHostContactsQueryResult = Apollo.QueryResult<
  Types.ListSalesforceHostContactsQuery,
  Types.ListSalesforceHostContactsQueryVariables
>;
export const ListSalesforcePropertiesDocument = gql`
  query listSalesforceProperties($input: ListSalesforcePropertiesInput) {
    listSalesforceProperties(input: $input) {
      edges {
        hid
        pid
        evcId
        propertyName
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
      }
      page
      pageSize
      total
    }
  }
`;

/**
 * __useListSalesforcePropertiesQuery__
 *
 * To run a query within a React component, call `useListSalesforcePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesforcePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesforcePropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSalesforcePropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListSalesforcePropertiesQuery,
    Types.ListSalesforcePropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSalesforcePropertiesQuery, Types.ListSalesforcePropertiesQueryVariables>(
    ListSalesforcePropertiesDocument,
    options,
  );
}
export function useListSalesforcePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSalesforcePropertiesQuery,
    Types.ListSalesforcePropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSalesforcePropertiesQuery, Types.ListSalesforcePropertiesQueryVariables>(
    ListSalesforcePropertiesDocument,
    options,
  );
}
export type ListSalesforcePropertiesQueryHookResult = ReturnType<typeof useListSalesforcePropertiesQuery>;
export type ListSalesforcePropertiesLazyQueryHookResult = ReturnType<typeof useListSalesforcePropertiesLazyQuery>;
export type ListSalesforcePropertiesQueryResult = Apollo.QueryResult<
  Types.ListSalesforcePropertiesQuery,
  Types.ListSalesforcePropertiesQueryVariables
>;
export const UpdateHostDocument = gql`
  mutation updateHost($hostInput: UpdateHostInput) {
    updateHost(input: $hostInput) {
      altId
    }
  }
`;
export type UpdateHostMutationFn = Apollo.MutationFunction<Types.UpdateHostMutation, Types.UpdateHostMutationVariables>;

/**
 * __useUpdateHostMutation__
 *
 * To run a mutation, you first call `useUpdateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHostMutation, { data, loading, error }] = useUpdateHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *   },
 * });
 */
export function useUpdateHostMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateHostMutation, Types.UpdateHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateHostMutation, Types.UpdateHostMutationVariables>(UpdateHostDocument, options);
}
export type UpdateHostMutationHookResult = ReturnType<typeof useUpdateHostMutation>;
export type UpdateHostMutationResult = Apollo.MutationResult<Types.UpdateHostMutation>;
export type UpdateHostMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateHostMutation,
  Types.UpdateHostMutationVariables
>;
export const CreateUploadDocument = gql`
  mutation createUpload($input: Upload!) {
    createUpload(input: $input) {
      altId
      url
      fileName
      mimeType
      encoding
      createdAt
    }
  }
`;
export type CreateUploadMutationFn = Apollo.MutationFunction<
  Types.CreateUploadMutation,
  Types.CreateUploadMutationVariables
>;

/**
 * __useCreateUploadMutation__
 *
 * To run a mutation, you first call `useCreateUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadMutation, { data, loading, error }] = useCreateUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateUploadMutation, Types.CreateUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateUploadMutation, Types.CreateUploadMutationVariables>(
    CreateUploadDocument,
    options,
  );
}
export type CreateUploadMutationHookResult = ReturnType<typeof useCreateUploadMutation>;
export type CreateUploadMutationResult = Apollo.MutationResult<Types.CreateUploadMutation>;
export type CreateUploadMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUploadMutation,
  Types.CreateUploadMutationVariables
>;
export const CreateChargerModelDocument = gql`
  mutation createChargerModel($modelInput: CreateChargerModelInput) {
    createChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;
export type CreateChargerModelMutationFn = Apollo.MutationFunction<
  Types.CreateChargerModelMutation,
  Types.CreateChargerModelMutationVariables
>;

/**
 * __useCreateChargerModelMutation__
 *
 * To run a mutation, you first call `useCreateChargerModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerModelMutation, { data, loading, error }] = useCreateChargerModelMutation({
 *   variables: {
 *      modelInput: // value for 'modelInput'
 *   },
 * });
 */
export function useCreateChargerModelMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateChargerModelMutation, Types.CreateChargerModelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateChargerModelMutation, Types.CreateChargerModelMutationVariables>(
    CreateChargerModelDocument,
    options,
  );
}
export type CreateChargerModelMutationHookResult = ReturnType<typeof useCreateChargerModelMutation>;
export type CreateChargerModelMutationResult = Apollo.MutationResult<Types.CreateChargerModelMutation>;
export type CreateChargerModelMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateChargerModelMutation,
  Types.CreateChargerModelMutationVariables
>;
export const GetChargerModelDocument = gql`
  query getChargerModel($chargerModelInput: GetChargerModelInput) {
    getChargerModel(input: $chargerModelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;

/**
 * __useGetChargerModelQuery__
 *
 * To run a query within a React component, call `useGetChargerModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerModelQuery({
 *   variables: {
 *      chargerModelInput: // value for 'chargerModelInput'
 *   },
 * });
 */
export function useGetChargerModelQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetChargerModelQuery, Types.GetChargerModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChargerModelQuery, Types.GetChargerModelQueryVariables>(
    GetChargerModelDocument,
    options,
  );
}
export function useGetChargerModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChargerModelQuery, Types.GetChargerModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetChargerModelQuery, Types.GetChargerModelQueryVariables>(
    GetChargerModelDocument,
    options,
  );
}
export type GetChargerModelQueryHookResult = ReturnType<typeof useGetChargerModelQuery>;
export type GetChargerModelLazyQueryHookResult = ReturnType<typeof useGetChargerModelLazyQuery>;
export type GetChargerModelQueryResult = Apollo.QueryResult<
  Types.GetChargerModelQuery,
  Types.GetChargerModelQueryVariables
>;
export const ListChargerModelOptionsDocument = gql`
  query listChargerModelOptions {
    listChargerModelOptions {
      altId
      modelName
    }
  }
`;

/**
 * __useListChargerModelOptionsQuery__
 *
 * To run a query within a React component, call `useListChargerModelOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerModelOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerModelOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListChargerModelOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListChargerModelOptionsQuery,
    Types.ListChargerModelOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargerModelOptionsQuery, Types.ListChargerModelOptionsQueryVariables>(
    ListChargerModelOptionsDocument,
    options,
  );
}
export function useListChargerModelOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListChargerModelOptionsQuery,
    Types.ListChargerModelOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargerModelOptionsQuery, Types.ListChargerModelOptionsQueryVariables>(
    ListChargerModelOptionsDocument,
    options,
  );
}
export type ListChargerModelOptionsQueryHookResult = ReturnType<typeof useListChargerModelOptionsQuery>;
export type ListChargerModelOptionsLazyQueryHookResult = ReturnType<typeof useListChargerModelOptionsLazyQuery>;
export type ListChargerModelOptionsQueryResult = Apollo.QueryResult<
  Types.ListChargerModelOptionsQuery,
  Types.ListChargerModelOptionsQueryVariables
>;
export const ListChargerModelsDocument = gql`
  query listChargerModels($input: ListChargerModelsInput) {
    listChargerModels(input: $input) {
      edges {
        altId
        breakerRating
        currentThd
        efficiency
        inputAcVoltage
        manufacturer
        modelName
        powerFactor
        powerOutput
        simultaneousChargeSupported
        unitType
        evses {
          edges {
            connectors {
              edges {
                altId
              }
            }
          }
        }
      }
      page
      pageSize
      total
      sort {
        modelName
        manufacturer
        unitType
      }
      filter {
        manufacturer {
          in
        }
      }
      search {
        modelName {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListChargerModelsQuery__
 *
 * To run a query within a React component, call `useListChargerModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerModelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListChargerModelsQuery, Types.ListChargerModelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListChargerModelsQuery, Types.ListChargerModelsQueryVariables>(
    ListChargerModelsDocument,
    options,
  );
}
export function useListChargerModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListChargerModelsQuery, Types.ListChargerModelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListChargerModelsQuery, Types.ListChargerModelsQueryVariables>(
    ListChargerModelsDocument,
    options,
  );
}
export type ListChargerModelsQueryHookResult = ReturnType<typeof useListChargerModelsQuery>;
export type ListChargerModelsLazyQueryHookResult = ReturnType<typeof useListChargerModelsLazyQuery>;
export type ListChargerModelsQueryResult = Apollo.QueryResult<
  Types.ListChargerModelsQuery,
  Types.ListChargerModelsQueryVariables
>;
export const ListVehicleConnectorsDocument = gql`
  query listVehicleConnectors($input: ListVehicleConnectorsInput) {
    listVehicleConnectors(input: $input) {
      edges {
        id
        connectorName: name
      }
    }
  }
`;

/**
 * __useListVehicleConnectorsQuery__
 *
 * To run a query within a React component, call `useListVehicleConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVehicleConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVehicleConnectorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListVehicleConnectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListVehicleConnectorsQuery, Types.ListVehicleConnectorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListVehicleConnectorsQuery, Types.ListVehicleConnectorsQueryVariables>(
    ListVehicleConnectorsDocument,
    options,
  );
}
export function useListVehicleConnectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListVehicleConnectorsQuery,
    Types.ListVehicleConnectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListVehicleConnectorsQuery, Types.ListVehicleConnectorsQueryVariables>(
    ListVehicleConnectorsDocument,
    options,
  );
}
export type ListVehicleConnectorsQueryHookResult = ReturnType<typeof useListVehicleConnectorsQuery>;
export type ListVehicleConnectorsLazyQueryHookResult = ReturnType<typeof useListVehicleConnectorsLazyQuery>;
export type ListVehicleConnectorsQueryResult = Apollo.QueryResult<
  Types.ListVehicleConnectorsQuery,
  Types.ListVehicleConnectorsQueryVariables
>;
export const UpdateChargerModelDocument = gql`
  mutation UpdateChargerModel($modelInput: UpdateChargerModelInput) {
    updateChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;
export type UpdateChargerModelMutationFn = Apollo.MutationFunction<
  Types.UpdateChargerModelMutation,
  Types.UpdateChargerModelMutationVariables
>;

/**
 * __useUpdateChargerModelMutation__
 *
 * To run a mutation, you first call `useUpdateChargerModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerModelMutation, { data, loading, error }] = useUpdateChargerModelMutation({
 *   variables: {
 *      modelInput: // value for 'modelInput'
 *   },
 * });
 */
export function useUpdateChargerModelMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateChargerModelMutation, Types.UpdateChargerModelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateChargerModelMutation, Types.UpdateChargerModelMutationVariables>(
    UpdateChargerModelDocument,
    options,
  );
}
export type UpdateChargerModelMutationHookResult = ReturnType<typeof useUpdateChargerModelMutation>;
export type UpdateChargerModelMutationResult = Apollo.MutationResult<Types.UpdateChargerModelMutation>;
export type UpdateChargerModelMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateChargerModelMutation,
  Types.UpdateChargerModelMutationVariables
>;
export const ListNotificationGroupsDocument = gql`
  query listNotificationGroups(
    $notificationGroupsInput: ListNotificationGroupsInput
    $notificationItemsInput: ListNotificationItemsInput
  ) {
    listNotificationGroups(input: $notificationGroupsInput) {
      edges {
        groupName
        sortOrder
        notificationItems(input: $notificationItemsInput) {
          edges {
            id
            notificationName
            notificationDesc
            onePrefRequired
          }
        }
      }
    }
  }
`;

/**
 * __useListNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useListNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotificationGroupsQuery({
 *   variables: {
 *      notificationGroupsInput: // value for 'notificationGroupsInput'
 *      notificationItemsInput: // value for 'notificationItemsInput'
 *   },
 * });
 */
export function useListNotificationGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListNotificationGroupsQuery, Types.ListNotificationGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListNotificationGroupsQuery, Types.ListNotificationGroupsQueryVariables>(
    ListNotificationGroupsDocument,
    options,
  );
}
export function useListNotificationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListNotificationGroupsQuery,
    Types.ListNotificationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListNotificationGroupsQuery, Types.ListNotificationGroupsQueryVariables>(
    ListNotificationGroupsDocument,
    options,
  );
}
export type ListNotificationGroupsQueryHookResult = ReturnType<typeof useListNotificationGroupsQuery>;
export type ListNotificationGroupsLazyQueryHookResult = ReturnType<typeof useListNotificationGroupsLazyQuery>;
export type ListNotificationGroupsQueryResult = Apollo.QueryResult<
  Types.ListNotificationGroupsQuery,
  Types.ListNotificationGroupsQueryVariables
>;
export const ListFalconConstraintsDocument = gql`
  query listFalconConstraints($optionsInput: ListFalconConstraintsInput) {
    listFalconConstraints(input: $optionsInput) {
      edges {
        id
        columnName
        columnText
        columnValue
      }
    }
  }
`;

/**
 * __useListFalconConstraintsQuery__
 *
 * To run a query within a React component, call `useListFalconConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFalconConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFalconConstraintsQuery({
 *   variables: {
 *      optionsInput: // value for 'optionsInput'
 *   },
 * });
 */
export function useListFalconConstraintsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListFalconConstraintsQuery, Types.ListFalconConstraintsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListFalconConstraintsQuery, Types.ListFalconConstraintsQueryVariables>(
    ListFalconConstraintsDocument,
    options,
  );
}
export function useListFalconConstraintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListFalconConstraintsQuery,
    Types.ListFalconConstraintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListFalconConstraintsQuery, Types.ListFalconConstraintsQueryVariables>(
    ListFalconConstraintsDocument,
    options,
  );
}
export type ListFalconConstraintsQueryHookResult = ReturnType<typeof useListFalconConstraintsQuery>;
export type ListFalconConstraintsLazyQueryHookResult = ReturnType<typeof useListFalconConstraintsLazyQuery>;
export type ListFalconConstraintsQueryResult = Apollo.QueryResult<
  Types.ListFalconConstraintsQuery,
  Types.ListFalconConstraintsQueryVariables
>;
export const CreatePlanDocument = gql`
  mutation createPlan($planInput: CreatePlanInput!) {
    createPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<Types.CreatePlanMutation, Types.CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreatePlanMutation, Types.CreatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreatePlanMutation, Types.CreatePlanMutationVariables>(CreatePlanDocument, options);
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<Types.CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePlanMutation,
  Types.CreatePlanMutationVariables
>;
export const GetPlanDocument = gql`
  query getPlan($planInput: GetPlanInput) {
    getPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useGetPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetPlanQuery, Types.GetPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetPlanQuery, Types.GetPlanQueryVariables>(GetPlanDocument, options);
}
export function useGetPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlanQuery, Types.GetPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetPlanQuery, Types.GetPlanQueryVariables>(GetPlanDocument, options);
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<Types.GetPlanQuery, Types.GetPlanQueryVariables>;
export const ListPlanDetailsDocument = gql`
  query listPlanDetails($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        products(input: { pageSize: 9999 }) {
          edges {
            altId
            credit {
              creditAmount
              creditReoccurrence
            }
            subscription {
              subscriptionFee
              subscriptionReoccurrence
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListPlanDetailsQuery__
 *
 * To run a query within a React component, call `useListPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlanDetailsQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlanDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListPlanDetailsQuery, Types.ListPlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListPlanDetailsQuery, Types.ListPlanDetailsQueryVariables>(
    ListPlanDetailsDocument,
    options,
  );
}
export function useListPlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListPlanDetailsQuery, Types.ListPlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListPlanDetailsQuery, Types.ListPlanDetailsQueryVariables>(
    ListPlanDetailsDocument,
    options,
  );
}
export type ListPlanDetailsQueryHookResult = ReturnType<typeof useListPlanDetailsQuery>;
export type ListPlanDetailsLazyQueryHookResult = ReturnType<typeof useListPlanDetailsLazyQuery>;
export type ListPlanDetailsQueryResult = Apollo.QueryResult<
  Types.ListPlanDetailsQuery,
  Types.ListPlanDetailsQueryVariables
>;
export const ListPlanNamesDocument = gql`
  query listPlanNames($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      edges {
        altId
        planName
      }
    }
  }
`;

/**
 * __useListPlanNamesQuery__
 *
 * To run a query within a React component, call `useListPlanNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlanNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlanNamesQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlanNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListPlanNamesQuery, Types.ListPlanNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListPlanNamesQuery, Types.ListPlanNamesQueryVariables>(ListPlanNamesDocument, options);
}
export function useListPlanNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListPlanNamesQuery, Types.ListPlanNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListPlanNamesQuery, Types.ListPlanNamesQueryVariables>(
    ListPlanNamesDocument,
    options,
  );
}
export type ListPlanNamesQueryHookResult = ReturnType<typeof useListPlanNamesQuery>;
export type ListPlanNamesLazyQueryHookResult = ReturnType<typeof useListPlanNamesLazyQuery>;
export type ListPlanNamesQueryResult = Apollo.QueryResult<Types.ListPlanNamesQuery, Types.ListPlanNamesQueryVariables>;
export const ListPlansDocument = gql`
  query listPlans($input: ListPlansInput) {
    listPlans(input: $input) {
      total
      page
      pageSize
      sort {
        planName
        planDisplayName
        createdAt
      }
      search {
        planName {
          iLike
        }
      }
      edges {
        altId
        createdAt
        planName
        planCode
        planStatus
        planDuration
        planDescription
        planDisplayName
        driverRank
      }
    }
  }
`;

/**
 * __useListPlansQuery__
 *
 * To run a query within a React component, call `useListPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListPlansQuery, Types.ListPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListPlansQuery, Types.ListPlansQueryVariables>(ListPlansDocument, options);
}
export function useListPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListPlansQuery, Types.ListPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListPlansQuery, Types.ListPlansQueryVariables>(ListPlansDocument, options);
}
export type ListPlansQueryHookResult = ReturnType<typeof useListPlansQuery>;
export type ListPlansLazyQueryHookResult = ReturnType<typeof useListPlansLazyQuery>;
export type ListPlansQueryResult = Apollo.QueryResult<Types.ListPlansQuery, Types.ListPlansQueryVariables>;
export const ListPlansForContractsDocument = gql`
  query listPlansForContracts($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        createdAt
        enrollmentClose
        enrollmentOpen
        planName
        planStatus
        intervalUnit
        intervalLength
        planDuration
        terminationBehavior
      }
    }
  }
`;

/**
 * __useListPlansForContractsQuery__
 *
 * To run a query within a React component, call `useListPlansForContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlansForContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlansForContractsQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlansForContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListPlansForContractsQuery, Types.ListPlansForContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListPlansForContractsQuery, Types.ListPlansForContractsQueryVariables>(
    ListPlansForContractsDocument,
    options,
  );
}
export function useListPlansForContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListPlansForContractsQuery,
    Types.ListPlansForContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListPlansForContractsQuery, Types.ListPlansForContractsQueryVariables>(
    ListPlansForContractsDocument,
    options,
  );
}
export type ListPlansForContractsQueryHookResult = ReturnType<typeof useListPlansForContractsQuery>;
export type ListPlansForContractsLazyQueryHookResult = ReturnType<typeof useListPlansForContractsLazyQuery>;
export type ListPlansForContractsQueryResult = Apollo.QueryResult<
  Types.ListPlansForContractsQuery,
  Types.ListPlansForContractsQueryVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($planInput: UpdatePlanInput!) {
    updatePlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<Types.UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePlanMutation,
  Types.UpdatePlanMutationVariables
>;
export const UpdatePlanDriverRankDocument = gql`
  mutation UpdatePlanDriverRank($input: [DriverRankInput!]!) {
    updatePlanDriverRank(input: $input) {
      success
      message
    }
  }
`;
export type UpdatePlanDriverRankMutationFn = Apollo.MutationFunction<
  Types.UpdatePlanDriverRankMutation,
  Types.UpdatePlanDriverRankMutationVariables
>;

/**
 * __useUpdatePlanDriverRankMutation__
 *
 * To run a mutation, you first call `useUpdatePlanDriverRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanDriverRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanDriverRankMutation, { data, loading, error }] = useUpdatePlanDriverRankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanDriverRankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePlanDriverRankMutation,
    Types.UpdatePlanDriverRankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePlanDriverRankMutation, Types.UpdatePlanDriverRankMutationVariables>(
    UpdatePlanDriverRankDocument,
    options,
  );
}
export type UpdatePlanDriverRankMutationHookResult = ReturnType<typeof useUpdatePlanDriverRankMutation>;
export type UpdatePlanDriverRankMutationResult = Apollo.MutationResult<Types.UpdatePlanDriverRankMutation>;
export type UpdatePlanDriverRankMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePlanDriverRankMutation,
  Types.UpdatePlanDriverRankMutationVariables
>;
export const ImportPropertyDocument = gql`
  mutation importProperty($propertyInput: ImportPropertyInput) {
    importProperty(input: $propertyInput) {
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      status
      host {
        altId
      }
      altId
      pid
      postalCode
      propertyName
    }
  }
`;
export type ImportPropertyMutationFn = Apollo.MutationFunction<
  Types.ImportPropertyMutation,
  Types.ImportPropertyMutationVariables
>;

/**
 * __useImportPropertyMutation__
 *
 * To run a mutation, you first call `useImportPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPropertyMutation, { data, loading, error }] = useImportPropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useImportPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ImportPropertyMutation, Types.ImportPropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ImportPropertyMutation, Types.ImportPropertyMutationVariables>(
    ImportPropertyDocument,
    options,
  );
}
export type ImportPropertyMutationHookResult = ReturnType<typeof useImportPropertyMutation>;
export type ImportPropertyMutationResult = Apollo.MutationResult<Types.ImportPropertyMutation>;
export type ImportPropertyMutationOptions = Apollo.BaseMutationOptions<
  Types.ImportPropertyMutation,
  Types.ImportPropertyMutationVariables
>;
export const ListPropertiesDocument = gql`
  query listProperties($propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listProperties(input: $propertiesInput) {
      edges {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        status
        host {
          altId
        }
        altId
        pid
        postalCode
        propertyName
        sites(input: $sitesInput) {
          edges {
            chargers {
              total
            }
          }
          total
        }
      }
      page
      pageSize
      total
      sort {
        altId
        pid
        propertyName
        hostName
      }
      filter {
        status {
          in
        }
      }
      search {
        pid {
          iLike
        }
        propertyName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListPropertiesQuery__
 *
 * To run a query within a React component, call `useListPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertiesQuery({
 *   variables: {
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListPropertiesQuery, Types.ListPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListPropertiesQuery, Types.ListPropertiesQueryVariables>(
    ListPropertiesDocument,
    options,
  );
}
export function useListPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListPropertiesQuery, Types.ListPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListPropertiesQuery, Types.ListPropertiesQueryVariables>(
    ListPropertiesDocument,
    options,
  );
}
export type ListPropertiesQueryHookResult = ReturnType<typeof useListPropertiesQuery>;
export type ListPropertiesLazyQueryHookResult = ReturnType<typeof useListPropertiesLazyQuery>;
export type ListPropertiesQueryResult = Apollo.QueryResult<
  Types.ListPropertiesQuery,
  Types.ListPropertiesQueryVariables
>;
export const UpdatePropertyDocument = gql`
  mutation updateProperty($propertyInput: UpdatePropertyInput) {
    updateProperty(input: $propertyInput) {
      altId
      status
    }
  }
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<
  Types.UpdatePropertyMutation,
  Types.UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdatePropertyMutation, Types.UpdatePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePropertyMutation, Types.UpdatePropertyMutationVariables>(
    UpdatePropertyDocument,
    options,
  );
}
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<Types.UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePropertyMutation,
  Types.UpdatePropertyMutationVariables
>;
export const GetSessionDocument = gql`
  query getSession($sessionInput: GetSessionInput!) {
    getSession(input: $sessionInput) {
      altId
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionInput: // value for 'sessionInput'
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSessionQuery, Types.GetSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSessionQuery, Types.GetSessionQueryVariables>(GetSessionDocument, options);
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSessionQuery, Types.GetSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSessionQuery, Types.GetSessionQueryVariables>(GetSessionDocument, options);
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<Types.GetSessionQuery, Types.GetSessionQueryVariables>;
export const ListSessionsV2Document = gql`
  query ListSessionsV2($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      total
      edges {
        altId
        vendorId
        startSource {
          columnValue
        }
        startTime
        endTime
        sessionDuration
        energyDelivered
        plan {
          altId
          planName
        }
        transaction {
          altId
          vendorId
          amount
          driver {
            altId
            firstName
            lastName
          }
        }
        meterStart
        meterEnd
        lastMeterValue
        connector {
          evse {
            charger {
              chargerName
            }
          }
        }
        cost
        corrupted
        corruptedReasons
        card {
          internalNumber
          externalNumber
          evcId
        }
        stopSource {
          columnValue
        }
      }
    }
  }
`;

/**
 * __useListSessionsV2Query__
 *
 * To run a query within a React component, call `useListSessionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsV2Query(
  baseOptions: Apollo.QueryHookOptions<Types.ListSessionsV2Query, Types.ListSessionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSessionsV2Query, Types.ListSessionsV2QueryVariables>(
    ListSessionsV2Document,
    options,
  );
}
export function useListSessionsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSessionsV2Query, Types.ListSessionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSessionsV2Query, Types.ListSessionsV2QueryVariables>(
    ListSessionsV2Document,
    options,
  );
}
export type ListSessionsV2QueryHookResult = ReturnType<typeof useListSessionsV2Query>;
export type ListSessionsV2LazyQueryHookResult = ReturnType<typeof useListSessionsV2LazyQuery>;
export type ListSessionsV2QueryResult = Apollo.QueryResult<
  Types.ListSessionsV2Query,
  Types.ListSessionsV2QueryVariables
>;
export const GetSiteDocument = gql`
  query getSite($siteInput: GetSiteInput) {
    getSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSiteFragmentDoc}
`;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useGetSiteQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSiteQuery, Types.GetSiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSiteQuery, Types.GetSiteQueryVariables>(GetSiteDocument, options);
}
export function useGetSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSiteQuery, Types.GetSiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSiteQuery, Types.GetSiteQueryVariables>(GetSiteDocument, options);
}
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = Apollo.QueryResult<Types.GetSiteQuery, Types.GetSiteQueryVariables>;
export const GetSitesTotalDocument = gql`
  query getSitesTotal {
    listSites {
      total
    }
  }
`;

/**
 * __useGetSitesTotalQuery__
 *
 * To run a query within a React component, call `useGetSitesTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesTotalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetSitesTotalQuery, Types.GetSitesTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSitesTotalQuery, Types.GetSitesTotalQueryVariables>(GetSitesTotalDocument, options);
}
export function useGetSitesTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSitesTotalQuery, Types.GetSitesTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSitesTotalQuery, Types.GetSitesTotalQueryVariables>(
    GetSitesTotalDocument,
    options,
  );
}
export type GetSitesTotalQueryHookResult = ReturnType<typeof useGetSitesTotalQuery>;
export type GetSitesTotalLazyQueryHookResult = ReturnType<typeof useGetSitesTotalLazyQuery>;
export type GetSitesTotalQueryResult = Apollo.QueryResult<Types.GetSitesTotalQuery, Types.GetSitesTotalQueryVariables>;
export const ListMappedSitesDocument = gql`
  query listMappedSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          edges {
            altId
            chargerName
            cid
            fieldStationStatus
            chargerModel {
              altId
              modelName
            }
          }
          total
          search {
            cid {
              iLike
            }
            chargerName {
              iLike
            }
            site_address1 {
              iLike
            }
            site_locality {
              iLike
            }
            site_administrativeArea {
              iLike
            }
            site_postalCode {
              iLike
            }
            site_property_propertyName {
              iLike
            }
          }
          sort {
            cid
            chargerName
            fieldStationStatus
            propertyName
            chargerModel_modelName
          }
          filter {
            chargerModel_altId {
              in
            }
            fieldStationStatus {
              in
            }
            tags {
              or {
                contains
              }
            }
          }
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;

/**
 * __useListMappedSitesQuery__
 *
 * To run a query within a React component, call `useListMappedSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMappedSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMappedSitesQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListMappedSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListMappedSitesQuery, Types.ListMappedSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListMappedSitesQuery, Types.ListMappedSitesQueryVariables>(
    ListMappedSitesDocument,
    options,
  );
}
export function useListMappedSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListMappedSitesQuery, Types.ListMappedSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListMappedSitesQuery, Types.ListMappedSitesQueryVariables>(
    ListMappedSitesDocument,
    options,
  );
}
export type ListMappedSitesQueryHookResult = ReturnType<typeof useListMappedSitesQuery>;
export type ListMappedSitesLazyQueryHookResult = ReturnType<typeof useListMappedSitesLazyQuery>;
export type ListMappedSitesQueryResult = Apollo.QueryResult<
  Types.ListMappedSitesQuery,
  Types.ListMappedSitesQueryVariables
>;
export const ListSiteOptionsDocument = gql`
  query listSiteOptions {
    listSiteOptions {
      altId
      siteName
    }
  }
`;

/**
 * __useListSiteOptionsQuery__
 *
 * To run a query within a React component, call `useListSiteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSiteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSiteOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSiteOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListSiteOptionsQuery, Types.ListSiteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSiteOptionsQuery, Types.ListSiteOptionsQueryVariables>(
    ListSiteOptionsDocument,
    options,
  );
}
export function useListSiteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSiteOptionsQuery, Types.ListSiteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSiteOptionsQuery, Types.ListSiteOptionsQueryVariables>(
    ListSiteOptionsDocument,
    options,
  );
}
export type ListSiteOptionsQueryHookResult = ReturnType<typeof useListSiteOptionsQuery>;
export type ListSiteOptionsLazyQueryHookResult = ReturnType<typeof useListSiteOptionsLazyQuery>;
export type ListSiteOptionsQueryResult = Apollo.QueryResult<
  Types.ListSiteOptionsQuery,
  Types.ListSiteOptionsQueryVariables
>;
export const ListSitesDocument = gql`
  query listSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          total
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
        siteStatusId
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;

/**
 * __useListSitesQuery__
 *
 * To run a query within a React component, call `useListSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListSitesQuery, Types.ListSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSitesQuery, Types.ListSitesQueryVariables>(ListSitesDocument, options);
}
export function useListSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSitesQuery, Types.ListSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSitesQuery, Types.ListSitesQueryVariables>(ListSitesDocument, options);
}
export type ListSitesQueryHookResult = ReturnType<typeof useListSitesQuery>;
export type ListSitesLazyQueryHookResult = ReturnType<typeof useListSitesLazyQuery>;
export type ListSitesQueryResult = Apollo.QueryResult<Types.ListSitesQuery, Types.ListSitesQueryVariables>;
export const ListSitesForChargerDocument = gql`
  query listSitesForCharger($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        administrativeArea
        altId
        country
        hoursOfOperation {
          custom
          from
          to
          monday {
            closed
            timeslots {
              from
              to
            }
          }
          tuesday {
            closed
            timeslots {
              from
              to
            }
          }
          wednesday {
            closed
            timeslots {
              from
              to
            }
          }
          thursday {
            closed
            timeslots {
              from
              to
            }
          }
          friday {
            closed
            timeslots {
              from
              to
            }
          }
          saturday {
            closed
            timeslots {
              from
              to
            }
          }
          sunday {
            closed
            timeslots {
              from
              to
            }
          }
        }
        latitude
        locality
        longitude
        postalCode
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteName
        siteStatusId
      }
    }
  }
`;

/**
 * __useListSitesForChargerQuery__
 *
 * To run a query within a React component, call `useListSitesForChargerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesForChargerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesForChargerQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListSitesForChargerQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListSitesForChargerQuery, Types.ListSitesForChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListSitesForChargerQuery, Types.ListSitesForChargerQueryVariables>(
    ListSitesForChargerDocument,
    options,
  );
}
export function useListSitesForChargerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSitesForChargerQuery, Types.ListSitesForChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListSitesForChargerQuery, Types.ListSitesForChargerQueryVariables>(
    ListSitesForChargerDocument,
    options,
  );
}
export type ListSitesForChargerQueryHookResult = ReturnType<typeof useListSitesForChargerQuery>;
export type ListSitesForChargerLazyQueryHookResult = ReturnType<typeof useListSitesForChargerLazyQuery>;
export type ListSitesForChargerQueryResult = Apollo.QueryResult<
  Types.ListSitesForChargerQuery,
  Types.ListSitesForChargerQueryVariables
>;
export const ListWarehouseOptionsDocument = gql`
  query listWarehouseOptions {
    listWarehouseOptions {
      altId
      warehouseName
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
    }
  }
`;

/**
 * __useListWarehouseOptionsQuery__
 *
 * To run a query within a React component, call `useListWarehouseOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWarehouseOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWarehouseOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWarehouseOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListWarehouseOptionsQuery, Types.ListWarehouseOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListWarehouseOptionsQuery, Types.ListWarehouseOptionsQueryVariables>(
    ListWarehouseOptionsDocument,
    options,
  );
}
export function useListWarehouseOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWarehouseOptionsQuery, Types.ListWarehouseOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListWarehouseOptionsQuery, Types.ListWarehouseOptionsQueryVariables>(
    ListWarehouseOptionsDocument,
    options,
  );
}
export type ListWarehouseOptionsQueryHookResult = ReturnType<typeof useListWarehouseOptionsQuery>;
export type ListWarehouseOptionsLazyQueryHookResult = ReturnType<typeof useListWarehouseOptionsLazyQuery>;
export type ListWarehouseOptionsQueryResult = Apollo.QueryResult<
  Types.ListWarehouseOptionsQuery,
  Types.ListWarehouseOptionsQueryVariables
>;
export const UpdateSiteDocument = gql`
  mutation UpdateSite($siteInput: UpdateSiteInput) {
    updateSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSiteFragmentDoc}
`;
export type UpdateSiteMutationFn = Apollo.MutationFunction<Types.UpdateSiteMutation, Types.UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useUpdateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateSiteMutation, Types.UpdateSiteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSiteMutation, Types.UpdateSiteMutationVariables>(UpdateSiteDocument, options);
}
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<Types.UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSiteMutation,
  Types.UpdateSiteMutationVariables
>;
export const CreateTagDocument = gql`
  mutation createTag($input: CreateTagInput) {
    createTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers {
        edges {
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
        }
        total
      }
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<Types.CreateTagMutation, Types.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateTagMutation, Types.CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Types.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTagMutation,
  Types.CreateTagMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($deleteTagsInput: DeleteTagInput) {
    deleteTag(input: $deleteTagsInput) {
      altId
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      deleteTagsInput: // value for 'deleteTagsInput'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>(DeleteTagDocument, options);
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<Types.DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTagMutation,
  Types.DeleteTagMutationVariables
>;
export const GetTagDocument = gql`
  query getTag($input: GetTagInput, $chargersInput: ListChargersInput) {
    getTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
              host {
                hostName
              }
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
          hostName
          propertyName
        }
        total
      }
    }
  }
`;

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTagQuery, Types.GetTagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetTagQuery, Types.GetTagQueryVariables>(GetTagDocument, options);
}
export function useGetTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTagQuery, Types.GetTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetTagQuery, Types.GetTagQueryVariables>(GetTagDocument, options);
}
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>;
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>;
export type GetTagQueryResult = Apollo.QueryResult<Types.GetTagQuery, Types.GetTagQueryVariables>;
export const ListTagsDocument = gql`
  query listTags($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
        description
        chargers {
          total
        }
        filters {
          criteria
          exclude
          values {
            label
            valid
            value
          }
        }
      }
      page
      pageSize
      total
      sort {
        tagName
      }
      search {
        tagName {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListTagsQuery, Types.ListTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTagsQuery, Types.ListTagsQueryVariables>(ListTagsDocument, options);
}
export function useListTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTagsQuery, Types.ListTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTagsQuery, Types.ListTagsQueryVariables>(ListTagsDocument, options);
}
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>;
export type ListTagsLazyQueryHookResult = ReturnType<typeof useListTagsLazyQuery>;
export type ListTagsQueryResult = Apollo.QueryResult<Types.ListTagsQuery, Types.ListTagsQueryVariables>;
export const ListTagsOptionsDocument = gql`
  query listTagsOptions($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
      }
      page
      pageSize
      total
    }
  }
`;

/**
 * __useListTagsOptionsQuery__
 *
 * To run a query within a React component, call `useListTagsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsOptionsQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListTagsOptionsQuery, Types.ListTagsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTagsOptionsQuery, Types.ListTagsOptionsQueryVariables>(
    ListTagsOptionsDocument,
    options,
  );
}
export function useListTagsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTagsOptionsQuery, Types.ListTagsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTagsOptionsQuery, Types.ListTagsOptionsQueryVariables>(
    ListTagsOptionsDocument,
    options,
  );
}
export type ListTagsOptionsQueryHookResult = ReturnType<typeof useListTagsOptionsQuery>;
export type ListTagsOptionsLazyQueryHookResult = ReturnType<typeof useListTagsOptionsLazyQuery>;
export type ListTagsOptionsQueryResult = Apollo.QueryResult<
  Types.ListTagsOptionsQuery,
  Types.ListTagsOptionsQueryVariables
>;
export const ListTagsTotalDocument = gql`
  query listTagsTotal($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      total
    }
  }
`;

/**
 * __useListTagsTotalQuery__
 *
 * To run a query within a React component, call `useListTagsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsTotalQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListTagsTotalQuery, Types.ListTagsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTagsTotalQuery, Types.ListTagsTotalQueryVariables>(ListTagsTotalDocument, options);
}
export function useListTagsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTagsTotalQuery, Types.ListTagsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTagsTotalQuery, Types.ListTagsTotalQueryVariables>(
    ListTagsTotalDocument,
    options,
  );
}
export type ListTagsTotalQueryHookResult = ReturnType<typeof useListTagsTotalQuery>;
export type ListTagsTotalLazyQueryHookResult = ReturnType<typeof useListTagsTotalLazyQuery>;
export type ListTagsTotalQueryResult = Apollo.QueryResult<Types.ListTagsTotalQuery, Types.ListTagsTotalQueryVariables>;
export const UpdateTagDocument = gql`
  mutation updateTag($input: UpdateTagInput, $chargersInput: ListChargersInput) {
    updateTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerName
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
            }
          }
        }
        page
        pageSize
        sort {
          cid
          fieldStationStatus
          chargerName
        }
        total
      }
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<Types.UpdateTagMutation, Types.UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateTagMutation, Types.UpdateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateTagMutation, Types.UpdateTagMutationVariables>(UpdateTagDocument, options);
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<Types.UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTagMutation,
  Types.UpdateTagMutationVariables
>;
export const ValidateTagDocument = gql`
  query validateTag($input: ValidateTagInput, $chargersInput: ListChargersInput) {
    validateTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
              host {
                altId
              }
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
          hostName
          propertyName
        }
        total
      }
    }
  }
`;

/**
 * __useValidateTagQuery__
 *
 * To run a query within a React component, call `useValidateTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useValidateTagQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ValidateTagQuery, Types.ValidateTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ValidateTagQuery, Types.ValidateTagQueryVariables>(ValidateTagDocument, options);
}
export function useValidateTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidateTagQuery, Types.ValidateTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ValidateTagQuery, Types.ValidateTagQueryVariables>(ValidateTagDocument, options);
}
export type ValidateTagQueryHookResult = ReturnType<typeof useValidateTagQuery>;
export type ValidateTagLazyQueryHookResult = ReturnType<typeof useValidateTagLazyQuery>;
export type ValidateTagQueryResult = Apollo.QueryResult<Types.ValidateTagQuery, Types.ValidateTagQueryVariables>;
export const ListTransactionV2DetailsDocument = gql`
  query listTransactionV2Details($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      total
      edges {
        amount
        createdAt
        tax
        charger {
          altId
          chargerName
          brand
        }
        type {
          columnValue
        }
        session {
          sessionDuration
          energyDelivered
          altId
          vendorId
          connector {
            altId
            connectorType {
              columnValue
            }
          }
          plan {
            planName
            chargerGroup {
              altId
            }
            chargerGroup {
              tariff {
                altId
                tariffName
              }
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          meterStart
          meterEnd
        }
        altId
        items {
          unitPrice
          amount
          altId
          productType {
            columnValue
            columnText
          }
          quantity
        }
      }
    }
  }
`;

/**
 * __useListTransactionV2DetailsQuery__
 *
 * To run a query within a React component, call `useListTransactionV2DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionV2DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionV2DetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionV2DetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListTransactionV2DetailsQuery,
    Types.ListTransactionV2DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTransactionV2DetailsQuery, Types.ListTransactionV2DetailsQueryVariables>(
    ListTransactionV2DetailsDocument,
    options,
  );
}
export function useListTransactionV2DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListTransactionV2DetailsQuery,
    Types.ListTransactionV2DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTransactionV2DetailsQuery, Types.ListTransactionV2DetailsQueryVariables>(
    ListTransactionV2DetailsDocument,
    options,
  );
}
export type ListTransactionV2DetailsQueryHookResult = ReturnType<typeof useListTransactionV2DetailsQuery>;
export type ListTransactionV2DetailsLazyQueryHookResult = ReturnType<typeof useListTransactionV2DetailsLazyQuery>;
export type ListTransactionV2DetailsQueryResult = Apollo.QueryResult<
  Types.ListTransactionV2DetailsQuery,
  Types.ListTransactionV2DetailsQueryVariables
>;
export const ListTransactionsV2Document = gql`
  query listTransactionsV2($input: ListTransactionsV2Input) {
    listTransactionsV2(input: $input) {
      total
      edges {
        id
        amount
        createdAt
        tax
        vendorId
        type {
          columnValue
        }
        session {
          accepted
          energyDelivered
          corrupted
          startTime
          endTime
          plan {
            planName
            altId
          }
        }
        items {
          comments
        }
        altId
      }
    }
  }
`;

/**
 * __useListTransactionsV2Query__
 *
 * To run a query within a React component, call `useListTransactionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionsV2Query(
  baseOptions?: Apollo.QueryHookOptions<Types.ListTransactionsV2Query, Types.ListTransactionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListTransactionsV2Query, Types.ListTransactionsV2QueryVariables>(
    ListTransactionsV2Document,
    options,
  );
}
export function useListTransactionsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListTransactionsV2Query, Types.ListTransactionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListTransactionsV2Query, Types.ListTransactionsV2QueryVariables>(
    ListTransactionsV2Document,
    options,
  );
}
export type ListTransactionsV2QueryHookResult = ReturnType<typeof useListTransactionsV2Query>;
export type ListTransactionsV2LazyQueryHookResult = ReturnType<typeof useListTransactionsV2LazyQuery>;
export type ListTransactionsV2QueryResult = Apollo.QueryResult<
  Types.ListTransactionsV2Query,
  Types.ListTransactionsV2QueryVariables
>;
export const AuthenticateUserDocument = gql`
  mutation authenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input) {
      token
      user {
        id: altId
        username
        userRole
      }
    }
  }
`;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
  Types.AuthenticateUserMutation,
  Types.AuthenticateUserMutationVariables
>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AuthenticateUserMutation, Types.AuthenticateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AuthenticateUserMutation, Types.AuthenticateUserMutationVariables>(
    AuthenticateUserDocument,
    options,
  );
}
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<Types.AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.AuthenticateUserMutation,
  Types.AuthenticateUserMutationVariables
>;
export const CreateAccountVehicleDocument = gql`
  mutation createAccountVehicle($accountVehicleInput: CreateAccountVehicleInput) {
    createAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      nickName
      vin
      macAddress
      deletedAt
    }
  }
`;
export type CreateAccountVehicleMutationFn = Apollo.MutationFunction<
  Types.CreateAccountVehicleMutation,
  Types.CreateAccountVehicleMutationVariables
>;

/**
 * __useCreateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useCreateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountVehicleMutation, { data, loading, error }] = useCreateAccountVehicleMutation({
 *   variables: {
 *      accountVehicleInput: // value for 'accountVehicleInput'
 *   },
 * });
 */
export function useCreateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAccountVehicleMutation,
    Types.CreateAccountVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateAccountVehicleMutation, Types.CreateAccountVehicleMutationVariables>(
    CreateAccountVehicleDocument,
    options,
  );
}
export type CreateAccountVehicleMutationHookResult = ReturnType<typeof useCreateAccountVehicleMutation>;
export type CreateAccountVehicleMutationResult = Apollo.MutationResult<Types.CreateAccountVehicleMutation>;
export type CreateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAccountVehicleMutation,
  Types.CreateAccountVehicleMutationVariables
>;
export const ListVehiclesDocument = gql`
  query listVehicles($vehiclesInput: ListVehiclesInput) {
    listVehicles(input: $vehiclesInput) {
      edges {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      pageSize
      total
    }
  }
`;

/**
 * __useListVehiclesQuery__
 *
 * To run a query within a React component, call `useListVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVehiclesQuery({
 *   variables: {
 *      vehiclesInput: // value for 'vehiclesInput'
 *   },
 * });
 */
export function useListVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ListVehiclesQuery, Types.ListVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListVehiclesQuery, Types.ListVehiclesQueryVariables>(ListVehiclesDocument, options);
}
export function useListVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ListVehiclesQuery, Types.ListVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListVehiclesQuery, Types.ListVehiclesQueryVariables>(ListVehiclesDocument, options);
}
export type ListVehiclesQueryHookResult = ReturnType<typeof useListVehiclesQuery>;
export type ListVehiclesLazyQueryHookResult = ReturnType<typeof useListVehiclesLazyQuery>;
export type ListVehiclesQueryResult = Apollo.QueryResult<Types.ListVehiclesQuery, Types.ListVehiclesQueryVariables>;
